import React, { useContext, useEffect, useRef, useState } from 'react';
import './style.scss';
import ScreenHeader from '../../../components/logged/ScreenHeader';
import { getDashboardRoute } from '../../../dashboard/Dashboard';
import Button, { ButtonColor, ButtonStyle } from '../../../components/Button';
import { IconAdd, IconAdd2x, IconButtonRemove, IconButtonRemove2x, IconEdit, IconEdit2x, IconSelectArrow, IconSelectArrow2x } from '../../../components/images';
import { ENUM_SHORTCUT } from 'erva-doce-common';
import Table from '../../../components/Table';
import FeedbackFormModal from './FeedbackFormModal';
import { EnvironmentContext } from '../../../contexts/EnviromentContext';
import * as FeedbackService from '../../../services/FeedbackService';
import { DateTime } from 'luxon';

const initialState = {
    name: '',
    occupation: '',
    comment: '',
    active: false,
};

export default function Feedbacks() {
    const newFeedbackRef = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedFeedback, setSelectedFeedback] = useState(); 
    const [feedbacks, setFeedbacks] = useState();
    const { backendConnectionError } = useContext(EnvironmentContext);

    const title = 'Feedbacks';

    useEffect(() => {
        if (selectedFeedback) {
            setShowModal(true);
        }
    }, [selectedFeedback]);

    const getFeebacks = async () => {
        try {
            const data = await FeedbackService.getAll();
            setFeedbacks(data);
        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to fetch feedbacks', err, null, title);
        } finally {
            setIsLoading(false);
        } 
    };

    const updateOrder = async (feedback, newOrder) => {
        try {
            setIsLoading(true);

            if (newOrder > feedbacks.length - 1 || newOrder < 0) {
                return;
            }

            const data = {
                ...feedback,
                order: newOrder,
            };

            await FeedbackService.update(feedback.uuid, data);

            getFeebacks();
        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to fetch update feedback order', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    const deleteFeedback = async (uuid) => {
        try {
            setIsLoading(true);
            
            await FeedbackService.destroy(uuid);

            getFeebacks();
        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to fetch destroy feedback', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getFeebacks();
    }, []);

    const renderTable = () => {
        return (
            <>
                <Table
                    loading={isLoading}
                    columns={[
                        {
                            width: '20px',
                        },
                        {
                            name: 'Comentário',
                            align: 'left',
                            width: '40%',
                        },
                        {
                            name: 'Nome',
                            align: 'center',
                        },
                        {
                            name: 'Profissão',
                            align: 'center',
                        },
                        {
                            name: 'Data criação',
                            align: 'center',
                        },
                        {
                            name: 'Situação',
                            align: 'center',
                        },
                        {
                            name: 'Ordenação',
                            align: 'center',
                        },
                        {
                            name: 'Ações',
                            align: 'center',
                        },
                    ]}
                >
                    {feedbacks?.map((feedback, idx) => (
                        <tr key={idx}>
                            <td className={'text-start'}>
                                <div className={'d-flex flex-column'}>
                                    <Button
                                        className={'transparent'}
                                        onClick={() => updateOrder(feedback, idx - 1)}
                                        disabled={idx === 0 || isLoading}
                                    >
                                        <img
                                            src={IconSelectArrow}
                                            srcSet={`${IconSelectArrow} 1x, ${IconSelectArrow2x} 2x`}
                                            alt={'Subir ordem'}
                                            title={'Subir ordem'}
                                            style={{
                                                width: '16px',
                                                transform: 'rotateZ(180deg)'
                                            }}
                                        />
                                    </Button>
                                    <Button
                                        className={'transparent mt-8'}
                                        onClick={() => updateOrder(feedback, idx + 1)}
                                        disabled={idx === feedbacks.length - 1 || isLoading}
                                    >
                                        <img
                                            src={IconSelectArrow}
                                            srcSet={`${IconSelectArrow} 1x, ${IconSelectArrow2x} 2x`}
                                            alt={'Descer ordem'}
                                            title={'Descer ordem'}
                                            style={{
                                                width: '16px',
                                            }}
                                        />
                                    </Button>
                                </div>
                            </td>
                            <td className={'text-start'}>{feedback.comment}</td>
                            <td className={'text-center'}>{feedback.name}</td>
                            <td className={'text-center'}>{feedback.occupation}</td>
                            <td className={'text-center'}>
                                {DateTime.fromISO(feedback?.createdAt).toFormat('dd/MM/yyyy')}
                            </td>
                            <td className={'text-center'}>
                                {feedback?.active ? 'Ativo' : 'Inativo'}
                            </td>
                            <td className={'text-center'}>{feedback.order}</td>
                            <td className={'text-center'}>
                                <Button
                                    className={'transparent mt-8'}
                                    onClick={() => setSelectedFeedback(feedback)}
                                >
                                    <img
                                        src={IconEdit}
                                        srcSet={`${IconEdit} 1x, ${IconEdit2x} 2x`}
                                        alt={'Editar banner'}
                                        title={'Editar banner'}
                                        style={{
                                            width: '16px',
                                        }}
                                    />
                                </Button>
                                <Button
                                    className={'transparent mt-8 ml-8'}
                                    onClick={() => deleteFeedback(feedback.uuid)}
                                >
                                    <img
                                        src={IconButtonRemove}
                                        srcSet={`${IconButtonRemove} 1x, ${IconButtonRemove2x} 2x`}
                                        alt={'Excluir feedback'}
                                        title={'Excluir feedback'}
                                        style={{
                                            width: '10px',
                                        }}
                                    />
                                </Button>
                            </td>
                        </tr>
                    ))}
                </Table>
            </>
        );
    };

    return (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Loja virtual', route: getDashboardRoute() },
                        { name: 'Marketing', route: getDashboardRoute() },
                        { name: title, route: getFeedbacksRoute() },
                    ]}
                    hideStore
                />
                <div className={'row justify-content-end mb-32'}>
                    <div className={'col-6 col-md-4'} />
                    <div className={'hide-mobile col-md-6'} />
                    <div className={'col-6 col-md-2'}>
                        <Button
                            buttonStyle={ButtonStyle.BUTTON_SHADOW}
                            color={ButtonColor.BUTTON_COLOR_GREEN}
                            icon={IconAdd}
                            icon2x={IconAdd2x}
                            onClick={() => {
                                setSelectedFeedback(initialState);
                            }}
                            ref={newFeedbackRef}
                            className={'w-100'}
                        >
                            {`Novo comentário [${ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY}]`}
                        </Button>
                    </div>
                </div>

                {renderTable()}
            </div>
            <FeedbackFormModal
                show={showModal}
                initialData={selectedFeedback}
                onConfirm={() => {
                    setShowModal(false);
                    setSelectedFeedback(null);
                    getFeebacks();
                }}
                onCancel={() => {
                    setShowModal(false);
                    setSelectedFeedback(null);
                }}
            />
        </>
    );
}

export function getFeedbacksRoute() {
    return '/loja-virtual/marketing/feedbacks';
}