import { useContext, useEffect, useState } from 'react';
import FieldCpf from '../../components/FieldCpf';
import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import FieldText from '../../components/FieldText';
import './CustomersFormModal.scss';
import { Loading, LoadingSize } from '../../components/Loading';
import * as CustomerService from '../../services/CustomerService';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import FieldPhone from '../../components/FieldPhone';
import { isValidEmail, unmaskCpf, unmaskPhone, ValidationErrorCustomerText } from 'erva-doce-common';
import FieldAddress, { emptyAddressData, getAddressObject, getAddressData } from '../../components/FieldAddress';

export default function CustomersFormModal({
    show,
    onCancel,
    onConfirm,
    value,
    disabledCPF,
    nameIsRequired,
    phoneIsRequired,
    emailIsRequired,
    addressIsRequired=true,
    disabledField=true,
    cancelEsc=false,
    customerUuid
}) {
    const emptyFormData = {
        id: '',
        uuid: '',
        name: '',
        lastName: '',
        cpf: '',
        phone: '',
        email: '',
    };
    const [formData, setFormData] = useState(emptyFormData);
    const emptyFormError = emptyFormData;
    const [formError, setFormError] = useState(emptyFormError);
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [title, setTitle] = useState('');
    const [loadingCustomer, setLoadingCustomer] = useState(false);
    const { backendConnectionError, } = useContext(EnvironmentContext);
    const [addressData, setAddressData] = useState(emptyAddressData);

    function updateFormData(data) {
        setFormData((formData) => ({ ...formData, ...data }));
    }

    async function confirm() {
        if (hasValidationError()) {
            return;
        }

        const customer = await createOrUpdateSimpleCustomer({
            ...formData,
            cpf: unmaskCpf(formData.cpf),
            address: getAddressObject(addressData),
            phone: unmaskPhone(formData.phone),
        });

        updateFormData(customer);
        onConfirm(customer);
    }

    function hasValidationError() {
        let hasError = false;
        setFormError(emptyFormError);
        setValidateOnChange(true);

        if (nameIsRequired && !formData.name?.trim()) {
            hasError = true;
            setFormError((formError) => ({ ...formError, name: 'Digite o nome do cliente' }));
        }

        if (nameIsRequired && !formData.lastName?.trim()) {
            hasError = true;
            setFormError((formError) => ({ ...formError, lastName: 'Digite o sobrenome do cliente' }));
        }

        if (!formData.cpf?.trim()) {
            hasError = true;
            setFormError((formError) => ({ ...formError, description: 'Digite o cpf do cliente' }));
        }

        if (phoneIsRequired && !formData.phone?.trim()) {
            hasError = true;
            setFormError((formError) => ({ ...formError, phone: 'Digite o telefone do cliente' }));
        }

        if (
            emailIsRequired 
            && !formData.email?.trim()
            || !isValidEmail(formData.email)
        ) {
            hasError = true;
            setFormError((formError) => ({ ...formError, email: 'Digite o email do cliente' }));
        }

        if (addressIsRequired && (!addressData.address || !addressData.city || !addressData.neighborhood || !addressData.state || !addressData.number || !addressData.zipCode)) {
            hasError = true;
            setFormError((formError) => ({ ...formError, address: 'Preencha o endereço' }));
        }

        return hasError;
    }

    async function fetchCustomerByCpf(cpf) {
        try {
            setLoadingCustomer(true);
            const customer = await CustomerService.getCustomerByCpf(unmaskCpf(cpf));

            if(!customer?.uuid) {
                setTitle('Cadastrar cliente');
                setFormData({ ...emptyFormData, cpf: value });

                if (customerUuid && !customer.address) {
                    const customerByUuid = await CustomerService.getCustomerByUuid(customerUuid);

                    setFormData({ ...customerByUuid });
                    setAddressData({});
                }
            } else {
                setTitle('Confirmar Cliente');
                setFormData({
                    id: customer.id,
                    uuid: customer.uuid,   
                    name: customer.name,
                    lastName: customer.lastName,
                    cpf: customer.cpf,
                    phone: customer.phone,
                    email: customer.email
                });
                setAddressData(getAddressData(customer.address != null ? customer.address : emptyAddressData ));
            }
        } catch (e) {
            const { response } = e;
            if (response?.status === 404) {
            } else {
                backendConnectionError('Fail to fetch product', e, null, title);
            }
        } finally {
            setLoadingCustomer(false);
        }
    }

    async function createOrUpdateSimpleCustomer(data) {
        try {
            const newUser = await CustomerService.createOrUpdateSimpleCustomer(data);
            return newUser;
        } catch (e) {
            backendConnectionError('Fail to create/edit classificação fiscal', e, null, title, ValidationErrorCustomerText);
        }
    }

    useEffect(() => {
        setFormError({});
        setValidateOnChange(false);

        if (show && value) {
            updateFormData({ cpf: value });
            fetchCustomerByCpf(value);
        }
    }, [show, value]);

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [formData]);

    if (loadingCustomer) {
        return (
            <Loading
                size={LoadingSize.LARGE}
            />
        );
    } else {
        return (
            <SimpleConfirmModal
                show={show}
                onCancel={() => onCancel()}
                onConfirm={confirm}
                title={title}
                className={'customer-model'}
                cancelEsc={cancelEsc}
            >
                <div className={'container-customer'}>
                    <div className={'fullname'}>
                        <FieldText
                            className={'gd-col-6'}
                            label={nameIsRequired ? 'Digite o nome do cliente (*campo obrigatório)' : 'Digite o nome do cliente'}
                            value={formData?.name}
                            onChange={({ target }) =>
                                updateFormData({
                                    name: target.value,
                                })
                            }
                            validationError={formError?.name}
                            disabled={disabledField}
                        />
                        <FieldText
                            className={'gd-col-6'}
                            label={nameIsRequired ? 'Digite o sobrenome do cliente (*campo obrigatório)' : 'Digite o sobrenome do cliente'}
                            value={formData?.lastName}
                            onChange={({ target }) =>
                                updateFormData({
                                    lastName: target.value,
                                })
                            }
                            validationError={formError?.lastName}
                            disabled={disabledField}
                        />
                    </div>
                    
                    <div className={'infos'}>
                        <div className={'gd-col-3'}>
                            <FieldCpf
                                label={'CPF (*campo obrigatório)'}
                                placeholder={'Informe o CPF do cliente'}
                                value={formData.cpf}
                                onDisabled={disabledCPF}
                                validationError={formError?.cpf}
                            />
                        </div>

                        <div className={'gd-col-3'}>
                            <FieldPhone
                                label={phoneIsRequired ? 'Digite o telefone do cliente (*campo obrigatório)' : 'Digite o telefone do cliente'}
                                onChange={({ target }) => updateFormData({ phone: target.value })}
                                value={formData?.phone}
                                validationError={formError?.phone}
                                disabled={disabledField}
                            />
                        </div>

                        <div className={'gd-col-6'}>
                            <FieldText
                                label={emailIsRequired ? 'Digite o email do cliente (*campo obrigatório)' : 'Digite o email do cliente'}
                                value={formData?.email}
                                onChange={({ target }) =>
                                    updateFormData({
                                        email: target.value,
                                    })
                                }
                                validationError={formError?.email}
                                disabled={disabledField}
                            />
                        </div>
                    </div>

                    <div className={'gd-col-12 address'}>
                        <FieldAddress
                            label={addressIsRequired ? 'Endereço principal (*campo obrigatório)' : 'Endereço principal'}
                            value={addressData}
                            onChange={(addressData) => {
                                setAddressData(addressData);
                            }}
                            validationError={formError?.address}
                            disabled={disabledField}
                        />
                    </div>
                </div>
            </SimpleConfirmModal>
        );
    }
}
