import { formatValue } from '../util/formatValue';
import FieldInteger from './FieldInteger';

function ProductDevolutionList({ productsExchange }) {
    return (
        <div className={'payment-list'}>
            <div className={'gd field-group payment-header'}>
                <div className={'gd-col-6'}>
                    <div className={'container-title'}>
                        {/*I18N*/}
                        <span>{'Descrição'}</span>
                    </div>
                </div>
                <div className={'gd-col-2'}>
                    <div className={'container-title text-center'}>
                        {/*I18N*/}
                        <span>{'Quantidade'}</span>
                    </div>
                </div>
                <div className={'gd-col-2'}>
                    <div className={'container-title text-center'}>
                        {/*I18N*/}
                        <span>{'Valor unitário'}</span>
                    </div>
                </div>
                <div className={'gd-col-2'}>
                    <div className={'container-title text-center'}>
                        {/*I18N*/}
                        <span>{'Valor Total'}</span>
                    </div>
                </div>
            </div>
            {productsExchange?.map((product, index) => {                
                return (
                    <div key={index} className={'gd field-group'}>
                        <div className={'gd-col-6'}>
                            <span>{product.name1}</span>
                        </div>
                        <div className={'gd-col-2'}>
                            <FieldInteger
                                // I18N
                                fieldGroup={false}
                                readOnly={true}
                                value={product.amount}
                                className={'field-quantity-product'}
                            />
                        </div>
                        <div 
                            className={'gd-col-2'}
                            style={{ display: 'flex', justifyContent: 'center' }}
                        >
                            <span>{formatValue(`${product.price}`)}</span>
                        </div>
                        <div 
                            className={'gd-col-2'}
                            style={{ display: 'flex', justifyContent: 'center' }}
                        >
                            <span>{formatValue(product.amount * Number(product.price))}</span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default ProductDevolutionList;
