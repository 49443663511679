import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import PropTypes from 'prop-types';
import './PermissionedLink.scss';

function PermissionedLink({ to, children, permission, className }) {
    const { user } = useContext(EnvironmentContext);

    const hasPermission =
        user?.isAdmin ||
        (Array.isArray(user?.roles) && (
            Array.isArray(permission)
                ? user.roles.some(role => permission.includes(role))
                : user.roles.includes(permission)
        ));

    if (!children) {
        return <span className={className}>{'-'}</span>;
    }

    if (!hasPermission) {
        return <span className={className}>{children}</span>;
    }

    return (
        <div className={'permissioned-link-container'}>
            <Link
                onClick={(e) => e.stopPropagation()}
                target={'_blank'}
                to={to}
                className={`permissioned-link ${className || ''}`}
            >
                {children}
            </Link>
        </div>
    );
}

PermissionedLink.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node,
    permission: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
    className: PropTypes.string
};

export default PermissionedLink;