export function calculateMarginForProducts(value: {
  price: number;
  cost: number;
}): number {
    if (!value.price || !value.cost) {
        return 0;
    }

    if (value.price === 0) return 0;

    const marginPercentage = (
        ((value.price - value.cost) / value.price) *
    100
    ).toFixed(2);
    return Number(marginPercentage);
}
