import api from '../util/api';

export async function createVoucher (formData) {
    return await api.post('/vouchers', formData);
}


export async function getVouchers (   
    page,
    search,
    order,
    date,
    cashier,
    collaborator,
    role,
    status,
    cancelToken) {

    return (await api.get('/vouchers', {
        params: {
            page,
            search,
            order,
            date,
            cashier,
            collaborator,
            role,
            status
        },
        cancelToken: cancelToken
    })).data;
}

export async function RedeemVoucher (uuid) {
    return await api.put(`/vouchers/${uuid}/redeem`);
}