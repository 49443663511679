import { formatValue } from '../../../util/formatValue';
import { DateTime } from 'luxon';
import { VirtualOrderStatus } from 'erva-doce-common';
import { getCustomerFormRoute } from '../../customers/CustomerForm';
import { Link } from 'react-router-dom';

export default function SummaryTab({ data, virtualTotal }) {
    const deliveryDateFormatted = DateTime.fromISO(data?.deliveryDate || data.createdAt).toFormat('yyyy-LL-dd');

    return (
        <div className={'virtual-order-form-container'}>
            <div className={'row'}>
                <div className={'col-4'}>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <h2 className={'mb-10'}>{'Resumo'}</h2>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <div>
                                <p>
                                    {'O pedido de N° '}
                                    <strong>{(data.id).toString().padStart(7, '0')}</strong> {' '}
                                    {`foi gerado em ${DateTime.fromISO(data.createdAt).toFormat('dd/MM/yyyy')}, `} {'  '}
                                    {'no valor de'} <strong>{formatValue(virtualTotal || 0)}</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={'row mt-42'}>
                        <div className={'col-12'}>
                            <h2 className={'mb-10'}>{'Atualização'}</h2>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <div className={'mr-20'}>
                                <p><strong>{'Última atualização do pedido'}</strong></p>
                                <p>{data.updatedAt && DateTime.fromISO(data.updatedAt).toFormat('dd/MM/yyyy') || '-'}</p>
                            </div>
                        </div>
                    </div>
                    <div className={'row mt-20'}>
                        <div className={'col-12'}>
                            <div>
                                <p><strong>{'Previsão de entrega'}</strong></p>
                                <p>
                                    {deliveryDateFormatted
                                        ? DateTime.fromFormat(deliveryDateFormatted, 'yyyy-MM-dd').toFormat('dd/MM/yyyy')
                                        : 'sem previsão'
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'col-8'}>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <h2 className={'mb-10'}>{'Cliente'}</h2>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <div className={'mr-20'}>
                                <p><strong>{'Nome'}</strong></p>
                                <Link
                                    className={'product-name'}
                                    onClick={(e) => e.stopPropagation()}
                                    target={'_blank'}
                                    to={getCustomerFormRoute(data.customerUuid)}
                                >
                                    {data.customerName} {'-'} {data.customerCpf}
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className={'row mt-42'}>
                        <div className={'col-12'}>
                            <h2 className={'mb-10'}>{'Estado do pedido'}</h2>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <div>
                                <p><strong>{VirtualOrderStatus(data.status)}</strong></p>
                                <p>
                                    {DateTime.fromISO(
                                        data.statusHistory.find(s => s.status === data.status)?.createdAt
                                        || data.createdAt
                                    ).toFormat('dd/MM/yyyy') || '-'
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
