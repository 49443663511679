import { useEffect, useState } from 'react';
import SimpleConfirmModal from '../../../components/modal/SimpleConfirmModal';
import FieldCpf from '../../../components/FieldCpf';

export default function VirtualOrdersFilterModal({
    show,
    onCancel,
    onConfirm,
    filter,
}) {
    const [formData, setFormData] = useState(filter);

    const confirm = () => {
        onConfirm(formData);
    };

    useEffect(() => {
        setFormData(filter);
    }, [filter]);

    const updateFormData = (item) => {
        setFormData({
            ...formData,
            ...item,
        });
    };

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={onCancel}
            onConfirm={confirm}
            // I18N
            title={'Filtrar'}
        >
            <div className={'filter_modal'}>
                <FieldCpf
                    label={'CPF'}
                    placeholder={'Informe o CPF do cliente'}
                    value={formData.customerCpf}
                    onChange={({ target }) =>
                        updateFormData({ customerCpf: target.value.replace(/[.-]/g, '') })
                    }
                />
            </div>
        </SimpleConfirmModal>
    );
}
