import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import { useState } from 'react';
import Pills from '../../components/Pills';
import FieldJobTitle from '../../components/FieldJobTitle';
import FieldStore from '../../components/FieldStore';
import ToolTip from '../../components/ToolTip';
import classNames from 'classnames';
import './CollaboratorsFilterModal.scss';
import { UserStatusEnum } from 'erva-doce-common';

function CollaboratorsFilterModal({
    show,
    onCancel,
    onConfirm,
    filter,
}) {
    const [jobsTitles, setJobsTitles] = useState(filter?.jobsTitles ?? []);
    const [stores, setStores] = useState(filter?.stores ?? []);
    const [collaboratorsActive, setCollaboratorsActive] = useState(filter.collaboratorsActive ?? '');
    const [collaboratorsInactive, setCollaboratorsInactive] = useState(filter.collaboratorsInactive ?? '');


    function confirm() {
        onConfirm({
            jobsTitles,
            stores,
            collaboratorsActive,
            collaboratorsInactive,
        });
    }

    function cancel() {
        onCancel();
        setJobsTitles(filter.jobsTitles);
        setStores(filter.stores);
    }

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={cancel}
            onConfirm={confirm}
            // I18N
            title={'Filtrar colaboradores'}
        >
            <div className={'gd'}>
                <div className={'gd-col gd-col-12'}>
                    <FieldJobTitle
                        // I18N
                        label={'Função'}
                        multipleSelection={true}
                        onAddItem={(record) => {
                            setJobsTitles((jobsTitles) => {
                                return [
                                    ...jobsTitles,
                                    record
                                ];
                            });
                        }}
                        removeButton={true}
                        ignoreItems={jobsTitles.map(record => record.id)}
                    >
                        <Pills
                            readOnly={true}
                            pills={jobsTitles}
                            onRemoveItem={(record) => {
                                setJobsTitles((jobsTitles) => {
                                    return jobsTitles.filter(item => item.id !== record.id);
                                });
                            }}
                        />
                    </FieldJobTitle>
                </div>
            </div>
            <div className={'gd'}>
                <div className={'gd-col gd-col-12'}>
                    <FieldStore
                        // I18N
                        label={'Loja'}
                        multipleSelection={true}
                        removeButton={true}
                        onAddItem={(record) => {
                            setStores((stores) => {
                                return [
                                    ...stores,
                                    record
                                ];
                            });
                        }}
                        ignoreItems={stores.map(record => record.id)}
                    >
                        <Pills
                            readOnly={true}
                            pills={stores}
                            onRemoveItem={(record) => {
                                setStores((store) => {
                                    return store.filter(item => item.id !== record.id);
                                });
                            }}
                        />
                    </FieldStore>
                </div>
            </div>
            <div className={'gd'}>
                <div className={'gd-col gd-col-12'}>

                    <div className={'col-12 align-left mt-20 tooltip-collaborators'}>
                        <label className={'color-black mb-20'}>
                            {'Colaboradores'}
                        </label>
                        <ToolTip
                            description={'Filtra pelos colaboradores ativos'}
                        />
                    </div>
                    <div className={'col-12 d-flex mb-35'}>
                        <div
                            className={classNames('filter-collaborators-button mr-20', { 'active': collaboratorsActive === UserStatusEnum.ACTIVE })}
                            onClick={() => {
                                setCollaboratorsActive((prev) =>
                                    prev === UserStatusEnum.ACTIVE ? '' : UserStatusEnum.ACTIVE
                                );
                            }}
                        >
                            {'Ativo'}
                        </div>
                        <div
                            className={classNames('filter-collaborators-button mr-20', { 'active': collaboratorsInactive === UserStatusEnum.INACTIVE })}
                            onClick={() => {
                                setCollaboratorsInactive((prev) =>
                                    prev === UserStatusEnum.INACTIVE ? '' : UserStatusEnum.INACTIVE
                                );
                            }}
                        >
                            {'Inativo'}
                        </div>
                    </div>
                </div>
            </div>

        </SimpleConfirmModal>
    );
}

export default CollaboratorsFilterModal;