import './InputBox.scss';

export default function InputBox({
    value,
    onChange,
    onBlur,
    label,
    max,
    maxLenght,
}) {
    return (
        <div className={'input-box'}>
            <div className={'w-50'}>
                <input 
                    type={'number'} 
                    min={15} 
                    max={max} 
                    value={value} 
                    onChange={onChange} 
                    onBlur={onBlur} 
                    maxLength={maxLenght}
                />
            </div>
            <p className={'w-50'}>
                {label}
            </p>
        </div>
    );
}
