import { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { OrderByRevisionsEnum, PackageTypeText } from 'erva-doce-common';
import { getProduct } from '../../services/ProductsService';
import { getProductRevisions } from '../../services/RevisionsService';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import { getProductsRoute } from './Products';
import Table from '../../components/Table';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import { Loading, LoadingSize } from '../../components/Loading';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import Pagination from '../../components/Pagination';
import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import { getProductFormRoute } from './ProductsForm';

export function getProductLogsRoute(uuid) {
    return `/produtos/listagem/${uuid}/logs`;
}

const operationTypes = {
    'create': 'Novo cadastro',
    'update': 'Atualização',
    'destroy': 'Exclusão'
};

const productFields = {
    'productCode': 'Código do produto',   
    'minValue': 'Valor mínimo',
    'name1': 'Nome do produto 1',
    'shortName1': 'Aplido 1',
    'minValue1': 'Valor mínimo 1',
    'name2': 'Nome do produto 2',
    'shortName2': 'Aplido 2',
    'name3': 'Nome do produto 3',
    'shortName3': 'Aplido 3',
    'description': 'Descrição completa',
    'shortDescription': 'Descrição curta',
    'budget': 'Verba (Valor negociado)',
    'brand': 'Marca',
    'segmentation': 'Segmentação',
    'category': 'Categoria',
    'subCategory': 'Sub-categoria',
    'balance': 'Estoque (TODO)',
    'family': 'Família',
    'store': 'Ativo (por loja)',
    'newPrice': 'Novo preço',
    'tag': 'Etiqueta',
    'tagQuantity': 'Quantidade de etiqueta',
    'status': 'Status',
    'isBestOfEdos': 'Queridinho da EDOS',
    'cost': 'Custo',
    'newCost': 'Novo custo',
    'stock': 'Estoque',
    //Dados pacote ou fracionados
    'packageType': 'Dados pacote ou fracionados',
    'packageQuantity': 'Quantidade no pacote',
    'minPackageQuantity': 'Quantidade mínima no pacote',
    //Fracionado
    'fractionedCode': 'Código fracionado',
    'fractionedDescription': 'Descrição fracionado',
    'fractionedQuantity': 'Quantidade',
    'fractionedCost': 'Custo',
    'fractionedMargin': 'Margem',
    'fractionedValue': 'Valor',
    //Dados fiscais
    'icms': 'ICMS',
    'taxClassification': 'Classificação fiscal',
    'taxSituation': 'Situação tributária',
    'cfop': 'CFOP',
    'ncm': 'NCM',
    //Produtos Semelhantes
    'similarProducts': 'Produtos Semelhantes',
    //representantes/fornecedores
    'representativeSupplier': 'Fornecedor e representante',
    'representative': 'Representante',
    'representativeCode': 'Código do Representante',
    'supplier': 'Fornecedor',
    'supplierCode': 'Código do fornecedor',
    'notPurchaseSuggestion': 'Sem sugestão de compra',
    //código de barras
    'barCode': 'Código de barras',
    'main': 'Código de barras principal',
};

export default function ProductLogs() {
    const navigate = useNavigate();
    const { uuid } = useParams();
    const {
        backendConnectionError,
        setInfoModal
    } = useContext(EnvironmentContext);

    const [loading, setLoading] = useState(!!uuid);
    const [product, setProduct] = useState(null);
    const [logs, setLogs] = useState([]);
    const [selectedLog, setSelectedLog] = useState(null);
    const [pagination, setPagination] = useState({
        page: 0,
        pageSize: null,
        count: null
    });
    const [filter, setFilter] = useState({
        order: OrderByRevisionsEnum.DATE_DESC,
        page: 0
    });

    useEffect(() => {
        fetchProductAndLogs();
    }, [filter]);

    async function fetchProductAndLogs() {
        try {
            setLoading(true);

            const product = await getProduct(uuid);
            const {
                count,
                page,
                pageSize,
                records
            } = await getProductRevisions(uuid, filter) || {};

            setProduct(product);
            setLogs(records.map(record => {
                const updatedFields = [];
                for(const prop in record?.document || {}){
                    if(['uuid', 'product'].includes(prop)) continue;
                    const name = productFields[prop] || prop;
                    let value = record.document[prop] || '';
                    if(prop === 'packageType' && value){
                        value = PackageTypeText(value);
                    }

                    updatedFields.push({ name, value });
                }

                let onlyNameFields = '';
                for(const [index, { name }] of updatedFields.entries()){
                    onlyNameFields += index + 1 === updatedFields.length ? name : `${name}, `;
                }

                return {
                    ...record,
                    formattedDate: format(new Date(record.date), 'dd/MM/yy'),
                    hours: format(new Date(record.date), 'HH:mm'),
                    updatedFields,
                    onlyNameFields
                };
            }));
            setPagination({
                page,
                pageSize,
                count
            });
        } catch (e) {
            const title = 'Log do sistema';
            const { response } = e;
            if (response?.status === 404) {
                setInfoModal({
                    title,
                    message: 'Produto não encontrado.',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: navigate(),
                });
            } else {
                backendConnectionError(
                    'Fail to fetch product',
                    e,
                    null,
                    title
                );
            }
        } finally {
            setLoading(false);
        }
    }

    return (<>
        {loading ?
            <Loading
                size={LoadingSize.LARGE}
            /> :
            <div className={'crud-list'}>
                <ScreenHeader
                    title={'Log do sistema'}
                    breadcrumbs={[
                        { name: 'Produtos', route: getDashboardRoute() },
                        { name: 'Listagem', route: getProductsRoute() },
                        { name: 'Detalhamento do produto', route: getProductFormRoute(uuid) },
                        { name: 'Log do sistema', route: getProductLogsRoute(uuid) }
                    ]}
                    backRoute={getProductFormRoute(uuid)}
                >
                </ScreenHeader>
                <div className={'product-table-container'} style={{ marginBottom: '16px' }}>
                    <table className={'table'}>
                        <thead>
                            <tr>
                                <th>
                                    <div style={{ textAlign: 'left' }}>
                                        {'Produto'}
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ borderRadius: '0 0 0 8px', paddingLeft: '1%', textAlign: 'left' }}>
                                    {`${[product.name1, product.name2, product.name3].join(' ')}`}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={'table-scroll'}>
                    <Table
                        loading={loading}
                        currentSort={filter.order}
                        columns={[
                            {
                                name: 'Data',
                                sortAsc: OrderByRevisionsEnum.DATE_ASC,
                                sortDesc: OrderByRevisionsEnum.DATE_DESC,
                                onSortChange: (order) => setFilter({ ...filter, order })
                            },
                            {
                                name: 'Hora',
                            },
                            {
                                name: 'Colaborador',
                                sortAsc: OrderByRevisionsEnum.USER_ASC,
                                sortDesc: OrderByRevisionsEnum.USER_DESC,
                                onSortChange: (order) => setFilter({ ...filter, order })
                            },
                            {
                                name: 'Tipo de alteração',
                                sortAsc: OrderByRevisionsEnum.OPERATION_TYPE_ASC,
                                sortDesc: OrderByRevisionsEnum.OPERATION_TYPE_DESC,
                                onSortChange: (order) => setFilter({ ...filter, order })
                            },
                            {
                                name: 'Campos atualizados',
                            },
                        ]}
                    >
                        {logs.length ? logs.map(log => {
                            return (
                                <tr
                                    key={log.id}
                                    onClick={() => setSelectedLog(log)}
                                >
                                    <td>{log.formattedDate}</td>
                                    <td>{log.hours}</td>
                                    <td>{log?.userName}</td>
                                    <td>{operationTypes[log.operation] || ''}</td>
                                    <td>{log.onlyNameFields}</td>
                                </tr>
                            );
                        }) : <></>}
                    </Table>
                    <Pagination
                        page={pagination.page}
                        pageSize={pagination.pageSize}
                        count={pagination.count}
                        recordCount={logs.length}
                        onPageChange={page => setFilter({ ...filter, page })}
                    />
                </div>
            </div>
        }
        <SimpleConfirmModal
            show={selectedLog}
            cancelText={'Fechar'}
            onCancel={() => setSelectedLog(null)}
            title={'Log detalhado'}
        >
            {selectedLog &&
                <div>
                    <div>
                        {`
                            ${operationTypes[selectedLog.operation] || ''}
                            no dia ${selectedLog.formattedDate} às ${selectedLog.hours}
                            feito por ${selectedLog.userName}.
                        `}
                    </div>
                    <div>
                        <p>{'Campos atualizados:'}</p>
                        {selectedLog.updatedFields.map(field => {
                            return (
                                <p key={field.name}>
                                    <b>{`${field.name}: `}</b>{field.value}
                                </p>
                            );
                        })}
                    </div>
                </div>
            }
        </SimpleConfirmModal>
    </>);
}
