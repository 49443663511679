import { useNavigate } from 'react-router';
import Table from '../../../components/Table';
import { formatValue } from '../../../util/formatValue';
import { DateTime } from 'luxon';   
import { DeliveryMethodTypeEnum, OrderByVirtualOrdersEnum, VirtualOrderStatusEnum } from 'erva-doce-common';
import Button, { ButtonColor, ButtonStyle } from '../../../components/Button';
import './VirtualOrder.scss';
import { getVirtualOrderDetailRoute } from '../virtual-order-detail/VirtualOrderDetail';

export default function VirtualOrdersTable({ 
    data, 
    filter, 
    isLoading, 
    onSortChange,
    onDeliveredChange,
    onSubmittedChange, 
    onSeparatingChange,
    onGenerateNFCeChange
}) {
    const navigate = useNavigate();
    const btnActions = (title, callback = () => {}) => {
        return (
            <Button
                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                color={ButtonColor.BUTTON_COLOR_GREEN}
                onClick={() => callback()}
                className={'w-80'}
            >
                {title}
            </Button>
        ); 
    };

    const handleActionsStatus = (status, deliveryType, uuid) => {
        switch (status) {
        case VirtualOrderStatusEnum.PAYMENT_APPROVED: 
            return btnActions('Iniciar separação', () => onSeparatingChange(uuid));
        case VirtualOrderStatusEnum.SEPARATING:
            return btnActions('Emitir Nota Fiscal', () => onGenerateNFCeChange(uuid));
        case VirtualOrderStatusEnum.NOTE_ISSUED:
            if (deliveryType === DeliveryMethodTypeEnum.FIX_TAX) {
                return btnActions('Pedido enviado', () => onSubmittedChange(uuid));
            } else {
                return '-';
            }
        case VirtualOrderStatusEnum.SUBMITTED:
            if (deliveryType === DeliveryMethodTypeEnum.FIX_TAX) {
                return btnActions('Pedido recebido', () => onDeliveredChange(uuid));
            } else {
                return '-';
            }
        default:
            return '-';
        }
    };

    const hasCompletedStatus = 
        data?.some(item => item.status === (VirtualOrderStatusEnum.CANCELLED));

    const columns = [
        {
            name: 'N° Pedido',
            sortAsc: OrderByVirtualOrdersEnum.ID_ASC,
            sortDesc: OrderByVirtualOrdersEnum.ID_DESC,
            onSortChange: (order) => onSortChange('order', order),
        },
        {
            name: 'Valor',
            sortAsc: OrderByVirtualOrdersEnum.TOTAL_ASC,
            sortDesc: OrderByVirtualOrdersEnum.TOTAL_DESC,
            onSortChange: (order) => onSortChange('order', order),
        },
        {
            name: 'Nome do cliente',
            sortAsc: OrderByVirtualOrdersEnum.CUSTOMER_ASC,
            sortDesc: OrderByVirtualOrdersEnum.CUSTOMER_DESC,
            onSortChange: (order) => onSortChange('order', order),
        },
        {
            name: 'Previsão Entrega',
            align: 'center',
            sortAsc: OrderByVirtualOrdersEnum.DELIVERY_DATE_ASC,
            sortDesc: OrderByVirtualOrdersEnum.DELIVERY_DATE_DESC,
            onSortChange: (order) => onSortChange('order', order),
        },
        {
            name: 'Última Atualização',
            align: 'center',
            sortAsc: OrderByVirtualOrdersEnum.LAST_UPDATE_ASC,
            sortDesc: OrderByVirtualOrdersEnum.LAST_UPDATE_DESC,
            onSortChange: (order) => onSortChange('order', order),
        },
        {
            name: 'Ações',
            align: 'center',
        },
    ];

    if (hasCompletedStatus) {
        columns.splice(-1, 0, {
            name: 'Status',
            align: 'left',
        });
    }

    return (
        <Table
            loading={isLoading}
            currentSort={filter.order}
            columns={columns}
        >
            {data?.map((item, index) => (
                <tr
                    className={'table-row'}
                    key={index}
                    onClick={() => navigate(getVirtualOrderDetailRoute(item.id))}
                >
                    <td className={'supplier'}>
                        <div>
                            <p>{`${item.id}`.padStart(7, '0')}</p>
                            <p>
                                {DateTime.fromISO(item.createdAt).toFormat('dd/MM/yyyy')}
                            </p>
                        </div>
                    </td>
                    <td>{formatValue(item.total)}</td>
                    <td>
                        <p>{`${item.customerName} - ${item.customerCpf}`}</p>
                    </td>
                    <td className={'text-center'}>
                        {item.deliveryDate
                            ? DateTime.fromISO(item.deliveryDate).toFormat('dd/MM/yyyy')
                            : DateTime.fromISO(item.createdAt).plus({ days: item.additionalDays }).toFormat('dd/MM/yyyy')
                        }
                    </td>
                    <td className={'customer'}>
                        {item?.employeeName ? (
                            <div>
                                <p>{item?.employeeName}</p>
                                <p>
                                    {DateTime.fromISO(item.updatedAt).toFormat('dd/MM/yyyy')}
                                </p>
                            </div>
                        ) : (
                            <div>
                                <p>
                                    {DateTime.fromISO(item.updatedAt).toFormat('dd/MM/yyyy')}
                                </p>
                            </div>
                        )}
                    </td>
                    {hasCompletedStatus && (
                        <td>
                            {item.status === VirtualOrderStatusEnum.CANCELLED ? 'Cancelado' :
                                item.status === VirtualOrderStatusEnum.DELIVERED ? 'Entregue' :
                                    ''}
                        </td>
                    )}
                    <td 
                        className={'text-center'}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {handleActionsStatus(
                            item.status, 
                            item?.delivery?.type, 
                            item.uuid
                        )}
                    </td>
                </tr>
            ))}
        </Table>
    );
}
