import { ENUM_SHORTCUT, ExchangeStatusEnum, ValidationErrorExchangesText, formatCpfMask } from 'erva-doce-common';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Amount from '../../components/Amount';
import Button, { ButtonColor, ButtonStyle } from '../../components/Button';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as ExchangesService from '../../services/ExchangesService';
import { formatValue } from '../../util/formatValue';
import { getExchangesRoute } from './Exchanges';
import './ExchangesForm.scss';
import FieldCpf from '../../components/FieldCpf';
import ToolTip from '../../components/ToolTip';

export default function ExchangesForm() {
    const {
        backendConnectionError,
        setInfoModal,
        addHotkey,
        removeHotkey,
        setConfirmModal,
    } = useContext(EnvironmentContext);
    const emptyFormData = {
        products: [],
        customerCpf: '',
        customerId: '',
    };

    const emptyFormError = emptyFormData;
    const [formData, setFormData] = useState(emptyFormData);
    const { uuid } = useParams();
    const [exchangeName, setExchangeName] = useState('');
    const [loading, setLoading] = useState(false);
    const [, setDeleteLoading] = useState(false);
    const deleteBtnRef = useRef();
    const [productsSelected, setProductsSelected] = useState([]);
    const navigate = useNavigate();

    async function fetchExchange() {
        try {
            setLoading(true);
            const exchange = await ExchangesService.getExchange(uuid);

            setProductsSelected(exchange.products);

            setFormData({
                ...exchange
            });
            setExchangeName(exchange.uuid);
        } catch (e) {
            const title = getTitle();
            const { response } = e;
            if (response?.status === 404) {
                back();
            } else {
                backendConnectionError('Fail to fetch exchange', e, null, title);
            }
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (uuid) {
            fetchExchange();
        }
    }, [uuid]);

    useEffect(() => {
        const shortcutCancel = addHotkey(ENUM_SHORTCUT.SHORTCUT_CANCEL, () => {
            if (deleteBtnRef.current) {
                deleteBtnRef.current.click();
            }
        });

        return () => {
            removeHotkey(shortcutCancel);
        };
    }, []);

    function back() {
        navigate(getExchangesRoute());
    }

    async function deleteExchange() {
        // I18N
        let title = 'Cancelar Devolução';
        async function proceed() {
            try {
                setDeleteLoading(true);
                await ExchangesService.deleteExchange(uuid);
                // I18N
                const message = 'Devolução cancelada com sucesso!';
                const style = InfoModalStyle.SUCCESS;
                const onClose = back;
                setInfoModal({
                    title,
                    message,
                    style,
                    show: true,
                    onClose,
                });
            } catch (e) {
                backendConnectionError('Fail to delete exchange', e, null, title, ValidationErrorExchangesText);
            } finally {
                setDeleteLoading(false);
            }
        }

        setConfirmModal({
            title,
            // I18N
            message: 'Você tem certeza que deseja cancelar a devolução?',
            onConfirm: proceed,
            show: true,
        });
    }

    function getTitle(windowTitle = false) {
        if (uuid) {
            // I18N
            let title = 'Visualizar devolução';
            if (windowTitle && exchangeName) title += ` - ${exchangeName}`;
            return title;
        }
    }

    const totalExchange = useMemo(() => {
        return formatValue((productsSelected.reduce((sum, product) => { return sum + Number(product.total); }, 0)) ?? 0);
    }, [productsSelected]);

    const totalItens = useMemo(() => {
        return productsSelected.reduce((sum, product) => { return sum + Number(product.amount); }, 0) ?? 0;
    }, [productsSelected]);

    const title = getTitle();


    return (
        <>
            <div className={'crud-form exchanges-form'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Vendas', route: getDashboardRoute() },
                        { name: 'Devoluções', route: getExchangesRoute() },
                        {
                            name: uuid
                                ? loading
                                    ? '...'
                                    : exchangeName
                                : title,
                        },
                    ]}
                    backRoute={getExchangesRoute()}
                />
                <div className={'mb-28'}>
                    <div className={'row'}>
                        <div className={'col-4 d-flex'}>
                            <div className={'quantity-field mr-4'}>
                            </div>
                        </div>
                        <div className={'col-8 align-right'}>
                            <div className={'row col-12 align-right'}>
                                <div className={'col-3'}>
                                    {
                                        formData.status !== ExchangeStatusEnum.RESCUED && (
                                            <Button
                                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                                color={ButtonColor.BUTTON_COLOR_GRAY}
                                                style={{ display: `${uuid ? '' : 'none'}` }}
                                                onClick={deleteExchange}
                                                className={'button-cancel'}
                                                ref={deleteBtnRef}
                                            >
                                                {`Cancelar devolução [${ENUM_SHORTCUT.SHORTCUT_CANCEL}]`}
                                            </Button>
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        formData.chaveNFe && (
                            <ToolTip title={'Nota fiscal registrada'} description={formData.chaveNFe} />
                        )
                    }

                </div>
                <div>
                    <div className={'table-container'}>
                        <table className={'products_header table'}>
                            <thead>
                                <tr>
                                    <th style={{ width: '5%' }}>{'Linha'}</th>
                                    <th
                                        style={{ width: '40%' }}
                                        className={'text-start'}
                                    >
                                        {'Descrição do produto'}
                                    </th>
                                    <th>{'Quantidade'}</th>
                                    <th>{'Preço unitário'}</th>
                                    <th>{'Total'}</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
                <div className={'middle-container'}>
                    <table className={'products_body'}>
                        <tbody>
                            {productsSelected.length ? (
                                productsSelected.map((product, index) => (
                                    <tr key={product.uuid}>
                                        <td style={{ width: '5%' }}>
                                            {index + 1}
                                        </td>
                                        <td
                                            style={{ width: '40%' }}
                                            className={'text-start'}
                                        >
                                            {product.name1}
                                        </td>
                                        <td>{product.amount}</td>
                                        <td>{formatValue(product.price)}</td>
                                        <td>{formatValue(product.total)}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={6}>
                                        {'Nenhum produto adicionado'}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className={'mt-8 row'}>
                    <div className={'col-12 totals__container'}>
                        <div className={'totals__wrapper mr-6'}>
                            <div className={'d-flex'}>
                                <FieldCpf
                                    placeholder={'Informe o CPF do cliente'}
                                    value={formatCpfMask(formData?.customerCpf)}
                                    className={'cpf__input'}
                                    onDisabled={true}
                                />
                            </div>
                            <div className={'d-flex mt-6'}>
                                <Amount
                                    title={'Quantidade de linhas'}
                                    amount={productsSelected.length}
                                    radius={'12px 0px 0px 12px'}
                                    className={'amount-exchange mr-6'}
                                />
                                <Amount
                                    title={'Quantidade de itens'}
                                    amount={totalItens}
                                    radius={'0px 0px 0px 0px'}
                                    className={'amount-exchange'}
                                />
                            </div>
                        </div>
                        <div className={'totals__exchanges'}>
                            <p>{'Total devolução'}</p>
                            <span>{totalExchange}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export function getExchangesFormRoute(uuid) {
    // I18Ns
    if (uuid) {
        return `/vendas/devolucoes/${uuid}`;
    } else {
        return '/vendas/devolucoes/novo';
    }
}
