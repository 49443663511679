import { useContext, useEffect, useRef, useState } from 'react';
import FieldTextSearch from '../../components/FieldTextSearch';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { AccountBankText, BillToPayFilterTabEnum, BillToPayFilterTabText, BillToPayTypeText, ENUM_SHORTCUT } from 'erva-doce-common';
import Button, { ButtonColor, ButtonFontColor, ButtonStyle } from '../../components/Button';
import { IconAdd, IconAdd2x, IconCheckBox, IconFilter, IconFilter2x } from '../../components/images';
import BillsToPayFilterModal from './BillsToPayFilterModal';
import Pagination from '../../components/Pagination';
import * as Tabs from '@radix-ui/react-tabs';
import Table from '../../components/Table';
import { DateTime } from 'luxon';
import { formatCurrency } from '../../components/FieldCurrency';
import * as BillsToPayService from '../../services/BillsToPayService';
import { getBillsToPayFormRoute } from './BillsToPayForm';
import ButtonGoto from '../../components/ButtonGoto';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import { formatValue } from '../../util/formatValue';
import SelectAccountsModal from '../../components/SelectAccountsModal';
import { useNavigate } from 'react-router';
import ToolTip from '../../components/ToolTip';

const INITIAL_FILTER = {
    page: 0,
    status: BillToPayFilterTabEnum.PENDING,
    search: '',
};

export default function BillsToPay() {
    const TABLE_COL_SPAN = 11;
    const title = 'Contas a Pagar';
    const { setInfoModal, backendConnectionError, addHotkey, removeHotkey, } = useContext(EnvironmentContext);
    const [filter, setFilter] = useState(INITIAL_FILTER);
    const [showFilter, setShowFilter] = useState(false);
    const [showSelectAccount, setShowSelectAccount] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({});
    const [selected, setSelected] = useState([]);
    const navigate = useNavigate();
    const inputSearchRef = useRef();
    const filterBtnRef = useRef();

    const billPayedTab = filter.status !== BillToPayFilterTabEnum.PAYED;

    const handleFilterTabChange = (e) => {
        setFilter({
            ...filter,
            status: e,
        });

        setSelected([]);
    };

    const handleSelect = (item) => {
        setSelected(
            selected.find(x => x.id === item.id) ?
                selected.filter(id => id.id !== item.id) :
                [...selected, item]
        );
    };

    const fetchItems = async () => {
        try {
            setIsLoading(true);

            const res = await BillsToPayService.get(filter);

            setData(res);
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    const update = async (accountId) => {
        try {
            setIsLoading(true);

            const data = selected.map((items) => {
                return {
                    uuid: items?.uuid,
                    payed: true,
                    account: accountId,
                };
            });
            await BillsToPayService.writeOffAccount(data);

            setSelected([]);

            setInfoModal({
                title,
                message: 'Baixa em contas com sucesso',
                style: InfoModalStyle.SUCCESS,
                show: true,
                onClose: () => fetchItems(),
            });

        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to save billToPay', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    const destroy = async () => {
        try {
            setIsLoading(true);

            await Promise.all(
                selected.map(async (item) => {
                    await BillsToPayService.destroy(item.uuid);
                })
            );

            setSelected([]);

            setInfoModal({
                title,
                message: 'Contas excluídas com sucesso!',
                style: InfoModalStyle.SUCCESS,
                show: true,
                onClose: () => fetchItems(),
            });

        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to save billToPay', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const shortcutSearchSecondary = addHotkey(ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY, () => {
            inputSearchRef.current?.focus();
        });

        const shortcutCreatePrimary = addHotkey(ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY, () => {
            navigate(getBillsToPayFormRoute());
        });

        const shortcutFilter = addHotkey(ENUM_SHORTCUT.SHORTCUT_FILTER, () => {
            if (filterBtnRef.current) {
                filterBtnRef.current.click();
            }
        });

        return () => {
            removeHotkey(shortcutSearchSecondary);
            removeHotkey(shortcutCreatePrimary);
            removeHotkey(shortcutFilter);
        };
    }, []);

    useEffect(() => {
        fetchItems();
    }, [filter]);

    return (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Financeiro', route: '/' },
                        { name: title, route: getBillsToPayRoute() },
                    ]}
                    hideStore
                />
                <div className={'controls'}>
                    <div className={'gd'}>
                        <div className={'gd-col gd-col-6'}>
                            <FieldTextSearch
                                label={`<em>Buscar por <strong>conta</strong> ou <strong>fornecedor/credor</strong> [${ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY}]</em>`}
                                value={filter?.search || ''}
                                onChange={({ target }) =>
                                    setFilter({ ...filter, search: target.value })
                                }
                                ref={inputSearchRef}
                            />
                        </div>
                        <div className={'gd-col gd-col-2'}></div>
                        <div className={'gd-col gd-col-2'}>
                            <Button
                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                icon={IconAdd}
                                icon2x={IconAdd2x}
                                route={getBillsToPayFormRoute()}
                            >
                                {`Gerar nova despesa [${ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY}]`}
                            </Button>
                        </div>
                        <div className={'gd-col gd-col-2'}>
                            <Button
                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                color={ButtonColor.BUTTON_COLOR_ORANGE}
                                icon={IconFilter}
                                icon2x={IconFilter2x}
                                onClick={() => setShowFilter(true)}
                                ref={filterBtnRef}
                            >
                                {`Filtrar [${ENUM_SHORTCUT.SHORTCUT_FILTER}]`}
                            </Button>
                        </div>
                    </div>
                </div>
                <div>
                    <Tabs.Root
                        defaultValue={filter.status}
                        className={'tabs_container'}
                        onValueChange={handleFilterTabChange}
                    >
                        <Tabs.List
                            className={'tabs_list'}
                            aria-label={'Manage your account'}
                        >
                            {Object.values(BillToPayFilterTabEnum).map(
                                (status, index) => {
                                    if (status === BillToPayFilterTabEnum.CANCELADO) return null;

                                    return (
                                        <Tabs.Trigger
                                            key={index}
                                            className={'tabs_trigger'}
                                            value={status}
                                        >
                                            {`${BillToPayFilterTabText(status)} (${data?.totals?.[status]?.count || 0})`}
                                        </Tabs.Trigger>
                                    );
                                })}
                        </Tabs.List>
                    </Tabs.Root>
                    <Table
                        loading={isLoading}
                        currentSort={filter.order}
                        selectAll={data?.records?.length === selected.length}
                        handleSelectAll={
                            data?.records?.length && billPayedTab 
                                ? (e) => {setSelected(e.target.checked ? data.records : []);} 
                                : null
                        }
                        columns={[
                            {
                                name: 'Nome',
                            },
                            {
                                name: 'Emissão',
                                align: 'center',
                            },
                            {
                                name: 'Tipo',
                                align: 'center',
                            },
                            {
                                name: 'N° Título',
                                align: 'center',
                            },
                            {
                                name: 'Parcela',
                                align: 'center',
                            },
                            {
                                name: 'Valor',
                                align: 'center',
                            },
                            {
                                name: 'Vencimento',
                                align: 'center',
                            },
                            {
                                name: 'Pagamento',
                                align: 'center',
                            },
                            {
                                name: 'Conta',
                                align: 'center',
                            },
                            {
                                name: 'Ações',
                                align: 'end',
                            },
                        ]}
                    >
                        {
                            data?.records?.length ? data?.records?.map((item, index) => (
                                <tr
                                    className={'table-row'}
                                    key={index}
                                >
                                    {billPayedTab && (
                                        <td>
                                            <label className={'custom-checkbox'}>
                                                <input
                                                    type={'checkbox'}
                                                    checked={selected.find(x => x.id === item.id)}
                                                    onChange={() => handleSelect(item)}
                                                    hidden
                                                />
                                                <span className={'custom-checkbox-icon'}>
                                                    <img src={IconCheckBox} alt={'Check'} />
                                                </span>
                                            </label>
                                        </td>
                                    )}
                                    <td className={'supplier'}>
                                        <div>
                                            {
                                                item.description ? (
                                                    <ToolTip title={item.name} description={item.description} />
                                                ) : <p>{item.name}</p>
                                            }
                                            <p>
                                                {item.creditor?.fantasyName || item.supplier?.fantasyName || '-'}
                                            </p>
                                        </div>
                                    </td>
                                    <td className={'text-center'}>{DateTime.fromISO(item.issueDate).toFormat('dd/MM/yyyy')}</td>
                                    <td className={'text-center'}>{`${BillToPayTypeText(item.type)}`}</td>
                                    <td className={'text-center'}>{item.numberDocument || '-'}</td>
                                    <td className={'text-center'}>{`${item.currentInstallment}/${item.totalInstallments}`}</td>
                                    <td className={'text-center'}>{formatCurrency(item.value.toFixed(2))}</td>
                                    <td className={'text-center'}>{DateTime.fromISO(item.dueDate).toFormat('dd/MM/yyyy')}</td>
                                    <td className={'text-center'}>{item.payedDate ? DateTime.fromISO(item.payedDate).toFormat('dd/MM/yyyy HH:mm') : '-'}</td>
                                    <td className={'text-center'}>{item?.account ? `${AccountBankText(item?.account?.bank)} - ${item?.account?.accountNumber}` : '-'}</td>
                                    <td className={'text-center'}>
                                        <div className={'d-flex justify-content-end'}>
                                            <ButtonGoto route={getBillsToPayFormRoute(item.uuid)} />
                                        </div>
                                    </td>
                                </tr>
                            )) : <tr><td colSpan={TABLE_COL_SPAN} className={'text-center'}>{'Nenhum item encontrado'}</td></tr>
                        }
                        {
                            !!data?.records?.length && (
                                <tr className={'total-row'}>
                                    {billPayedTab && (
                                        <td></td>
                                    )}
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className={'text-center'}>
                                        {formatValue(data.total)}
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )
                        }
                    </Table>
                    <Pagination
                        page={data?.page}
                        pageSize={data?.pageSize}
                        count={data?.count}
                        recordCount={data?.records?.length || 0}
                        onPageChange={(page) => setFilter({ ...filter, page })}
                    />
                </div>
                <div className={'row d-flex justify-content-end'}>
                    <div className={'col-6 align-right update-purchase'}>
                        {
                            !!selected?.length && (
                                <>
                                    {
                                        (!selected.some(x => x.orderBilling) && billPayedTab)  && (
                                            <Button
                                                buttonStyle={ButtonStyle.BUTTON_NORMAL}
                                                className={'ml-10'}
                                                color={ButtonColor.BUTTON_COLOR_GRAY}
                                                onClick={destroy}
                                            >
                                                {'Excluir contas'}
                                            </Button>
                                        )
                                    }
                                    {
                                        (!selected.some(x => x.payed) && billPayedTab) && (
                                            <Button
                                                buttonStyle={ButtonStyle.BUTTON_NORMAL}
                                                fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                                                className={'ml-10'}
                                                color={ButtonColor.BUTTON_COLOR_GREEN}
                                                onClick={() => setShowSelectAccount(true)}
                                            >
                                                {'Dar baixa em contas'}
                                            </Button>
                                        )
                                    }
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
            <BillsToPayFilterModal
                show={showFilter}
                onConfirm={() => {
                    setShowFilter(false);
                }}
                onCancel={() => {
                    setShowFilter(false);
                }}
            />
            <SelectAccountsModal
                show={showSelectAccount}
                onCancel={() => {
                    setShowSelectAccount(false);
                }}
                onConfirm={(e) => {
                    setShowSelectAccount(false);
                    update(e);
                }}
            />
        </>
    );
}

export function getBillsToPayRoute() {
    return '/financeiro/contas-a-pagar';
}
