export enum CashStateEnum {
  OPEN = "OPEN",
  FINISHED = "FINISHED",
}

export function CashStateText(type: CashStateEnum | string) {
    switch (type) {
    case CashStateEnum.OPEN:
        // I18N
        return "Aberto";
    case CashStateEnum.FINISHED:
        // I18N
        return "Finalizado";
    default:
        throw new Error(`Not implemented for ${type}`);
    }
}