import './Pills.scss';
import Button from './Button';
import { IconButtonClose, IconButtonClose2x } from './images';

function Pills({
    pills,
    onRemoveItem,
    readOnly = false,
}) {
    const handlePillClick = (pill) => {
        if (readOnly) {
            onRemoveItem(pill);
        }
    };
    return (
        <div className={'pills-container'}>
            {pills.map((pill) => (
                <div className={'pill'} key={pill.id} onClick={() => handlePillClick(pill)}>
                    {readOnly ? null : (
                        <Button
                            className={'transparent'}
                            onClick={(e) => {
                                e.stopPropagation();
                                onRemoveItem(pill);
                            }}
                        >
                            <img
                                src={IconButtonClose}
                                srcSet={`${IconButtonClose} 1x, ${IconButtonClose2x} 2x`}
                                alt={''}
                            />
                        </Button>
                    )}
                    <div className={'text'}>
                        {pill.value}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Pills;