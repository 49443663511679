import api from '../util/api';

export async function getAll() {
    return (await api.get('/feedbacks')).data;
}

export async function getByUuid(uuid) {
    return (await api.get(`/feedbacks/${uuid}`)).data;
}

export async function create(formData) {
    return (await api.post('/feedbacks', formData)).data;
}

export async function update(uuid, formData) {
    return (await api.put(`/feedbacks/${uuid}`, formData)).data;
}

export async function destroy(uuid) {
    await api.delete(`/feedbacks/${uuid}`);
}