export const MachineTypeEnum = {
  POS: "POS",
  TEF: "TEF",
} as const;

export function MachineTypeText(key: string): string {
  switch (key) {
    case "POS":
      return "POS";
    case "TEF":
      return "TEF";
  }
}
