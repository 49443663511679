import { useContext, useEffect, useState } from 'react';
import SimpleConfirmModal from '../../../components/modal/SimpleConfirmModal';
import FieldSelect from '../../../components/FieldSelect';
import { EcommercePageListEnum, EcommercePageListText, MenuItemTypeEnum, MenuItemTypeText } from 'erva-doce-common';
import FieldText from '../../../components/FieldText';
import FieldProductCategory from '../../../components/FieldProductCategory';
import FieldProductSubCategory from '../../../components/FieldProductSubCategory';
import FieldProductBrand from '../../../components/FieldProductBrand';
import { EnvironmentContext } from '../../../contexts/EnviromentContext';
import * as MenuService from '../../../services/MenuService';

export default function MenuItemModal({
    show,
    onCancel,
    onConfirm,
    initialData,
    locationType,
}) {
    const { backendConnectionError } = useContext(EnvironmentContext);

    const [formData, setFormData] = useState(initialData);
    const [formError, setFormError] = useState({});
    const title = (formData?.id ? 'Editar' : 'Criar') + ` ${'item de menu'}`;

    const confirm = async () => {
        if (hasValidationError()) return;

        try {
            const data = {
                locationType,
                type: formData.type,
                locationValue: formData.locationValue,
                locationLabel: formData.locationLabel,
                name: formData.name,
                link: formData.link,
                subItems: formData.subItems || [],
            };

            if (initialData.id) {
                await MenuService.update(initialData.uuid, data);
            } else {
                await MenuService.create(data);
            }

            onConfirm(formData);
        } catch(err) {
            console.log(err);
            backendConnectionError('Fail to fetch destroy banner order', err, null, title);
        }
    };

    const updateFormData = (value) => {
        setFormData(state => ({
            ...state,
            ...value,
        }));
    };

    const hasValidationError = () => {
        let hasError = false;
        setFormError({});

        if (!formData?.type) {
            hasError = true;
            setFormError(state => ({
                ...state,
                type: 'Preencha corretamente',
            }));
        }

        if (formData?.type === MenuItemTypeEnum.FREE) {
            if (!formData?.name) {
                hasError = true;
                setFormError(state => ({
                    ...state,
                    name: 'Preencha corretamente',
                }));
            }

            if (!formData?.link) {
                hasError = true;
                setFormError(state => ({
                    ...state,
                    link: 'Preencha corretamente',
                }));
            }
        } else {
            if (!formData?.locationValue) {
                hasError = true;
                setFormError(state => ({
                    ...state,
                    locationValue: 'Preencha corretamente',
                }));
            }
        }

        return hasError;
    };

    const handleTypeOptions = () => {
        const options = [];
        const exclude = [];

        for (const option in MenuItemTypeEnum) {
            if (!exclude.includes(option)) {
                options.push({
                    id: option,
                    value: MenuItemTypeText(option),
                });
            }
        }

        return options;
    };

    const handlePagesOptions = () => {
        const options = [];
        const exclude = [EcommercePageListEnum.DETAILED_PRODUCT, EcommercePageListEnum.SEARCH_RESULT, EcommercePageListEnum.SHARED_LIST];

        for (const option in EcommercePageListEnum) {
            if (!exclude.includes(option) && !formData?.locations?.find(x => x.value === option)) {
                options.push({
                    id: option,
                    value: EcommercePageListText(option),
                });
            }
        }

        return options;
    };

    useEffect(() => {
        setFormError({});
        setFormData(initialData);
    }, [initialData]);

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={onCancel}
            onConfirm={confirm}
            confirmText={'Salvar'}
            title={title}
            className={'modal-menu-item'}
        >
            <>
                <div className={'row'}>
                    <div className={'col-6'}>
                        <FieldSelect
                            label={'Tipo de item de menu'}
                            options={handleTypeOptions()}
                            onChange={({ target }) => {
                                let type = target.value;

                                if (target === '-1') {
                                    type = null;
                                }

                                updateFormData({ type, locationValue: null, locationLabel: null });
                            }}
                            value={formData?.type || '-1'}
                            validationError={formError?.type}
                            placeholder={'Selecione'}
                            disableDefaultOption={false}
                        />
                    </div>
                    <div className={'col-6'}>
                        {
                            formData?.type === MenuItemTypeEnum.PAGE && (
                                <FieldSelect
                                    label={'Páginas'}
                                    options={handlePagesOptions()}
                                    onChange={({ target }) => {
                                        let type = target.value;

                                        if (target === '-1') {
                                            return;
                                        }

                                        updateFormData({
                                            locationValue: type,
                                            locationLabel: EcommercePageListText(type),
                                        });
                                    }}
                                    value={formData?.locationValue}
                                    validationError={formError?.locationValue}
                                    placeholder={'Selecione'}
                                    disableDefaultOption={false}
                                />
                            )
                        }
                        {
                            formData?.type === MenuItemTypeEnum.CATEGORY && (
                                <FieldProductCategory
                                    label={'Categoria'}
                                    select={formData?.locationValue ? {  value: formData?.locationLabel, id: formData?.locationValue } : null}
                                    onSelected={(category) => {
                                        if (category) {
                                            updateFormData({
                                                locationValue: category.id,
                                                locationLabel: category.value,
                                            });
                                        }
                                    }}
                                    validationError={formError?.locationValue}
                                />
                            )
                        }
                        {
                            formData?.type === MenuItemTypeEnum.SUBCATEGORY && (
                                <FieldProductSubCategory
                                    label={'Subcategoria'}
                                    select={formData?.locationValue ? {  value: formData?.locationLabel, id: formData?.locationValue } : null}
                                    onSelected={(subCategory) => {
                                        if (subCategory) {
                                            updateFormData({
                                                locationValue: subCategory.id,
                                                locationLabel: subCategory.value,
                                            });
                                        }
                                    }}
                                    validationError={formError?.locationValue}
                                />
                            )
                        }
                        {
                            formData?.type === MenuItemTypeEnum.BRAND && (
                                <FieldProductBrand
                                    label={'Marca'}
                                    select={formData?.locationValue ? {  value: formData?.locationLabel, id: formData?.locationValue } : null}
                                    onSelected={(brand) => {
                                        updateFormData({
                                            locationValue: brand.id,
                                            locationLabel: brand.value,
                                        });
                                    }}
                                    validationError={formError?.locationValue}
                                />
                            )
                        }
                    </div>
                </div>
                {
                    formData?.type === MenuItemTypeEnum.FREE && (
                        <div className={'row'}>
                            <div className={'col-6'}>
                                <FieldText
                                    label={'Nome'}
                                    onChange={({ target }) => updateFormData({ name: target.value })}
                                    value={formData?.name || ''}
                                    validationError={formError?.name}
                                    maxLength={255}
                                />
                            </div>
                            <div className={'col-6'}>
                                <FieldText
                                    label={'Link'}
                                    onChange={({ target }) => updateFormData({ link: target.value })}
                                    value={formData?.link || ''}
                                    validationError={formError?.link}
                                />
                            </div>
                        </div>
                    )
                }
            </>
        </SimpleConfirmModal>
    );
}
