import { useNavigate, useParams } from 'react-router';
import { useContext, useEffect, useRef, useState } from 'react';
import RouteChangePrompt from '../../components/RouteChangePrompt';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import { getCustomersRoute } from './Customers';
import * as CustomerService from '../../services/CustomerService';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import { Loading, LoadingSize } from '../../components/Loading';
import { CustomerPersonalDataForm } from './CustomerPersonalDataForm';
import {
    ENUM_SHORTCUT,
    isValidCpf,
    isValidDate,
    isValidEmail,
    isValidName,
    isValidPhone,
    PermissionsEnum,
    unmaskCpf,
    unmaskPhone,
    ValidationErrorCustomerText
} from 'erva-doce-common';
import { emptyAddressData, getAddressData, getAddressObject } from '../../components/FieldAddress';
import CustomerTableVirtualOrder from './CustomerTableVirtualOrder';
import Button, { ButtonColor, ButtonFontColor, ButtonStyle } from '../../components/Button';
import { IconFilter, IconFilter2x } from '../../components/images';

const customerData = {
    name: '',
    cpf: '',
    birthDate: '',
    email: '',
    phone: '',
};

export function formDataHasValidationError(formData, updateFormError, useStatusField = true) {
    let hasError = false;
    if (!isValidName(formData.name)) {
        hasError = true;
        // I18N
        updateFormError({ name: 'Digite o nome completo' });
    }
    if (useStatusField && !formData.status) {
        hasError = true;
        // I18N
        updateFormError({ status: 'Selecione o status' });
    }
    if (!isValidCpf(formData.cpf)) {
        hasError = true;
        // I18N
        updateFormError({ cpf: 'Digite um CPF válido' });
    }
    if (!isValidDate(formData.birthDate)) {
        hasError = true;
        // I18N
        updateFormError({ birthDate: 'Digite uma data válida' });
    }
    if (!isValidEmail(formData.email)) {
        hasError = true;
        // I18N
        updateFormError({ email: 'Digite um e-mail válido' });
    }
    if (!!formData.phone?.trim() && !isValidPhone(formData.phone)) {
        hasError = true;
        // I18N
        updateFormError({ phone: 'Digite um telefone válido' });
    }
    return hasError;
}

export default function CustomerForm() {
    const { uuid } = useParams();
    const [formData, setFormData] = useState(customerData);
    const [formError, setFormError] = useState({ ...customerData });
    const [showFilter,setShowFilter] = useState();
    const [loadingCustomer, setLoadingCustomer] = useState(!!uuid);
    const [hasChange, setHasChange] = useState(false);
    const navigate = useNavigate();
    const [addressData, setAddressData] = useState(emptyAddressData);
    const [saveLoading, setSaveLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const buttonSaveRef = useRef();
    const buttonDeleteRef = useRef();
    const filterBtnRef = useRef();
    const [validateOnChange, setValidateOnChange] = useState(false);

    const {
        setLoading,
        backendConnectionError,
        setInfoModal,
        setConfirmModal,
        user,
    } = useContext(EnvironmentContext);

    const canSave = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_CUSTOMERS);
    const canRemove = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_CUSTOMERS);

    const customerName = formData.name;
    const getTitle = (windowTitle = false) => {
        if (uuid) {
            // I18N
            let title = 'Editar cliente';
            if (windowTitle && customerName) title += ` - ${customerName}`;
            return title;
        } else {
            return 'Novo cliente';
        }
    };

    const title = getTitle();

    function hasValidationError() {
        setValidateOnChange(true);
        setFormError(formError);
        // noinspection JSCheckFunctionSignatures
        let hasError = formDataHasValidationError(
            formData,
            (error) => setFormError((formError) => ({ ...formError, ...error }))
        );

        return hasError;
    }

    const save = async () => {
        if (saveLoading) return;
        if (hasValidationError()) return;

        try {
            setSaveLoading(true);

            const body = {
                ...formData,
                birthDate: new Date(formData.birthDate),
                cpf: unmaskCpf(formData.cpf),
                phone: unmaskPhone(formData.phone),
                address: {
                    ...getAddressObject(addressData)
                },
            };

            await CustomerService.editCustomer(uuid, body);

            const message = 'Cliente editado com sucesso!';

            setHasChange(false);
            setInfoModal({
                title,
                message,
                style: InfoModalStyle.SUCCESS,
                show: true,
                onClose: navigate(getCustomersRoute())
            });
        } catch (error) {
            backendConnectionError('Fail to invite user', error, null, title, ValidationErrorCustomerText);
        } finally {
            setSaveLoading(false);
        }
    };

    const removeCustomer = async () => {
        // I18N
        const title = 'Excluir cliente';
        async function proceed() {
            try {
                setDeleteLoading(true);
                await CustomerService.removeCustomer(uuid);
                // I18N
                const message = 'Cliente excluído com sucesso!';
                setHasChange(false);
                setInfoModal({
                    title,
                    message,
                    style: InfoModalStyle.SUCCESS,
                    show: true,
                    onClose: navigate(getCustomersRoute()),
                });
            } catch (err) {
                backendConnectionError('Fail to remove customer', err, null, title, ValidationErrorCustomerText);
            } finally {
                setDeleteLoading(false);
            }
        }

        setConfirmModal({
            title,
            // I18N
            message: 'Você tem certeza que deseja excluir o cliente?',
            onConfirm: proceed,
            show: true,
        });
    };

    function updateFormData(data) {
        setFormData((formData) => ({ ...formData, ...data }));
        setHasChange(true);
    }

    useEffect(() => {
        const loading = saveLoading || deleteLoading;
        setLoading(loading, true);
    }, [saveLoading, deleteLoading]);

    const fetchCustomer = async () => {
        try {
            setLoadingCustomer(true);

            const customer = await CustomerService.getCustomerByUuid(uuid);
            setFormData({ ...customer });

            setAddressData(getAddressData(customer?.address));
        } catch (error) {
            const title = getTitle();
            const { response } = error;

            if (response?.status === 404) {
                setInfoModal({
                    title,
                    // I18N
                    message: 'Cliente não encontrado.',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: navigate(getCustomersRoute()),
                });
            } else {
                backendConnectionError('Fail to fetch collaborator', error, null, title);
            }
        } finally {
            setLoadingCustomer(false);
        }
    };

    useEffect(() => {
        if (uuid) {
            fetchCustomer();
        }
    }, [uuid]);

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [formData]);

    const renderForm = () => {
        if (loadingCustomer) {
            return (
                <Loading
                    size={LoadingSize.LARGE}
                />
            );
        }

        return (
            <>
                <div>
                    <CustomerPersonalDataForm
                        formData={formData}
                        formError={formError}
                        updateFormData={updateFormData}
                        addressData={addressData}
                        setAddressData={setAddressData}
                        readOnly={!canSave}
                        setFormError={setFormError}
                    />

                    <fieldset>
                        <legend>{'Pedidos'}</legend>
                        <div className={'gd field-group mt-20'}>
                            <div className={'gd-col gd-col-9'} />
                            <div className={'gd-col gd-col-3 align-right mb-12'}>
                                <Button
                                    buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                    color={ButtonColor.BUTTON_COLOR_ORANGE}
                                    icon={IconFilter}
                                    icon2x={IconFilter2x}
                                    ref={filterBtnRef}
                                    onClick={() => setShowFilter(true)}
                                >
                                    {`Filtrar [${ENUM_SHORTCUT.SHORTCUT_FILTER}]`}
                                </Button>
                            </div>
                        </div>
                        <CustomerTableVirtualOrder
                            customerUuid={uuid}
                            showFilter={showFilter}
                            setShowFilter={setShowFilter}
                        />
                    </fieldset>
                </div>
                <div className={'controls'}>
                    <div className={'gd'}>
                        <div className={`gd-col ${canSave ? 'gd-col-8' : 'gd-col-2'}`} />
                        <div className={'gd-col gd-col-2'}>
                            {(uuid && canRemove) && (
                                <Button
                                    ref={buttonDeleteRef}
                                    color={ButtonColor.BUTTON_COLOR_RED}
                                    loading={deleteLoading}
                                    onClick={removeCustomer}
                                >
                                    {`Excluir [${ENUM_SHORTCUT.SHORTCUT_DELETE}]`}
                                </Button>
                            )}
                        </div>
                        {canSave && (
                            <div className={'gd-col gd-col-2'}>
                                <Button
                                    ref={buttonSaveRef}
                                    color={ButtonColor.BUTTON_COLOR_GREEN}
                                    fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                                    loading={saveLoading}
                                    onClick={save}
                                >
                                    {`Salvar [${ENUM_SHORTCUT.SHORTCUT_CONFIRM}]`}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            <RouteChangePrompt enabled={hasChange} />
            <div className={'crud-form'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        // I18N
                        { name: 'SAC', route: getDashboardRoute() },
                        // I18N
                        { name: 'Clientes', route: getCustomersRoute() },
                        { name: uuid ? (loadingCustomer ? '...' : customerName) : title },
                    ]}
                    backRoute={getCustomersRoute()}
                >
                </ScreenHeader>

                {renderForm()}
            </div>
        </>
    );
}

export function getCustomerFormRoute(uuid) {
    // I18N
    if (uuid) {
        return `/sac/clientes/${uuid}`;
    } else {
        return '/sac/clientes/novo';
    }
}
