import { forwardRef, useImperativeHandle } from 'react';
import FieldText from './FieldText';
import { IconCalendar, IconCalendar2x } from './images';
import { useRef } from 'react';

function FieldDate({ minDate, ...props }, ref) {
    const inputRef = useRef(null);

    useImperativeHandle(ref, () => ({
        getDate: () => {
            if (inputRef.current?.value.match(/\d{4}-\d{2}-\d{2}/)) {
                return new Date(inputRef.current?.value);
            } else {
                return null;
            }
        },
        input: inputRef.current
    }));

    return (
        <FieldText
            {...props}
            min={minDate}
            ref={inputRef}
            type={'date'}
            icon={IconCalendar}
            icon2x={IconCalendar2x}
            required={true}
            className={'has-icon ' + props.className}
        />
    );
}

export default forwardRef(FieldDate);
