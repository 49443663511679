import { CfopEnum, CfopValue, IcmsValue, NcmCodeAndText, NcmEnum, PermissionsEnum } from 'erva-doce-common';
import FieldSelect from '../../../components/FieldSelect';
import FieldText from '../../../components/FieldText';
import { useContext } from 'react';
import { EnvironmentContext } from '../../../contexts/EnviromentContext';

const handleCfopOptions = () => {
    const options = [];
    for (const option in CfopEnum) {
        options.push({
            id: option,
            value: CfopValue(option),
        });
    }
    return options;
};

const handleNcmOptions = () => {
    const options = [];
    for (const option in NcmEnum) {
        options.push({
            id: option,
            value: NcmCodeAndText(option),
        });
    }
    return options;
};

export default function FiscalForm({ data, updateFormData, formError }) {
    const { user } = useContext(EnvironmentContext);

    const disabled = !user.isAdmin && (!user.roles?.includes(PermissionsEnum.HANDLE_PRODUCTS) && !user.roles?.includes(PermissionsEnum.HANDLE_PRODUCT_FISCAL));

    return (
        <>
            <div className={'product-form-container'}>
                <div className={'row'}>
                    <div className={'col-12'}>
                        <h2 className={'mb-10'}>{'Classificações'}</h2>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-4'}>
                        <FieldSelect
                            label={'NCM'}
                            options={handleNcmOptions()}
                            onChange={({ target }) => {
                                updateFormData({ ncm: target.value });
                            }}
                            value={data.ncm != null ? data.ncm : ''}
                            placeholder={'Selecione o NCM'}
                            validationError={formError?.ncm}
                            disableDefaultOption={false}
                            disabled={disabled}
                            readOnly={disabled}
                        />
                    </div>
                    <div className={'col-4'}>
                        <FieldSelect
                            label={'CFOP'}
                            options={handleCfopOptions()}
                            onChange={({ target }) =>
                                updateFormData({ cfop: target.value })
                            }
                            value={data.cfop ? data.cfop : ''}
                            placeholder={'Selecione o CFOP'}
                            validationError={formError?.cfop}
                            disableDefaultOption={false}
                            disabled={disabled}
                            readOnly={disabled}
                        />
                    </div>
                    <div className={'col-4'}>
                        <FieldText
                            label={'ICMS'}
                            disabled={true}
                            readOnly={true}
                            value={
                                data.icms != null
                                    ? IcmsValue(data.icms)
                                    : ''
                            }
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
