import ScreenHeader from '../../components/logged/ScreenHeader';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import { useContext, useEffect, useRef, useState } from 'react';
import FieldTextSearch from '../../components/FieldTextSearch';
import Button, { ButtonColor, ButtonStyle } from '../../components/Button';
import { IconFilter, IconFilter2x } from '../../components/images';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import CustomerFilterModal from './CustomerFilterModal';
import * as CustomerService from '../../services/CustomerService';
import { DateTime } from 'luxon';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { ENUM_SHORTCUT } from 'erva-doce-common';
import { useNavigate } from 'react-router';
import { getCustomerFormRoute } from './CustomerForm';
import { maskPhoneString } from '../../components/FieldPhone';

const FILTERS = {
    search: null,
    page: 0,
};

export default function Customers() {
    const title = 'Clientes';
    const inputSearchRef = useRef();
    const [filter, setFilter] = useState(FILTERS);
    const [showFilter, setShowFilter] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(false);
    const { addHotkey, removeHotkey } = useContext(EnvironmentContext);
    const filterBtnRef = useRef();
    const navigate = useNavigate();
    const handleRowClick = (route) => navigate(route);

    const getCustomers = async () => {
        try {
            setIsLoading(true);

            const response = await CustomerService.getAll(filter);
            setData(response);

        } catch (err) {

        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const shortcutSearchSecondary = addHotkey(ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY, () => {
            inputSearchRef.current?.focus();
        });

        const shortcurtFilter = addHotkey(ENUM_SHORTCUT.SHORTCUT_FILTER, () => {
            if(filterBtnRef.current) {
                filterBtnRef.current.click();
            }
        });

        return () => {
            removeHotkey(shortcutSearchSecondary);
            removeHotkey(shortcurtFilter);
        };
    }, []);

    useEffect(() => {
        getCustomers();
    }, []);

    return (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'SAC', route: getDashboardRoute() },
                        { name: title, route: getCustomersRoute() },
                    ]}
                    hideStore
                />
                <div className={'controls'}>
                    <div className={'gd'}>
                        <div className={'gd-col gd-col-6'}>
                            <FieldTextSearch
                                ref={inputSearchRef}
                                label={`<em>Buscar por <strong>cliente</strong> [${ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY}]</em>`}
                                onChange={({ target }) =>
                                    setFilter({ ...filter, search: target.value })
                                }
                            />

                        </div>
                        <div className={'gd-col gd-col-3'}></div>
                        <div className={'gd-col gd-col-3'}>
                            <Button
                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                color={ButtonColor.BUTTON_COLOR_ORANGE}
                                icon={IconFilter}
                                icon2x={IconFilter2x}
                                onClick={() => setShowFilter(true)}
                                ref={filterBtnRef}
                            >
                                {`Filtrar [${ENUM_SHORTCUT.SHORTCUT_FILTER}]`}
                            </Button>
                        </div>
                    </div>
                </div>
                <div>
                    <Table
                        loading={isLoading}
                        columns={[
                            {
                                name: 'Nome',
                                align: 'left',
                            },
                            {
                                name: 'Sobrenome',
                                align: 'left',
                            },
                            {
                                name: 'CPF',
                                align: 'center',
                            },
                            {
                                name: 'E-mail',
                                align: 'left',
                            },
                            {
                                name: 'Telefone',
                                align: 'center',
                            },
                            {
                                name: 'Compras loja física',
                                align: 'center',
                            },
                            {
                                name: 'Compras loja online',
                                align: 'center',
                            },
                            {
                                name: 'Data de cadastro',
                                align: 'center',
                            },
                        ]}
                    >
                        {
                            data?.records?.map((customer, idx) => (
                                <tr key={idx} onClick={() => handleRowClick(getCustomerFormRoute(customer.uuid))}>
                                    <td>{customer.name || '-'}</td>
                                    <td>{customer.lastName || '-'}</td>
                                    <td className={'text-center'}>{customer.cpf}</td>
                                    <td>{customer.email || '-'}</td>
                                    <td className={'text-center'}>{maskPhoneString(customer.phone) || '-'}</td>
                                    <td className={'text-center'}>
                                        {customer.stats?.fisicalStore || '-'}
                                    </td>
                                    <td className={'text-center'}>
                                        {customer.stats?.onlineStore || '-'}
                                    </td>
                                    <td className={'text-center'}>
                                        {DateTime.fromISO(customer.createdAt).toFormat('dd/MM/yyyy')}
                                    </td>
                                </tr>
                            ))
                        }
                    </Table>
                    <Pagination
                        page={data?.page}
                        pageSize={data?.pageSize}
                        count={data?.count}
                        recordCount={data?.records?.length}
                        onPageChange={page => setFilter({ ...filter, page })}
                    />
                </div>
            </div>
            <CustomerFilterModal
                show={showFilter}
                onCancel={() => {
                    setShowFilter(false);
                }}
                onConfirm={(filter) => {
                    setShowFilter(false);
                    setFilter(filter);
                }}
            />
        </>
    );
}

export function getCustomersRoute() {
    return '/sac/clientes';
}
