import {
    ENUM_SHORTCUT,
    PermissionCashierEnum,
    PermissionsEnum,
    TypeNFSaleEnum,
    TypeNFSaleText,
} from 'erva-doce-common';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button, { ButtonColor, ButtonFontColor } from '../../components/Button';
import FieldText from '../../components/FieldText';
import { Loading, LoadingSize } from '../../components/Loading';
import RouteChangePrompt from '../../components/RouteChangePrompt';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import { getCashiersRoute } from './Cashiers';
import * as CashiersService from '../../services/CashiersService';
import FieldCollaborator from '../../components/FieldCollaborator';
import FieldSelect from '../../components/FieldSelect';

export function personalFormDataHasValidationError(formData, updateFormError = true) {
    let hasError = false;
    if (!formData.name) {
        hasError = true;
        // I18N
        updateFormError({ name: 'Digite o nome completo' });
    }
    return hasError;
}

export default function CashiersForm() {
    const emptyFormData = {
        name: '',
        collaborator: null,
        typeNF: null,
    };
    const emptyFormError = {
        ...emptyFormData,
        status: '',
    };
    const [formData, setFormData] = useState(emptyFormData);
    const [formError, setFormError] = useState(emptyFormError);
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [resendInviteLoading, setResendInviteLoading] = useState(false);
    const buttonSaveRef = useRef();
    const buttonResendInviteRef = useRef();
    const buttonDeleteRef = useRef();
    const {
        setLoading,
        backendConnectionError,
        setInfoModal,
        setConfirmModal,
        addHotkey,
        removeHotkey,
        user,
    } = useContext(EnvironmentContext);
    const { uuid } = useParams();
    const [loadingCashiers, setloadingCashiers] = useState(!!uuid);
    const [cashiers, setCashiersName] = useState('');
    const [hasChange, setHasChange] = useState(false);
    const navigate = useNavigate();

    function updateFormData(data) {
        // noinspection JSCheckFunctionSignatures
        setFormData((formData) => ({ ...formData, ...data }));
        setHasChange(true);
    }

    function hasValidationError() {
        setValidateOnChange(true);
        setFormError(emptyFormError);
        let hasError = personalFormDataHasValidationError(
            formData,
            (error) => setFormError((formError) => ({ ...formError, ...error }))
        );

        if (!formData.name || formData.name.trim().length === 0) {
            hasError = true;
            setFormError((formError) => ({ ...formError, name: 'Digite o nome do caixa' }));
        }
        if (!formData.typeNF || formData.typeNF === '-1') {
            hasError = true;
            setFormError((formError) => ({ ...formError, typeNF: 'Escolha um tipo' }));
        }
        return hasError;
    }

    async function fetchCashiers() {
        try {
            setloadingCashiers(true);
            const cashiers = await CashiersService.getCashierByUuid(uuid);
            setCashiersName(cashiers.name);
            setFormData({
                name: cashiers.name,
                typeNF: TypeNFSaleEnum[cashiers.typeNF],
                collaborator: cashiers.collaborator ? {
                    id: cashiers.collaborator?.id,
                    value: cashiers.collaborator?.name
                } : null,
            });
        } catch (e) {
            const title = getTitle();
            const { response } = e;
            if (response?.status === 404) {
                back();
            } else {
                backendConnectionError('Fail to fetch cashiers', e, null, title);
            }
        } finally {
            setloadingCashiers(false);
        }
    }

    useEffect(() => {
        if (uuid) {
            // noinspection JSIgnoredPromiseFromCall
            fetchCashiers();
        }
    }, [uuid]);

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [formData]);

    useEffect(() => {
        if (!loadingCashiers) setHasChange(false);
    }, [loadingCashiers]);

    const canSave = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_USERS);
    const canRemove = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_USERS);

    useEffect(() => {
        let shortcutDelete = null;
        let shortcutConfirm = null;
        let shortcutFilter = null;

        if (uuid) {
            if (canRemove) {
                shortcutDelete = addHotkey(ENUM_SHORTCUT.SHORTCUT_DELETE, () => {
                    buttonDeleteRef.current?.click();
                });
            }
            if (canSave) {
                if (!formData.inviteAccepted) {
                    shortcutFilter = addHotkey(ENUM_SHORTCUT.SHORTCUT_FILTER, () => {
                        buttonResendInviteRef.current?.click();
                    });
                }
            }
        }
        if (canSave) {
            shortcutConfirm = addHotkey(ENUM_SHORTCUT.SHORTCUT_CONFIRM, () => {
                buttonSaveRef.current?.click();
            });
        }
        return () => {
            removeHotkey(shortcutConfirm);
            removeHotkey(shortcutDelete);
            removeHotkey(shortcutFilter);
        };
    }, []);

    function back() {
        navigate(getCashiersRoute());
    }

    async function deleteCashiers() {
        // I18N
        const title = 'Excluir Caixa';

        async function proceed() {
            try {
                setDeleteLoading(true);
                await CashiersService.removeCashier(uuid);
                // I18N
                const message = 'Caixa excluído com sucesso!';
                const style = InfoModalStyle.SUCCESS;
                const onClose = back;
                setHasChange(false);
                setInfoModal({
                    title,
                    message,
                    style,
                    show: true,
                    onClose,
                });
            } catch (e) {
                backendConnectionError('Fail to delete user', e, null, title,);
            } finally {
                setDeleteLoading(false);
            }
        }

        setConfirmModal({
            title,
            // I18N
            message: 'Você tem certeza que deseja excluir o Caixa selecionado?',
            onConfirm: proceed,
            show: true,
        });
    }


    async function save() {
        if (saveLoading) return;
        if (hasValidationError()) return;

        const title = getTitle();
        try {
            setSaveLoading(true);
            let collaboratorData = null;

            if (formData.collaborator && formData.collaborator.id !== null) {
                collaboratorData = await CashiersService.getByUuidCollaborator(formData.collaborator.id);
            }

            if (collaboratorData?.id) {
                setInfoModal({
                    title,
                    message: 'Colaborador já é responsável por um caixa.',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: () => setInfoModal(infoModal => ({ ...infoModal, show: false }))
                });
                return;
            }

            const body = {
                name: formData.name.trim(),
                typeNF: formData.typeNF,
                collaborator: formData.collaborator?.id || null,
            };

            let message;
            if (!uuid) {
                await CashiersService.createCashier(body);
                message = 'Caixa salvo com sucesso!';
            } else {
                await CashiersService.editCashier(uuid, body);
                message = 'Caixa editado com sucesso!';
            }

            setInfoModal({
                title,
                message,
                style: InfoModalStyle.SUCCESS,
                show: true,
                onClose: back,
            });

            setHasChange(false);
        } catch (e) {
            backendConnectionError('Fail to create/edit cashier', e, null, title);
        } finally {
            setSaveLoading(false);
        }
    }

    useEffect(() => {
        const loading = saveLoading || resendInviteLoading || deleteLoading;
        setLoading(loading, true);
    }, [saveLoading, resendInviteLoading, deleteLoading]);

    function getTitle(windowTitle = false) {
        if (uuid) {
            // I18N
            let title = 'Editar caixa';
            if (windowTitle && cashiers) title += ` - ${cashiers}`;
            return title;
        } else {
            return 'Novo caixa';
        }
    }

    const handleTypeOptions = () => {
        const options = [];
        for (const option in TypeNFSaleEnum) {
            options.push({
                id: option,
                value: TypeNFSaleText(option),
            });
        }

        return options;
    };

    function renderForm() {
        if (loadingCashiers) {
            return (
                <Loading
                    size={LoadingSize.LARGE}
                />
            );
        }

        return (
            <>
                <div>
                    <fieldset>
                        <legend>
                            {/*I18N*/}
                            {'Dados do Caixa'}
                        </legend>
                        <div className={'gd'}>
                            <div className={'gd-col-4'}>
                                <FieldText
                                    // I18N
                                    label={'Nome'}
                                    onChange={({ target }) =>
                                        updateFormData({ name: target.value })
                                    }
                                    value={formData?.name}
                                    validationError={formError?.name}
                                    maxLength={120}
                                    readOnly={!canSave}
                                />
                            </div>
                            <div className={'gd-col-4'}>
                                <FieldCollaborator
                                    select={formData?.collaborator}
                                    label={'Responsável'}
                                    multipleSelection={false}
                                    onSelected={(value) => {
                                        updateFormData({ collaborator: value });
                                    }}
                                />
                            </div>
                            <div className={'gd-col-4'}>
                                <FieldSelect
                                    label={'Nota fiscal'}
                                    options={handleTypeOptions()}
                                    onChange={({ target }) => {
                                        updateFormData({ typeNF: target.value });
                                    }}
                                    value={formData.typeNF || '-1'}
                                    validationError={formError?.typeNF}
                                    placeholder={'Selecione o tipo de nota fiscal'}
                                    disableDefaultOption={false}
                                />
                            </div>
                        </div>
                    </fieldset>
                </div>

                <div className={'controls'}>
                    <div className={'gd'}>
                        <div className={`gd-col ${canSave ? 'gd-col-8' : 'gd-col-8'}`} />
                        <div className={'gd-col gd-col-2'}>
                            {(uuid && canRemove) && (
                                <Button
                                    ref={buttonDeleteRef}
                                    color={ButtonColor.BUTTON_COLOR_RED}
                                    loading={deleteLoading}
                                    onClick={deleteCashiers}
                                >
                                    {`Excluir [${ENUM_SHORTCUT.SHORTCUT_DELETE}]`}
                                </Button>
                            )}
                        </div>
                        {canSave && (
                            <div className={'gd-col gd-col-2'}>
                                <Button
                                    ref={buttonSaveRef}
                                    color={ButtonColor.BUTTON_COLOR_GREEN}
                                    fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                                    loading={saveLoading}
                                    onClick={save}
                                    disabled={!formData.name}
                                >
                                    {`${!uuid ? 'Criar' : 'Salvar'} [${ENUM_SHORTCUT.SHORTCUT_CONFIRM}]`}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }

    const title = getTitle();

    return (
        <>
            <RouteChangePrompt enabled={hasChange} />
            <div className={'crud-form'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        // I18N
                        { name: 'Administrativo', route: getDashboardRoute() },
                        // I18N
                        { name: 'Caixas', route: getCashiersRoute() },
                        { name: uuid ? (loadingCashiers ? '...' : cashiers) : title },
                    ]}
                    backRoute={getCashiersRoute()}
                >
                </ScreenHeader>

                {renderForm()}
            </div>
        </>
    );
}

export function getCashiersFormRoute(uuid) {
    // I18N
    if (uuid) {
        return `/administrativo/Caixas/${uuid}`;
    } else {
        return '/administrativo/Caixas/novo';
    }
}