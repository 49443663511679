import './ErrorMessage.scss';
import classNames from 'classnames';

export default function ErrorMessage({ className, errorMessage }) {
    return (
        <p
            className={classNames(
                'error-message custom-error-message',
                className
            )}
        >
            {errorMessage}
        </p>
    );
}
