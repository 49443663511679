import { forwardRef } from 'react';
import { IconButtonRemove, IconButtonRemove2x } from './images';
import { NavLink } from 'react-router-dom';

function ButtonRemove({
    onClick,
    route,
    disabled
}, ref) {
    const Button = forwardRef(({ route, onClick, className, children, ...props }, ref) => {
        if (route) {
            return (
                <NavLink ref={ref} className={className} to={route}>
                    {children}
                </NavLink>
            );
        } else {
            return (
                <button ref={ref} className={className} onClick={onClick}  disabled={disabled}>
                    {children}
                </button>
            );
        }
    });
    Button.displayName = 'ButtonRemove';

    return (
        <Button
            disabled={disabled}
            className={'button transparent'}
            ref={ref}
            route={route}
            onClick={onClick}
        >
            <img
                src={IconButtonRemove}
                srcSet={`${IconButtonRemove} 1x, ${IconButtonRemove2x} 2x`}
                alt={''}
            />
        </Button>
    );
}

export default forwardRef(ButtonRemove);