import { useNavigate } from 'react-router';
import Table from '../../../components/Table';
import { DateTime } from 'luxon';
import { OrderByStockCheckEnum, StockCheckFilterTabText, PermissionsEnum } from 'erva-doce-common';
import { getStockCheckDetailRoute } from './StockCheckDetail';
import PermissionedLink from '../../../components/PermissionedLink';
import { getStoresFormRoute } from '../../stores/StoreForm'; 
import { getCollaboratorFormRoute } from '../../collaborators/CollaboratorForm';

export default function StockCheckTable({ data, filter, isLoading, onSortChange }) {
    const navigate = useNavigate();

    return (
        <Table
            loading={isLoading}
            currentSort={filter.order}
            columns={[
                {
                    name: 'N° Conferência',
                    sortAsc: OrderByStockCheckEnum.ID_ASC,
                    sortDesc: OrderByStockCheckEnum.ID_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
                {
                    name: 'Descrição',
                },
                {
                    name: 'Estoque de conferência',
                    sortAsc: OrderByStockCheckEnum.STOCK_ASC,
                    sortDesc: OrderByStockCheckEnum.STOCK_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
                {
                    name: 'Conferidos QTD',
                    align: 'center',
                    sortAsc: OrderByStockCheckEnum.COUNT_ASC,
                    sortDesc: OrderByStockCheckEnum.COUNT_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
                {
                    name: 'Data início',
                    align: 'center',
                    sortAsc: OrderByStockCheckEnum.INITIATED_ASC,
                    sortDesc: OrderByStockCheckEnum.INITIATED_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
                {
                    name: 'Data fim',
                    align: 'center',
                    sortAsc: OrderByStockCheckEnum.FINISHED_ASC,
                    sortDesc: OrderByStockCheckEnum.FINISHED_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
                {
                    name: 'Status',
                    align: 'center',
                    sortAsc: OrderByStockCheckEnum.STATUS_ASC,
                    sortDesc: OrderByStockCheckEnum.STATUS_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
                {
                    name: 'Autor',
                    align: 'center',
                    sortAsc: OrderByStockCheckEnum.AUTHOR_ASC,
                    sortDesc: OrderByStockCheckEnum.AUTHOR_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
            ]}
        >
            {data?.map((item, index) => (
                <tr
                    className={'table-row'}
                    key={index}
                    onClick={() => navigate(getStockCheckDetailRoute(item.id))}
                >
                    <td className={'supplier'}>
                        <div>
                            <p>{`${item.id}`.padStart(7, '0')}</p>
                            <p>
                                {DateTime.fromISO(item.createdAt).toFormat(
                                    'dd/MM/yyyy'
                                )}
                            </p>
                        </div>
                    </td>
                    <td className={'text-truncate-lines'}>{item.description}</td>
                    <td>
                        {item.checkCD ? (
                            'CD'
                        ) : (
                            <PermissionedLink
                                permission={PermissionsEnum.HANDLE_PRODUCTS}
                                to={getStoresFormRoute(item?.store?.uuid)}
                            >
                                {item?.store?.fantasyName}
                            </PermissionedLink>
                        )}
                    </td>
                    <td className={'text-center'}>{item.count}</td>
                    <td className={'text-center'}>{item.initiatedAt}</td>
                    <td className={'text-center'}>{item.finishedAt}</td>
                    <td className={'text-center'}>{StockCheckFilterTabText(item.status)}</td>
                    <td className={'text-center'}>
                        <PermissionedLink
                            to={getCollaboratorFormRoute(item.authorUUID)}
                            permission={PermissionsEnum.HANDLE_USERS}
                        >
                            {item.authorName}
                        </PermissionedLink>
                    </td>
                </tr>
            ))}
        </Table>
    );
}
