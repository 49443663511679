import { useContext, useEffect, useRef, useState } from 'react';
import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';

import './StockCheckFormModal.scss';
import FieldCheckbox from '../../components/FieldCheckbox';
import FieldText from '../../components/FieldText';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import * as StockCheckService from '../../services/StockCheckService';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import ErrorMessage from '../../components/ErrorMessage';

const INITIAL_STATE = {
    checkCD: false,
    checkStore: false,
    store: null,
    description: '',
};

export default function StockCheckFormModal({
    show,
    onCancel,
    onConfirm,
    id,
    title='Cadastrar conferência',
    responsible,
}) {
    const isNew = !id;
    const { backendConnectionError, user, selectedStore, setInfoModal } = useContext(EnvironmentContext);

    const inputRef = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(INITIAL_STATE);
    const [formError, setFormError] = useState({});

    const cancel = () => {
        setData({});
        setFormError({});
        onCancel();
    };

    const hasValidationError = () => {
        let hasErrorTeste = false;
        setFormError({});

        if (!data?.checkCD && !data?.checkStore) {
            hasErrorTeste = true;
            setFormError((prev) => ({
                ...prev,
                checks: 'Escolha uma das opções acima',
            }));
        }

        if(!data?.description || data?.description === '') {
            hasErrorTeste = true;
            setFormError((prev) => ({
                ...prev,
                description: 'A descrição é obrigatória'
            }));
        }

        return hasErrorTeste;
    };

    const confirm = async () => {
        try {
            if(hasValidationError()) return;
            setIsLoading(true);

            const entity = {
                ...data,
                responsible,
                store: { id: selectedStore.uuid, fantasyName: selectedStore.fantasyName },
                author: user.uuid,
            };

            if (isNew) {
                await StockCheckService.create(entity);
            } else {
                await StockCheckService.update(id, entity);
            }

            setInfoModal({
                title,
                message: 'Conferência cadastrada com sucesso',
                style: InfoModalStyle.SUCCESS,
                show: true,
                onClose: () => onConfirm(),
            });
            setFormError({});
            setData({});
        } catch(err) {
            console.log(err);
            backendConnectionError('Fail to post info', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    const updateData = (value) => {
        setData(state => ({
            ...state,
            ...value,
        }));
    };
    
    const getData = async () => {
        try {
            setData({});
        } catch(err) {
            console.log(err);
            // backendConnectionError('Fail to fetch info', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            getData().then();
        } else {
            setData({});
        }
    }, [id]);

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={cancel}
            onConfirm={confirm}
            title={title}
            className={'register-stock-check-modal'}
        >
            <div className={'suggestion_modal'}>
                <div>
                    <div className={'checkbox-group'}>
                        <FieldCheckbox
                            label={'Local de estoque'}
                            readOnly={!isNew}
                            inputs={[
                                {
                                    type: 'radio',
                                    label: 'Loja',
                                    checked: data?.checkCD === false,
                                    onChange: () => {
                                        updateData({
                                            checkCD: false,
                                            checkStore: true,
                                        });
                                    },
                                },
                                {
                                    type: 'radio',
                                    label: 'CD',
                                    checked: data?.checkCD,
                                    onChange: () => {
                                        updateData({
                                            checkCD: true,
                                            checkStore: false,
                                            store: null,
                                        });
                                    },
                                },
                            ]}
                        />
                    </div>
                    {formError?.checks && (
                        <ErrorMessage errorMessage={formError?.checks} />
                    )}
                </div>
                <div>
                    <FieldText
                        ref={inputRef}
                        label={'Descrição'}
                        onChange={({ target }) => updateData({ description: target.value })}
                        value={data?.description || ''}
                        maxLength={80}
                    />
                    {
                        formError?.description && (
                            <ErrorMessage errorMessage={formError?.description}/>
                        )
                    }
                </div>
            </div>
        </SimpleConfirmModal>
    );
}
