import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import FieldTextSearch from '../../components/FieldTextSearch';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import {
    IconAdd,
    IconAdd2x,
    IconCheckBox,
    IconFilter,
    IconFilter2x,
} from '../../components/images/Icons';
import ScreenHeader from '../../components/logged/ScreenHeader';
import * as BillsToReceiveService from '../../services/BillsToReceiveService';
import { formatValue } from '../../util/formatValue';
import { getBillsToReceiveFormRoute } from './BilsToReceiveForm';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import Button, {
    ButtonColor,
    ButtonFontColor,
    ButtonStyle,
} from '../../components/Button';
import * as Tabs from '@radix-ui/react-tabs';
import BillsToReceiveFilterModal from './BillsToReceiveFilterModal';
import {
    AccountBankText,
    BillToReceiveFilterTabEnum,
    BillToReceiveFilterTabText,
    ENUM_SHORTCUT,
    OrderBillsToReceiveEnum,
} from 'erva-doce-common';
import ButtonGoto from '../../components/ButtonGoto';
import { formatCurrency } from '../../components/FieldCurrency';
import { DateTime } from 'luxon';
import SelectAccountsModal from '../../components/SelectAccountsModal';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import ToolTip from '../../components/ToolTip';

const INITIAL_FILTER = {
    page: 0,
    status: BillToReceiveFilterTabEnum.PENDING,
    search: '',
    order: OrderBillsToReceiveEnum.DUE_DATE_ASC,
};

export default function BillsToReceive() {
    const title = 'Contas a receber';
    const navigate = useNavigate();
    const { backendConnectionError, setInfoModal, addHotkey, removeHotkey } =
        useContext(EnvironmentContext);

    const [filter, setFilter] = useState(INITIAL_FILTER);
    const [showFilter, setShowFilter] = useState(false);
    const [, setIsLoading] = useState(false);
    const [showSelectAccount, setShowSelectAccount] = useState(false);
    const [data, setData] = useState({});
    const [billsToReceive, setBillsToReceive] = useState('');
    const [selected, setSelected] = useState([]);
    const inputSearchRef = useRef();
    const filterBtnRef = useRef();

    const handleSelect = (item) => {
        setSelected(
            selected.find((x) => x.id === item.id)
                ? selected.filter((id) => id.id !== item.id)
                : [...selected, item]
        );
    };

    const handleFilterTabChange = (e) => {
        setFilter({
            ...filter,
            status: e,
        });

        setSelected([]);
    };

    const fetchBillsToReceive = async () => {
        try {
            setIsLoading(true);

            const response = await BillsToReceiveService.get(filter);

            setData(response);
        } catch (err) {
            console.log(err);
            backendConnectionError(
                'Fail to save billToReceive',
                err,
                null,
                title
            );
        } finally {
            setIsLoading(false);
        }
    };

    const update = async (accountId) => {
        try {
            setIsLoading(true);

            const data = selected?.map((item) => {
                return {
                    uuid: item?.uuid,
                    received: true,
                    account: accountId,
                };
            });

            await BillsToReceiveService.update({ data });

            setSelected([]);

            setInfoModal({
                title,
                message: 'Baixa em recebimentos com sucesso',
                style: InfoModalStyle.SUCCESS,
                show: true,
                onClose: () => fetchBillsToReceive(),
            });
        } catch (err) {
            console.log(err);
            backendConnectionError(
                'Fail to save billToReceive',
                err,
                null,
                title
            );
        } finally {
            setIsLoading(false);
        }
    };

    const destroy = async () => {
        try {
            setIsLoading(true);

            await Promise.all(
                selected.map(async (item) => {
                    await BillsToReceiveService.destroy(item.uuid);
                })
            );

            setSelected([]);

            setInfoModal({
                title,
                message: 'Recebimentos excluídos com sucesso!',
                style: InfoModalStyle.SUCCESS,
                show: true,
                onClose: () => fetchBillsToReceive(),
            });
        } catch (err) {
            console.log(err);
            backendConnectionError(
                'Fail to save billToReceive',
                err,
                null,
                title
            );
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchBillsToReceive();
    }, [filter]);

    useEffect(() => {
        const shortcutSearchSecondary = addHotkey(
            ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY,
            () => {
                inputSearchRef.current?.focus();
            }
        );

        const shortcutCreatePrimary = addHotkey(
            ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY,
            () => {
                navigate(getBillsToReceiveFormRoute());
            }
        );

        const shortcutFilter = addHotkey(ENUM_SHORTCUT.SHORTCUT_FILTER, () => {
            if (filterBtnRef.current) {
                filterBtnRef.current.click();
            }
        });

        return () => {
            removeHotkey(shortcutSearchSecondary);
            removeHotkey(shortcutCreatePrimary);
            removeHotkey(shortcutFilter);
        };
    }, []);

    return (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Financeiro', route: '/' },
                        { name: title, route: getBillsToReceiveRoute() },
                    ]}
                />
                <div className={'controls'}>
                    <div className={'gd'}>
                        <div className={'gd-col gd-col-6'}>
                            <FieldTextSearch
                                label={`<em>Buscar por <strong>conta</strong> [${ENUM_SHORTCUT.SHORTCUT_SEARCH_PRIMARY}]</em>`}
                                value={billsToReceive}
                                onChange={({ target }) =>
                                    setBillsToReceive(target.value)
                                }
                                onChangeDebounce={(value) => {
                                    setFilter({ ...filter, search: value });
                                }}
                                debounceTime={400}
                                ref={inputSearchRef}
                            />
                        </div>
                        <div className={'gd-col gd-col-2'}></div>
                        <div className={'gd-col gd-col-2'}>
                            <Button
                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                icon={IconAdd}
                                icon2x={IconAdd2x}
                                route={getBillsToReceiveFormRoute()}
                            >
                                {`Gerar nova entrada [${ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY}]`}
                            </Button>
                        </div>
                        <div className={'gd-col gd-col-2'}>
                            <Button
                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                color={ButtonColor.BUTTON_COLOR_ORANGE}
                                icon={IconFilter}
                                icon2x={IconFilter2x}
                                onClick={() => setShowFilter(true)}
                                ref={filterBtnRef}
                            >
                                {`Filtrar [${ENUM_SHORTCUT.SHORTCUT_FILTER}]`}
                            </Button>
                        </div>
                    </div>
                </div>
                <div>
                    <Tabs.Root
                        defaultValue={filter.status}
                        className={'tabs_container'}
                        onValueChange={handleFilterTabChange}
                    >
                        <Tabs.List
                            className={'tabs_list'}
                            aria-label={'Manage your account'}
                        >
                            {Object.values(BillToReceiveFilterTabEnum).map(
                                (status, index) => {
                                    if (
                                        status ===
                                        BillToReceiveFilterTabEnum.CANCELADO
                                    )
                                        return null;

                                    return (
                                        <Tabs.Trigger
                                            key={index}
                                            className={'tabs_trigger'}
                                            value={status}
                                        >
                                            {`${BillToReceiveFilterTabText(
                                                status
                                            )} (${
                                                data?.totals?.[status]?.count ||
                                                0
                                            })`}
                                        </Tabs.Trigger>
                                    );
                                }
                            )}
                        </Tabs.List>
                    </Tabs.Root>
                    <Table
                        selectAll={data?.records?.length === selected.length}
                        currentSort={filter.order}
                        handleSelectAll={
                            data?.records?.length
                                ? (e) => {
                                    setSelected(
                                        e.target.checked ? data.records : []
                                    );
                                }
                                : null
                        }
                        columns={[
                            {
                                name: 'Nome',
                            },
                            {
                                name: 'Emissão',
                                align: 'center',
                                sortAsc: OrderBillsToReceiveEnum.ISSUE_DATE_ASC,
                                sortDesc: OrderBillsToReceiveEnum.ISSUE_DATE_DESC,
                                onSortChange: (order) =>
                                    setFilter({ ...filter, order }),
                            },
                            {
                                name: 'N° Título',
                                align: 'center',
                            },
                            {
                                name: 'Parcela',
                                align: 'center',
                            },
                            {
                                name: 'Valor',
                                align: 'center',
                            },
                            {
                                name: 'Vencimento',
                                align: 'center',
                            },
                            {
                                name: 'Recebimento',
                                align: 'center',
                            },
                            {
                                name: 'Conta',
                                align: 'center',
                            },
                            {
                                name: 'Ações',
                                align: 'end',
                            },
                        ]}
                    >
                        {data?.records?.length ? (
                            data?.records?.map((item, index) => (
                                <tr className={'table-row'} key={index}>
                                    <td>
                                        <label className={'custom-checkbox'}>
                                            <input
                                                type={'checkbox'}
                                                checked={selected.find(
                                                    (x) => x.id === item.id
                                                )}
                                                onChange={() =>
                                                    handleSelect(item)
                                                }
                                                hidden
                                            />
                                            <span
                                                className={
                                                    'custom-checkbox-icon'
                                                }
                                            >
                                                <img
                                                    src={IconCheckBox}
                                                    alt={'Check'}
                                                />
                                            </span>
                                        </label>
                                    </td>
                                    <td className={'supplier'}>
                                        <div>
                                            {item.description ? (
                                                <ToolTip
                                                    title={item.name}
                                                    description={
                                                        item.description
                                                    }
                                                />
                                            ) : (
                                                <p>{item.name}</p>
                                            )}
                                            <p>
                                                {
                                                    item.creditor?.fantasyName ||
                                                    item.supplier?.fantasyName ||
                                                    item.customer?.name || '-'
                                                }
                                            </p>
                                        </div>
                                    </td>
                                    <td className={'text-center'}>
                                        {DateTime.fromISO(
                                            item.issueDate
                                        ).toFormat('dd/MM/yyyy')}
                                    </td>
                                    <td className={'text-center'}>
                                        {item.numberDocument || '-'}
                                    </td>
                                    <td
                                        className={'text-center'}
                                    >{`${item.currentInstallment}/${item.totalInstallments}`}</td>
                                    <td className={'text-center'}>
                                        {formatCurrency(item.value.toFixed(2))}
                                    </td>
                                    <td className={'text-center'}>
                                        {DateTime.fromISO(
                                            item.dueDate
                                        ).toFormat('dd/MM/yyyy')}
                                    </td>
                                    <td className={'text-center'}>
                                        {item.receivedDate
                                            ? DateTime.fromISO(
                                                item.receivedDate
                                            ).toFormat('dd/MM/yyyy HH:mm')
                                            : '-'}
                                    </td>
                                    <td className={'text-center'}>
                                        {item?.account
                                            ? `${AccountBankText(
                                                item?.account?.bank
                                            )} - ${
                                                item?.account?.accountNumber
                                            }`
                                            : '-'}
                                    </td>
                                    <td className={'text-center'}>
                                        <div
                                            className={
                                                'd-flex justify-content-end'
                                            }
                                        >
                                            <ButtonGoto
                                                route={getBillsToReceiveFormRoute(
                                                    item.uuid
                                                )}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={10} className={'text-center'}>
                                    {'Nenhum item encontrado'}
                                </td>
                            </tr>
                        )}
                        {!!data?.records?.length && (
                            <tr className={'total-row'}>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className={'text-center'}>
                                    {formatValue(data.total)}
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}
                    </Table>
                    <Pagination
                        page={data?.page}
                        pageSize={data?.pageSize}
                        count={1}
                        recordCount={data?.records?.length}
                    />
                </div>
                <div className={'row d-flex justify-content-end'}>
                    <div className={'col-6 align-right update-purchase'}>
                        {!!selected?.length && (
                            <>
                                {!selected.some((x) => x.sale) && (
                                    <Button
                                        buttonStyle={ButtonStyle.BUTTON_NORMAL}
                                        className={'ml-10'}
                                        color={ButtonColor.BUTTON_COLOR_GRAY}
                                        onClick={destroy}
                                    >
                                        {'Excluir recebimentos'}
                                    </Button>
                                )}
                                {!selected.some((x) => x.received) && (
                                    <Button
                                        buttonStyle={ButtonStyle.BUTTON_NORMAL}
                                        fontColor={
                                            ButtonFontColor.BUTTON_FONT_COLOR_LIGHT
                                        }
                                        className={'ml-10'}
                                        color={ButtonColor.BUTTON_COLOR_GREEN}
                                        onClick={() =>
                                            setShowSelectAccount(true)
                                        }
                                    >
                                        {'Dar baixa em recebimentos'}
                                    </Button>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
            <BillsToReceiveFilterModal
                show={showFilter}
                filter={filter}
                onCancel={() => {
                    setShowFilter(false);
                }}
                onConfirm={() => {
                    setShowFilter(false);
                }}
            />
            <SelectAccountsModal
                show={showSelectAccount}
                onCancel={() => {
                    setShowSelectAccount(false);
                }}
                onConfirm={(e) => {
                    setShowSelectAccount(false);
                    update(e);
                }}
            />
        </>
    );
}

export function getBillsToReceiveRoute() {
    return '/financeiro/contas-a-receber';
}
