export enum ProductMovementsFilterEnum {
  INDIVIDUAL_SALE = "INDIVIDUAL_SALE",
  DAILY_SALE   = "DAILY_SALE",
  WEEKLY_SALE = "WEEKLY_SALE",
  MONTHLY_SALE = "MONTHLY_SALE",
  ENTRIES = "ENTRIES",
}

export function ProductMovementsFilterEnumText(type: ProductMovementsFilterEnum | string) {
    switch (type) {
    case ProductMovementsFilterEnum.INDIVIDUAL_SALE:
        return "Venda individual";
    case ProductMovementsFilterEnum.DAILY_SALE:
        return "Venda diária";
    case ProductMovementsFilterEnum.WEEKLY_SALE:
        return "Venda semanal";
    case ProductMovementsFilterEnum.MONTHLY_SALE:
      return "Venda mensal";
    case ProductMovementsFilterEnum.ENTRIES:
      return "Entradas";
    default:
        throw new Error(`Not implemented for ${type}`);
    }
}