import { useNavigate } from 'react-router';
import Button, { ButtonColor, ButtonStyle } from '../../components/Button';
import { IconAdd, IconAdd2x } from '../../components/images';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import { getSelectSupplierRoute } from './SelectSupplier';
import * as Tabs from '@radix-ui/react-tabs';
import * as OrderReceptionService from '../../services/OrderReceptionService';
import { useContext, useEffect, useState } from 'react';
import Pagination from '../../components/Pagination';
import OrderReceivesTable from './OrderReceivesTable';
import { ENUM_SHORTCUT } from 'erva-doce-common';
import { EnvironmentContext } from '../../contexts/EnviromentContext';

const INITIAL_FILTER = {
    page: 0,
    status: 'ALL',
};

export default function OrderReceive() {
    const title = 'Recebimento de mercadoria';

    const navigate = useNavigate();

    const [filter, setFilter] = useState(INITIAL_FILTER);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({});
    const { addHotkey, removeHotkey } = useContext(EnvironmentContext);

    const handleFilterTabChange = (e) => {
        setFilter({
            ...filter,
            status: e,
        });
    };

    const updateFilter = (name, value) => {
        setFilter({
            ...filter,
            [name]: value,
        });
    };

    const fetchReceives = async () => {
        try {
            setIsLoading(true);
            const data = await OrderReceptionService.getAll(filter);

            setData(data);
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchReceives();
    }, [filter]);

    useEffect(() => {
        const shortcutCreatePrimary = addHotkey(ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY, () => {
            navigate(getSelectSupplierRoute());
        });

        return () => {
            removeHotkey(shortcutCreatePrimary);
        };
    }, []);

    return (
        <>
            <div className={'stock crud-list'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Compras', route: getDashboardRoute() },
                        { name: 'Pedidos' },
                        { name: title, route: getOrderReceiveRoute() },
                    ]}
                    hideStore
                />
                <div className={'row justify-content-end mb-20'}>
                    <div className={'col-12 col-md-2'}>
                        <Button
                            buttonStyle={ButtonStyle.BUTTON_SHADOW}
                            color={ButtonColor.BUTTON_COLOR_GREEN}
                            icon={IconAdd}
                            icon2x={IconAdd2x}
                            onClick={() => navigate(getSelectSupplierRoute())}
                            className={'w-100'}
                        >
                            {`Iniciar novo recebimento [${ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY}]`}
                        </Button>
                    </div>
                </div>
                <div className={'Order_suggestion_list'}>
                    <Tabs.Root
                        defaultValue={filter.status}
                        className={'tabs_container'}
                        onValueChange={handleFilterTabChange}
                    >
                        <Tabs.List
                            className={'tabs_list'}
                            aria-label={'Manage your account'}
                        >
                            <Tabs.Trigger
                                className={'tabs_trigger'}
                                value={'ALL'}
                            >
                                {`Todos (${data?.count || 0})`}
                            </Tabs.Trigger>
                        </Tabs.List>
                    </Tabs.Root>
                    <OrderReceivesTable
                        data={data?.records}
                        isLoading={isLoading}
                        filter={filter}
                    />
                    <Pagination
                        page={data?.page}
                        pageSize={data?.pageSize}
                        count={data?.count}
                        recordCount={data?.records?.length || 0}
                        onPageChange={(page) => setFilter({ ...filter, page })}
                    />
                </div>
            </div>
        </>
    );
}

export function getOrderReceiveRoute() {
    return '/compras/recebimento-de-mercadorias';
}
