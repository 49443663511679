import { useContext, useEffect, useRef, useState } from 'react';
import { getProductsNeedPrintTags, printTags, updatePrice } from '../../services/TagsService';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import ScreenHeader from '../../components/logged/ScreenHeader';
import FieldCheckbox from '../../components/FieldCheckbox';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import Button, { ButtonColor, ButtonFontColor, ButtonStyle } from '../../components/Button';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import FieldTextSearch from '../../components/FieldTextSearch';
import ReprintModal from './ReprintModal';
import './Tags.scss';
import { getProductFormRoute } from '../products/ProductsForm';
import { ENUM_SHORTCUT, ProductStatusEnum, ProductTagTypeText, PermissionsEnum } from 'erva-doce-common';
import CheckBoxTags from '../../components/CheckBoxTags';
import PermissionedLink from '../../components/PermissionedLink';

export function getTagsRoute() {
    return '/produtos/etiquetas';
}

export default function Tags() {
    const inputSearchRef = useRef();
    const buttonPrintRef = useRef();
    const buttonUpdatePriceRef = useRef();
    const { addHotkey, removeHotkey, selectedStore, setLoading } = useContext(EnvironmentContext);
    const [tagloading, setTagloading] = useState(false);
    const [productTags, setProductTags] = useState([]);
    const [productTagsSearch, setProductTagsSearch] = useState('');
    const [selectedProductTags, setSelectedProductTags] = useState([]);
    const [showReprint, setShowReprint] = useState(false);
    const reprintBtnRef = useRef();
    const [pagination, setPagination] = useState({
        page: 0,
        pageSize: null,
        count: null
    });
    const [filter, setFilter] = useState({
        order: 'ASC',
        search: '',
        page: 0
    });

    useEffect(() => {
        fetchProductAndLogs();
    }, [filter.page, filter.search, filter.order, selectedStore]);

    useEffect(() => {
        setSelectedProductTags([]);
    }, [selectedStore]);

    useEffect(() => {
        const shortcutSearchSecondary = addHotkey(ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY, () => {
            inputSearchRef.current?.focus();
        });

        const shortcutConfirm = addHotkey(ENUM_SHORTCUT.SHORTCUT_CONFIRM, () => {
            buttonPrintRef.current?.click();
        });

        const shortcutRefresh = addHotkey(ENUM_SHORTCUT.SHORTCUT_REFRESH, () => {
            buttonUpdatePriceRef.current?.click();
        });

        const shortcutCreatePrimary = addHotkey(ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY, () => {
            if (reprintBtnRef.current) {
                reprintBtnRef.current.click();
            }
        });

        return () => {
            removeHotkey(shortcutSearchSecondary);
            removeHotkey(shortcutConfirm);
            removeHotkey(shortcutRefresh);
            removeHotkey(shortcutCreatePrimary);
        };
    }, []);

    async function updatePrices() {
        try {
            setLoading(true);
            await updatePrice(selectedProductTags);
            window.location.reload();
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }

    async function PrintTags() {
        try {
            setLoading(true);

            await printTags(selectedProductTags, selectedProductTags.map(p => p.tagQuantity));

            window.location.reload();
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }

    async function fetchProductAndLogs() {
        try {
            setTagloading(true);
            const {
                count,
                page,
                pageSize,
                records
            } = await getProductsNeedPrintTags(filter.page, filter.search, filter.order);


            const formattedRecords = records.filter(p => p !== null).map(p => {
                const product = {
                    id: p.id,
                    uuid: p.uuid,
                    barCode: p.barCode,
                    name: [p.name1, p.name2, p.name3].filter(Boolean).join(' | '),
                    newPrice: p.newPrice ? p.newPrice.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                    }) : null,
                    newPriceStore: p.newPriceStore ? p.newPriceStore.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                    }) : null,
                    tags: ProductTagTypeText(p.tags),
                    tagQuantity: p.tagQuantity,
                    price: p.price ? p.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : null,
                    printed: p.statusStore === ProductStatusEnum.AWAITING_AUTH_PRICE_UPDATE,
                    statusStore: p.statusStore,
                    status: p.status,
                };

                const family = p.family || {};
                if (family.prioritizeFamilyPrinting) {
                    product.barCode = 'Família';
                    product.name = family.name || '';
                    product.tags = ProductTagTypeText(family.tags);
                }

                return product;
            });

            setProductTags(formattedRecords);
            setPagination({ page, pageSize, count });
        } catch (e) {
            console.error(e);
        } finally {
            setTagloading(false);
        }
    }

    const handleSelectedProductTag = (idProduct) => {
        setSelectedProductTags(
            selectedProductTags.includes(idProduct) ?
                selectedProductTags.filter(id => id !== idProduct) :
                [...selectedProductTags, idProduct]
        );
    };

    return (
        <div className={'crud-list'}>
            <ScreenHeader
                title={'Listagem'}
                breadcrumbs={[
                    { name: 'Produtos', route: getDashboardRoute() },
                    { name: 'Etiquetas' },
                ]}
            />
            <div className={'controls'}>
                <div className={'gd'}>
                    <div className={'gd-col gd-col-8'}>
                        <FieldTextSearch
                            ref={inputSearchRef}
                            label={`<em>Buscar por nomes de <strong>produtos/famílias</strong> [${ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY}]</em>`}
                            value={productTagsSearch}
                            onChange={({ target }) => {
                                setProductTagsSearch(target.value);
                            }}
                            onChangeDebounce={(value) => {
                                setFilter({ ...filter, search: value });
                            }}
                            debounceTime={400}
                        />
                    </div>
                    <div className={'gd-col gd-col-4'}>
                        <Button
                            buttonStyle={ButtonStyle.BUTTON_SHADOW}
                            color={ButtonColor.BUTTON_COLOR_ORANGE}
                            onClick={() => setShowReprint(!showReprint)}
                            ref={reprintBtnRef}
                        >
                            {`Reimpressão de etiquetas [${ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY}]`}
                        </Button>
                    </div>
                </div>
                <ReprintModal
                    filter={filter}
                    setFilter={setFilter}
                    showModal={showReprint}
                    onCloseModal={setShowReprint}
                    productTags={productTags}
                />
            </div>
            <div className={'table-scroll tags'}>
                <Table
                    loading={tagloading}
                    currentSort={filter.order}
                    columns={[
                        {
                            name: (
                                <CheckBoxTags
                                    inputs={[{
                                        disabled1: true,
                                        type: 'checkbox',
                                        value1: selectedProductTags.length === productTags.length,

                                        onChange1: () => {
                                            setSelectedProductTags(selectedProductTags.length === productTags.length ? [] : productTags.map(p => p.id));
                                        },
                                        label2: 'Etiqueta não impressa',
                                        value2: selectedProductTags.length === productTags.filter(p => !p.printed).length,
                                        onChange2: () => {
                                            const notPrintedTags = productTags.filter(p => !p.printed).map(p => p.id);
                                            setSelectedProductTags(selectedProductTags.length === notPrintedTags.length ? [] : notPrintedTags);
                                        },
                                        label3: 'Preço desatualizado',
                                        value3: selectedProductTags.length === productTags.filter(p => p.price !== p.newPrice).length,
                                        onChange3: () => {
                                            const outdatedPriceTags = productTags.filter(p => p.price !== p.newPrice).map(p => p.id);
                                            setSelectedProductTags(selectedProductTags.length === outdatedPriceTags.length ? [] : outdatedPriceTags);
                                        },
                                    }]}
                                />
                            )
                        },
                        { name: '' },
                        { name: 'Nome' },
                        { name: 'Novo Preço' },
                        { name: 'Etiqueta' },
                        { name: 'Impressa' },
                    ]}
                >
                    {productTags.map(product => (
                        
                        <tr key={product.id}
                            className={`product-tag-row ${selectedProductTags.includes(product.id) ? 'selected' : ''}`}>
                            <td>
                                <FieldCheckbox
                                    inputs={[{
                                        value: selectedProductTags.includes(product.id),
                                        onChange: () => handleSelectedProductTag(product.id),
                                        checked: selectedProductTags.includes(product.id)
                                    }]}
                                />
                            </td>
                            <td>{product.barCode}</td>
                            <td>
                                <PermissionedLink
                                    permission={
                                        PermissionsEnum.HANDLE_PRODUCTS
                                    }
                                    to={getProductFormRoute(product.uuid)}
                                >
                                    {product.name}
                                </PermissionedLink>
                            </td>
                            <td>{product.newPrice !== product.newPriceStore ? product.newPriceStore : product.newPrice}</td>
                            <td>{product.tags}</td>
                            <td>{product.printed ? 'Sim' : 'Não'}</td>
                        </tr>
                    ))}
                </Table>
                <Pagination
                    page={pagination.page}
                    pageSize={pagination.pageSize}
                    disabled={selectedProductTags.length !== 0}
                    count={pagination.count}
                    recordCount={productTags.length}
                    onPageChange={page => setFilter({ ...filter, page })}
                />
                <div className={'controls'}>
                    <div className={'gd'}>
                        <div className={'gd-col gd-col-8'} />
                        <div className={'gd-col gd-col-2'}>
                            <Button
                                ref={buttonPrintRef}
                                color={ButtonColor.BUTTON_COLOR_GREEN}
                                fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                                loading={false}
                                disabled={selectedProductTags.length === 0}
                                onClick={() => PrintTags()}
                            >
                                {`Imprimir [${ENUM_SHORTCUT.SHORTCUT_CONFIRM}]`}
                            </Button>
                        </div>
                        <div className={'gd-col gd-col-2'}>
                            <Button
                                disabled={
                                    selectedProductTags.length === 0 ||
                                    selectedProductTags.some(id => productTags.find(p => p.id === id).statusStore !== ProductStatusEnum.AWAITING_AUTH_PRICE_UPDATE)

                                }
                                ref={buttonUpdatePriceRef}
                                color={ButtonColor.BUTTON_COLOR_GREEN}
                                fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                                loading={false}
                                onClick={() => updatePrices()}
                            >
                                {`Atualizar preços [${ENUM_SHORTCUT.SHORTCUT_REFRESH}]`}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
