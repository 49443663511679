import { DateTime } from 'luxon';
import { formatValue } from '../../../util/formatValue';
import { DeliveryMethodTypeText } from 'erva-doce-common';
import { Link } from 'react-router-dom';

export default function VirtualOrderDeliveryTab({ data }) {
    const deliveryName = data?.delivery
        && data?.delivery.name || '-';

    const deliveryType = data?.delivery
            && DeliveryMethodTypeText(data?.delivery.type);

    const address = data?.address && data?.address;
    const addressFormatted = `${address?.street}, ${address?.number} - ${address?.district}`;
    const deliveryDateFormatted = DateTime.fromISO(data?.deliveryDate || data.createdAt).toFormat('yyyy-LL-dd');

    function formatCEP(cep) {
        return cep.slice(0, 5) + '-' + cep.slice(5);
    }

    function copyableText() {
        const textToCopy = `${addressFormatted}\n${address?.city}, ${address?.state} - ${formatCEP(address?.cep)}`;

        navigator.clipboard.writeText(textToCopy);
    }

    return (
        <div className={'virtual-order-form-container'}>
            <div className={'row'}>
                <div className={'col-5'}>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <h2 className={'mb-10'}>{'Endereço'}</h2>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <div>
                                <p>
                                    {'Pedido será enviado para '}
                                    <span className={'copy-address'} title={'Copiar endereço'} onClick={copyableText()}>
                                        <strong>
                                            {addressFormatted}
                                        </strong>
                                        <p>
                                            <strong>{`${address?.city}, ${address?.state} -`} {formatCEP(address?.cep)}</strong>
                                        </p>
                                    </span>
                                </p>
                            </div>
                        </div>
                        <Link
                            className={'mt-20'}
                            target={'_blank'}
                            to={`https://www.google.com/maps/search/${addressFormatted}`}
                        >
                            {'Pesquisar no Google Maps'}
                        </Link>
                    </div>
                    <div className={'row'}>
                        <div className={'col-12 mt-42'}>
                            <h2 className={'mb-10'}>{'Método'}</h2>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <div>
                                <p>
                                    {'O método de envio será por '}
                                    <strong>
                                        {deliveryName}{' por '}{formatValue(data?.deliveryValue || 0)}
                                    </strong>
                                </p>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col-12'}>
                                <div className={'mr-20 mt-12'}>
                                    <p><strong>{'Tipo'}</strong></p>
                                    <p>{deliveryType || '-'}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'col-7'}>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <h2 className={'mb-10'}>{'Frete'}</h2>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <div className={'mr-20'}>
                                <p>
                                    {data?.isFreeDelivery ?
                                        (
                                            <>
                                                {'Pedido com valor mínimo para frete '}<strong>{'grátis'}</strong>
                                            </>
                                        )
                                        : (
                                            <>
                                                {'Frete no valor de '}<strong>{formatValue(data?.deliveryValue || 0)}</strong>
                                            </>
                                        )
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={'row mt-20'}>
                        <div className={'col-12'}>
                            <div>
                                <p><strong>{'Previsão de entrega'}</strong></p>
                                <p>
                                    {deliveryDateFormatted
                                        ? DateTime.fromFormat(deliveryDateFormatted, 'yyyy-MM-dd').toFormat('dd/MM/yyyy')
                                        : 'sem previsão'
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
