import api from '../util/api';

export async function getAll(filter) {
    if (!filter) return [];

    return (await api.get('/customers', {
        params: filter,
    })).data;
}

export async function getCustomerByCpf(cpf) {
    return (await api.get(`/customers/cpf/${cpf}`)).data;
}

export async function getCustomerByUuid(uuid) {
    return (await api.get(`/customers/simple-customer/${uuid}`)).data;
}

export async function createOrUpdateSimpleCustomer(formData){
    return (await api.post('/customers/simple-customer', formData)).data;
}

export async function getVirtualOrders(uuid, filters) {
    return (await api.get(`/customers/${uuid}/orders`, {
        params: { ...filters }
    })).data;
}

export async function editCustomer(uuid, body) {
    return (await api.put(`/customers/${uuid}`, body))?.data?.response;
}

export async function removeCustomer(uuid) {
    return (await api.delete(`/customers/${uuid}`))?.data?.response;
}