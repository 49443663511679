import React, { useContext, useEffect, useState } from 'react';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import FieldTextSearch from '../../components/FieldTextSearch';
import Button, { ButtonColor, ButtonStyle } from '../../components/Button';
import { IconLog, IconTransfer } from '../../components/images';
import { useNavigate } from 'react-router';
import FieldCheckbox from '../../components/FieldCheckbox';
import './Stock.scss';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import * as StockService from '../../services/StockService';
import classNames from 'classnames';
import TransferModal from './TransferModal';
import { getStockBulkRoute } from './StockBulk';
import { getProductFormRoute } from '../products/ProductsForm';
import { OrderByStockEnum } from 'erva-doce-common';
import { getStockProductLogRoute } from './StockProductLog';
import PermissionedLink from '../../components/PermissionedLink';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import { PermissionsEnum } from 'erva-doce-common';
import ToolTip from '../../components/ToolTip';

const filters = {
    search: null,
    page: 0,
    cdUrgent: false,
    storeUrgent: false,
    order: null,
};

const handleReferenceTooltip = () => {
    return (
        <ToolTip
            title={'Ref. (1) Período'}
            description={
                <p>
                    {'Período\u00A0'}
                    <strong>{'de 30 dias'}</strong>
                </p>
            }
        />
    );
};


export default function Stock() {
    const title = 'Gerenciar';
    const navigate = useNavigate();
    const {
        backendConnectionError,
        setConfirmModal,
        setInfoModal,
        selectedStore,
    } = useContext(EnvironmentContext);

    const [filter, setFilter] = useState(filters);
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showTransferModal, setShowTransferModal] = useState(false);
    const [data, setData] = useState({});
    const [product, setProduct] = useState(null);

    const updateFilter = (name, value) => {
        setFilter({
            ...filter,
            [name]: value,
        });
    };

    const handleTransferProduct = async ({ from, to, count, id }) => {
        const proceed = async () => {
            try {
                setIsLoading(true);

                await StockService.tranferProduct([
                    {
                        from,
                        to,
                        count,
                        id,
                    },
                ]);

                setInfoModal({
                    title: 'Transferir itens entre estoques',
                    style: InfoModalStyle.SUCCESS,
                    message: 'Transferência realizada com sucesso',
                    onClose: () => getItems(),
                    show: true,
                });
            } catch (err) {
                console.log(err);
                backendConnectionError(
                    'Fail to transfer Item',
                    err,
                    null,
                    title
                );
            } finally {
                setIsLoading(false);
                setProduct(null);
            }
        };

        setConfirmModal({
            title: 'Transferir itens entre estoques',
            message: 'Você tem certeza de que deseja realizar a transferência?',
            onConfirm: proceed,
            show: true,
        });
    };

    const getItems = async () => {
        try {
            setIsLoading(true);

            const response = await StockService.getItems(filter);
            setData(response);
        } catch (err) {
            console.log(err);
            backendConnectionError(
                'Fail to fetch stock products',
                err,
                null,
                title
            );
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getItems();
    }, [filter, selectedStore]);

    return (
        <>
            <div className={'stock crud-list'}>
                <ScreenHeader
                    title={'Gerenciar'}
                    breadcrumbs={[
                        { name: 'Produtos', route: getDashboardRoute() },
                        { name: 'Estoque' },
                        { name: 'Gerenciar', route: getStockRoute() },
                    ]}
                />
                <div className={'row justify-content-end mb-32'}>
                    <div className={'col-6 col-md-4'}>
                        <FieldTextSearch
                            label={
                                '<em>Buscar por <strong>produto</strong> ou <strong>código</strong></em>'
                            }
                            onChangeDebounce={() =>
                                updateFilter('search', search)
                            }
                            onChange={(e) => setSearch(e.target.value)}
                            value={search}
                            className={'text_filter'}
                        />
                    </div>
                    <div className={'hide-mobile col-md-6'}></div>
                    <div className={'col-6 col-md-2'}>
                        <Button
                            buttonStyle={ButtonStyle.BUTTON_SHADOW}
                            color={ButtonColor.BUTTON_COLOR_GREEN}
                            icon={IconTransfer}
                            icon2x={IconTransfer}
                            onClick={() => {
                                navigate(getStockBulkRoute());
                            }}
                            className={'w-100'}
                        >
                            {'Transferência em massa'}
                        </Button>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-7 d-flex align-items-center'}>
                        <FieldCheckbox
                            className={'mb-0'}
                            type={'checkbox'}
                            inputs={[
                                {
                                    value: filter.cdUrgent,
                                    label: '% urgência CD',
                                    onChange: () => {
                                        updateFilter(
                                            'cdUrgent',
                                            !filter.cdUrgent
                                        );
                                    },
                                    checked: filter.cdUrgent,
                                },
                                {
                                    value: filter.storeUrgent,
                                    label: '% urgência loja',
                                    onChange: () => {
                                        updateFilter(
                                            'storeUrgent',
                                            !filter.storeUrgent
                                        );
                                    },
                                    checked: filter.storeUrgent,
                                },
                            ]}
                        />
                    </div>
                </div>
                <div className={'mt-24'}>
                    <Table
                        loading={isLoading}
                        currentSort={filter.order}
                        columns={[
                            {
                                name: 'EAN',
                                align: 'center',
                                width: '10%',
                                sortAsc: OrderByStockEnum.BARCODE_ASC,
                                sortDesc: OrderByStockEnum.BARCODE_DESC,
                                onSortChange: (order) =>
                                    updateFilter('order', order),
                            },
                            {
                                name: 'Produto',
                                width: '20%',
                                sortAsc: OrderByStockEnum.NAME_ASC,
                                sortDesc: OrderByStockEnum.NAME_DESC,
                                onSortChange: (order) =>
                                    updateFilter('order', order),
                            },
                            {
                                name: 'Estoque total',
                                align: 'center',
                            },
                            {
                                name: 'Estoque CD',
                                align: 'center',
                                className: 'border-left',
                                sortAsc: OrderByStockEnum.CD_STOCK_ASC,
                                sortDesc: OrderByStockEnum.CD_STOCK_DESC,
                                onSortChange: (order) =>
                                    updateFilter('order', order),
                            },
                            {
                                name: handleReferenceTooltip(),
                                align: 'center',
                                sortAsc: OrderByStockEnum.MIN_VALUE_CD_ASC,
                                sortDesc: OrderByStockEnum.MIN_VALUE_CD_DESC,
                                onSortChange: (order) =>
                                    updateFilter('order', order),
                            },
                            {
                                name: 'Percentual urgência',
                                align: 'center',
                                className: 'center border-right',
                            },
                            {
                                name: 'Estoque Loja',
                                align: 'center',
                                sortAsc: OrderByStockEnum.STORE_STOCK_ASC,
                                sortDesc: OrderByStockEnum.STORE_STOCK_DESC,
                                onSortChange: (order) =>
                                    updateFilter('order', order),
                            },
                            {
                                name: 'Venda mensal',
                                align: 'center',
                                sortAsc: OrderByStockEnum.MIN_VALUE_STORE_ASC,
                                sortDesc: OrderByStockEnum.MIN_VALUE_STORE_DESC,
                                onSortChange: (order) =>
                                    updateFilter('order', order),
                            },
                            {
                                name: 'Percentual urgência',
                                align: 'center',
                                className: 'center border-right',
                            },
                            {
                                width: '5%',
                            },
                            {
                                name: 'Ações',
                                align: 'end',
                                width: '5%',
                            },
                        ]}
                    >
                        {data?.records?.map((product) => (
                            <>
                                <tr key={product?.id}>
                                    <td className={'text-center'}>
                                        {product?.barCode}
                                    </td>
                                    <td> <PermissionedLink
                                        className={'product-name'}
                                        permission={
                                            PermissionsEnum.HANDLE_PRODUCTS
                                        }
                                        to={getProductFormRoute(product?.uuid)}
                                    >
                                        {product?.name}
                                    </PermissionedLink></td>
                                    <td className={'text-center'}>
                                        {product?.productStore?.stock +
                                            product?.warehouse?.stock}
                                    </td>
                                    <td className={'text-center border-left'}>
                                        {product?.warehouse?.stock}
                                    </td>
                                    <td className={'text-center'}>
                                        {product?.warehouse?.minValue}
                                    </td>
                                    <td
                                        className={classNames(
                                            'text-center',
                                            'border-right',
                                            product.warehouse.urgentPercentage <
                                                1
                                                ? 'red'
                                                : 'green'
                                        )}
                                    >
                                        {
                                            (
                                                product?.warehouse?.minValue === 0
                                                && product?.warehouse?.stock === 0
                                            ) ? '-' : product?.warehouse?.urgentPercentage.toFixed(2)
                                        }
                                    </td>

                                    <td className={'text-center'}>
                                        {product?.productStore?.stock}
                                    </td>
                                    <td className={'text-center'}>
                                        {product?.productStore?.minValue}
                                    </td>
                                    <td
                                        className={classNames(
                                            'text-center',
                                            'border-right',
                                            product?.productStore?.urgentPercentage < 1
                                                ? 'red'
                                                : 'green'
                                        )}
                                    >
                                        {
                                            (product?.productStore?.minValue === 0
                                                && product?.productStore?.stock === 0
                                            ) ? '-' : product?.productStore?.urgentPercentage.toFixed(2)
                                        }
                                    </td>
                                    <td className={'text-center'}>{ }</td>
                                    <td className={'text-center'}>
                                        <div
                                            className={
                                                'd-flex justify-content-end'
                                            }
                                        >
                                            <Button
                                                className={
                                                    'transparent button-sort'
                                                }
                                                onClick={() => {
                                                    setShowTransferModal(true);
                                                    setProduct(product);
                                                }}
                                                aria-label={'Transferir item'}
                                                title={'Transferir item'}
                                            >
                                                <img
                                                    src={IconTransfer}
                                                    width={20}
                                                ></img>
                                            </Button>
                                            <Button
                                                className={
                                                    'transparent button-sort ml-10'
                                                }
                                                onClick={() => {
                                                    navigate(
                                                        getStockProductLogRoute(
                                                            product.uuid
                                                        )
                                                    );
                                                }}
                                                aria-label={'Ver histórico'}
                                                title={'Ver histórico'}
                                            >
                                                <img
                                                    src={IconLog}
                                                    width={20}
                                                ></img>
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            </>
                        ))}
                    </Table>
                </div>
                <div>
                    <Pagination
                        page={data?.page}
                        pageSize={data?.pageSize}
                        count={data?.count}
                        recordCount={data?.records?.length || 0}
                        onPageChange={(page) => setFilter({ ...filter, page })}
                    />
                </div>
            </div>
            <TransferModal
                show={showTransferModal && product}
                product={product}
                onCancel={() => {
                    setShowTransferModal(false);
                    setProduct(null);
                }}
                onConfirm={(data) => {
                    setShowTransferModal(false);
                    handleTransferProduct(data);
                }}
            />
        </>
    );
}

export function getStockRoute() {
    return '/produtos/estoque/gerenciar';
}
