import React, { useContext, useEffect, useRef, useState } from 'react';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import './NewMenu.scss';
import classNames from 'classnames';
import Picture, { PictureSize, PictureStyle } from '../Picture';
import { PermissionsEnum, UserProfileText } from 'erva-doce-common';
import Button from '../Button';
import { getMyProfileRoute } from '../../my-profile/MyProfile';
import {
    IconArrowDropdown,
    IconButtonBack,
    IconButtonBack2x,
    MainMenuEditUser,
    MainMenuEditUser2x,
} from '../images';
import {
    AiFillBank,
    AiFillCalculator,
    AiFillPhone,
    AiFillProduct,
    AiFillSliders,
    AiFillTruck,
    AiOutlineAppstoreAdd,
    AiOutlineAreaChart,
    AiOutlineShoppingCart,
} from 'react-icons/ai';
import { Link, useLocation } from 'react-router-dom';
import ProfileDropDown from '../ProfileDropDown';
import { getPanelPage } from '../../admin/sales/Panel';
import { getGeneralCashClosingPage } from '../../admin/sales/GeneralCashClosing';
import { getStockRoute } from '../../admin/stock/Stock';
import { getDivergenceReasonRoute } from '../../admin/divergence-reason/DivergenceReason';
import { getProductsRoute } from '../../admin/products/Products';
import { getProductFamiliesRoute } from '../../admin/products-families/ProductsFamilies';
import { getProductsCategoriesRoute } from '../../admin/products-categories/ProductsCategories';
import { getProductsBrandsRoute } from '../../admin/products-brands/ProductsBrands';
import { getSegmentationsRoute } from '../../admin/segmentations/Segmentations';
import { getQuotationsTypesRoute } from '../../admin/quotations-types/QuotationsTypes';
import { getTagsRoute } from '../../admin/tags/Tags';
import { getBillsRoute } from '../../admin/bills/Bills';
import { getBillsToPayRoute } from '../../admin/bills-to-pay/BillsToPay';
import { getBillsToReceiveRoute } from '../../admin/bills-to-receive/BillsToReceive';
import { getAccountsRoute } from '../../admin/accounts/Accounts';
import { getExpensesTypesRoute } from '../../admin/expenses-types/ExpensesTypes';
import { getPaymentsTypesRoute } from '../../admin/payments-types/PaymentsTypes';
import { getMachinesRoute } from '../../admin/machines/Machines';
import { getCollaboratorsRoute } from '../../admin/collaborators/Collaborators';
import { getJobsTitlesRoute } from '../../admin/jobs-titles/JobsTitles';
import { getStoresRoute } from '../../admin/stores/Stores';
import { getCashiersRoute } from '../../admin/cashier/Cashiers';
import { getTaxesClassificationsRoute } from '../../admin/taxes-classifications/TaxesClassifications';
import { getTaxesSituationsRoute } from '../../admin/taxes-situations/TaxesSituations';
import { getSuppliersRoute } from '../../admin/suppliers/Suppliers';
import { getRepresentativesRoute } from '../../admin/representatives/Representatives';
import { getPurchaseSuggestionRoute } from '../../admin/purchase-suggestion/PurchaseSuggestion';
import { getOrdersRoute } from '../../admin/order/Orders';
import { getOrderListRoute } from '../../admin/order-list/OrderList';
import { getOrderDocumentsRoute } from '../../admin/order-documents/OrderDocuments';
import { getCancelReasonRoute } from '../../admin/cancel-reason/CancelReason';
import { getCustomersRoute } from '../../admin/customers/Customers';
import { getCampaignRoute } from '../../admin/campaign/Campaign';
import { getCuponsRoute } from '../../admin/cupons';
import { getBannersRoute } from '../../admin/loja-virtual/banners';
import { getNewsletterRoute } from '../../admin/loja-virtual/newsletters';
import { getDeliveryAreaRoute } from '../../admin/loja-virtual/areas-de-entrega';
import { getParametrosRoute } from '../../admin/loja-virtual/parametros';
import { getMenuPrincipalRoute } from '../../admin/loja-virtual/menu-principal';
import { getRodapeRoute } from '../../admin/loja-virtual/rodape';
import * as LoginService from '../../services/LoginService';
import { getSalesRoute } from '../../admin/sales/Sales';
import { getExchangesRoute } from '../../admin/exchanges/Exchanges';
import { getAdminStockCheckRoute } from '../../admin/stock/admin-stock-check/AdminStockCheck';
import { getOrderReceiveRoute } from '../../admin/order-receive';
import { MdLogout } from 'react-icons/md';
import { getStockCheckRoute } from '../../admin/stock/stock-check/StockCheck';
import { getContactsRoute } from '../../admin/contacts/Contacts';
import { getFeedbacksRoute } from '../../admin/loja-virtual/feedbacks';
import { getVoucherRoute } from '../../admin/voucher/Voucher';
import { getSalesFormRoute } from '../../admin/sales/SalesForm';
import { getVirtualOrderListRoute } from '../../admin/loja-virtual/virtual-order-list/VirtualOrderList';
import { getVirtualOrderRoute } from '../../admin/loja-virtual/vitual-order/VirtualOrder';

export default function NewMenu() {
    const menuRef = useRef();
    const {
        menuCollapsed,
        setMenuCollapsed,
        user,
        setLoading,
        setIsLogged,
        setUser,
        backendConnectionError,
    } = useContext(EnvironmentContext);

    const [scroll, setScroll] = useState(0);

    const toggleMenu = () => {
        setMenuCollapsed((state) => !state);
    };

    const logoff = async () => {
        setLoading(true, false, 'Saindo...');

        try {
            await LoginService.logoff();
            setIsLogged(false);
            setUser(null);
        } catch (e) {
            backendConnectionError('Fail to logoff', e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (menuRef.current) {
            const onScroll = (e) => {
                setScroll(e.target.scrollTop);
            };

            menuRef?.current?.addEventListener('scroll', onScroll);

            return () =>
                menuRef?.current?.removeEventListener('scroll', onScroll);
        }
    }, [menuRef]);

    return (
        <div className={classNames('side-menu', { collapsed: menuCollapsed })}>
            <div
                className={'hide-desktop d-flex justify-content-end mr-16 mt-8'}
            >
                <a
                    aria-label={'Fechar menu'}
                    onClick={() => {
                        setMenuCollapsed(true);
                    }}
                    title={'Fechar menu'}
                >
                    <img
                        src={IconButtonBack}
                        srcSet={`${IconButtonBack} 1x, ${IconButtonBack2x} 2x`}
                        alt={'Fechar'}
                        className={'mr-4'}
                    />
                    {'Fechar'}
                </a>
            </div>
            <button onClick={toggleMenu} className={'btn-toggle hide-mobile'}>
                {menuCollapsed ? '>' : '<'}
            </button>
            <div className={'profile-container-menu'}>
                <Picture
                    picture={user?.picture}
                    name={user?.name}
                    style={PictureStyle.USER_ROUNDED}
                    size={PictureSize.MAIN_MENU}
                />
                <div className={'user-info'}>
                    <div className={'user-name'} title={user?.name ?? '...'}>
                        {user?.name ?? '...'}
                    </div>
                    <div className={'job-title'}>
                        {user.isAdmin
                            ? UserProfileText(user.profile)
                            : user?.jobTitle ?? ''}
                    </div>
                </div>
                <div className={'user-controls'}>
                    <Button
                        className={'button transparent'}
                        route={getMyProfileRoute()}
                    >
                        <img
                            src={MainMenuEditUser}
                            srcSet={`${MainMenuEditUser} 1x, ${MainMenuEditUser2x} 2x`}
                            alt={''}
                        />
                    </Button>
                </div>
            </div>
            <div className={'hide-desktop d-flex justify-content-center'}>
                <ProfileDropDown />
            </div>
            <hr />
            <div ref={menuRef} className={'menus-container'}>
                <Item
                    title={'Caixa'}
                    scroll={scroll}
                    visibleWhen={
                        user.isAdmin ||
                        user.roles?.includes(PermissionsEnum.CREATE_SALES) ||
                        user.roles?.includes(PermissionsEnum.CREATE_EXCHANGES)
                    }
                    route={'/vendas'}
                    icon={<AiOutlineShoppingCart />}
                    items={[
                        {
                            title: 'Vender',
                            visibleWhen:
                                user?.cashier?.id &&
                                (user.isAdmin ||
                                    user.roles?.includes(
                                        PermissionsEnum.CREATE_SALES
                                    )),
                            to: getSalesFormRoute(),
                        },
                        {
                            title: 'Painel',
                            visibleWhen:
                                user?.cashier?.id &&
                                (user.isAdmin ||
                                    user.roles?.includes(
                                        PermissionsEnum.CREATE_SALES
                                    )),
                            to: getPanelPage(),
                        },
                        {
                            title: 'Painel geral',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.HANDLE_SALES
                                ),
                            to: getGeneralCashClosingPage(),
                        },
                        {
                            title: 'Listagem de vendas',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.CREATE_SALES
                                ),
                            to: getSalesRoute(),
                        },
                        {
                            title: 'Listagem de devoluções',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.CREATE_EXCHANGES
                                ),
                            to: getExchangesRoute(),
                        },
                    ]}
                />
                <Item
                    title={'Produtos'}
                    scroll={scroll}
                    visibleWhen={
                        user.isAdmin ||
                        user.roles?.includes(PermissionsEnum.HANDLE_PRODUCTS) ||
                        user.roles?.includes(PermissionsEnum.CREATE_STOCK_CHECK) ||
                        user.roles?.includes(PermissionsEnum.VALIDATE_STOCK_CHECK) ||
                        user.roles?.includes(PermissionsEnum.HANDLE_PRODUCT_FISCAL) ||
                        user.roles?.includes(PermissionsEnum.HANDLE_PRODUCT_MAIN) ||
                        user.roles?.includes(PermissionsEnum.HANDLE_PRODUCT_PRICING) ||
                        user.roles?.includes(PermissionsEnum.VIEW_PRODUCT_FISCAL) ||
                        user.roles?.includes(PermissionsEnum.VIEW_PRODUCT_PRICING) ||
                        user.roles?.includes(PermissionsEnum.VIEW_PRODUCT_MAIN)
                    }
                    route={'/produtos'}
                    icon={<AiFillProduct />}
                    items={[
                        {
                            title: 'Estoque',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.VALIDATE_STOCK_CHECK
                                ) ||
                                user.roles?.includes(
                                    PermissionsEnum.CREATE_STOCK_CHECK
                                ),
                            to: '/produtos/estoque',
                            items: [
                                {
                                    title: 'Gerenciar',
                                    visibleWhen: user.isAdmin,
                                    to: getStockRoute(),
                                },
                                {
                                    title: 'Motivos de divergência',
                                    visibleWhen: user.isAdmin,
                                    to: getDivergenceReasonRoute(),
                                },
                                {
                                    title: 'Minhas conferências',
                                    visibleWhen:
                                        user.isAdmin ||
                                        user.roles?.includes(
                                            PermissionsEnum.CREATE_STOCK_CHECK
                                        ),
                                    to: getStockCheckRoute(),
                                },
                                {
                                    title: 'Administrar conferências',
                                    visibleWhen:
                                        user.isAdmin ||
                                        user.roles?.includes(
                                            PermissionsEnum.VALIDATE_STOCK_CHECK
                                        ),
                                    to: getAdminStockCheckRoute(),
                                },
                            ],
                        },
                        {
                            title: 'Listagem de produtos',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(PermissionsEnum.HANDLE_PRODUCTS) ||
                                user.roles?.includes(PermissionsEnum.HANDLE_PRODUCT_FISCAL) ||
                                user.roles?.includes(PermissionsEnum.HANDLE_PRODUCT_MAIN) ||
                                user.roles?.includes(PermissionsEnum.HANDLE_PRODUCT_PRICING) ||
                                user.roles?.includes(PermissionsEnum.VIEW_PRODUCT_FISCAL) ||
                                user.roles?.includes(PermissionsEnum.VIEW_PRODUCT_PRICING) ||
                                user.roles?.includes(PermissionsEnum.VIEW_PRODUCT_MAIN),
                            to: getProductsRoute(),
                        },
                        {
                            title: 'Famílias',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.HANDLE_PRODUCTS
                                ),
                            to: getProductFamiliesRoute(),
                        },
                        {
                            title: 'Categorias',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.HANDLE_PRODUCTS
                                ),
                            to: getProductsCategoriesRoute(),
                        },
                        {
                            title: 'Marcas',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.HANDLE_PRODUCTS
                                ),
                            to: getProductsBrandsRoute(),
                        },
                        {
                            title: 'Segmentações',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.HANDLE_PRODUCTS
                                ),
                            to: getSegmentationsRoute(),
                        },
                        {
                            title: 'Tipos de cotações',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.HANDLE_PRODUCTS
                                ),
                            to: getQuotationsTypesRoute(),
                        },
                        {
                            title: 'Impressão de etiquetas',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.HANDLE_PRODUCTS
                                ),
                            to: getTagsRoute(),
                        },
                    ]}
                />
                <Item
                    title={'Controle Financeiro'}
                    scroll={scroll}
                    visibleWhen={
                        user.isAdmin ||
                        user.roles?.includes(PermissionsEnum.HANDLE_BILLS)
                    }
                    route={'/financeiro'}
                    icon={<AiFillBank />}
                    items={[
                        {
                            title: 'Conta corrente',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.HANDLE_BILLS
                                ),
                            to: getBillsRoute(),
                        },
                        {
                            title: 'Contas a pagar',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.HANDLE_BILLS
                                ),
                            to: getBillsToPayRoute(),
                        },
                        {
                            title: 'Contas a receber',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.HANDLE_BILLS
                                ),
                            to: getBillsToReceiveRoute(),
                        },
                        {
                            title: 'Contas bancárias',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.HANDLE_BILLS
                                ),
                            to: getAccountsRoute(),
                        },
                        {
                            title: 'Tipos de despesas',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.HANDLE_BILLS
                                ),
                            to: getExpensesTypesRoute(),
                        },
                        {
                            title: 'Tipos de pagamentos',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.HANDLE_BILLS
                                ),
                            to: getPaymentsTypesRoute(),
                        },
                        {
                            title: 'Máquinas de pagamentos',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.HANDLE_BILLS
                                ),
                            to: getMachinesRoute(),
                        },
                    ]}
                />
                <Item
                    title={'Administrativo'}
                    scroll={scroll}
                    visibleWhen={
                        user.isAdmin ||
                        user.roles?.includes(PermissionsEnum.HANDLE_USERS) ||
                        user.roles?.includes(
                            PermissionsEnum.HANDLE_JOB_TITLES
                        ) ||
                        user.roles?.includes(PermissionsEnum.HANDLE_SUPPLIERS)||
                        user.roles?.includes(PermissionsEnum.CREATE_SALES)
                    }
                    route={'/administrativo'}
                    icon={<AiFillSliders />}
                    items={[
                        {
                            title: 'Colaboradores',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.HANDLE_USERS
                                ),
                            to: getCollaboratorsRoute(),
                        },
                        {
                            title: 'Caixas',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.HANDLE_USERS
                                ),
                            to: getCashiersRoute(),
                        },
                        {
                            title: 'Vales',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.CREATE_SALES
                                ),
                            to: getVoucherRoute(),
                        },
                        {
                            title: 'Cargos',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.HANDLE_JOB_TITLES
                                ),
                            to: getJobsTitlesRoute(),
                        },
                        {
                            title: 'Contatos',
                            visibleWhen: user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_SUPPLIERS),
                            to: getContactsRoute(),
                        },
                        {
                            title: 'Lojas',
                            visibleWhen: user.isAdmin,
                            to: getStoresRoute(),
                        },
                    ]}
                />
                <Item
                    title={'Fiscal'}
                    scroll={scroll}
                    visibleWhen={
                        user.isAdmin ||
                        user.roles?.includes(PermissionsEnum.HANDLE_FISCAL)
                    }
                    route={'/fiscal'}
                    icon={<AiFillCalculator />}
                    items={[
                        {
                            title: 'Classificações fiscais',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.HANDLE_FISCAL
                                ),
                            to: getTaxesClassificationsRoute(),
                        },
                        {
                            title: 'Situações tributárias',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.HANDLE_FISCAL
                                ),
                            to: getTaxesSituationsRoute(),
                        },
                    ]}
                />
                <Item
                    title={'Compras'}
                    scroll={scroll}
                    visibleWhen={
                        user.isAdmin ||
                        user.roles?.includes(
                            PermissionsEnum.HANDLE_SUGGESTIONS
                        ) ||
                        user.roles?.includes(PermissionsEnum.CHECK_ORDER) ||
                        user.roles?.includes(PermissionsEnum.EDIT_ORDER) ||
                        user.roles?.includes(PermissionsEnum.CONFIRM_ORDER) ||
                        user.roles?.includes(PermissionsEnum.BILLING_ORDER) ||
                        user.roles?.includes(PermissionsEnum.RECEIVE_ORDER)
                    }
                    route={'/compras'}
                    icon={<AiFillTruck />}
                    items={[
                        {
                            title: 'Fornecedores',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.HANDLE_SUPPLIERS
                                ),
                            to: getSuppliersRoute(),
                        },
                        {
                            title: 'Representantes',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.HANDLE_SUPPLIERS
                                ),
                            to: getRepresentativesRoute(),
                        },
                        {
                            title: 'Sugestões de pedidos',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.HANDLE_SUGGESTIONS
                                ),
                            to: getPurchaseSuggestionRoute(),
                        },
                        {
                            title: 'Receber mercadorias',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.RECEIVE_ORDER
                                ),
                            to: getOrderReceiveRoute(),
                        },
                        {
                            title: 'Pedidos',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.CHECK_ORDER
                                ) ||
                                user.roles?.includes(
                                    PermissionsEnum.EDIT_ORDER
                                ) ||
                                user.roles?.includes(
                                    PermissionsEnum.CONFIRM_ORDER
                                ) ||
                                user.roles?.includes(
                                    PermissionsEnum.BILLING_ORDER
                                ) ||
                                user.roles?.includes(
                                    PermissionsEnum.HANDLE_RECEIVE_ORDER
                                ),
                            to: '/compras/pedidos',
                            items: [
                                {
                                    title: 'Acompanhamento',
                                    visibleWhen:
                                        user.isAdmin ||
                                        user.roles?.includes(
                                            PermissionsEnum.CHECK_ORDER
                                        ) ||
                                        user.roles?.includes(
                                            PermissionsEnum.EDIT_ORDER
                                        ) ||
                                        user.roles?.includes(
                                            PermissionsEnum.CONFIRM_ORDER
                                        ) ||
                                        user.roles?.includes(
                                            PermissionsEnum.BILLING_ORDER
                                        ) ||
                                        user.roles?.includes(
                                            PermissionsEnum.HANDLE_RECEIVE_ORDER
                                        ),
                                    to: getOrdersRoute(),
                                },
                                {
                                    title: 'Listagem',
                                    visibleWhen:
                                        user.isAdmin ||
                                        user.roles?.includes(
                                            PermissionsEnum.CHECK_ORDER
                                        ) ||
                                        user.roles?.includes(
                                            PermissionsEnum.EDIT_ORDER
                                        ) ||
                                        user.roles?.includes(
                                            PermissionsEnum.CONFIRM_ORDER
                                        ) ||
                                        user.roles?.includes(
                                            PermissionsEnum.BILLING_ORDER
                                        ) ||
                                        user.roles?.includes(
                                            PermissionsEnum.HANDLE_RECEIVE_ORDER
                                        ),
                                    to: getOrderListRoute(),
                                },
                                {
                                    title: 'Notas importadas',
                                    visibleWhen:
                                        user.isAdmin ||
                                        user.roles?.includes(
                                            PermissionsEnum.BILLING_ORDER
                                        ),
                                    to: getOrderDocumentsRoute(),
                                },
                            ],
                        },
                        {
                            title: 'Motivos de cancelamento',
                            visibleWhen: user.isAdmin,
                            to: getCancelReasonRoute(),
                        },
                    ]}
                />
                <Item
                    title={'SAC'}
                    scroll={scroll}
                    visibleWhen={
                        user.isAdmin ||
                        user.roles?.includes(PermissionsEnum.HANDLE_CUSTOMERS)
                    }
                    route={'/sac'}
                    icon={<AiFillPhone />}
                    items={[
                        {
                            title: 'Clientes',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.HANDLE_CUSTOMERS
                                ),
                            to: getCustomersRoute(),
                        },
                    ]}
                />
                <Item
                    title={'Comercial'}
                    scroll={scroll}
                    visibleWhen={
                        user.isAdmin ||
                        user.roles?.includes(PermissionsEnum.HANDLE_CAMPAIGNS)
                    }
                    route={'/comercial'}
                    icon={<AiOutlineAreaChart />}
                    items={[
                        {
                            title: 'Campanhas',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.HANDLE_CAMPAIGNS
                                ),
                            to: getCampaignRoute(),
                        },
                        {
                            title: 'Cupons',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.HANDLE_CAMPAIGNS
                                ),
                            to: getCuponsRoute(),
                        },
                    ]}
                />
                <Item
                    title={'Loja virtual'}
                    scroll={scroll}
                    visibleWhen={
                        user.isAdmin ||
                        user.roles?.includes(
                            PermissionsEnum.HANDLE_VIRTUAL_STORE
                        )
                    }
                    route={'/loja-virtual'}
                    icon={<AiOutlineAppstoreAdd />}
                    items={[
                        {
                            title: 'Pedidos',
                            visibleWhen: user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_VIRTUAL_STORE),
                            to: '/loja-virtual/pedidos',
                            items: [
                                {
                                    title: 'Acompanhamento',
                                    visibleWhen: user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_VIRTUAL_STORE),
                                    to: getVirtualOrderRoute(),
                                },
                                {
                                    title: 'Listagem',
                                    visibleWhen: user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_VIRTUAL_STORE),
                                    to: getVirtualOrderListRoute(),
                                },
                            ],
                        },
                        {
                            title: 'Marketing',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.HANDLE_VIRTUAL_STORE
                                ),
                            to: '/loja-virtual/marketing',
                            items: [
                                {
                                    title: 'Banners',
                                    visibleWhen:
                                        user.isAdmin ||
                                        user.roles?.includes(
                                            PermissionsEnum.HANDLE_VIRTUAL_STORE
                                        ),
                                    to: getBannersRoute(),
                                },
                                {
                                    title: 'Newsletters',
                                    visibleWhen:
                                        user.isAdmin ||
                                        user.roles?.includes(
                                            PermissionsEnum.HANDLE_VIRTUAL_STORE
                                        ),
                                    to: getNewsletterRoute(),
                                },
                                {
                                    title: 'Feedbacks',
                                    visibleWhen:
                                        user.isAdmin ||
                                        user.roles?.includes(
                                            PermissionsEnum.HANDLE_VIRTUAL_STORE
                                        ),
                                    to: getFeedbacksRoute(),
                                },
                            ],
                        },
                        {
                            title: 'Entregas',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.HANDLE_VIRTUAL_STORE
                                ),
                            to: '/loja-virtual/entregas',
                            items: [
                                {
                                    title: 'Áreas de entrega',
                                    visibleWhen:
                                        user.isAdmin ||
                                        user.roles?.includes(
                                            PermissionsEnum.HANDLE_VIRTUAL_STORE
                                        ),
                                    to: getDeliveryAreaRoute(),
                                },
                            ],
                        },
                        {
                            title: 'Geral',
                            visibleWhen:
                                user.isAdmin ||
                                user.roles?.includes(
                                    PermissionsEnum.HANDLE_VIRTUAL_STORE
                                ),
                            to: '/loja-virtual/geral',
                            items: [
                                {
                                    title: 'Parâmetros',
                                    visibleWhen:
                                        user.isAdmin ||
                                        user.roles?.includes(
                                            PermissionsEnum.HANDLE_VIRTUAL_STORE
                                        ),
                                    to: getParametrosRoute(),
                                },
                                {
                                    title: 'Menu principal',
                                    visibleWhen:
                                        user.isAdmin ||
                                        user.roles?.includes(
                                            PermissionsEnum.HANDLE_VIRTUAL_STORE
                                        ),
                                    to: getMenuPrincipalRoute(),
                                },
                                {
                                    title: 'Rodapé',
                                    visibleWhen:
                                        user.isAdmin ||
                                        user.roles?.includes(
                                            PermissionsEnum.HANDLE_VIRTUAL_STORE
                                        ),
                                    to: getRodapeRoute(),
                                },
                            ],
                        },
                    ]}
                />
                <Item
                    title={'Sair'}
                    visibleWhen={true}
                    icon={<MdLogout />}
                    onClick={logoff}
                />
            </div>
        </div>
    );
}

function Item({ title, visibleWhen, icon, items, onClick, scroll, route }) {
    if (!visibleWhen) {
        return;
    }

    const { menuCollapsed } = useContext(EnvironmentContext);
    const location = useLocation();
    const active = location.pathname.startsWith(route);
    const ref = useRef();
    const itemsRef = useRef();
    const tooltipRef = useRef();

    const handleClick = () => {
        onClick && onClick();
    };

    useEffect(() => {
        if (ref.current) {
            const position = ref.current.getBoundingClientRect();

            if (itemsRef.current) {
                itemsRef.current.style.top = `${position.top}px`;
                itemsRef.current.style.left = 'calc(100%)';
            }

            if (tooltipRef.current) {
                tooltipRef.current.style.top = `calc(${position.top}px + 14px)`;
                tooltipRef.current.style.left = 'calc(100%)';
            }
        }
    }, [ref, itemsRef, tooltipRef, scroll]);

    return (
        <button
            onClick={handleClick}
            ref={ref}
            className={classNames('menu-item', { active })}
        >
            <div className={'icon'}>
                {React.cloneElement(icon, {
                    size: '24px',
                    color: '#000',
                })}
            </div>
            <p className={'text'}>{title}</p>
            {items?.length ? (
                <div ref={itemsRef} className={'items'}>
                    {menuCollapsed && <p>{title}</p>}
                    <Items items={items} />
                </div>
            ) : (
                <div ref={tooltipRef} className={'text-tooltip'}>
                    <div className={'triangle'}></div>
                    {title}
                </div>
            )}
        </button>
    );
}

function Items({ items }) {
    const location = useLocation();

    return (
        <ul className={'items-list'}>
            {items.map((item, index) => {
                if (!item.visibleWhen) {
                    return;
                }

                if (item.items?.length) {
                    return <SubItem key={index} item={item} />;
                }

                const active = location.pathname.startsWith(item.to);

                return (
                    <li key={index} className={classNames({ active })}>
                        {item.onClick ? (
                            <button
                                className={'item-link'}
                                onClick={item.onClick}
                            >
                                {item.title}
                            </button>
                        ) : (
                            <Link className={'item-link'} to={item.to}>
                                {item.title}
                            </Link>
                        )}
                    </li>
                );
            })}
        </ul>
    );
}

function SubItem({ item }) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <li>
            <button
                className={classNames('sub-item', { 'is-open': isOpen })}
                onClick={() => setIsOpen((state) => !state)}
            >
                {item.title}
                <img src={IconArrowDropdown} />
            </button>
            {isOpen && (
                <div className={'sub-items'}>
                    <Items items={item.items} />
                </div>
            )}
        </li>
    );
}
