import api from '../util/api';
export async function addCashMovement(formData) {
    return (await api.put('/cash-movements', formData)).data;
}

export async function getCahshMovements(
    page,
    search,
    order,
    type,
    day,
    cashieruuid,
    cancelToken
) {
    return (await api.get('/cash-movements/movements', {
        params: {
            page,
            search,
            order,
            type,
            day,
            cashieruuid

        },
        cancelToken: cancelToken

    })).data;
}

export async function deleteCashMovement(uuid, formData) {
    return (await api.put(`/cash-movements/${uuid}`, formData)).data;
}




