import api from '../util/api';

export async function getVirtualOrders(filters) {
    return (
        await api.get('/virtual-orders', { params: filters })
    ).data;
}

export async function getVirtualOrderById(id) {
    return (
        await api.get(`/virtual-orders/${id}`)
    ).data;
}

export async function getVirtualOrderProducts(id, filters) {
    return (await api.get(`/virtual-orders/${id}/products`, {
        params: filters,
    })).data;
}

export async function updateVirtualOrder(uuid, data) {
    return (await api.put(`/virtual-orders/${uuid}`, data)).data;
}

export async function generateNFCe(id, data) {
    return (await api.put(`/virtual-orders/${id}/nfce`, data)).data;
}