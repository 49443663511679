import './style.scss';

import { useContext, useEffect, useRef, useState } from 'react';
import ScreenHeader from '../../../components/logged/ScreenHeader';
import { getDashboardRoute } from '../../../dashboard/Dashboard';
import * as DeliveryAreasService from '../../../services/DeliveryAreasService';
import { EnvironmentContext } from '../../../contexts/EnviromentContext';
import Button, { ButtonColor, ButtonFontColor, ButtonStyle } from '../../../components/Button';
import { IconAdd, IconAdd2x, IconEdit, IconEdit2x, IconSelectArrow, IconSelectArrow2x } from '../../../components/images';
import Table from '../../../components/Table';
import { getDeliveryAreaRoute } from '.';
import { useNavigate, useParams } from 'react-router';
import Section from '../../../components/Section';
import { DeliveryMethodTypeText, ENUM_SHORTCUT, isUuid } from 'erva-doce-common';
import FieldText from '../../../components/FieldText';
import FieldZipCode, { unmaskZipCode } from '../../../components/FieldZipCode';
import DeliveryMethodFormModal from './DeliveryMethodFormModal';
import { extractCurrencyNumber, formatCurrency } from '../../../components/FieldCurrency';
import { formatValue } from '../../../util/formatValue';
import emptyField from '../../../util/validateEmptyTextField';

const initialState = {
    name: '',
    start: '',
    end: '',
    methods: [],
};

const initialMethodState = {};

export default function DeliveryAreaForm() {
    const { uuid } = useParams();
    const navigate = useNavigate();
    const { backendConnectionError, setConfirmModal, setInfoModal, addHotkey, removeHotkey } = useContext(EnvironmentContext);

    const isNew = uuid === 'novo';
    const title = (isNew ? 'Nova ' : 'Editar ') + 'área de entrega';

    const [isLoading, setIsLoading] = useState(false);
    const [deliveryArea, setDeliveryArea] = useState(initialState);
    const [formError, setFormError] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [selectedMethod, setSelectedMethod] = useState(null);
    const saveBtnRef = useRef();

    const updateFormData = (value) => {
        setDeliveryArea(state => ({
            ...state,
            ...value,
        }));
    };

    const hasValidationErrors = () => {
        let hasError = false;
        setFormError({});
        
        if (!deliveryArea.name || emptyField(deliveryArea.name)) {
            hasError = true;
            setFormError((prev) => ({
                ...prev,
                name: 'Preencha corretamente' 
            }));
        }

        if (!deliveryArea?.start && deliveryArea.start !== 0) {
            hasError = true;
            setFormError((prev) => ({
                ...prev,
                start: 'Preencha corretamente'
            }));
        }

        if (!deliveryArea.end) {
            hasError = true;
            setFormError((prev) => ({
                ...prev,
                end: 'Preencha corretamente'
            }));
        }

        return hasError;
    };

    const fetchDeliveryAreaInfo = async () => {
        try {
            setIsLoading(true);

            const res = await DeliveryAreasService.getByUUID(uuid);

            setDeliveryArea(res);
        } catch (err) {
            if(err?.response?.status === 404) {
                navigate(getDeliveryAreaRoute());
                return;            
            }
            backendConnectionError('Fail to fetch deliveryArea', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    const save = async () => {
        if (hasValidationErrors()) return;

        const onClose = () => {
            navigate(getDeliveryAreaRoute());
        };

        const proceed = async () => {
            try {
                setIsLoading(true);

                const data = {
                    id: deliveryArea?.id,
                    uuid: deliveryArea?.uuid,
                    name: deliveryArea.name,
                    order: deliveryArea.order,
                    start: unmaskZipCode(deliveryArea.start),
                    end: unmaskZipCode(deliveryArea.end),
                    methods: deliveryArea.methods.map((method, index) => ({
                        id: method?.id,
                        uuid: method?.uuid,
                        name: method.name,
                        type: method.type,
                        additionalDays: method.additionalDays,
                        additionalValue: method.additionalValue ? extractCurrencyNumber(method.additionalValue) : null,
                        order: index,
                    })),
                };

                if (isNew) {
                    await DeliveryAreasService.create(data);
                } else {
                    await DeliveryAreasService.update(uuid, data);
                }

                let message = 'Área de entrega alterada com sucesso!';

                if (isNew) {
                    message = 'Área de entrega criada com sucesso!';
                }

                setInfoModal({
                    title: 'Salvar Área de entrega',
                    message,
                    show: true,
                    onClose,
                });
            } catch (err) {
                console.log(err);
                backendConnectionError('Fail to save deliveryArea', err, null, title);
            } finally {
                setIsLoading(false);
            }
        };

        setConfirmModal({
            title: 'Salvar Área de entrega',
            message: 'Deseja realmente salvar a Área de entrega?',
            show: true,
            onConfirm: proceed,
        });
    };

    const destroy = () => {
        const onClose = () => {
            navigate(getDeliveryAreaRoute());
        };

        const proceed = async () => {
            try {
                setIsLoading(true);

                await DeliveryAreasService.destroy(uuid);

                setInfoModal({
                    title: 'Excluir Área de entrega',
                    message: 'Área de entrega excluída com sucesso!',
                    show: true,
                    onClose,
                });
            } catch (err) {
                console.log(err);
                backendConnectionError('Fail to destroy deliveryArea', err, null, title);
            } finally {
                setIsLoading(false);
            }
        };

        setConfirmModal({
            title: 'Excluir Área de entrega',
            message: 'Deseja realmente excluir a Área de entrega?',
            show: true,
            onConfirm: proceed,
        });
    };

    const updateOrder = (oldOrder, newOrder) => {
        const newMethods = [...deliveryArea.methods];
        const aux = newMethods[newOrder];

        if (newOrder > deliveryArea?.methods?.length - 1 || newOrder < 0) {
            return;
        }

        newMethods[newOrder] = newMethods[oldOrder];
        newMethods[oldOrder] = aux;

        updateFormData({
            methods: newMethods.map((x, i) => ({ ...x, order: i })),
        });
    };

    function updateFormDataTeste({ data }) {    
        const { uuid } = data;
        const index = deliveryArea.methods.findIndex((x) => x.uuid === uuid);
        if(index !== -1) {    
            setDeliveryArea((prev) => ({
                ...prev,
                methods: prev.methods.map((method) =>
                    method.uuid === data.uuid ? { ...method, ...data } : method
                )
            }));    
            setSelectedMethod({});    
            return;  
        }
        setDeliveryArea((prev) => ({
            ...prev,
            methods: [
                ...(prev.methods || []), { 
                    ...data, 
                    order: deliveryArea.methods.length, 
                }
            ],
        }));        
        setSelectedMethod({});        
    }

    function returnValue({ value }) {
        if(typeof value === 'string') {
            return formatCurrency(value);
        }
        return formatValue(value);
    }

    useEffect(() => {
        if (uuid && !isNew) {
            fetchDeliveryAreaInfo();
        }
    }, [uuid]);


    useEffect(() => {
        const shortcutCreatePrimary = addHotkey(ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY, () => {
            navigate(setSelectedMethod(initialMethodState));
        });

        const shortcutConfirm = addHotkey(ENUM_SHORTCUT.SHORTCUT_CONFIRM, () => {
            if(saveBtnRef.current) {
                saveBtnRef.current.click();
            }
        });

        return () => {
            removeHotkey(shortcutCreatePrimary);
            removeHotkey(shortcutConfirm);
        };
    }, []);


    return (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Loja virtual', route: getDashboardRoute() },
                        { name: 'Entregas', route: getDashboardRoute() },
                        { name: 'Áreas de entrega', route: getDeliveryAreaRoute() },
                        { name: title, route: getDeliveryAreaFormRoute(uuid) },
                    ]}
                    hideStore
                />
                <div className={'row mb-24'}>
                    <div className={'col-3'}>
                        <FieldText
                            label={'Nome'}
                            onChange={({ target }) => updateFormData({ name: target.value })}
                            value={deliveryArea?.name}
                            validationError={formError?.name}
                            maxLength={255}
                        />
                    </div>
                    <div className={'col-3'}>
                        <FieldZipCode
                            label={'Início'}
                            onChange={({ target }) => updateFormData({ start: target.value })}
                            value={`${deliveryArea?.start}`.padStart(8, '0')}
                            validationError={formError?.start}
                        />
                    </div>
                    <div className={'col-3'}>
                        <FieldZipCode
                            label={'Fim'}
                            onChange={({ target }) => updateFormData({ end: target.value })}
                            value={`${deliveryArea?.end}`.padStart(8, '0')}
                            validationError={formError?.end}
                        />
                    </div>
                </div>
                <Section title={'Métodos de entrega'}>
                    <div className={'row mb-16'}>
                        <div className={'col-6 col-md-3'}>
                            <Button
                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                color={ButtonColor.BUTTON_COLOR_GREEN}
                                icon={IconAdd}
                                icon2x={IconAdd2x}
                                onClick={() => {
                                    setShowModal(true);
                                }}
                                className={'w-100'}
                            >
                                {`Novo método de entrega [${ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY}]`}
                            </Button>
                        </div>
                    </div>
                    <div>
                        <Table
                            loading={isLoading}
                            columns={[
                                {
                                    width: '20px',
                                },
                                {
                                    name: 'Nome',
                                    align: 'left',
                                },
                                {
                                    name: 'Tipo',
                                    align: 'center',
                                },
                                {
                                    name: 'Dias adicionais',
                                    align: 'center',
                                },
                                {
                                    name: 'Valor adicional',
                                    align: 'center',
                                },
                                {
                                    name: 'Ordenação',
                                    align: 'center',
                                },
                                {
                                    name: 'Ações',
                                    align: 'center',
                                },
                            ]}
                        >
                            {deliveryArea?.methods?.map((method, index) => (
                                <tr key={method.id}>
                                    <td className={'d-flex flex-column'}>
                                        <Button
                                            className={'transparent'}
                                            onClick={() => updateOrder(index, index - 1)}
                                            disabled={index === 0}
                                        >
                                            <img
                                                src={IconSelectArrow}
                                                srcSet={`${IconSelectArrow} 1x, ${IconSelectArrow2x} 2x`}
                                                alt={'Subir ordem'}
                                                title={'Subir ordem'}
                                                style={{
                                                    width: '16px',
                                                    transform: 'rotateZ(180deg)'
                                                }}
                                            />
                                        </Button>
                                        <Button
                                            className={'transparent mt-8'}
                                            onClick={() => updateOrder(index, index + 1)}
                                            disabled={index === deliveryArea?.methods?.length - 1}
                                        >
                                            <img
                                                src={IconSelectArrow}
                                                srcSet={`${IconSelectArrow} 1x, ${IconSelectArrow2x} 2x`}
                                                alt={'Descer ordem'}
                                                title={'Descer ordem'}
                                                style={{
                                                    width: '16px',
                                                }}
                                            />
                                        </Button>
                                    </td>
                                    <td className={'text-start'}>{method?.name}</td>
                                    <td className={'text-center'}>{DeliveryMethodTypeText(method?.type)}</td>
                                    <td className={'text-center'}>{method?.additionalDays || '-'}</td>
                                    <td className={'text-center'}>{returnValue({ value:method?.additionalValue }) || '-'}</td>
                                    <td className={'text-center'}>{method?.order}</td>
                                    <td className={'text-center'}>
                                        <Button
                                            className={'transparent mt-8'}
                                            onClick={() => {
                                                setShowModal(true);
                                                setSelectedMethod(method);
                                            }}
                                        >
                                            <img
                                                src={IconEdit}
                                                srcSet={`${IconEdit} 1x, ${IconEdit2x} 2x`}
                                                alt={'Editar método'}
                                                title={'Editar método'}
                                                style={{
                                                    width: '16px',
                                                }}
                                            />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </Table>
                    </div>
                </Section>
                <div className={'row mt-24'}>
                    <div className={'col-8'}></div>
                    <div className={'col-2'}>
                        {
                            !isNew && (
                                <Button
                                    className={'w-100'}
                                    color={ButtonColor.BUTTON_COLOR_RED}
                                    fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                                    onClick={destroy}
                                >
                                    {'Excluir'}
                                </Button>
                            )
                        }
                    </div>
                    <div className={'col-2'}>
                        <Button
                            className={'w-100'}
                            color={ButtonColor.BUTTON_COLOR_GREEN}
                            fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                            onClick={save}
                            ref={saveBtnRef}
                        >
                            {`Salvar [${ENUM_SHORTCUT.SHORTCUT_CONFIRM}]`}
                        </Button>
                    </div>
                </div>
            </div>
            {
                showModal && (
                    <DeliveryMethodFormModal
                        show={showModal}
                        initialData={selectedMethod || {}}
                        onConfirm={(newMethod) => {
                            setShowModal(false);
                            updateFormDataTeste({ data: newMethod });
                            setSelectedMethod(null);
                        }}
                        onCancel={() => {
                            setShowModal(false);
                            setSelectedMethod(null);
                        }}
                    />
                )
            }
            
        </>
    );
}

export function getDeliveryAreaFormRoute(uuid) {
    if (uuid) {
        return `/loja-virtual/entregas/areas-de-entrega/${uuid}`;
    } else {
        return '/loja-virtual/entregas/areas-de-entrega/novo';
    }
}
