import { Link } from 'react-router-dom';
import Table from '../../../components/Table';
import { formatValue } from '../../../util/formatValue';
import { OrderByPurchaseSuggestionDetailedEnum } from 'erva-doce-common';
import { getProductFormRoute } from '../../products/ProductsForm';

export default function VirtualOrderProductsTable({
    data,
    isLoading,
    filter,
    onSortChange,
}) {
    return (
        <Table
            loading={isLoading}
            currentSort={filter.order}
            columns={[
                {
                    name: 'Código de barras',
                    width: '20%',
                    sortAsc: OrderByPurchaseSuggestionDetailedEnum.PRODUCT_NAME_ASC,
                    sortDesc: OrderByPurchaseSuggestionDetailedEnum.PRODUCT_NAME_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
                {
                    name: 'Produtos',
                    width: '30%',
                    sortAsc: OrderByPurchaseSuggestionDetailedEnum.PRODUCT_NAME_ASC,
                    sortDesc: OrderByPurchaseSuggestionDetailedEnum.PRODUCT_NAME_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
                {
                    name: 'Pedido QTD',
                    align: 'center',
                },
                {
                    name: 'Preço',
                    align: 'center',
                    sortAsc: OrderByPurchaseSuggestionDetailedEnum.COST_ASC,
                    sortDesc: OrderByPurchaseSuggestionDetailedEnum.COST_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
                {
                    name: 'Total',
                    align: 'center',
                    sortAsc: OrderByPurchaseSuggestionDetailedEnum.TOTAL_ASC,
                    sortDesc: OrderByPurchaseSuggestionDetailedEnum.TOTAL_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
            ]}
        >
            {data?.map((product, index) => (
                <tr key={index}>
                    <td>{product.barCodes}</td>
                    <td>
                        <Link
                            className={'product-name'}
                            onClick={(e) => e.stopPropagation()}
                            target={'_blank'}
                            to={getProductFormRoute(product.uuid)}
                        >
                            {product.name}
                        </Link>
                    </td>
                    <td className={'count-selected text-center'}>
                        {product.qtyOrder}
                    </td>
                    <td className={'text-center'}>
                        {formatValue(product.price)}
                    </td>
                    <td className={'text-center'}>
                        {formatValue(product.price * product.qtyOrder)}
                    </td>
                </tr>
            ))}
        </Table>
    );
}
