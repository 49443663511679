import { useContext, useEffect, useRef, useState } from 'react';
import SimpleConfirmModal from '../../../components/modal/SimpleConfirmModal';
import FieldCollaborator from '../../../components/FieldCollaborator';

import './StockCheckFormModal.scss';
import FieldStore from '../../../components/FieldStore';
import FieldCheckbox from '../../../components/FieldCheckbox';
import FieldText from '../../../components/FieldText';
import { EnvironmentContext } from '../../../contexts/EnviromentContext';
import * as CollaboratorsService from '../../../services/CollaboratorsService';
import * as StockCheckService from '../../../services/StockCheckService';
import { InfoModalStyle } from '../../../components/modal/InfoModal';
import ErrorMessage from '../../../components/ErrorMessage';

const INITIAL_STATE = {
    checkCD: false,
    checkStore: false,
    store: null,
    responsible: null,
    description: '',
};

export default function StockCheckFormModal({
    show,
    onCancel,
    onConfirm,
    id,
    title = 'Cadastrar conferência',
    responsible,
}) {
    const isNew = !id;
    const { backendConnectionError, selectedStore, user, setInfoModal } =
        useContext(EnvironmentContext);

    const inputRef = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(INITIAL_STATE);
    const [enabledStores, setEnabledStores] = useState([]);
    const [formError, setFormError] = useState({});

    const cancel = () => {
        setData({});
        setEnabledStores([]);
        setFormError({});
        onCancel();
    };

    const hasValidationError = () => {
        let hasErrorTeste = false;
        setFormError({});

        if (!data?.checkCD && !data?.checkStore) {
            hasErrorTeste = true;
            setFormError((prev) => ({
                ...prev,
                checks: 'Escolha uma das opções acima',
            }));
        }

        if(!data?.description || data?.description === '') {
            hasErrorTeste = true;
            setFormError((prev) => ({
                ...prev,
                description: 'A descrição é obrigatória'
            }));
        }

        if((!data?.checkCD || data?.checkCD) && !data.responsible) {
            hasErrorTeste = true;
            setFormError((prev) => ({
                ...prev,
                responsible: 'Selecione um resposável'
            }));
        }

        if((!data?.checkStore || data?.checkStore) && !data.store) {
            hasErrorTeste = true;
            setFormError((prev) => ({
                ...prev,
                checkStore: 'Selecione uma loja'
            }));
        }

        return hasErrorTeste;
    };

    const confirm = async () => {
        try {
            if (hasValidationError()) return;
            setIsLoading(true);

            const entity = {
                ...data,
                author: user.uuid,
            };
            
            if (isNew) {
                await StockCheckService.create(entity);
            } else {
                await StockCheckService.update(id, entity);
            }

            setInfoModal({
                title,
                message: 'Conferência cadastrada com sucesso',
                style: InfoModalStyle.SUCCESS,
                show: true,
                onClose: () => onConfirm(),
            });
            setFormError({});
            setData({});
        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to post info', err, null, title);
        } finally {
            setIsLoading(false);
            setEnabledStores([]);
        }
    };

    const updateData = (value) => {
        setData((state) => ({
            ...state,
            ...value,
        }));
    };

    const getData = async () => {
        try {
            setData({});
        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to fetch info', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    const getEnabledStores = async () => {
        try {
            setIsLoading(true);

            const response = await CollaboratorsService.getCollaborator(
                data.responsible.id
            );

            setEnabledStores(
                response?.stores?.map((x) => ({
                    id: x.uuid,
                    fantasyName: x.fantasyName,
                })) || []
            );
        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to fetch info', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            getData().then();
        } else {
            setData({});
        }
    }, [id]);

    useEffect(() => {
        if (data.responsible) {
            getEnabledStores().then();
        } else {
            updateData({ store: null });
            setEnabledStores([]);
        }
    }, [data.responsible]);

    useEffect(() => {
        if (responsible) {
            updateData({ responsible });
            updateData({
                store: {
                    id: selectedStore.uuid,
                    fantasyName: selectedStore.fantasyName,
                },
            });
        }
    }, [responsible]);
    
    return (
        <SimpleConfirmModal
            show={show}
            onCancel={cancel}
            onConfirm={confirm}
            title={title}
            className={'register-stock-check-modal'}
        >
            <div className={'suggestion_modal'}>
                <div>
                    <div className={'checkbox-group'}>
                        <FieldCheckbox
                            label={'Local de estoque'}
                            readOnly={!isNew}
                            inputs={[
                                {
                                    type: 'radio',
                                    label: 'Loja',
                                    checked: data.checkCD === false,
                                    onChange: () => {
                                        updateData({
                                            checkCD: false,
                                            checkStore: true,
                                        });
                                    },
                                },
                                {
                                    type: 'radio',
                                    label: 'CD',
                                    checked: data.checkCD,
                                    onChange: () => {
                                        updateData({
                                            checkCD: true,
                                            checkStore: false,
                                            store: null,
                                        });
                                    },
                                },
                            ]}
                        />
                    </div>
                    {formError?.checks && (
                        <ErrorMessage errorMessage={formError?.checks} />
                    )}
                </div>
                <div>

                    <FieldText
                        ref={inputRef}
                        label={'Descrição'}
                        onChange={({ target }) =>
                            updateData({ description: target.value })
                        }
                        value={data?.description || ''}
                        maxLength={80}
                    />
                    {
                        formError?.description && (
                            <ErrorMessage errorMessage={formError?.description}/>
                        )
                    }
                </div>
                <div className={'row'}>
                    <div className={'col-6'}>
                        <div>
                            <FieldCollaborator
                                label={'Responsável'}
                                multipleSelection={false}
                                onSelected={(responsible) => {
                                    updateData({ responsible });
                                }}
                                select={data.responsible}
                                readOnly={responsible || !isNew}
                            />

                            {
                                formError?.responsible && (
                                    <ErrorMessage errorMessage={formError?.responsible}/>
                                )
                            }
                        </div>
                    </div>
                    <div className={'col-6'}>
                        <div>
                            {data.checkCD === false && (
                                <FieldStore
                                    label={'Loja para conferência'}
                                    multipleSelection={false}
                                    select={data?.store}
                                    onSelected={(store) => {
                                        if (store) {
                                            updateData({
                                                store: {
                                                    id: store.id,
                                                    fantasyName: store.value,
                                                },
                                            });
                                        }
                                    }}
                                />
                            )}
                            {
                                (formError?.checkStore && data.checkStore) && (
                                    <ErrorMessage errorMessage={formError?.checkStore}/>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </SimpleConfirmModal>
    );
}
