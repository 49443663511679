import Table from '../../components/Table';
import { maskCnpjString } from '../../components/FieldCnpj';
import { getHandleScanProductsRoute } from './HandleScanProducts';
import { useNavigate } from 'react-router';
import { getOrderReceiveDetailRoute } from './OrderReceiveDetail';
import PermissionedLink from '../../components/PermissionedLink';
import { PermissionsEnum } from 'erva-doce-common';
import { getRepresentativeFormRoute } from '../representatives/RepresentativeForm';
import { getCollaboratorFormRoute } from '../collaborators/CollaboratorForm';
import { getSupplierFormRoute } from '../suppliers/SupplierForm';

export default function OrderReceivesTable({
    data,
    filter,
    isLoading,
}) {
    const navigate = useNavigate();

    return (
        <Table
            loading={isLoading}
            currentSort={filter.order}
            columns={[
                {
                    name: 'N° Recebimento',
                },
                {
                    name: 'Fornecedor/Representante',
                },
                {
                    name: 'CNPJ',
                    align: 'center',
                },
                {
                    name: 'Responsável',
                    align: 'center',
                },
                {
                    name: 'Data',
                    align: 'center',
                },
                {
                    name: 'Finalizado em',
                    align: 'center',
                },
            ]}
        >
            {data?.map((receive, index) => (
                <tr key={index} onClick={() => {
                    navigate(getOrderReceiveDetailRoute(receive.uuid));
                }}>
                    <td className={'supplier'}>
                        <div>
                            <p>{`${receive.id}`.padStart(7, '0')}</p>
                            <p>
                                {receive.createdAt}
                            </p>
                        </div>
                    </td>
                    <td className={'supplier'}>
                        <div>
                            <p className={'supplier-p-info'}>
                                <PermissionedLink
                                    permission={PermissionsEnum.HANDLE_SUPPLIERS}
                                    to={getSupplierFormRoute(receive?.supplierUuid)}
                                >
                                    {receive.supplierName}
                                </PermissionedLink>
                            </p>

                            <p className={'supplier-p-detail'}>
                                <PermissionedLink
                                    permission={PermissionsEnum.HANDLE_SUPPLIERS}
                                    to={getRepresentativeFormRoute(receive?.representativeUuid)}
                                >
                                    {receive.representativeName}
                                </PermissionedLink>
                            </p>
                        </div>
                    </td>
                    <td className={'text-center'}>{maskCnpjString(receive.supplierCNPJ)}</td>
                    <td className={'text-center'}>
                        <PermissionedLink
                            permission={PermissionsEnum.HANDLE_SUPPLIERS}
                            to={getCollaboratorFormRoute(receive?.responsibleUuid)}
                        >
                            {receive.responsibleName}
                        </PermissionedLink>
                    </td>
                    <td className={'text-center'}>{receive.createdAt}</td>
                    <td className={'text-center'}>{receive.finishedAt || '-'}</td>
                </tr>
            ))}
        </Table>
    );
}
