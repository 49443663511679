export enum PermissionsEnum {
  CREATE_SALES = "CREATE_SALES",
  CREATE_EXCHANGES = "CREATE_EXCHANGES",
  HANDLE_PRODUCTS = "HANDLE_PRODUCTS",
  CREATE_STOCK_CHECK = "CREATE_STOCK_CHECK",
  VALIDATE_STOCK_CHECK = "VALIDATE_STOCK_CHECK",
  HANDLE_BILLS = "HANDLE_BILLS",
  HANDLE_USERS = "HANDLE_USERS",
  HANDLE_JOB_TITLES = "HANDLE_JOB_TITLES",
  HANDLE_SUPPLIERS = "HANDLE_SUPPLIERS",
  HANDLE_FISCAL = "HANDLE_FISCAL",
  HANDLE_SUGGESTIONS = "HANDLE_SUGGESTIONS",
  CHECK_ORDER = "CHECK_ORDER",
  EDIT_ORDER = "EDIT_ORDER",
  CONFIRM_ORDER = "CONFIRM_ORDER",
  BILLING_ORDER = "BILLING_ORDER",
  RECEIVE_ORDER = "RECEIVE_ORDER",
  HANDLE_RECEIVE_ORDER = "HANDLE_RECEIVE_ORDER",
  HANDLE_CUSTOMERS = "HANDLE_CUSTOMERS",
  HANDLE_CAMPAIGNS = "HANDLE_CAMPAIGNS",
  HANDLE_VIRTUAL_STORE = "HANDLE_VIRTUAL_STORE",
  HANDLE_SALES = "HANDLE_SALES",
  HANDLE_PRODUCT_MAIN = "HANDLE_PRODUCT_MAIN",
  HANDLE_PRODUCT_PRICING = "HANDLE_PRODUCT_PRICING",
  HANDLE_PRODUCT_FISCAL = "HANDLE_PRODUCT_FISCAL",
  VIEW_PRODUCT_MAIN = "VIEW_PRODUCT_MAIN",
  VIEW_PRODUCT_PRICING = "VIEW_PRODUCT_PRICING",
  VIEW_PRODUCT_FISCAL = "VIEW_PRODUCT_FISCAL",
}

export function PermissionsText(Permissions: PermissionsEnum | string) {
  switch (Permissions) {
    case PermissionsEnum.CREATE_SALES:
      return "Realizar vendas";
    case PermissionsEnum.CREATE_EXCHANGES:
      return "Realizar devoluções";
    case PermissionsEnum.HANDLE_PRODUCTS:
      return "Gerenciar produtos";
    case PermissionsEnum.CREATE_STOCK_CHECK:
      return "Cadastrar conferências";
    case PermissionsEnum.VALIDATE_STOCK_CHECK:
      return "Validar conferências";
    case PermissionsEnum.HANDLE_BILLS:
      return "Gerenciar contas";
    case PermissionsEnum.HANDLE_USERS:
      return "Gerenciar colaboradores";
    case PermissionsEnum.HANDLE_JOB_TITLES:
      return "Gerenciar cargos";
    case PermissionsEnum.HANDLE_SUPPLIERS:
      return "Gerenciar fornecedores";
    case PermissionsEnum.HANDLE_FISCAL:
      return "Gerenciar fiscal";
    case PermissionsEnum.HANDLE_SUGGESTIONS:
      return "Gerenciar sugestões";
    case PermissionsEnum.CHECK_ORDER:
      return "Conferir pedidos";
    case PermissionsEnum.EDIT_ORDER:
      return "Negociar pedidos";
    case PermissionsEnum.CONFIRM_ORDER:
      return "Confirmar pedidos";
    case PermissionsEnum.BILLING_ORDER:
      return "Faturar pedidos";
    case PermissionsEnum.RECEIVE_ORDER:
      return "Receber pedidos";
    case PermissionsEnum.HANDLE_RECEIVE_ORDER:
      return "Gerenciar recebimento de pedidos";
    case PermissionsEnum.HANDLE_CUSTOMERS:
      return "Gerenciar clientes";
    case PermissionsEnum.HANDLE_CAMPAIGNS:
      return "Gerenciar campanhas";
    case PermissionsEnum.HANDLE_VIRTUAL_STORE:
      return "Gerenciar loja virtual";
    case PermissionsEnum.HANDLE_SALES:
      return "Gerenciar vendas";
    case PermissionsEnum.HANDLE_PRODUCT_MAIN:
      return "Gerenciar características de produto";
    case PermissionsEnum.HANDLE_PRODUCT_PRICING:
      return "Gerenciar precificação de produto";
    case PermissionsEnum.HANDLE_PRODUCT_FISCAL:
      return "Gerenciar fiscal de produto";
    case PermissionsEnum.VIEW_PRODUCT_PRICING:
      return "Visualizar precificação de produto";
    case PermissionsEnum.VIEW_PRODUCT_FISCAL:
      return "Visualizar fiscal de produto";
    case PermissionsEnum.VIEW_PRODUCT_MAIN:
      return "Visualizar características de produto";
    default:
      throw new Error(`Not implemented for ${Permissions}`);
  }
}

export function getPermissionDescription(
  Permissions: PermissionsEnum | string
) {
  switch (Permissions) {
    case PermissionsEnum.CREATE_SALES:
      return "Permite criar e listar vendas.";
    case PermissionsEnum.CREATE_EXCHANGES:
      return "Permite criar e listar devolução.";
    case PermissionsEnum.HANDLE_SALES:
      return "Permite realizar vendas e devolução";
    case PermissionsEnum.HANDLE_PRODUCTS:
      return "Permite criar e editar produtos, gerenciar etiquetas, famílias, categorias, marcas, segmentações e cotações";
    case PermissionsEnum.CREATE_STOCK_CHECK:
      return "Permite registrar conferências de estoque.";
    case PermissionsEnum.VALIDATE_STOCK_CHECK:
      return "Permite validar conferências de estoque.";
    case PermissionsEnum.HANDLE_BILLS:
      return "Permite cadastrar e editar contas a pagar, contas a receber, contas bancárias, tipos de despesas, tipos de pagamentos e máquinas de pagamento.";
    case PermissionsEnum.HANDLE_USERS:
      return "Permite cadastrar e editar colaboradores e caixas.";
    case PermissionsEnum.HANDLE_JOB_TITLES:
      return "Permite cadastrar e editar cargos";
    case PermissionsEnum.HANDLE_SUPPLIERS:
      return "Permite cadastrar e editar fornecedores e representantes.";
    case PermissionsEnum.HANDLE_FISCAL:
      return "Permite cadastrar e editar classificação fiscal e situações tributárias.";
    case PermissionsEnum.HANDLE_SUGGESTIONS:
      return "Permite criar e editar sugestões de pedidos, pedidos e motivos de cancelamento.";
    case PermissionsEnum.CHECK_ORDER:
      return "Permite conferir e visualizar pedidos de compras.";
    case PermissionsEnum.EDIT_ORDER:
      return "Permite enviar, negociar e visualizar pedidos de compras.";
    case PermissionsEnum.CONFIRM_ORDER:
      return "Permite confirmar e visualizar pedidos de compras";
    case PermissionsEnum.BILLING_ORDER:
      return "Permite realizar o faturamento de pedidos de compras.";
    case PermissionsEnum.RECEIVE_ORDER:
      return "Permite realizar o recebimento de pedidos de compras.";
    case PermissionsEnum.HANDLE_RECEIVE_ORDER:
      return "Gerenciar recebimento de pedidos";
    case PermissionsEnum.HANDLE_CUSTOMERS:
      return "Permite gerenciar o controle de clientes da loja.";
    case PermissionsEnum.HANDLE_CAMPAIGNS:
      return "Permite criar e editar campanhas e cupons.";
    case PermissionsEnum.HANDLE_VIRTUAL_STORE:
      return "Permite gerenciar marketing, áreas de entrega, métodos de pagamento, menu e rodapé da loja virtual.";
    case PermissionsEnum.HANDLE_PRODUCT_MAIN:
      return "Permite editar a aba 'Características' da página do produto";
    case PermissionsEnum.HANDLE_PRODUCT_PRICING:
      return "Permite editar a aba 'Precificação' da página do produto";
    case PermissionsEnum.HANDLE_PRODUCT_FISCAL:
      return "Permite editar a aba 'Fiscal' da página do produto";
    case PermissionsEnum.VIEW_PRODUCT_PRICING:
      return "Permite visualizar a aba 'Precificação' da página do produto";
    case PermissionsEnum.VIEW_PRODUCT_FISCAL:
      return "Permite visualizar a aba 'Fiscal' da página do produto";
    case PermissionsEnum.VIEW_PRODUCT_MAIN:
      return "Permite visualizar a aba 'Características' da página do produto";
    default:
      throw new Error(`Not implemented for ${Permissions}`);
  }
}
