import React, { useContext, useEffect, useRef, useState } from 'react';
import ScreenHeader from '../../../components/logged/ScreenHeader';
import { getDashboardRoute } from '../../../dashboard/Dashboard';
import { ENUM_SHORTCUT, OrderByVirtualOrdersEnum, VirtualOrderStatus, VirtualOrderStatusEnum } from 'erva-doce-common';
import { EnvironmentContext } from '../../../contexts/EnviromentContext';
import * as VirtualOrderService from '../../../services/VirtualOrderService';
import FieldTextSearch from '../../../components/FieldTextSearch';
import Button, { ButtonColor, ButtonStyle } from '../../../components/Button';
import * as Tabs from '@radix-ui/react-tabs';
import Pagination from '../../../components/Pagination';
import VirtualOrdersFilterModal from './VirtualOrdersFilterModal';
import VirtualOrdersTable from './VirtualOrdersTable';
import { InfoModalStyle } from '../../../components/modal/InfoModal';

const FILTERS = {
    order: OrderByVirtualOrdersEnum.ID_ASC,
    search: null,
    page: 0,
    status: VirtualOrderStatusEnum.RECEIVED,
    customer: '',
    customerCpf: ''
};

export default function VirtualOrder() {
    const inputSearchCustomerRef = useRef();
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [showFilterModal, setShowFilterModal] = useState(null);
    const [filter, setFilter] = useState(FILTERS);
    const [search, setSearch] = useState({ customer: '' });
    const filterBtnRef = useRef();

    const { addHotkey, removeHotkey, setInfoModal, setLoading, backendConnectionError } = useContext(EnvironmentContext);

    const updateFilter = (name, value) => {
        setFilter({
            ...filter,
            [name]: value,
        });
    };

    const orderData = (order) => {
        const data = {
            customer: order.customer,
            cart: order.cart,
            seller: order.seller,
            deliveryMethod: order?.delivery.id,
            deliveryType: order?.delivery?.type,
            paymentMethod: order?.paymentMethod,
            virtualOrderPayment: order?.virtualOrderPayment,
            total: order.total,
        };

        return data;
    };

    const onSeparatingChange = async (uuid, showInfoMessage=true) => {
        try {
            setLoading(true);
            setIsLoading(true);

            const order = data?.records.find(item => item.uuid === uuid);
            const item = orderData(order);
            await VirtualOrderService.updateVirtualOrder(uuid, {
                ...item,
                status: VirtualOrderStatusEnum.SEPARATING
            });

            if (showInfoMessage) {
                setInfoModal({
                    title: 'Iniciar separação',
                    message: 'Pedido iniciado com sucesso!',
                    style: InfoModalStyle.SUCCESS,
                    show: true,
                });
            }
            await fetchOrders();
        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to save order', err, null, 'Separação do pedido');
        } finally {
            setIsLoading(false);
            setLoading(false);
        }
    };

    const onGenerateNFCeChange = async (uuid, showInfoMessage=true) => {
        try {
            setLoading(true);
            setIsLoading(true);

            const order = data?.records.find(item => item.uuid === uuid);
            const item = orderData(order);
            await VirtualOrderService.generateNFCe(order.id, {
                ...item,
                status: VirtualOrderStatusEnum.NOTE_ISSUED
            });

            if (showInfoMessage) {
                setInfoModal({
                    title: 'Emitir Nota Fiscal',
                    message: 'NFCe do pedido gerado com sucesso!',
                    style: InfoModalStyle.SUCCESS,
                    show: true,
                });
            }
            await fetchOrders();
        } catch (err) {
            console.log(err);
            backendConnectionError(
                'Fail to save order', 
                err, 
                null, 
                'Emitir Nota Fiscal',
                (error) => error
            );
        } finally {
            setIsLoading(false);
            setLoading(false);
        }
    };

    const onSubmittedChange = async (uuid, showInfoMessage=true) => {
        try {
            setLoading(true);
            setIsLoading(true);
            
            const order = data?.records.find(item => item.uuid === uuid);
            const item = orderData(order);
            await VirtualOrderService.updateVirtualOrder(uuid, {
                ...item,
                status: VirtualOrderStatusEnum.SUBMITTED
            });

            if (showInfoMessage) {
                setInfoModal({
                    title: 'Pedido enviado',
                    message: 'Pedido enviado com sucesso!',
                    style: InfoModalStyle.SUCCESS,
                    show: true,
                });
            }
            await fetchOrders();
        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to save order', err, null, 'Pedido enviado');
        } finally {
            setIsLoading(false);
            setLoading(false);
        }
    };

    const onDeliveredChange = async (uuid, showInfoMessage=true) => {
        try {
            setLoading(true);
            setIsLoading(true);
            
            const order = data?.records.find(item => item.uuid === uuid);
            const item = orderData(order);
            await VirtualOrderService.updateVirtualOrder(uuid, {
                ...item,
                status: VirtualOrderStatusEnum.DELIVERED
            });

            if (showInfoMessage) {
                setInfoModal({
                    title: 'Pedido recebido',
                    message: 'Pedido recebido com sucesso!',
                    style: InfoModalStyle.SUCCESS,
                    show: true,
                });
            }
            await fetchOrders();
        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to save order', err, null, 'Pedido recebido');
        } finally {
            setIsLoading(false);
            setLoading(false);
        }
    };

    const handleFilterTabChange = (ev) => {
        let status = ev;
        if (ev === VirtualOrderStatusEnum.CANCELLED || ev === VirtualOrderStatusEnum.DELIVERED) {
            status = VirtualOrderStatusEnum.COMPLETED;
        }
        
        setFilter({
            ...filter,
            status,
        });
    };

    const fetchOrders = async () => {
        try {
            setIsLoading(true);
            const data = await VirtualOrderService.getVirtualOrders(filter);
            setData(data);
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchOrders();
    }, [filter]);

    useEffect(() => {
        const shortcurtFilter = addHotkey(ENUM_SHORTCUT.SHORTCUT_FILTER, () => {
            if(filterBtnRef.current) {
                filterBtnRef.current.click();
            }
        });
        const shortcutSearchPrimary = addHotkey(ENUM_SHORTCUT.SHORTCUT_SEARCH_PRIMARY, () => {
            inputSearchCustomerRef.current?.focus();
        });

        return () => {
            removeHotkey(shortcutSearchPrimary);
            removeHotkey(shortcurtFilter);
        };
    }, []);

    return (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    title={'Acompanhamento de pedidos'}
                    breadcrumbs={[
                        { name: 'Loja virtual', route: getDashboardRoute() },
                        {
                            name: 'Acompanhamento',
                        },
                    ]}
                    hideStore
                />
                <div className={'row justify-content-end mb-32'}>
                    <div className={'col-3'}>
                        <FieldTextSearch
                            ref={inputSearchCustomerRef}
                            label={
                                `<em>Buscar por <strong>clientes [${ENUM_SHORTCUT.SHORTCUT_SEARCH_PRIMARY}]</strong></em>`
                            }
                            onChangeDebounce={() =>
                                updateFilter('customer', search.customer)
                            }
                            onChange={(e) =>
                                setSearch({
                                    ...search,
                                    customer: e.target.value,
                                })
                            }
                            value={search.customer}
                            className={'text_filter'}
                        />
                    </div>
                    <div className={'col-2'}>
                        <Button
                            buttonStyle={ButtonStyle.BUTTON_SHADOW}
                            color={ButtonColor.BUTTON_COLOR_ORANGE}
                            onClick={() => {
                                setShowFilterModal(true);
                            }}
                            ref={filterBtnRef}
                            className={'w-100'}
                        >
                            {`Filtrar [${ENUM_SHORTCUT.SHORTCUT_FILTER}]`}
                        </Button>
                    </div>
                </div>
                <div className={'Order_suggestion_list'}>
                    <Tabs.Root
                        defaultValue={filter.status}
                        className={'tabs_container'}
                        onValueChange={handleFilterTabChange}
                    >
                        <Tabs.List
                            className={'tabs_list'}
                            aria-label={'Manage your account'}
                        >
                            {Object.values(VirtualOrderStatusEnum).map(
                                (status, index) => {
                                    if ([VirtualOrderStatusEnum.DELIVERED, VirtualOrderStatusEnum.CANCELLED].includes(status)) {
                                        return null;
                                    }

                                    const count = (status === VirtualOrderStatusEnum.COMPLETED)
                                        ? (data?.totals?.[VirtualOrderStatusEnum.DELIVERED]?.count || 0) + 
                                            (data?.totals?.[VirtualOrderStatusEnum.CANCELLED]?.count || 0)
                                        : data?.totals?.[status]?.count || 0;

                                    return (
                                        <Tabs.Trigger
                                            key={index}
                                            className={'tabs_trigger'}
                                            value={status}
                                        >
                                            {`${VirtualOrderStatus(status)} (${count})`}
                                        </Tabs.Trigger>
                                    );
                                })}
                        </Tabs.List>
                    </Tabs.Root>
                    <VirtualOrdersTable
                        data={data?.records}
                        filter={filter}
                        onSortChange={updateFilter}
                        onSubmittedChange={onSubmittedChange}
                        onGenerateNFCeChange={onGenerateNFCeChange}
                        onSeparatingChange={onSeparatingChange}
                        onDeliveredChange={onDeliveredChange}
                        isLoading={isLoading}
                    />
                    <Pagination
                        page={data?.page}
                        pageSize={data?.pageSize}
                        count={data?.count}
                        recordCount={data?.records?.length || 0}
                        onPageChange={(page) => setFilter({ ...filter, page })}
                    />
                </div>
            </div>
            <VirtualOrdersFilterModal
                show={showFilterModal}
                filter={filter}
                onCancel={() => setShowFilterModal(false)}
                onConfirm={(filterData) => {
                    setShowFilterModal(false);
                    setFilter(filterData);
                }}
            />
        </>
    );
}

export function getVirtualOrderRoute() {
    return '/loja-virtual/pedidos/acompamento';
}
