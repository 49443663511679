import {
    RecurringBillsToPayStatusEnum,
    RecurringBillsToPayStatusText,
} from 'erva-doce-common';
import './RecurringInfo.scss';
import classNames from 'classnames';

export default function RecurringInfo({ status }) {
    if (!status) return <span>{'Carregando...'}</span>;

    return (
        <div className={'box'}>
            <div className={'status-title'}>
                <span className={'status'}>{'Status da recorrência: '}</span>
                <span
                    className={classNames(
                        status === RecurringBillsToPayStatusEnum.ACTIVE
                            ? 'active'
                            : 'deactivated'
                    )}
                >
                    {RecurringBillsToPayStatusText(status)}
                </span>
            </div>
            {(status === RecurringBillsToPayStatusEnum.DEACTIVATED ??
                'deactivated') && (
                <div className={'content-info'}>
                    <p>
                        {
                            'A recorrência desta conta foi desativada. Para reativá-la, é necessário criar uma nova conta.'
                        }
                    </p>
                </div>
            )}
        </div>
    );
}
