import {  useContext, useRef } from 'react';
import './ProductTable.scss';
import { getProductLogsRoute } from '../admin/products/ProductLogs';
import { IconLog } from './images';
import Button from './Button';
import { useNavigate } from 'react-router';
import { MdOpenInNew } from 'react-icons/md';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import { PermissionsEnum } from 'erva-doce-common';
import classNames from 'classnames';
import { extractCurrencyNumber } from './FieldCurrency';

export default function Table({
    columns,
    className,
    data,
}) {
    const tbodyRef = useRef();
    const { user, selectedStore } = useContext(EnvironmentContext);
    const navigate = useNavigate();
    const empty = !data;
    const productType = { color: '#1F92E2', text: 'Principal' };

    if (data.parentProduct) {
        productType.color = '#B741B3';
        productType.text = 'Variação';
    }

    if (data.ucvParentProduct) {
        productType.color = '#46822f';
        productType.text = 'UCV';
    }

    return (
        <div className={classNames('product-table-container', className)}>
            <table className={'table'}>
                <thead>
                    <tr>
                        <th>{'Código'}</th>
                        <th>{'Tipo'}</th>
                        <th>{'EAN Principal'}</th>
                        <th>{'Nome do Produto'}</th>
                        <th>{'Nome Web'}</th>
                        <th>{'Estoque'}</th>
                        {
                            (user.isAdmin || user.roles?.includes(PermissionsEnum.VALIDATE_STOCK_CHECK)) && (
                                <th>{'Estoque CD'}</th>
                            )
                        }
                        <th>{'Preço de Venda'}</th>
                        <th>{'Site'}</th>
                        <th>{'Log'}</th>
                    </tr>
                </thead>
                <tbody ref={tbodyRef}>
                    {empty && (
                        <tr>
                            <td colSpan={columns?.length} className={'status-container'}>
                                {'Não há dados para exibir'}
                            </td>
                        </tr>
                    )}
                    {!empty && (
                        <tr>
                            <td
                                style={{
                                    borderRadius: '0 0 0 8px',
                                    paddingLeft: '1%',
                                    textAlign: 'left',
                                }}
                            >
                                {data?.id || '-'}
                            </td>
                            <td>
                                <div
                                    style={{
                                        color: '#fff',
                                        backgroundColor: productType.color,
                                        textAlign: 'center',
                                        borderRadius: '4px',
                                        padding: '4px',
                                    }}
                                >
                                    {productType.text}
                                </div>
                            </td>
                            <td style={{ maxWidth: '100px' }}>{data.barCodes ? (data.barCodes.find(x => x.main)?.barCode || '-') : '-'}</td>
                            <td style={{ maxWidth: '100px' }}>{data.name1 || '-'}</td>
                            <td style={{ maxWidth: '100px' }}>{data.shortName1 || '-'}</td>
                            <td>{selectedStore?.isOnline ? `${data.cdStock || 0} (CD)` : data.stock || 0}</td>
                            {
                                (user.isAdmin || user.roles?.includes(PermissionsEnum.VALIDATE_STOCK_CHECK)) && (
                                    <td>{data.cdStock}</td>
                                )
                            }
                            {
                                data?.priceStore && (
                                    <td>{extractCurrencyNumber(data?.priceStore) === 0 ? 'Indisponível' : data?.priceStore || '-'}</td> 
                                )
                            }
                            {
                                !data?.priceStore && (
                                    <td> {'-'} </td>
                                )
                            }
                            <td>
                                {
                                    data.ecommerceURL ? (
                                        <a href={data.ecommerceURL} target={'_blank'} title={'Visitar produto no ecommerce'} rel={'noreferrer'}>
                                            <MdOpenInNew size={24} color={'#000'} title={'Visitar produto no ecommerce'} />
                                        </a>
                                    ) : 'Indisponível'
                                }
                            </td>
                            {data.uuid ? (
                                <td
                                    style={{ borderRadius: '0 0 8px 0' }}
                                    onClick={() => navigate(getProductLogsRoute(data.uuid))}
                                >
                                    {
                                        <Button className={'transparent button-sort'}>
                                            <img src={IconLog}></img>
                                        </Button>
                                    }
                                </td>
                            ) : (
                                <td
                                    style={{ borderRadius: '0 0 8px 0' }}
                                ></td>
                            )}
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}
