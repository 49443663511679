export enum ProductMovementsEnum {
  ENTRY = "ENTRY",
  EXIT = "EXIT"
}

export function ProductMovementsText(text: ProductMovementsEnum | string) {
  switch(text){
    case ProductMovementsEnum.ENTRY:
      return "Entrada";
    case ProductMovementsEnum.EXIT:
      return "Saída";
    default:
      throw new Error(`Not implemented for ${text}`);     
  }
}