import { useContext, useEffect, useRef, useState } from 'react';
import Button, { ButtonColor, ButtonStyle } from '../../components/Button';
import ScreenHeader from '../../components/logged/ScreenHeader';
import * as Tabs from '@radix-ui/react-tabs';
import Pagination from '../../components/Pagination';
import OrderDocumentsTable from './OrderDocumentsTable';
import ImportFileModal from '../../components/ImportFileModal';
import * as DocumentsServices from '../../services/DocumentsServices';
import { IconFilter, IconFilter2x } from '../../components/images';
import OrderDocumentsFilterModal from './OrderDocumentsFilterModal';
import { ENUM_SHORTCUT, FileTypeEnum, OrderBillingDocumentsFilterTabEnum, OrderByOrderDocumentsEnum, ValidationErrorOrderDocumentsText } from 'erva-doce-common';
import { EnvironmentContext } from '../../contexts/EnviromentContext';

const FILTERS = {
    order: OrderByOrderDocumentsEnum.EMITENTE_ASC,
    page: 0,
    documentType: OrderBillingDocumentsFilterTabEnum.ALL,
    documents: [],
};

export default function OrderDocuments() {

    const [isLoading, setIsLoading] = useState(false);
    const [showImportFileModal, setShowImportFileModal] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [filter, setFilter] = useState(FILTERS);
    const [data, setData] = useState({});
    const {
        addHotkey,
        removeHotkey,
    } = useContext(EnvironmentContext);
    const importXmlBtnRef = useRef();
    const filterBtnRef = useRef();

    const updateFilter = (name, value) => {
        setFilter({
            ...filter,
            [name]: value,
        });
    };

    const fetchDocuments = async () => {
        try {
            setIsLoading(true);
            const data =
                await DocumentsServices.getDocuments(filter);

            setData(data);
        } catch (err) {
            console.log(err);
            // SHOW MODAL ERROR
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const shortcutFilter = addHotkey(ENUM_SHORTCUT.SHORTCUT_FILTER, () => {
            setShowFilterModal(true);
            if(filterBtnRef.current) {
                filterBtnRef.current.click();
            }
        });

        const shortcutCreatePrimary = addHotkey(ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY, () => {
            if(importXmlBtnRef.current) {
                importXmlBtnRef.current.click();
            }
        });

        return () => {
            removeHotkey(shortcutFilter);
            removeHotkey(shortcutCreatePrimary);
        };
    }, []);

    useEffect(() => {
        fetchDocuments();
    }, [filter]);

    return (
        <>
            <div className={'purchase-detail crud-list'}>
                <ScreenHeader
                    title={'Importar XML'}
                    breadcrumbs={[
                        { name: 'Compras', route: '/' },
                        {
                            name: 'Importar XML',
                        },
                    ]}
                    hideStore
                />
                <div className={'row justify-content-end'}>
                    <div className={'col-2 update-purchase'}>
                        <Button
                            className={'w-100'}
                            buttonStyle={ButtonStyle.BUTTON_NORMAL}
                            color={ButtonColor.BUTTON_COLOR_GREEN}
                            onClick={() => setShowImportFileModal(true)}
                            ref={importXmlBtnRef}
                        >
                            {`Importar XML [${ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY}]`}
                        </Button>
                    </div>
                    <div className={'col-2'}>
                        <Button
                            buttonStyle={ButtonStyle.BUTTON_SHADOW}
                            color={ButtonColor.BUTTON_COLOR_ORANGE}
                            icon={IconFilter}
                            icon2x={IconFilter2x}
                            onClick={() => {
                                setShowFilterModal(true);
                            }}
                            className={'w-100'}
                            ref={filterBtnRef}
                        >
                            {`Filtrar [${ENUM_SHORTCUT.SHORTCUT_FILTER}]`}
                        </Button>
                    </div>
                </div>
                <div>
                    <Tabs.Root
                        defaultValue={filter.documentType}
                        className={'tabs_container'}
                        onValueChange={(e) => updateFilter('documentType', e)}
                    >
                        <Tabs.List
                            className={'tabs_list'}
                            aria-label={'Manage your account'}
                        >
                            <Tabs.Trigger
                                className={'tabs_trigger all'}
                                value={OrderBillingDocumentsFilterTabEnum.ALL}
                            >
                                {`Todos (${data.total || 0})`}
                            </Tabs.Trigger>
                            <Tabs.Trigger
                                className={'tabs_trigger'}
                                value={OrderBillingDocumentsFilterTabEnum.LINKED}
                            >
                                {'Vinculados'}
                            </Tabs.Trigger>
                            <Tabs.Trigger
                                className={'tabs_trigger'}
                                value={OrderBillingDocumentsFilterTabEnum.NOT_LINKED}
                            >
                                {'Desvinculados'}
                            </Tabs.Trigger>
                        </Tabs.List>
                    </Tabs.Root>
                    <OrderDocumentsTable
                        data={data?.records}
                        filter={filter}
                        isLoading={isLoading}
                        onSortChange={updateFilter}
                    />
                    <Pagination
                        page={data?.page}
                        pageSize={data?.pageSize}
                        count={data?.count}
                        recordCount={data?.records?.length || 0}
                        onPageChange={(page) => setFilter({ ...filter, page })}
                    />
                </div>
            </div>
            <OrderDocumentsFilterModal
                show={showFilterModal}
                filter={filter}
                onCancel={() => {
                    setShowFilterModal(false);
                }}
                onConfirm={(filter) => {
                    setShowFilterModal(false);
                    setFilter(filter);
                }}
            />
            <ImportFileModal
                title={'Selecionar arquivos XMLs'}
                accept={[FileTypeEnum.TEXT_XML]}
                validationMessages={ValidationErrorOrderDocumentsText}
                show={showImportFileModal}
                onConfirm={() => {
                    setShowImportFileModal(false);
                    window.location.reload();
                }}
                onCancel={() => {
                    setShowImportFileModal(false);
                }}
                onRequestUpload={async (files, callback) => {
                    return await DocumentsServices.uploadDocuments(files, callback);
                }}
                allowMultipleFile
            />
        </>
    );
}

export function getOrderDocumentsRoute() {
    return '/compras/pedidos/notas';
}
