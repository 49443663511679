import { useContext, useEffect, useState } from 'react';
import ScreenHeader from '../../../components/logged/ScreenHeader';
import { getDashboardRoute } from '../../../dashboard/Dashboard';
import * as DeliveryAreasService from '../../../services/DeliveryAreasService';
import { EnvironmentContext } from '../../../contexts/EnviromentContext';
import Button, { ButtonColor, ButtonStyle } from '../../../components/Button';
import { IconAdd, IconAdd2x, IconEdit, IconEdit2x, IconSelectArrow, IconSelectArrow2x } from '../../../components/images';
import Table from '../../../components/Table';
import { maskZipCodeString } from '../../../components/FieldZipCode';
import { getDeliveryAreaFormRoute } from './DeliveryAreaForm';
import { useNavigate } from 'react-router';
import { ENUM_SHORTCUT } from 'erva-doce-common';

export default function DeliveryArea() {
    const title = 'Áreas de entrega';

    const navigate = useNavigate();
    const { backendConnectionError, addHotkey, removeHotkey } = useContext(EnvironmentContext);

    const [isLoading, setIsLoading] = useState(false);
    const [deliveryAreas, setDeliveryAreas] = useState([]);

    const fetchDeliveryAreas = async () => {
        try {
            setIsLoading(true);

            const res = await DeliveryAreasService.get();

            setDeliveryAreas(res);
        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to fetch deliveryArea', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    const updateOrder = async (deliveryArea, newOrder) => {
        try {
            setIsLoading(true);

            if (newOrder > deliveryAreas.length - 1 || newOrder < 0) {
                return;
            }

            const data = {
                ...deliveryArea,
                order: newOrder,
            };

            await DeliveryAreasService.update(deliveryArea.uuid, data);

            fetchDeliveryAreas();
        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to fetch update deliveryArea order', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchDeliveryAreas();
    }, []);

    useEffect(() => {      
        const shortcutCreatePrimary = addHotkey(ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY, () => {
            navigate(getDeliveryAreaFormRoute());
        });

        return () => {
            removeHotkey(shortcutCreatePrimary);
        };
    }, []);

    return (
        <div className={'crud-list'}>
            <ScreenHeader
                title={title}
                breadcrumbs={[
                    { name: 'Loja virtual', route: getDashboardRoute() },
                    { name: 'Entregas', route: getDashboardRoute() },
                    { name: title, route: getDeliveryAreaRoute() },
                ]}
                hideStore
            />
            <div className={'row justify-content-end mb-32'}>
                <div className={'col-6 col-md-4'}>
                </div>
                <div className={'hide-mobile col-md-5'}></div>
                <div className={'col-6 col-md-3'}>
                    <Button
                        buttonStyle={ButtonStyle.BUTTON_SHADOW}
                        color={ButtonColor.BUTTON_COLOR_GREEN}
                        icon={IconAdd}
                        icon2x={IconAdd2x}
                        onClick={() => {
                            navigate(getDeliveryAreaFormRoute());
                        }}
                        className={'w-100'}
                    >
                        {`Nova área de entrega [${ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY}]`}
                    </Button>
                </div>
            </div>
            <div className={'mt-24'}>
                <Table
                    loading={isLoading}
                    columns={[
                        {
                            width: '20px',
                        },
                        {
                            name: 'Nome',
                            align: 'left',
                            width: '20%',
                        },
                        {
                            name: 'Início',
                            align: 'center',
                        },
                        {
                            name: 'Fim',
                            align: 'center',
                        },
                        {
                            name: 'Métodos de entrega',
                            align: 'center',
                        },
                        {
                            name: 'Ordenação',
                            align: 'center',
                        },
                        {
                            name: 'Ações',
                            align: 'center',
                        },
                    ]}
                >
                    {deliveryAreas?.map((deliveryArea, index) => (
                        <tr key={deliveryArea.id}>
                            <td>
                                <div className={'d-flex flex-column'}>
                                    <Button
                                        className={'transparent'}
                                        onClick={() => updateOrder(deliveryArea, index - 1)}
                                        disabled={index === 0 || isLoading}
                                    >
                                        <img
                                            src={IconSelectArrow}
                                            srcSet={`${IconSelectArrow} 1x, ${IconSelectArrow2x} 2x`}
                                            alt={'Subir ordem'}
                                            title={'Subir ordem'}
                                            style={{
                                                width: '16px',
                                                transform: 'rotateZ(180deg)'
                                            }}
                                        />
                                    </Button>
                                    <Button
                                        className={'transparent mt-8'}
                                        onClick={() => updateOrder(deliveryArea, index + 1)}
                                        disabled={index === deliveryAreas.length - 1 || isLoading}
                                    >
                                        <img
                                            src={IconSelectArrow}
                                            srcSet={`${IconSelectArrow} 1x, ${IconSelectArrow2x} 2x`}
                                            alt={'Descer ordem'}
                                            title={'Descer ordem'}
                                            style={{
                                                width: '16px',
                                            }}
                                        />
                                    </Button>
                                </div>
                            </td>
                            <td className={'text-start'}>{deliveryArea.name}</td>
                            <td className={'text-center'}>{maskZipCodeString(`${deliveryArea?.start}`.padStart(8, '0'))}</td>
                            <td className={'text-center'}>{maskZipCodeString(`${deliveryArea?.end}`.padStart(8, '0'))}</td>
                            <td className={'text-center'}>{deliveryArea?.methods?.length || '-'}</td>
                            <td className={'text-center'}>{deliveryArea.order}</td>
                            <td className={'text-center'}>
                                <Button
                                    className={'transparent mt-8'}
                                    onClick={() => navigate(getDeliveryAreaFormRoute(deliveryArea.uuid))}
                                >
                                    <img
                                        src={IconEdit}
                                        srcSet={`${IconEdit} 1x, ${IconEdit2x} 2x`}
                                        alt={'Editar método'}
                                        title={'Editar método'}
                                        style={{
                                            width: '16px',
                                        }}
                                    />
                                </Button>
                            </td>
                        </tr>
                    ))}
                </Table>
            </div>
        </div>
    );
}

export function getDeliveryAreaRoute() {
    return '/loja-virtual/entregas/areas-de-entrega';
}
