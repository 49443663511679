import { useContext, useEffect, useRef, useState } from 'react';
import Button, { ButtonColor, ButtonStyle } from '../../../components/Button';
import { IconAdd, IconAdd2x, IconButtonRemove, IconButtonRemove2x, IconEdit, IconEdit2x, IconSelectArrow, IconSelectArrow2x } from '../../../components/images';
import ScreenHeader from '../../../components/logged/ScreenHeader';
import Table from '../../../components/Table';
import { getDashboardRoute } from '../../../dashboard/Dashboard';
import * as BannersService from '../../../services/BannersService';
import { EnvironmentContext } from '../../../contexts/EnviromentContext';
import { BannerLocationTypeEnum, BannerLocationTypeText, BannerTypeEnum, BannerTypeText, ENUM_SHORTCUT } from 'erva-doce-common';
import { DateTime } from 'luxon';
import BannerFormModal from './BannerFormModal';

import './style.scss';

const initialState = {
    title: '',
    description: '',
    link: '',
    isExternalLink: false,
    active: false,
    startAt: '',
    endAt: '',
    type: '',
    locationType: '',
    desktopBanner: null,
    mobileBanner: null,
    locations: [],
};

export default function Banners() {
    const title = 'Banners';

    const { backendConnectionError, addHotkey, removeHotkey } = useContext(EnvironmentContext);

    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedBanner, setSelectedBanner] = useState(null);
    const [banners, setBanners] = useState([]);
    const newBannerBtnRef = useRef();

    const getBanners = async () => {
        try {
            setIsLoading(true);

            const res = await BannersService.get();
            const data = res.map(x => ({
                ...x, startAt: x?.startAt ? DateTime.fromISO(x.startAt).toFormat('yyyy-MM-dd') : '',
                endAt: x?.endAt ? DateTime.fromISO(x.endAt).toFormat('yyyy-MM-dd') : '',
            }));
            setBanners(data);
        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to fetch banners', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    const updateOrder = async (banner, newOrder) => {
        try {
            setIsLoading(true);

            if (newOrder > banners.length - 1 || newOrder < 0) {
                return;
            }

            const data = {
                ...banner,
                order: newOrder,
            };

            await BannersService.update(banner.uuid, data);

            getBanners();
        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to fetch update banner order', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    const deleteBanner = async (banner) => {
        try {
            setIsLoading(true);

            await BannersService.destroy(banner.uuid);

            getBanners();
        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to fetch destroy banner order', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getBanners();
    }, []);

    useEffect(() => {
        if (selectedBanner) {
            setShowModal(true);
        }
    }, [selectedBanner]);

    useEffect(() => {
        const shortcutCreatePrimary = addHotkey(ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY, () => {
            if(newBannerBtnRef.current) {
                newBannerBtnRef.current.click();
            }
        });

        return () => {
            removeHotkey(shortcutCreatePrimary);
        };
    }, []);

    return (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Loja virtual', route: getDashboardRoute() },
                        { name: 'Marketing', route: getDashboardRoute() },
                        { name: title, route: getBannersRoute() },
                    ]}
                    hideStore
                />
                <div className={'row justify-content-end mb-32'}>
                    <div className={'col-6 col-md-4'}>
                    </div>
                    <div className={'hide-mobile col-md-6'}></div>
                    <div className={'col-6 col-md-2'}>
                        <Button
                            buttonStyle={ButtonStyle.BUTTON_SHADOW}
                            color={ButtonColor.BUTTON_COLOR_GREEN}
                            icon={IconAdd}
                            icon2x={IconAdd2x}
                            onClick={() => {
                                setSelectedBanner(initialState);
                            }}
                            ref={newBannerBtnRef}
                            className={'w-100'}
                        >
                            {`Novo banner [${ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY}]`}
                        </Button>
                    </div>
                </div>
                <div className={'mt-24'}>
                    <Table
                        loading={isLoading}
                        columns={[
                            {
                                width: '20px',
                            },
                            {
                                name: 'Título',
                                align: 'left',
                                width: '20%',
                            },
                            {
                                name: 'Tipo',
                                align: 'center',
                            },
                            {
                                name: 'Localização',
                                align: 'center',
                            },
                            {
                                name: 'Data de início',
                                align: 'center',
                            },
                            {
                                name: 'Data de fim',
                                align: 'center',
                            },
                            {
                                name: 'Data cadastro',
                                align: 'center',
                            },
                            {
                                name: 'Situação',
                                align: 'center',
                            },
                            {
                                name: 'Ordenação',
                                align: 'center',
                            },
                            {
                                name: 'Ações',
                                align: 'center',
                            },
                        ]}
                    >
                        {banners?.map((banner, index) => (
                            <tr key={banner.id}>
                                <td>
                                    <div className={'d-flex flex-column'}>
                                        <Button
                                            className={'transparent'}
                                            onClick={() => updateOrder(banner, index - 1)}
                                            disabled={index === 0 || isLoading}
                                        >
                                            <img
                                                src={IconSelectArrow}
                                                srcSet={`${IconSelectArrow} 1x, ${IconSelectArrow2x} 2x`}
                                                alt={'Subir ordem'}
                                                title={'Subir ordem'}
                                                style={{
                                                    width: '16px',
                                                    transform: 'rotateZ(180deg)'
                                                }}
                                            />
                                        </Button>
                                        <Button
                                            className={'transparent mt-8'}
                                            onClick={() => updateOrder(banner, index + 1)}
                                            disabled={index === banners.length - 1 || isLoading}
                                        >
                                            <img
                                                src={IconSelectArrow}
                                                srcSet={`${IconSelectArrow} 1x, ${IconSelectArrow2x} 2x`}
                                                alt={'Descer ordem'}
                                                title={'Descer ordem'}
                                                style={{
                                                    width: '16px',
                                                }}
                                            />
                                        </Button>
                                    </div>
                                </td>
                                <td className={'text-start'}>{banner.title}</td>
                                <td className={'text-center'}>{BannerTypeText(banner.type)}</td>
                                <td className={'text-center'}>
                                    {BannerLocationTypeText(banner.locationType)}
                                    {`${banner.locationType !== BannerLocationTypeEnum.ALL ? ` [${banner.locations.map(x => x.label).join(', ')}]` : ''}`}
                                </td>
                                <td className={'text-center'}>{banner.startAt ? DateTime.fromISO(banner.startAt).toFormat('dd/MM/yyyy') : '-'}</td>
                                <td className={'text-center'}>{banner.endAt ? DateTime.fromISO(banner.endAt).toFormat('dd/MM/yyyy') : '-'}</td>
                                <td className={'text-center'}>{DateTime.fromISO(banner.createdAt).toFormat('dd/MM/yyyy HH:mm')}</td>
                                <td className={'text-center'}>{banner.active ? 'Ativo' : 'Inativo'}</td>
                                <td className={'text-center'}>{banner.order}</td>
                                <td className={'text-center'}>
                                    <Button
                                        className={'transparent mt-8'}
                                        onClick={() => setSelectedBanner(banner)}
                                    >
                                        <img
                                            src={IconEdit}
                                            srcSet={`${IconEdit} 1x, ${IconEdit2x} 2x`}
                                            alt={'Editar banner'}
                                            title={'Editar banner'}
                                            style={{
                                                width: '16px',
                                            }}
                                        />
                                    </Button>
                                    <Button
                                        className={'transparent mt-8 ml-8'}
                                        onClick={() => deleteBanner(banner)}
                                    >
                                        <img
                                            src={IconButtonRemove}
                                            srcSet={`${IconButtonRemove} 1x, ${IconButtonRemove2x} 2x`}
                                            alt={'Excluir banner'}
                                            title={'Excluir banner'}
                                            style={{
                                                width: '10px',
                                            }}
                                        />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </Table>
                </div>
            </div>
            <BannerFormModal
                show={showModal}
                initialData={selectedBanner}
                onConfirm={() => {
                    setShowModal(false);
                    setSelectedBanner(null);
                    getBanners();
                }}
                onCancel={() => {
                    setShowModal(false);
                    setSelectedBanner(null);
                }}
            />
        </>
    );
}

export function getBannersRoute() {
    return '/loja-virtual/marketing/banners';
}
