import {
    ENUM_SHORTCUT,
    ExchangeStatusEnum,
    PermissionsEnum,
    unmaskCpf,
    ValidationErrorExchangesText,
    TypeNFSaleEnum,
    formatCpfMask,
} from 'erva-doce-common';
import './SalesForm.scss';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Amount from '../../components/Amount';
import Button, { ButtonFontColor, ButtonStyle } from '../../components/Button';
import RouteChangePrompt from '../../components/RouteChangePrompt';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { formatValue } from '../../util/formatValue';
import { getSalesRoute } from './Sales';
import { getExchangesFormRoute } from '../exchanges/ExchangesForm';
import DevolutionSaleModal from './DevolutionSaleModal';
import { auto } from '@popperjs/core';

import * as SalesService from '../../services/SalesService';
import * as FocusApiService from '../../services/FocusApiService';
import * as CouponService from '../../services/CouponService';
import * as ExchangesService from '../../services/ExchangesService';
import * as ProductsService from '../../services/ProductsService';
import * as CustomerService from '../../services/CustomerService';
import * as Sentry from '@sentry/react';

import { totalItensReduce, totalPayableReduce, totalSaleReduce } from './_utils';
import SalesProductTable from './SalesProductTable';
import FieldTextSearch from '../../components/FieldTextSearch';
import axios from 'axios';
import CustomersFormModal from '../customers/CustomersFormModal';
import SalesModal from './SalesModal';

const emptyFormDevolution = {
    products: [],
    customerCpf: '',
    storeId: '',
    saleOriginId: '',
    lineQuantity: '',
    itemsQuantity: '',
    total: '',
    status: '',
};

const emptyFormData = {
    payments: [],
};

export default function SalesDetailForm() {
    const {
        backendConnectionError,
        setInfoModal,
        addHotkey,
        removeHotkey,
        user,
        setConfirmModal,
        setLoading,
        selectedStore,
    } = useContext(EnvironmentContext);

    const emptyFormError = emptyFormData;
    const [formData, setFormData] = useState(emptyFormData);
    const [, setFormError] = useState(emptyFormError);
    const [, setValidateOnChange] = useState(false);
    const { uuid } = useParams();
    const [saleName, setSaleName] = useState('');
    const [saveLoading, setSaveLoading] = useState(false);
    const [loadingSale, setLoadingSale] = useState(false);
    const [, setDeleteLoading] = useState(false);
    const inputSearchRef = useRef();
    const inputSellerRef = useRef();
    const [showSaleModal, setShowSaleModal] = useState(false);
    const [showCustomerFormModal, setShowCustomerFormModal] = useState(false);
    const [hasChange, setHasChange] = useState(false);
    const [isSaleEditable,] = useState(true);
    const [productsSelected, setProductsSelected] = useState([]);
    const [salesForm, setSalesForm] = useState();
    const [selectedProdutcsToExchange, setSelectedProdutcsToExchange] = useState([]);
    const [showDevolutionModal, setShowDevolutionModal] = useState(false);
    const [formDevolution, setFormDevolution] = useState(emptyFormDevolution);
    const [exchangeData, setExchangeData] = useState();
    const [couponData, setCouponData] = useState();
    const [couponTotal, setCouponTotal] = useState(0);
    const [filter, setFilter] = useState({ search: '' });

    const navigate = useNavigate();

    const canSave = user.roles?.includes(PermissionsEnum.CREATE_EXCHANGES);
    const canEditableCustomer = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_CUSTOMERS);
    const exchangeTotal = exchangeData?.total || 0;
    const totalSale = totalSaleReduce(productsSelected);
    const totalPayable = totalPayableReduce(productsSelected, exchangeTotal, couponTotal);
    const totalItens = totalItensReduce(productsSelected);

    function getTitle(windowTitle = false) {
        if (uuid) {
            let title = 'Editar venda';
            if (windowTitle && saleName) title += ` - ${saleName}`;
            return title;
        }
    }
    const title = getTitle();

    function updateFormData(data) {
        // noinspection JSCheckFunctionSignatures
        setFormData((formData) => ({ ...formData, ...data }));
        if (canSave) setHasChange(true);
    }

    function updateFormDevolution(data) {
        setFormDevolution((formDevolution) => ({ ...formDevolution, ...data }));
    }

    async function fetchSale() {
        try {
            setLoadingSale(true);
            const sale = await SalesService.getSale(uuid);

            setProductsSelected(sale.products);

            const payments = [];
            for (const p of sale.payments) {
                const {
                    paymentType,
                    machine,
                    total,
                    amountInstallments,
                    quantityInstallments,
                } = p;
                const pt = paymentType
                    ? { id: paymentType, value: null }
                    : null;
                const m = machine ? { id: machine, value: null } : null;

                payments.push({
                    quantityInstallments,
                    paymentType: pt,
                    machine: m,
                    tefId: p.tefId,
                    tefConfirmed: p.tefConfirmed,
                    nsu: p.nsu,
                    total:
                        total?.toLocaleString(undefined, {
                            style: 'currency',
                            currency: 'BRL',
                        }) ?? '',
                    amountInstallments:
                        amountInstallments?.toLocaleString(undefined, {
                            style: 'currency',
                            currency: 'BRL',
                        }) ?? '',
                });
            }

            setFormData({
                ...sale,
                payments,
            });

            setSaleName(sale.clientName);
        } catch (error) {
            setHasChange(true);
            const title = getTitle();
            const { response } = error;
            if (response?.status === 404) {
                navigate(getSalesRoute());
            } else {
                backendConnectionError('Fail to fetch sale', error, null, title);
            }
        } finally {
            setLoadingSale(false);
        }
    }

    async function fetchCoupon(uuid) {
        try {
            const coupon = await CouponService.getCouponByUUID({ uuid });

            if (coupon) {
                setCouponData(coupon);
            }
        } catch (error) {
            const { response } = error;
            if (response?.status === 404) {
                setInfoModal({
                    title,
                    message: 'Cupom não encontrada.',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: () => navigate(getSalesRoute()),
                });
            } else {
                backendConnectionError('Fail to fetch coupon', error, null, title);
            }
        }
    }

    function hasValidationError() {
        let hasError = false;
        setFormError(emptyFormError);
        setValidateOnChange(true);
        return hasError;
    }

    async function createNFSale(sale) {
        await createNFCe(sale);
    }

    async function createNFCe(sale) {
        let onClose = () => {
            navigate(0);
        };
        const title = getTitle();
        try {
            let message = null;
            setLoading({ show: true });
            const response = await FocusApiService.createNFCe(sale);
            if (response.data) {
                printNF(response.data);
                message = 'Venda salva com sucesso!';
            } else {
                message = 'Venda salva com sucesso! Erro ao emitir NFCe.';
            }

            let style = InfoModalStyle.SUCCESS;
            setInfoModal({
                title,
                message,
                style,
                show: true,
                onClose,
            });
        } catch (error) {
            console.log(error);
            let message = 'Venda salva com sucesso! Erro ao emitir NFCe:';
            backendConnectionError(
                message, 
                error,
                () => navigate(0), 
                'Emitir Nota Fiscal',
                (error) => error
            );
        }
    }

    async function printNF(chaveNfe) {
        if (formData.chaveNFe && formData.typeNF == TypeNFSaleEnum.CFE) {
            const responseCfePdf = await FocusApiService.getCfePdf(chaveNfe);
            const pdfBlob = new Blob([responseCfePdf.data], {
                type: 'application/pdf',
            });
            const pdfUrl = URL.createObjectURL(pdfBlob);
            window.open(pdfUrl);
            return;
        }
        const responseNfcePdf = await FocusApiService.getNFCePdf(chaveNfe);
        const pdfBlob = new Blob([responseNfcePdf.data], {
            type: 'application/pdf',
        });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl);
        return;
    }

    async function saveDevolution() {
        if (saveLoading) return;
        try {
            setSaveLoading(true);
            const body = {
                products:
                    formDevolution.products.filter(
                        (product) => product.amount > 0
                    ),
                storeId: selectedStore.id,
                saleOriginId: formData.id,
                customerUuid: formData.customerUuid,
                lineQuantity: formDevolution.products.length,
                itemsQuantity:
                    formDevolution.products.reduce(
                        (sum, product) => sum + Number(product.amount), 0
                    ),
                total:
                    formDevolution.products.reduce(
                        (sum, product) => sum + Number(product.total), 0
                    ),
                status: ExchangeStatusEnum.AWAITING_RESCUE,
            };

            const chaveNFe = await ExchangesService.addExchange(body);
            const message = chaveNFe
                ? 'Devolução salva com sucesso!'
                : 'Devolução salva com sucesso! Erro ao emitir NFe';

            setHasChange(false);
            setInfoModal({
                title: 'Devolução',
                message,
                style:  InfoModalStyle.SUCCESS,
                show: true,
                onClose: () => navigate(0),
            });
        } catch (error) {
            backendConnectionError(
                'Fail to create/edit exchange',
                error,
                null,
                'Devolução',
                ValidationErrorExchangesText
            );
        } finally {
            setSaveLoading(false);
        }
    }

    const handleMakingReturn = () => {
        if (formData.customerCpf) {
            if (!formData.customerAddress) {
                setShowCustomerFormModal(true);
            } else {
                setShowDevolutionModal(true);
            }
        } else {
            setInfoModal({
                title: 'Realizar Devolução',
                message: 'Necessário vincular cliente para realizar devolução',
                style: InfoModalStyle.ERROR,
                show: true,
                onClose: () => setShowSaleModal(true),
            });
        }
    };

    const handlePrintNF = () => {
        if (formData?.chaveNfe) {
            printNF(formData?.chaveNfe);
        } else {
            createNFSale(formData?.uuid);
        }
    };

    const fetchProductsByBarCode = async () => {
        const productsSale = productsSelected.map((p) => p.uuid);

        try {
            if (filter.search) {
                const prod = await ProductsService.getProductByBarCode(filter.search);
                if (productsSale.includes(prod.uuid)) {
                    const existingProductIndex = selectedProdutcsToExchange.findIndex(
                        (product) => product.uuid === prod.uuid
                    );

                    const updatedProducts = [...selectedProdutcsToExchange];

                    if (existingProductIndex !== -1) {
                        const amount = updatedProducts[existingProductIndex].amount + 1;
                        const price = updatedProducts[existingProductIndex].price;

                        updatedProducts[existingProductIndex] = {
                            amount: amount,
                            total: parseFloat(amount * price),
                            ...updatedProducts[existingProductIndex],
                        };
                    } else {
                        updatedProducts.push({
                            ...prod,
                            amount: 1,
                            total: prod.price,
                        });
                    }

                    setSelectedProdutcsToExchange(updatedProducts);
                    updateFormDevolution({ products: [...updatedProducts] });
                    setFilter({ ...filter, search: '' });
                }

                if (!prod.uuid) {
                    setInfoModal({
                        title: 'Alerta',
                        message: 'Produto não encontrado na loja!',
                        style: InfoModalStyle.ERROR,
                        show: true,
                        onClose: () => {},
                    });
                    setFilter({ ...filter, search: '' });
                }
            }
        } catch (e) {
            if (axios.isCancel(e)) {
                console.debug('Request cancelled.', e);
            } else {
                console.error(e);
                Sentry.captureException(e);
            }
        }
    };

    async function CustomerByCpf(cpf) {
        if (cpf) {
            const customer = await CustomerService.getCustomerByCpf(cpf);
            if (customer) {
                setFormData({
                    ...formData,
                    customerCpf: customer.cpf,
                    customerUuid: customer.uuid,
                    customerName: customer.name,
                });
            }
            setShowCustomerFormModal(true);
        }
    }

    async function fetchExchange(uuid) {
        try {
            const exchange = await ExchangesService.getExchange(uuid);

            if (exchange) {
                setExchangeData(exchange);
            }
        } catch (error) {
            const title = getTitle();
            const { response } = error;
            if (response?.status === 404) {
                setInfoModal({
                    title,
                    message: 'Devolução não encontrada.',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: navigate(getSalesRoute()),
                });
            } else {
                backendConnectionError(
                    'Fail to fetch exchange',
                    error,
                    null,
                    title
                );
            }
        }
    }

    useEffect(() => {
        if (formData.exchange) {
            fetchExchange(formData.exchange);
        }
    }, [formData.exchange]);

    useEffect(() => {
        if (uuid) {
            setShowSaleModal(false);
            fetchSale();
        }
    }, [uuid]);

    useEffect(() => {
        setLoadingSale(loadingSale, true);
    }, [loadingSale]);

    useEffect(() => {
        const shortcutCancel = addHotkey(ENUM_SHORTCUT.SHORTCUT_CANCEL, () => {
            if (showSaleModal) {
                inputSearchRef?.current?.focus();
                setShowSaleModal(false);
            }
            if (showCustomerFormModal) setShowCustomerFormModal(false);
        });
        return () => {
            removeHotkey(shortcutCancel);
        };
    }, [showSaleModal, showCustomerFormModal]);

    useEffect(() => {
        if (formData.coupon) {
            fetchCoupon(formData.coupon);
        }
    }, [formData.coupon]);

    useEffect(() => {
        let couponTotal = 0;

        if (couponData) {
            if (couponData.type === 'fixed') {
                couponTotal = couponData.value;
            } else {
                couponTotal =
                    (productsSelected.reduce((sum, pro) => sum + Number(pro.total), 0)
                     - exchangeTotal) * (couponData.value / 100);
            }
        }

        setCouponTotal(couponTotal);
    }, [couponData]);

    useEffect(() => {
        if (productsSelected.length) {
            setHasChange(false);
        } else {
            setHasChange(true);
        }

        hasValidationError();
    }, [formData, productsSelected, salesForm]);

    useEffect(() => {
        const shortcutReatrieve = addHotkey(ENUM_SHORTCUT.SHORTCUT_RETRIEVE,
            () => {
                if (selectedProdutcsToExchange.length)
                    setShowDevolutionModal(true);
            }
        );

        return () => {
            removeHotkey(shortcutReatrieve);
        };

    }, [selectedProdutcsToExchange]);
    
    return (
        <>
            <RouteChangePrompt
                enabled={hasChange}
                message={'Realmente deseja sair?'}
            />
            <div
                className={'crud-form sales-form'}
                style={{ height: 'calc(100vh - 40px)' }}
            >
                <div
                    className={'header'}
                    style={{ display: 'flex', flexDirection: 'column' }}
                >
                    <ScreenHeader
                        title={title}
                        breadcrumbs={[
                            { name: 'Vendas', route: getSalesRoute() },
                            { name: loadingSale ? '...' : 'Editar venda' },
                        ]}
                        backRoute={getSalesRoute()}
                    />
                </div>

                <div className={'mb-24'}>
                    <div className={'row'}>
                        <div className={'row col-4 align-left'}>
                            <FieldTextSearch
                                ref={inputSearchRef}
                                label={'<em>Buscar <strong>produto</strong></em>'}
                                value={filter.search}
                                onChange={({ target }) => {
                                    const value = target.value.replace(/\D/g, '');
                                    setFilter({
                                        ...filter,
                                        search: value,
                                    });
                                }}
                                onKeyDown={(ev) => {
                                    if (ev.key === ENUM_SHORTCUT.SHORTCUT_ENTER || ev.code === ENUM_SHORTCUT.SHORTCUT_ENTER) {
                                        fetchProductsByBarCode();
                                    }
                                }}
                                className={'text_filter'}
                            />
                        </div>

                        <div
                            className={'col-8 align-right'}
                            style={{ marginLeft: auto }}
                        >
                            <div className={'row col-12 align-right'}>
                                <div className={'col-3'}>
                                    <Button
                                        className={'w-100'}
                                        buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                        fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                                        onClick={() => handleMakingReturn()}
                                        disabled={!selectedProdutcsToExchange.length}
                                    >
                                        {`Realizar Devolução [${ENUM_SHORTCUT.SHORTCUT_RETRIEVE}]`}
                                    </Button>
                                </div>
                                <div className={'col-3'}>
                                    <Button
                                        className={'w-100'}
                                        buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                        fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                                        onClick={() => handlePrintNF()}
                                        disabled={!isSaleEditable}
                                    >
                                        {'Reimprimir Cupom Fiscal'}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'row mb-24'}>
                    <div className={'col-12 d-flex align-items-center'}>
                        <div className={'mr-24'}>
                            {formData.customerCpf ? (
                                <div>
                                    <span>
                                        <b>{'Cliente: '}</b>
                                        {
                                            `${formData.customerName ? `${formData.customerName} - ` : ''
                                            }${formatCpfMask(formData.customerCpf)}`
                                        }
                                    </span>
                                    {!formData.customerCpf ||
                                        (!isSaleEditable && (
                                            <span
                                                className={'remove-customer clickable ml-8'}
                                                onClick={() => {
                                                    setSalesForm(
                                                        (saleForm) => ({
                                                            ...saleForm,
                                                            cpfNote: null,
                                                            customerCpf: null,
                                                        })
                                                    );
                                                    setExchangeData({});
                                                    updateFormData({
                                                        customerCpf: null,
                                                        customerName: null,
                                                        cpfNote: null,
                                                        exchange: null,
                                                    });
                                                }}
                                            >
                                                <b>{'X'}</b>
                                            </span>
                                        ))}
                                </div>
                            ) : (
                                <div>
                                    <span>
                                        <b>{'Sem cliente vinculado'}</b>
                                    </span>
                                </div>
                            )}
                        </div>
                        <div>
                            {couponData && (
                                <span>
                                    <b>{'Cupom utilizado: '}</b>
                                    {'"'}
                                    {couponData.code}
                                    {'"'}
                                    {' com desconto de '}
                                    {formatValue(couponTotal)}
                                </span>
                            )}
                        </div>
                    </div>
                </div>

                <SalesProductTable
                    updateFormData={updateFormData}
                    isSaleEditable={true}
                    productsSelected={productsSelected}
                    exchangeData={exchangeData}
                    setExchangeData={setExchangeData}
                    getExchangesFormRoute={getExchangesFormRoute}
                    selectedProdutcsToExchange={selectedProdutcsToExchange}
                />

                <div className={'mt-8 row'}>
                    <div className={'col-12 totals__container'}>
                        <div className={'totals__wrapper mr-6'}>
                            <div className={'d-flex'}>
                                <Amount
                                    title={'Qtd produtos'}
                                    amount={productsSelected.length}
                                    radius={'12px 0px 0px 12px'}
                                    className={'amount-sale mr-6'}
                                    inline
                                />
                                <Amount
                                    title={'Total a pagar'}
                                    amount={formatValue(totalSale)}
                                    radius={'0px 0px 0px 0px'}
                                    className={'amount-sale'}
                                />
                            </div>
                            <div className={'d-flex mt-6'}>
                                <Amount
                                    title={'Qtd itens'}
                                    amount={totalItens}
                                    radius={'12px 0px 0px 12px'}
                                    className={'amount-sale mr-6'}
                                    inline
                                />
                                <Amount
                                    title={'Devolução'}
                                    amount={formatValue(exchangeTotal)}
                                    radius={'0px 0px 0px 0px'}
                                    className={'amount-sale'}
                                />
                            </div>
                        </div>
                        <div className={'totals__sale'}>
                            <p>{'Total da compra'}</p>
                            <span>{formatValue(totalPayable)}</span>
                        </div>
                    </div>
                </div>
            </div>

            <DevolutionSaleModal
                cancelEsc={true}
                show={showDevolutionModal && formData.customerCpf}
                onConfirm={() => saveDevolution()}
                onCancel={() => setShowDevolutionModal(false)}
                productsExchange={selectedProdutcsToExchange}
            />

            <CustomersFormModal
                cancelEsc={true}
                setData={setFormData}
                show={showCustomerFormModal}
                nameIsRequired={true}
                addressIsRequired={title === 'Editar venda'}
                disabledField={!canEditableCustomer}
                customerUuid={formData.customerUuid}
                value={formData.customerCpf || formData.cpfNote}
                onCancel={() => {
                    if (title === 'Editar venda') {
                        setShowCustomerFormModal(false);
                    } else {
                        updateFormData({
                            customerCpf: null,
                            customerName: null,
                        });
                        setShowCustomerFormModal(false);
                    }
                }}
                disabledCPF={true}
                onConfirm={() => {
                    setShowCustomerFormModal(false);
                    setShowDevolutionModal(true);
                }}
            />

            <SalesModal
                title={'Devolução'}
                cancelEsc={true}
                isSaleEditable={isSaleEditable}
                inputSellerRef={inputSellerRef}
                show={showSaleModal}
                onCancel={() => setShowSaleModal(false)}
                onChangeForm={(form) => setSalesForm(form)}
                onConfirm={(target) => {
                    const cpf = target.formData.customerCpf;

                    updateFormData({
                        customerCpf: cpf,
                        cpfNote: target.formData?.customerCpf,
                        seller: target.formData?.seller,
                    });

                    if (cpf) {
                        CustomerByCpf(unmaskCpf(cpf));
                    }
                    setShowSaleModal(false);
                }}
            />
        </>
    );
}

export function getSalesDetailFormRoute(uuid) {
    if (uuid) {
        return `/vendas/listagem/${uuid}`;
    }
}
