export const routes = {
  HOME: "/",
  LOGIN: "/login",
  ESQUECI_SENHA: "/esqueci-minha-senha",
  RECUPERAR_SENHA: "/recuperar-minha-senha",
  CADASTRO: "/cadastro",
  MEUS_DADOS: "/meus-dados",
  LISTAS_FAVORITOS: "/meus-dados/minhas-listas/favoritos",
  LISTAS_SALVOS: "/meus-dados/minhas-listas/salvos",
  MINHAS_LISTAS: "/meus-dados/minhas-listas",
  LISTAS_HISTORICO: "/meus-dados/minhas-listas/historico",
  LISTAS_BY_UUID: (id) => `/meus-dados/minhas-listas/${id}`,
  PRODUTOS_SEARCH: "/produtos/busca/",
  PRODUTOS: (category, subCategory?) =>
    `/produtos/${category}${subCategory ? `/${subCategory}` : ""}`,
  PRODUTO_DETALHES: (id, name) =>
    `/produtos/detalhes/${id}/${name.trim().replaceAll(" ", "-").replaceAll("/", "-").toLowerCase()}`,
  PRODUTOS_MARCAS: (brand) => `/produtos/marcas/${brand}`,
  LISTA_COMPARTILHADA: (userUUID, listUUID) =>
    `/listas/${userUUID}/${listUUID}`,
  POLITICA_DE_PRIVACIDADE: "/politicas-de-privacidade",
  DUVIDAS_FREQUENTES: "/duvidas-frequentes",
  MEUS_PEDIDOS: "/meus-dados/meus-pedidos",
  MEUS_PEDIDOS_DETALHES: (uuid) => `/meus-dados/meus-pedidos/${uuid}`,
  RASTREAR_PEDIDOS: (uuid) =>
    `/meus-dados/meus-pedidos/${uuid}/rastrear-pedidos`,
  SACOLA: "/sacola",
  SACOLA_PAGAMENTO: "/sacola/pagamento",
  SACOLA_ENDERECO: "/sacola/endereco",
  SACOLA_IDENTIFICACAO: "/sacola/identificacao",
  SACOLA_CONFIRMACAO: "/sacola/confirmacao",
  AVALIAR_PEDIDO: (token) => `/pedido/avaliacao/${token}`,
};
