import {
    ValidationErrorProductsText,
    ProductTagTypeEnum,
    IcmsTaxSituationCfeValueByCfop,
    CfopValue,
    PermissionsEnum,
} from 'erva-doce-common';
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Button, { ButtonColor, ButtonFontColor } from '../../components/Button';
import {
    extractCurrencyNumber,
} from '../../components/FieldCurrency';
import ProductTable from '../../components/ProductTable';
import RouteChangePrompt from '../../components/RouteChangePrompt';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import * as ProductsService from '../../services/ProductsService';
import { getProductsRoute } from './Products';
import { cloneDeep } from 'lodash';
import './ProductsForm.scss';
import * as Tabs from '@radix-ui/react-tabs';
import MainForm from './ProductForm/MainForm';
import PricingForm from './ProductForm/PricingForm';
import FiscalForm from './ProductForm/FiscalForm';
import { Loading } from '../../components/Loading';
import MovementsForm from './ProductForm/MovementsForm';

const TABS = {
    'MAIN': 'MAIN',
    'PRICING': 'PRICING',
    'FISCAL': 'FISCAL',
};

const TABS_TEXT = {
    'MAIN': 'Principal',
    'PRICING': 'Precificação',
    'FISCAL': 'Fiscal',
};

const TABS_ROLES = {
    'MAIN': [
        PermissionsEnum.HANDLE_PRODUCTS,
        PermissionsEnum.HANDLE_PRODUCT_MAIN,
        PermissionsEnum.VIEW_PRODUCT_MAIN,
    ],
    'PRICING': [
        PermissionsEnum.HANDLE_PRODUCTS,
        PermissionsEnum.HANDLE_PRODUCT_PRICING,
        PermissionsEnum.VIEW_PRODUCT_PRICING,
    ],
    'FISCAL': [
        PermissionsEnum.HANDLE_PRODUCTS,
        PermissionsEnum.HANDLE_PRODUCT_FISCAL,
        PermissionsEnum.VIEW_PRODUCT_FISCAL,
    ],
};

const DEFAULT_VALUE_PRICE = 1;

export default function ProductForm() {
    const emptyFormData = {
        barCodes: '',
        name1: '',
        shortName1: '',
        name2: '',
        shortName2: '',
        name3: '',
        shortName3: '',
        description: '',
        shortDescription: '',
        brand: '',
        segmentation: '',
        category: '',
        family: '',
        icms: '',
        ncm: '',
        taxClassification: '',
        taxSituation: '',
        cfop: '',
        subCategory: '',
        stores: [],
        packageType: '',
        packageQuantity: 1,
        pictures: [],
        cost: 0,
        tag: ProductTagTypeEnum.DEFAULT_TAG,
        tagQuantity: 1,
        weight: '',
        newPrice: '',
        isBestOfEdos: false,
        notPurchaseSuggestion: false,
        tags: [],
        variations: [],
        similarProducts: [],
    };
    const emptyFormError = {
        ...emptyFormData,
        packageQuantity: '',
        tagQuantity: '',
    };

    const location = useLocation();
    const { uuid } = useParams();
    const navigate = useNavigate();
    const {
        setLoading,
        backendConnectionError,
        setInfoModal,
        setConfirmModal,
        setWindowTitle,
        addHotkey,
        removeHotkey,
        selectedStore,
        user,
    } = useContext(EnvironmentContext);
    const buttonDeleteRef = useRef();
    const buttonSaveRef = useRef();

    const queryParams = new URLSearchParams(location.search);
    const uuidCopy = queryParams.get('uuid');
    const createParentProduct = queryParams.get('parentProduct') === 'true';
    const createUCVParentProduct = queryParams.get('ucvParentProduct') === 'true';
    const IS_NEW = uuid === undefined ? true : false;

    const [tab, setTab] = useState(null);
    const [formData, setFormData] = useState(emptyFormData);
    const [formError, setFormError] = useState(emptyFormError);
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [loadingProduct, setLoadingProduct] = useState(!!uuid);
    const [productName, setProductName] = useState('');
    const [hasChange, setHasChange] = useState(false);
    const [barCodes, setBarCodes] = useState([]);

    function updateFormData(data) {
        // noinspection JSCheckFunctionSignatures
        setFormData((formData) => ({ ...formData, ...data }));
        setHasChange(true);
    }

    const handleTabChange = (e) => {
        setTab(e);
    };

    function getTitle(windowTitle = false) {
        if (uuid) {
            let title = 'Editar produto';
            if (windowTitle && productName) title += ` - ${productName}`;
            return title;
        } else {
            return 'Novo produto';
        }
    }

    async function fetchProduct(uuid) {
        try {
            setLoadingProduct(true);
            const product = await ProductsService.getProduct(uuid);

            if (!product) {
                return setInfoModal({
                    title,
                    message: 'Produto não encontrado.',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: back,
                });
            }

            setProductName(product.name1);
            setBarCodes(uuidCopy ? [] : product?.barCodes || []);
            setFormData({
                ...product,
                id: uuidCopy ? null : product.id,
                barCodes: uuidCopy ? [] : product.barCodes,
                stock: uuidCopy ? 0 : (product.stock || 0),
                cdStock: uuidCopy ? 0 : (product.cdStock || 0),
                productCode: uuidCopy ? null : product.productCode,
                parentProduct: createParentProduct
                    ? uuidCopy
                    : product.parentProduct,
                ucvParentProduct: createUCVParentProduct
                    ? uuidCopy
                    : product.ucvParentProduct,
                budget:
                    product?.budget?.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    }) ?? '',
                price:
                    product?.price?.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    }) ?? '',
                priceStore:
                    product?.priceStore?.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    }) ?? '',
                pictures: uuidCopy ? [] : product?.pictures,
                brand: product.brand
                    ? { id: product.brand, value: null }
                    : null,
                segmentation: product.segmentation
                    ? { id: product.segmentation, value: null }
                    : null,
                category: product.category
                    ? { id: product.category, value: null }
                    : null,
                subCategory: product.subCategory
                    ? { id: product.subCategory, value: null }
                    : null,
                family: product.family
                    ? { id: product.family, value: null }
                    : null,
                icms: product.icms,
                ncm: product.ncm,
                taxClassification: product.taxClassification
                    ? { id: product.taxClassification, value: null }
                    : null,
                taxSituation: product.taxSituation
                    ? { id: product.taxSituation, value: null }
                    : null,
                cfop: product.cfop,
                stores: product.stores || [],
                packageQuantity: createParentProduct
                    ? ''
                    : uuidCopy
                        ? 1
                        : product.packageQuantity,
                isBestOfEdos: product.isBestOfEdos,
                cost:
                    product?.cost?.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    }) ?? '',
                avgCost:
                    product?.cost?.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    }) ?? '',
                tag: product?.tag ?? ProductTagTypeEnum.DEFAULT_TAG,
                tagQuantity: product?.tagQuantity ?? '',
                weight: product?.weight ? String(product.weight) : '',
                notPurchaseSuggestion: product?.notPurchaseSuggestion || false,
                variation: createParentProduct ? '' : product?.variation,
                tags: product?.tags || [],
                variations: createParentProduct ? [product] : product?.variations || [],
                similarProducts: product?.similars || [],
                ucvs: createUCVParentProduct ? [product] : product?.ucvs || [],
            });
        } catch (e) {
            const title = getTitle();
            const { response } = e;
            if (response?.status === 404) {
                back();
            } else {
                backendConnectionError('Fail to fetch product', e, null, title);
            }
        } finally {
            setLoadingProduct(false);
        }
    }

    function hasValidationError() {
        let hasError = false;
        setFormError(emptyFormError);
        setValidateOnChange(true);

        if (!barCodes.length || !barCodes.find((bc) => bc.main)) {
            hasError = true;
            // I18N
            setFormError((formError) => ({
                ...formError,
                barCodes: 'Obrigatório ter um código principal',
            }));
        }

        if (!formData.tagQuantity) {
            hasError = true;
            // I18N
            setFormError((formError) => ({
                ...formError,
                tagQuantity: 'Campo obrigatório',
            }));
        }

        if (!formData.name1?.trim()) {
            hasError = true;
            // I18N
            setFormError((formError) => ({
                ...formError,
                name1: 'Digite um nome para o produto',
            }));
        }

        if (!formData.description?.trim()) {
            setFormError((formError) => ({
                ...formError,
                description: 'Digite uma descrição para o produto',
            }));
        }

        if (formData.parentProduct && !formData.variation?.trim()) {
            hasError = true;
            setFormError((formError) => ({
                ...formError,
                variation: 'Digite o nome da variação do produto',
            }));
        }

        if (
            !formData.parentProduct &&
            formData.variations.length &&
            !formData.variation?.trim()
        ) {
            hasError = true;
            setFormError((formError) => ({
                ...formError,
                variation: 'Digite o nome da variação do produto',
            }));
        }

        if (
            !formData.parentProduct &&
            formData.variations.some((x) => !x.variation)
        ) {
            hasError = true;
            setFormError((formError) => ({
                ...formError,
                variations: 'Preencha todos os valores',
            }));
        }

        if (!formData.shortName1?.trim()) {
            hasError = true;
            // I18N
            setFormError((formError) => ({
                ...formError,
                shortName1: 'Digite um nome web para o produto',
            }));
        }

        if (!formData.brand) {
            hasError = true;
            // I18N
            setFormError((formError) => ({
                ...formError,
                brand: 'Selecione uma marca',
            }));
        }

        if (!formData.category) {
            hasError = true;
            // I18N
            setFormError((formError) => ({
                ...formError,
                category: 'Selecione uma categoria',
            }));
        }

        if (!formData.icms) {
            hasError = true;
            // I18N
            setFormError((formError) => ({
                ...formError,
                icms: 'Campo obrigatório',
            }));
        }

        if (!formData.ncm) {
            hasError = true;
            setFormError((formError) => ({
                ...formError,
                ncm: 'Campo obrigatório',
            }));
        }

        if (!formData.cfop) {
            hasError = true;
            // I18N
            setFormError((formError) => ({
                ...formError,
                cfop: 'Campo obrigatório',
            }));
        }

        if (!formData.packageQuantity || formData.packageQuantity == 0) {
            hasError = true;
            // I18N
            setFormError((formError) => ({
                ...formError,
                packageQuantity: 'Quantidade deve ser no mínimo 1',
            }));
        }
        if (formData.newPrice <= 0) {
            hasError = true;
            // I18N
            setFormError((formError) => ({
                ...formError,
                newPrice: 'Preço deve ser maior que R$ 0,00',
            }));
        }
        return hasError;
    }

    function back() {
        navigate(getProductsRoute());
    }

    async function save() {
        const title = getTitle();

        if (saveLoading) return;
        if (hasValidationError()) {
            setInfoModal({
                title,
                show: true,
                style: InfoModalStyle.INFO,
                message: 'Verifique as pendências no formulário entre as abas',
            });
            return;
        }

        try {
            setSaveLoading(true);
            const cloneBarCodes = cloneDeep(barCodes);

            const body = {
                ...formData,
                barCodes: cloneBarCodes.map((item) => {
                    if (!item?.product) delete item.id;
                    return item;
                }),
                budget: extractCurrencyNumber(formData.budget) || DEFAULT_VALUE_PRICE,
                price: extractCurrencyNumber(formData.price) || DEFAULT_VALUE_PRICE,
                brand: formData.brand?.id ?? null,
                segmentation: formData.segmentation?.id ?? null,
                category: formData.category?.id ?? null,
                subCategory: formData.subCategory?.id ?? null,
                family: formData.family?.id ?? null,
                icms: formData.icms ?? null,
                ncm: formData.ncm ?? null,
                taxClassification: formData.taxClassification?.id ?? null,
                taxSituation: formData.taxSituation?.id ?? null,
                cfop: formData.cfop ?? null,
                isBestOfEdos: formData.isBestOfEdos,
                similarProducts: formData.similarProducts || [],
                weight: formData?.weight
                    ? formData.weight.replace(/[.g]/g, '')
                    : null,
                packageQuantity: !(
                    createUCVParentProduct ||
                    (formData?.ucvParentProduct && !uuidCopy)
                )
                    ? 1
                    : formData.packageQuantity,
                tags: formData.tags || [],
                variations: formData.variations || [],
                ucvs: formData.ucvs || [],
            };

            if (createParentProduct && uuidCopy) body.parentProduct = uuidCopy;

            let message;
            if (!uuid) {
                // new product
                await ProductsService.addProduct(body);
                // I18N
                message = 'Produto salvo com sucesso!';
            } else {
                await ProductsService.editProduct(uuid, body);
                // I18N
                message = 'Produto editado com sucesso!';
            }

            let style = InfoModalStyle.SUCCESS;
            let onClose = () => navigate(0);

            setHasChange(false);
            setInfoModal({
                title,
                message,
                style,
                show: true,
                onClose,
            });
        } catch (e) {
            backendConnectionError(
                'Fail to create/edit product',
                e,
                null,
                title,
                ValidationErrorProductsText
            );
        } finally {
            setSaveLoading(false);
        }
    }

    async function deleteProduct() {
        let title = 'Excluir produto';
        async function proceed() {
            try {
                setDeleteLoading(true);
                await ProductsService.deleteProduct(uuid);
                const message = 'Produto excluído com sucesso!';
                const style = InfoModalStyle.SUCCESS;
                const onClose = back;
                setHasChange(false);
                setInfoModal({
                    title,
                    message,
                    style,
                    show: true,
                    onClose,
                });
            } catch (e) {
                backendConnectionError(
                    'Fail to delete product',
                    e,
                    null,
                    title,
                    ValidationErrorProductsText
                );
            } finally {
                setDeleteLoading(false);
            }
        }

        setConfirmModal({
            title,
            message: 'Você tem certeza que deseja excluir o produto?',
            onConfirm: proceed,
            show: true,
        });
    }

    useEffect(() => {
        if (uuid || uuidCopy) {
            fetchProduct(uuid || uuidCopy);
        }
    }, [uuid, uuidCopy, selectedStore]);

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [formData, barCodes]);

    useEffect(() => {
        setLoading(saveLoading, true);
    }, [saveLoading]);

    useEffect(() => {
        let f3Hotkey;
        let f6Hotkey;
        if (uuid) {
            f3Hotkey = addHotkey('F3', () => {
                buttonDeleteRef.current?.click();
            });
        }
        f6Hotkey = addHotkey('F6', () => {
            buttonSaveRef.current?.click();
        });
        return () => {
            removeHotkey(f3Hotkey);
            removeHotkey(f6Hotkey);
        };
    }, []);

    useEffect(() => {
        setWindowTitle(getTitle(true));
        return () => {
            setWindowTitle();
        };
    }, [productName]);

    useEffect(() => {
        setFormData((data) => ({
            ...data,
            icms: IcmsTaxSituationCfeValueByCfop(CfopValue(formData.cfop)),
        }));
    }, [formData.cfop]);

    useEffect(() => {
        if (user) {
            if (user.isAdmin) {
                setTab(TABS.MAIN);
            } else {
                let found = false;
                Object.values(TABS).some(status => {
                    if (!found && TABS_ROLES[status].some(x => user?.roles?.includes(x))) {
                        setTab(status);
                        found = true;
                    }
                });
            }
        }
    }, [user]);

    useEffect(() => {
        if (!IS_NEW) {
            TABS['MOVEMENTS'] = 'MOVEMENTS';
            TABS_TEXT['MOVEMENTS'] = 'Movimentação';
            TABS_ROLES['MOVEMENTS'] = [PermissionsEnum.HANDLE_PRODUCTS];
            return;
        } else {
            delete TABS['MOVEMENTS'];
            delete TABS_TEXT['MOVEMENTS'];
            delete TABS_ROLES['MOVEMENTS'];
        }
    }, [IS_NEW]);

    const title = getTitle();

    if (loadingProduct) {
        return <Loading />;
    }

    return (
        <>
            <RouteChangePrompt enabled={hasChange} />
            <div className={'crud-form'}>
                <Tabs.Root
                    value={tab}
                    className={'tabs_container'}
                    onValueChange={handleTabChange}
                >
                    <div className={'product-screen-header'}>
                        <ScreenHeader
                            title={title}
                            breadcrumbs={[
                                { name: 'Produtos', route: getProductsRoute() },
                                { name: 'Listagem', route: getProductsRoute() },
                                {
                                    name: uuid
                                        ? loadingProduct
                                            ? '...'
                                            : productName
                                        : title,
                                },
                            ]}
                            backRoute={getProductsRoute()}
                        ></ScreenHeader>

                        <ProductTable data={formData} />
                    </div>
                    <Tabs.List
                        className={'tabs_list mt-20'}
                        aria-label={'Manage your account'}
                    >
                        {Object.values(TABS).map(
                            (status, index) => {
                                return (
                                    <Tabs.Trigger
                                        key={index}
                                        className={'tabs_trigger'}
                                        value={status}
                                        disabled={!user.isAdmin && !TABS_ROLES[status]?.some(x => user?.roles?.includes(x))}
                                    >
                                        {`${TABS_TEXT[status]}`}
                                    </Tabs.Trigger>
                                );
                            })}
                    </Tabs.List>

                    <Tabs.Content value={TABS.MAIN}>
                        <MainForm
                            data={formData}
                            updateFormData={updateFormData}
                            formError={formError}
                            barCodes={barCodes}
                            setBarCodes={setBarCodes}
                            uuidCopy={uuidCopy}
                            createParentProduct={createParentProduct}
                            isNew={IS_NEW}
                        />
                    </Tabs.Content>
                    <Tabs.Content value={TABS.PRICING}>
                        <PricingForm
                            data={formData}
                            updateFormData={updateFormData}
                            formError={formError}
                            isNew={IS_NEW}
                        />
                    </Tabs.Content>
                    <Tabs.Content value={TABS.FISCAL}>
                        <FiscalForm
                            data={formData}
                            updateFormData={updateFormData}
                            formError={formError}
                            isNew={IS_NEW}
                        />
                    </Tabs.Content>
                    {
                        !IS_NEW && (
                            <Tabs.Content value={TABS.MOVEMENTS}>
                                <MovementsForm uuid={uuid} isNew={IS_NEW} />
                            </Tabs.Content>
                        )
                    }

                    {
                        tab !== TABS?.MOVEMENTS && (
                            <div className={'controls mt-32'}>
                                <div className={'row'}>
                                    <div className={'col-8'} />
                                    <div className={'col-2'}>
                                        {
                                            (user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_PRODUCTS)) && (
                                                <Button
                                                    className={'w-100'}
                                                    ref={buttonDeleteRef}
                                                    color={ButtonColor.BUTTON_COLOR_RED}
                                                    style={{ display: `${uuid ? '' : 'none'}` }}
                                                    loading={deleteLoading}
                                                    onClick={deleteProduct}
                                                >
                                                    {'Excluir [F3]'}
                                                </Button>
                                            )
                                        }
                                    </div>
                                    <div className={'col-2'}>
                                        {
                                            (
                                                user.isAdmin ||
                                                user.roles?.includes(PermissionsEnum.HANDLE_PRODUCTS) ||
                                                user.roles?.includes(PermissionsEnum.HANDLE_PRODUCT_MAIN) ||
                                                user.roles?.includes(PermissionsEnum.HANDLE_PRODUCT_FISCAL) ||
                                                user.roles?.includes(PermissionsEnum.HANDLE_PRODUCT_PRICING)
                                            ) && (
                                                <Button
                                                    className={'w-100'}
                                                    ref={buttonSaveRef}
                                                    color={ButtonColor.BUTTON_COLOR_GREEN}
                                                    fontColor={
                                                        ButtonFontColor.BUTTON_FONT_COLOR_LIGHT
                                                    }
                                                    loading={saveLoading}
                                                    onClick={save}
                                                >
                                                    {'Salvar [F6]'}
                                                </Button>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </Tabs.Root>
            </div>
        </>
    );
}

export function getProductFormRoute(uuid, mode) {
    // I18N
    if (uuid) {
        return `/produtos/listagem/${uuid}`;
    } else {
        return '/produtos/listagem/novo';
    }
}
