import React, { useEffect, useRef, useState } from 'react';
import { status } from '../services/LoginService';
import InfoModal, { InfoModalSize, InfoModalStyle } from '../components/modal/InfoModal';
import LoadingModal from '../components/modal/LoadingModal';
import * as Sentry from '@sentry/react';
import { setCsrfToken, setSelectedStoreId } from '../util/api';
import ConfirmModal from '../components/modal/ConfirmModal';
import nextId from 'react-id-generator';
import { removeAppLoading } from '../util/appLoading';
import { UserProfileEnum } from 'erva-doce-common';
import * as StoreService from '../services/StoresService';
import { getInfoLocalStorage, removeDataLocalStoage, setInfoLocalStorage } from '../util/localStorage';

const EnvironmentContext = React.createContext({
    isLogged: null,
    user: null,
});

const EnvironmentProvider = ({ children }) => {
    const [isLogged, setIsLogged] = useState(null);
    const [user, setUser] = useState(null);
    // const [cashState, setCashState] = useState(null);
    const [menuCollapsed, setMenuCollapsed] = useState(null);
    const [selectedStore, setStore] = useState({});
    const [infoModal, setInfoModal] = useState({
        title: '',
        message: '',
        style: InfoModalStyle.INFO,
        size: InfoModalSize.DEFAULT,
        show: false,
        onClose: null,
    });
    const [confirmModal, setConfirmModal] = useState({
        title: '',
        message: '',
        show: false,
        cancelText: null,
        confirmText: null,
        onCancel: null,
        onConfirm: null,
    });
    const [loading, _setLoading] = useState({
        show: false,
        transparent: false,
        text: '',
        progress: null,
    });
    const [ready, setReady] = useState(false);
    // removes the focus when a modal is showing and take it back when modal closes
    const modalFocusedElement = useRef(null);
    const modalHasShowing = useRef(false);

    const hotkeyStackRef = useRef([]);
    useEffect(() => {
        function keyDown(e) {
            for (let i = hotkeyStackRef.current.length - 1; i >= 0; i--) {
                const shortcut = hotkeyStackRef.current[i];
                if (shortcut.key === e.key) {
                    e.stopImmediatePropagation();
                    e.preventDefault();
                    shortcut.callback(e);
                    if (shortcut.exclusive) break;
                }
            }
        }
        document.addEventListener('keydown', keyDown);
        return () => document.removeEventListener('keydown', keyDown);
    });
    function addHotkey(key, callback, exclusive = true) {
        const id = nextId('hotkey');
        hotkeyStackRef.current.push({ id, key, callback, exclusive });
        return id;
    }

    function removeHotkey(id) {
        if (!id) return;
        const index = hotkeyStackRef.current.findIndex(s => s.id === id);
        if (index >= 0) {
            hotkeyStackRef.current.splice(index, 1);
        }
    }

    function selectStore(store) {
        if (!store) {
            setSelectedStoreId(null);
            setStore(null);
            return;
        }

        if (user.isAdmin) {
            setInfoLocalStorage({ field: 'storeIdSelected', data: store.uuid });
            setSelectedStoreId(store.uuid);
            setStore(store);
        } else {
            const exists = user.stores.find(x => x.uuid === store.uuid);

            if (exists) {
                setSelectedStoreId(store.uuid);
                setStore(store);
            } else {
                setSelectedStoreId(null);
                setStore(null);
            }
        }
    }

    useEffect(() => {
        const show = infoModal.show || confirmModal.show || loading.show;
        if (modalHasShowing.current !== show) {
            if (show) {
                modalFocusedElement.current = document.activeElement;
                document.activeElement?.blur();
            } else {
                modalFocusedElement.current?.focus();
                modalFocusedElement.current = null;
            }
            modalHasShowing.current = show;
        }

    }, [infoModal, confirmModal, loading]);

    function setLoading(show, transparent = false, text = null, progress = null) {
        _setLoading((loading) => ({
            text: !show && !text ? loading.text : text, // this avoids to change the text when the loading is hiding
            show,
            transparent,
            progress,
        }));
    }

    function backendConnectionError(message, e, onClose, title, validationErrorMessageFn) {
        Sentry.captureException(e);
        const { response } = e || {};
        console.error(message, e, response?.status || undefined, response?.data?.message);
        // I18N
        let userMessage = '<strong>Erro ao comunicar com o servidor</strong>. Por favor, verifique a conexão e tente novamente em alguns minutos.';
        if (!response) {
            if (e?.code === 'ERR_NETWORK') {
                // I18N
                userMessage = '<strong>Não foi possível conectar ao servidor backend</strong>. Servidor está fora do ar ou a conexão com a internet não está disponível.';
            } else {
                // I18N
                userMessage = '<strong>Erro ao processar requisição</strong>. Nosso time foi notificado e estamos trabalhando para resolver o problema.';
            }
        } else if (response?.status === 500) {
            // I18N
            userMessage = '<strong>Erro interno do servidor</strong>. Nosso time foi notificado e estamos trabalhando para resolver o problema.';
        } else if (response?.status === 403) {
            // I18N
            userMessage = '<strong>Solicitação negada</strong>. Você não possui permissão para executar a operação solicitada.';
        } else if (response?.status === 412) {
            // I18N
            userMessage = '<strong>Validação do Recaptcha falhou</strong>. Por favor, tente novamente em alguns minutos.';
        } else if (response?.status === 404) {
            // I18N
            userMessage = '<strong>Recurso não encontrado</strong>';
        } else if (response?.status === 422) {
            if (response?.data?.errors && validationErrorMessageFn) {
                if (response.data.errors.length === 1) {
                    userMessage = validationErrorMessageFn(response.data.errors[0]);
                    if (userMessage) {
                        userMessage = userMessage.trim();
                        // error validation must not end with punctuation
                        if (!userMessage.endsWith('.')
                            && !userMessage.endsWith('!')
                            && !userMessage.endsWith('?')
                        ) {
                            userMessage += '.';
                        }
                    }
                } else {
                    // I18N
                    userMessage = 'Corrija os erros de validação abaixo e tente novamente:';
                    userMessage += '<ul>';
                    for (const error of response.data.errors) {
                        userMessage += `<li>${validationErrorMessageFn(error)}</li>`;
                    }
                    userMessage += '</ul>';
                }
            } else {
                // I18N
                userMessage = 'O servidor indicou um <strong>erro de validação</strong> nos dados enviados. Se o problema persistir, entre em contato com o suporte.';
            }
        }

        // I18N
        setInfoModal({
            title: title ?? 'Plataforma Administrativa - Erva Doce',
            message: userMessage,
            show: true,
            style: InfoModalStyle.ERROR,
            size: InfoModalSize.DEFAULT,
            onClose
        });
    }

    function recaptchaError(onClose) {
        // I18N
        setInfoModal({
            title: 'Plataforma Administrativa - Erva Doce',
            message: '<strong>Validação do Recaptcha falhou</strong>. Por favor, tente novamente em alguns minutos.',
            show: true,
            style: InfoModalStyle.ERROR,
            onClose
        });
    }

    useEffect(() => {
        if (isLogged == null || isLogged && !user) {
            console.debug('Retrieving authentication status...');
            status().then(async (user) => {
                if (user) {
                    setIsLogged(true);
                    setCsrfToken(user['csrfToken']);
                    delete user['csrfToken'];
                    setUser({
                        ...user,
                        isAdmin: user.profile === UserProfileEnum.ADMIN,
                    });

                    if (user.profile === UserProfileEnum.ADMIN) {
                        if (user.store?.id) {
                            setStore(user.store);
                            setSelectedStoreId(user.store.uuid);
                        } else {
                            const stores = await StoreService.getAllStores();
                            const selectStoreLocalStorage = getInfoLocalStorage({ field: 'storeIdSelected' });

                            const selectedStore = { store: {} };

                            if (selectStoreLocalStorage) {
                                const store = stores.find((store) => store.uuid === selectStoreLocalStorage);
                                if (store) selectedStore.store = store;
                            }

                            if (!selectStoreLocalStorage) {
                                selectedStore.store = stores[0];
                                removeDataLocalStoage({ field: 'storeIdSelected' });
                            }

                            setStore(selectedStore?.store);
                            setSelectedStoreId(selectedStore?.store?.uuid);
                            setUser(state => ({
                                ...state,
                                stores,
                            }));
                        }
                    } else {
                        setSelectedStoreId(user.store.uuid);
                        setStore(user.store);
                    }

                    Sentry.setUser(user);
                    console.debug('User logged.', user);
                } else {
                    setIsLogged(false);
                    setUser(null);
                    selectStore(null);
                    setSelectedStoreId(null);
                    Sentry.setUser(null);
                    console.debug('No user logged.');
                }
            }).catch((e) => {
                removeAppLoading(() => {
                    setIsLogged(false);
                    setUser(null);
                    selectStore(null);
                    setSelectedStoreId(null);
                    backendConnectionError('Fail to retrieve authentication status', e,
                        () => window.location.reload()
                    );
                });
            });
        }
    }, [isLogged, user]);

    useEffect(() => {
        if (isLogged) {
            const readyTimeout = setTimeout(() => {
                setReady(true);
            }, 200);
            return () => {
                clearTimeout(readyTimeout);
            };
        } else {
            setReady(false);
        }
    }, [isLogged]);

    function setWindowTitle(title) {
        const defaultWindowTitle = 'Plataforma Administrativa - Erva Doce';
        document.title = title ? `${title} - ${defaultWindowTitle}` : defaultWindowTitle;
    }

    useEffect(() => {
        if (localStorage.getItem('m_collapse')) {
            setMenuCollapsed(localStorage.getItem('m_collapse') === 'true');
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('m_collapse', menuCollapsed);
    }, [menuCollapsed]);

    // useEffect(() => {


    //     getCashState();
    // }, [isLogged]);

    // noinspection JSXUnresolvedComponent, JSCheckFunctionSignatures
    return (
        <EnvironmentContext.Provider
            value={{
                isLogged,
                setIsLogged,
                user,
                setUser,
                selectedStore,
                selectStore,
                infoModal,
                setInfoModal,
                confirmModal,
                setConfirmModal,
                backendConnectionError,
                setLoading,
                loading,
                recaptchaError,
                ready,
                setWindowTitle,
                addHotkey,
                removeHotkey,
                menuCollapsed,
                setMenuCollapsed,
                // cashState,
                // setCashState
            }}>
            {children}
            <InfoModal
                title={infoModal.title}
                message={infoModal.message}
                show={infoModal.show}
                style={infoModal.style}
                size={infoModal.size}
                onClose={() => {
                    // noinspection JSCheckFunctionSignatures
                    setInfoModal(infoModal => ({ ...infoModal, show: false }));
                    if (infoModal.onClose) infoModal.onClose();
                }}
            />
            <ConfirmModal
                title={confirmModal.title}
                message={confirmModal.message}
                show={confirmModal.show}
                cancelText={confirmModal.cancelText}
                confirmText={confirmModal.confirmText}
                onCancel={() => {
                    // noinspection JSCheckFunctionSignatures
                    setConfirmModal(confirmModal => ({ ...confirmModal, show: false }));
                    if (confirmModal.onCancel) confirmModal.onCancel();
                }}
                onConfirm={() => {
                    // noinspection JSCheckFunctionSignatures
                    setConfirmModal(confirmModal => ({ ...confirmModal, show: false }));
                    if (confirmModal.onConfirm) confirmModal.onConfirm();
                }}
            />
            <LoadingModal text={loading.text} transparent={loading.transparent} show={loading.show} progress={loading.progress} />
        </EnvironmentContext.Provider>
    );
};

export {
    EnvironmentContext,
    EnvironmentProvider
};
