import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import Pills from '../../components/Pills';
import FieldCollaborator from '../../components/FieldCollaborator';
import FieldDate from '../../components/FieldDate';
import FieldCashier from '../../components/FieldCashier';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import ToolTip from '../../components/ToolTip';
import { useContext, useState } from 'react';
import { VouchersStatusEnum } from 'erva-doce-common';
import classNames from 'classnames';
function VoucherFilterModal({
    show,
    onCancel,
    onConfirm,
    filter,

}) {
    const [cashier, setCashier] = useState(filter?.cashier.id ?? []);
    const [collaborator, setCollaborator] = useState(filter?.collaborator.id ?? []);
    const [date, setDate] = useState(filter?.date ?? null);
    const [status, setStatus] = useState(filter.voucherOpen ?? null);
    const { user } = useContext(EnvironmentContext);

    function confirm() {
        onConfirm({
            cashier,
            collaborator,
            date,
            status,
            page: 0,
        });
    }

    function cancel() {
        onCancel();
        setCashier(filter.cashier);
        setCollaborator(filter.collaborator);
        setDate(filter.date);
        setStatus(filter.status);
    }

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={cancel}
            onConfirm={confirm}
            // I18N
            title={'Filtrar vales'}
        >
            <div className={'gd'}>
                <div className={'gd-col gd-col-12'}>
                    <FieldDate
                        label={'Data'}
                        onChange={({ target }) => setDate(target.value || null)}
                    />
                </div>
            </div>
            <div className={'gd'}>
                <div className={'gd-col gd-col-12'}>

                    <div className={'col-12 align-left mt-20 tooltip-voucher'}>
                        <label className={'color-black mb-20'}>
                            {'Status vale'}
                        </label>
                        <ToolTip
                            description={'Filtra pelos status dos vales.'}
                        />
                    </div>
                    <div className={'col-12 d-flex mb-35'}>
                        <div
                            className={classNames('filter-collaborators-button mr-20', { 'active': status === VouchersStatusEnum.OPEN })}
                            onClick={() => {
                                setStatus((prev) =>
                                    prev === VouchersStatusEnum.OPEN ? '' : VouchersStatusEnum.OPEN
                                );
                            }}
                        >
                            {'Aberto'}
                        </div>
                        <div
                            className={classNames('filter-collaborators-button mr-20', { 'active': status === VouchersStatusEnum.WRITTEN_OFF })}
                            onClick={() => {
                                setStatus((prev) =>
                                    prev === VouchersStatusEnum.WRITTEN_OFF ? '' : VouchersStatusEnum.WRITTEN_OFF
                                );
                            }}
                        >
                            {'Baixado'}
                        </div>
                        <div
                            className={classNames('filter-collaborators-button mr-20', { 'active': status === VouchersStatusEnum.CANCELLED })}
                            onClick={() => {
                                setStatus((prev) =>
                                    prev === VouchersStatusEnum.CANCELLED ? '' : VouchersStatusEnum.CANCELLED
                                );
                            }}
                        >
                            {'Cancelado'}
                        </div>
                    </div>
                </div>
            </div>

            {
                user?.profile === 'ADMIN' ? (
                    <><div className={'gd'}>

                        <div className={'gd-col gd-col-12'}>
                            <FieldCashier
                                // I18N
                                label={'Caixas'}
                                multipleSelection={true}
                                removeButton={true}
                                onAddItem={(record) => {
                                    setCashier((cashier) => {
                                        return [
                                            ...cashier,
                                            record
                                        ];
                                    });
                                }}
                                ignoreItems={cashier.map(record => record.id)}
                            >
                                <Pills
                                    readOnly={true}
                                    pills={cashier}
                                    onRemoveItem={(record) => {
                                        setCashier((cashier) => {
                                            return cashier.filter(item => item.id !== record.id);
                                        });
                                    }} />
                            </FieldCashier>
                        </div>
                    </div>
                    <div className={'gd'}>
                        <div className={'gd-col gd-col-12'}>
                            <FieldCollaborator
                                // I18N
                                label={'Colaboradores'}
                                multipleSelection={true}
                                onAddItem={(record) => {
                                    setCollaborator((colaborador) => {
                                        return [
                                            ...colaborador,
                                            record
                                        ];
                                    });
                                }}
                                removeButton={true}
                                ignoreItems={collaborator.map(record => record.id)}
                            >
                                <Pills
                                    readOnly={true}
                                    pills={collaborator}
                                    onRemoveItem={(record) => {
                                        setCollaborator((colaborador) => {
                                            return colaborador.filter(item => item.id !== record.id);
                                        });
                                    }} />
                            </FieldCollaborator>
                        </div>
                    </div></>
                ) : null


            }

        </SimpleConfirmModal>
    );
}

export default VoucherFilterModal;