/* eslint-disable no-unused-vars */
import { getPermissionDescription, PermissionsText } from 'erva-doce-common';
import './FieldPermission.scss';
import classNames from 'classnames';
import ToolTip from './ToolTip';

function FieldPermissions({
    groupName,
    onSelected,
    permissions,
    readOnly,
    className,
    select = [],
}) {
    return (
        <div className={classNames('field-permissions col-3', className)}>
            <div className={'row'}>
                <div className={'col-12'}>
                    <div className={'title'}>{groupName}</div>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-12'}>
                    <div className={'options'}>
                        {permissions.map((option, index) => (
                            <div
                                key={index}
                                className={'col-12 option field-inputs tooltip-content'}
                            >
                                <label>
                                    <input
                                        type={'checkbox'}
                                        checked={select?.includes(option)}
                                        onChange={(e) => {
                                            if (onSelected) {
                                                if (e.target.checked) {
                                                    onSelected({
                                                        add: [option],
                                                    });
                                                } else {
                                                    onSelected({
                                                        remove: [option],
                                                    });
                                                }
                                            }
                                        }}
                                        disabled={readOnly}
                                    />
                                    {PermissionsText(option)}
                                </label>
                                <ToolTip description={getPermissionDescription(option)} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FieldPermissions;
