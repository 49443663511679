import FieldNumber from './FieldNumber';
import { forwardRef } from 'react';
import { parseNumberLocale } from '../util/numberParser';

const suffix = '%';

function FieldPercentage({
    ...props
}, ref) {
    return (
        <FieldNumber
            ref={ref}
            decimalLimit={0}
            integerLimit={3}
            suffix={suffix}
            {...props}
        />
    );
}

export default forwardRef(FieldPercentage);

export function extractPercentageNumber(value) {
    if (!value) return null;
    return parseNumberLocale(value.replace(suffix, '').trim());
}
