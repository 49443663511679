export enum SaleChannelEnum {
  FISICA = "FISICA",
  ECOMMERCE = "ECOMMERCE"
}

export function SaleChannelText(channel: SaleChannelEnum | string) {
    switch (channel) {
    case SaleChannelEnum.FISICA:
        return "Fisíca";
    case SaleChannelEnum.ECOMMERCE:
        return "E-commerce";
    default:
        throw new Error(`Not implemented for ${channel}`);
    }
}