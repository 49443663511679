import { useRef } from 'react';
import FieldAddress from '../../components/FieldAddress';
import FieldCpf from '../../components/FieldCpf';
import FieldDate from '../../components/FieldDate';
import FieldPhone from '../../components/FieldPhone';
import FieldText from '../../components/FieldText';

export function CustomerPersonalDataForm({
    formData,
    formError,
    updateFormData,
    addressData,
    setAddressData,
    readOnly,
    setFormError
}) {
    const phoneInputRef = useRef();

    return (
        <fieldset>
            <legend>
                {/*I18N*/}
                {'Dados pessoais'}
            </legend>
            <div className={'gd field-group'}>
                <div className={'gd-col-5'}>
                    <FieldText
                        // I18N
                        label={'Nome completo'}
                        onChange={({ target }) => updateFormData({ name: target.value })}
                        value={formData?.name}
                        validationError={formError?.name}
                        maxLength={120}
                        readOnly={readOnly}
                        onBlur={() => {
                            if (formData?.name) {
                                setFormError((formError) => ({ ...formError, name: null }));
                            }
                        }}
                    />
                </div>
            </div>
            <div className={'gd'}>
                <div className={'gd-col-4'}>
                    <FieldCpf
                        // I18N
                        label={'CPF'}
                        onChange={({ target }) => updateFormData({ cpf: target.value })}
                        value={formData?.cpf}
                        validationError={formError?.cpf}
                        readOnly={readOnly}
                        onBlur={() => {
                            if (formData?.cpf) {
                                setFormError((formError) => ({ ...formError, cpf: null }));
                            }
                        }}
                    />
                </div>
                <div className={'gd-col-4'}>
                    <FieldDate
                        // I18N
                        label={'Data de nascimento'}
                        onChange={({ target }) => updateFormData({ birthDate: target.value })}
                        value={formData?.birthDate}
                        validationError={formError?.birthDate}
                        readOnly={readOnly}
                        onBlur={() => {
                            if (formData?.birthDate) {
                                setFormError((formError) => ({ ...formError, birthDate: null }));
                            }
                        }}
                    />
                </div>
                <div className={'gd-col-4'}>
                    <FieldText
                        // I18N
                        label={'E-mail'}
                        maxLength={80}
                        onChange={({ target }) => updateFormData({ email: target.value })}
                        value={formData?.email}
                        validationError={formError?.email}
                        readOnly={readOnly}
                        onBlur={() => {
                            if (formData?.email) {
                                setFormError((formError) => ({ ...formError, email: null }));
                            }
                        }}
                    />
                </div>
            </div>
            <div className={'gd'}>
                <div className={'gd-col-4'}>
                    <FieldAddress
                        // I18N
                        label={'Endereço principal'}
                        onChange={(addressData) => {
                            setAddressData(addressData);
                        }}
                        value={addressData}
                        onClose={() => {
                            phoneInputRef.current?.inputElement?.focus();
                        }}
                        readOnly={readOnly}
                    />
                </div>
                <div className={'gd-col-4'}>
                    <FieldPhone
                        ref={phoneInputRef}
                        // I18N
                        label={'Telefone'}
                        onChange={({ target }) => updateFormData({ phone: target.value })}
                        value={formData?.phone}
                        validationError={formError?.phone}
                        readOnly={readOnly}
                        onBlur={() => {
                            if (formData?.phone) {
                                setFormError((formError) => ({ ...formError, phone: null }));
                            }
                        }}
                    />
                </div>
            </div>
        </fieldset>
    );
}