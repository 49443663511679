import { PaymentsEnum } from 'erva-doce-common';
import FieldCurrency, { extractCurrencyNumber, formatCurrency } from './FieldCurrency';
import FieldInteger from './FieldInteger';
import FieldMachine from './FieldMachine';
import FieldPaymentType from './FieldPaymentType';
import FieldText from './FieldText';
import { useEffect, useState } from 'react';

function PaymentList({
    formData,
    updateFormData,
    removeRowPayment,
    currencyRefs,
    onConfirm,
    disabled,
    amount,
}) {
    const [error, setError] = useState(false);

    const handlePaymentChange = (target, idx) => {
        const newFormData = [...formData.payments];
        const newTotal = formatCurrency(target.value || '0');
        const total = newFormData.reduce((acc, cur, index) => acc + (index === idx ? extractCurrencyNumber(newTotal) : extractCurrencyNumber(cur.total)), 0);
        const totalInstallments = newFormData.reduce((acc, cur) => acc + cur.quantityInstallments, 0);

        if (total > amount) {
            return;
        }

        newFormData[idx].total = newTotal;

        newFormData[idx].amountInstallments = newFormData[idx].quantityInstallments === 1
            ? newTotal
            : (newTotal / (totalInstallments || 1)).toFixed(2) - newTotal;

        updateFormData({ payments: newFormData });
    };

    useEffect(() => {
        const totalInputs = Array.from(
            document.querySelectorAll('[name=payment-total]')
        );
        const event = (e) => {
            if (e.key === 'Enter') {
                const activeElement = document.activeElement;
                const focusableElements = Array.from(
                    document.querySelectorAll('input, [tabindex]:not([tabindex=\'-1\'])')
                );
                const currentElementIndex = focusableElements.indexOf(activeElement);
                const nextElement = focusableElements[currentElementIndex + 1];

                e.preventDefault();
                nextElement.focus();
            }
        };

        totalInputs.map(input => {
            input.addEventListener('keydown', event);
        });

        return () => {
            totalInputs.map(input => {
                input.removeEventListener('keydown', event);
            });
        };
    }, [formData.payments]);

    return (
        <div className={'payment-list'}>
            <div className={'gd field-group payment-header'}>
                <div className={'gd-col-2 mr-25'}>
                    <div className={'container-title'}>
                        <span>{'Total'}</span>
                    </div>
                </div>
                <div className={'gd-col-2 mr-25'}>
                    <div className={'container-title'}>
                        <span>{'Tipo'}</span>
                    </div>
                </div>
                <div className={'gd-col-3'}>
                    <div className={'container-title'}>
                        <span>{'Máquina'}</span>
                    </div>
                </div>
                <div className={'gd-col-1 mr-25'}>
                    <div className={'container-title'}>
                        <span>{'Parcelas'}</span>
                    </div>
                </div>
                <div className={'gd-col-3'}>
                    <div className={'container-title'}>
                        <span>{'Valor parcelado'}</span>
                    </div>
                </div>
            </div>
            {formData.payments?.map((payment, idx) => {
                let { total, paymentType, machine, quantityInstallments, amountInstallments, } = payment;
                return (
                    <div key={idx} className={'gd field-group'}>
                        <div className={'remove'}>
                            {payment.tefId
                                ? <>{'OK'}</>
                                : formData.payments.filter(x => !x.tefId && !x.tefConfirmed).length > 1 &&
                            (
                                <button
                                    className={'remove-button'}
                                    onClick={() => removeRowPayment(idx)}
                                >
                                    {'X'}
                                </button>
                            )}
                        </div>
                        <div className={'gd-col-2 mr-25'}>
                            <FieldCurrency
                                value={total}
                                name={'payment-total'}
                                disabled={payment.tefId}
                                readOnly={payment.tefId}
                                className={'field-total'}
                                ref={(el) => currencyRefs.current[idx] = el}
                                onChange={({ target }) => {
                                    handlePaymentChange(target, idx);
                                }}
                                validationError={error && 'Valor maior que total da venda'}
                            />
                        </div>
                        <div className={'gd-col-2 mr-25'}>
                            <FieldPaymentType
                                select={paymentType}
                                name={'payment-type'}
                                disabled={payment.tefId}
                                readOnly={payment.tefId}
                                onSelected={(pt) => {
                                    payment.paymentType = pt;

                                    if (pt?.type === PaymentsEnum.DINNER) {
                                        payment.machine = null;
                                    }

                                    if ([PaymentsEnum.PIX, PaymentsEnum.DINNER].includes(pt?.type)) {
                                        payment.quantityInstallments = 1;
                                    }

                                    updateFormData({ payments: [...formData.payments], });
                                }}
                            />
                        </div>
                        <div className={'gd-col-3'}>
                            {paymentType?.type !== PaymentsEnum.DINNER ? (
                                <FieldMachine
                                    select={machine}
                                    name={'payment-machine'}
                                    disabled={payment.tefId}
                                    readOnly={payment.tefId}
                                    onSelected={(m) => {
                                        payment.machine = m;
                                        updateFormData({ payments: [...formData.payments] });
                                    }}
                                />
                            ) : null}
                        </div>
                        <div className={'gd-col-1 mr-25'}>
                            <FieldInteger
                                maxLength={2}
                                name={'payment-parcel'}
                                disabled={payment.tefId || [PaymentsEnum.PIX, PaymentsEnum.DINNER].includes(paymentType?.type)}
                                readOnly={payment.tefId || [PaymentsEnum.PIX, PaymentsEnum.DINNER].includes(paymentType?.type)}
                                value={quantityInstallments}
                                className={'field-quantity-installments'}
                                onChange={({ target }) => {
                                    const quantityInstallments = Number(target.value);
                                    const totalValue = extractCurrencyNumber(payment.total);
                                    payment.amountInstallments = quantityInstallments === 1
                                        ? payment.total
                                        : (totalValue / (quantityInstallments || 1)).toFixed(2);
                                    payment.quantityInstallments = quantityInstallments;
                                    updateFormData({ payments: [...formData.payments] });
                                }}
                                onConfirm={onConfirm}
                                hasError={disabled}
                            />

                        </div>
                        <div className={'gd-col-3'}>
                            <FieldText
                                maxLength={120}
                                readOnly={true}
                                validationError={false}
                                value={
                                    quantityInstallments === 1 ? formatCurrency(total) : formatCurrency(amountInstallments)
                                }
                                onChange={
                                    payment.amountInstallments = quantityInstallments === 1 ? total : formatCurrency(amountInstallments)
                                }
                                className={'field-amount-installments'}
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default PaymentList;
