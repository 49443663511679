export enum RecurringBillsToPayEnum {
  MONTHLY = "MONTHLY",
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  YEARLY = "YEARLY",
}

export enum RecurringBillsToPayStatusEnum {
  ACTIVE = "ACTIVE",
  DEACTIVATED = "DEACTIVATED",
}


export function RecurringBillsToPayTypeText(type: RecurringBillsToPayEnum | string) {
    switch (type) {
    case RecurringBillsToPayEnum.DAILY:
        return "Conta diária";
    case RecurringBillsToPayEnum.WEEKLY:
        return "Conta semanal";
    case RecurringBillsToPayEnum.MONTHLY:
        return "Conta mensal";
    case RecurringBillsToPayEnum.YEARLY:
        return "Conta anual";
    default:
        throw new Error(`Not implemented for ${type}`);
    }
}

export function RecurringBillsToPayStatusText(type: RecurringBillsToPayStatusEnum | string) {
    switch (type) {
    case RecurringBillsToPayStatusEnum.ACTIVE:
        return "Ativo";
    case RecurringBillsToPayStatusEnum.DEACTIVATED:
        return "Desativado";
    default:
        throw new Error(`Not implemented for ${type}`);
    }
}
