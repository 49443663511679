export enum ValidationErrorVirtualOrderEnum {
  INVALID_CPF = "INVALID_CPF",
  NO_PRODUCTS = "NO_PRODUCTS",
  COUPON_NOT_FOUND = "COUPON_NOT_FOUND",
  INVALID_COUPON = "INVALID_COUPON",
  DELIVERY_METHOD_NOT_FOUND = "DELIVERY_METHOD_NOT_FOUND",
  PAYMENT_METHOD_NOT_FOUND = "PAYMENT_METHOD_NOT_FOUND",
  ADDRESS_NOT_FOUND = "ADDRESS_NOT_FOUND",
  USER_NOT_FOUND = "USER_NOT_FOUND",
  VIRTUAL_ORDER_NOT_FOUND = "VIRTUAL_ORDER_NOT_FOUND",
  NOT_ENOUGH_STOCK = "NOT_ENOUGH_STOCK",
  INVALID_OR_EXPIRED_TOKEN = "INVALID_OR_EXPIRED_TOKEN"
}

export function ValidationErrorVirtualOrderText(error: ValidationErrorVirtualOrderEnum): string {
    switch (error) {
    case ValidationErrorVirtualOrderEnum.INVALID_CPF:
        return "O CPF é inválido";
    case ValidationErrorVirtualOrderEnum.NO_PRODUCTS:
        return "Não há produtos na sacola";
    case ValidationErrorVirtualOrderEnum.COUPON_NOT_FOUND:
        return "Cupom não encontrado";
    case ValidationErrorVirtualOrderEnum.INVALID_COUPON:
        return "O cupom é inválido ou não existe";
    case ValidationErrorVirtualOrderEnum.DELIVERY_METHOD_NOT_FOUND:
        return "Método de entrega não encontrado";
    case ValidationErrorVirtualOrderEnum.PAYMENT_METHOD_NOT_FOUND:
        return "Forma de pagamento não encontrada";
    case ValidationErrorVirtualOrderEnum.ADDRESS_NOT_FOUND:
        return "Endereço não encontrado";
    case ValidationErrorVirtualOrderEnum.USER_NOT_FOUND:
        return "Vendedor não encontrado";
    case ValidationErrorVirtualOrderEnum.VIRTUAL_ORDER_NOT_FOUND:
        return "Pedido não encontrado";
    case ValidationErrorVirtualOrderEnum.NOT_ENOUGH_STOCK:
        return "Estoque insuficiente";
    case ValidationErrorVirtualOrderEnum.INVALID_OR_EXPIRED_TOKEN:
        return "Token inválido ou prazo para a avaliação expirou ";
    default:
        throw new Error(`Not implemented for ${error}`);
    }
}