import {
    ProductTagTypeEnum,
    ProductTagTypeText,
    ValidationErrorProductFamilyText,
    PermissionsEnum,
    OrderByProductsEnum,
    ENUM_SHORTCUT,
} from 'erva-doce-common';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button, { ButtonColor, ButtonFontColor } from '../../components/Button';
import FieldText from '../../components/FieldText';
import FieldNumber from '../../components/FieldNumber';
import FieldSelect from '../../components/FieldSelect';
import { Loading, LoadingSize } from '../../components/Loading';
import RouteChangePrompt from '../../components/RouteChangePrompt';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as ProductFamiliesService from '../../services/ProductsFamiliesService';
import { getProductFamiliesRoute } from './ProductsFamilies';
import FieldCurrency, {
    extractCurrencyNumber,
    formatCurrency,
} from '../../components/FieldCurrency';
import axios from 'axios';
import { formatValue } from '../../util/formatValue';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import { calculateMarginPercentage } from '../../util/calculateMarginPercentage';
import { ButtonStyle } from '../../components/Button';
import './ProductsFamilies.scss';
import { isEqual } from 'lodash';

export default function ProductFamilyForm() {
    const emptyFormData = {
        name: '',
        tag: ProductTagTypeEnum.DEFAULT_TAG,
        tagQuantity: 1,
        newFamilyPrice: 0,
        oldFamilyPrice: 0,
    };

    const emptyFormError = {
        ...emptyFormData,
        tagQuantity: '',
    };

    const [formData, setFormData] = useState(emptyFormData);
    const [formDataClone, setFormDataClone] = useState({});
    const [formError, setFormError] = useState(emptyFormError);
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [isEdited , setisEdited] = useState(false);
    const buttonDeleteRef = useRef();
    const buttonSaveRef = useRef();
    const buttonPrintRef = useRef();
    const {
        setLoading,
        backendConnectionError,
        setInfoModal,
        setConfirmModal,
        addHotkey,
        removeHotkey,
        user,
    } = useContext(EnvironmentContext);
    const { uuid } = useParams();
    const [loadingProductFamily, setLoadingProductFamily] = useState(!!uuid);
    const [productFamilyName, setProductFamilyName] = useState('');
    const [hasChange, setHasChange] = useState(false);
    const [products, setProducts] = useState([]);

    const [filter, setFilter] = useState({
        order: OrderByProductsEnum.NAME_ASC,
        page: 0,
    });

    const navigate = useNavigate();
    const canSave =
        user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_PRODUCTS);
    const canRemove =
        user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_PRODUCTS);

    function updateFormData(data) {
        setFormData((formData) => ({ ...formData, ...data }));
        setHasChange(true);
    }

    async function fetchProductFamily() {
        try {
            setLoadingProductFamily(true);
            const productFamily = await ProductFamiliesService.getProductFamily(
                uuid
            );

            setProductFamilyName(productFamily.name);
            const obj = {
                ...productFamily,
                tag: productFamily?.tag ?? ProductTagTypeEnum.DEFAULT_TAG,
                newFamilyPrice: productFamily.newFamilyPrice,
                oldFamilyPrice: productFamily.newFamilyPrice,
            };
            setFormData({ ...obj });
            setFormDataClone({ ...obj });
        } catch (e) {
            const title = getTitle();
            const { response } = e;
            if (response?.status === 404) {
                back();
            } else {
                backendConnectionError(
                    'Fail to fetch product family',
                    e,
                    null,
                    title
                );
            }
        } finally {
            setLoadingProductFamily(false);
        }
    }

    useEffect(() => {
        if (uuid) {
            fetchProductFamily();
        }
    }, [uuid]);

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [formData]);

    useEffect(() => {
        if( isEqual(formData, formDataClone) ){
            setisEdited (false);
        } else {
            setisEdited (true);
        }

    }, [formData, formDataClone]);

    function hasValidationError() {
        let hasError = false;
        setFormError(emptyFormError);
        setValidateOnChange(true);

        if (!formData.name?.trim()) {
            hasError = true;
            setFormError((formError) => ({
                ...formError,
                name: 'Digite o nome da família de produto',
            }));
        }

        if (!formData.tagQuantity) {
            hasError = true;
            setFormError((formError) => ({
                ...formError,
                tagQuantity: 'Campo obrigatório',
            }));
        }

        return hasError;
    }

    useEffect(() => {
        let shortcutDelete;

        if (uuid) {
            shortcutDelete = addHotkey(ENUM_SHORTCUT.SHORTCUT_DELETE, () => {
                buttonDeleteRef.current?.click();
            });
        }

        const shortcutConfirm = addHotkey(ENUM_SHORTCUT.SHORTCUT_CONFIRM, () => {
            buttonSaveRef.current?.click();
        });

        const shortcutCreatePrimary = addHotkey(ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY, () => {
            buttonPrintRef.current?.click();
        });

        return () => {
            removeHotkey(shortcutDelete);
            removeHotkey(shortcutConfirm);
            removeHotkey(shortcutCreatePrimary);
        };
    }, []);

    function back() {
        navigate(getProductFamiliesRoute());
    }

    function handleRowClickNavigate({ uuid }) {
        const url = `/produtos/listagem/${uuid}`;
        navigate(url);
    }

    async function createFamily() {
        try {
            const title = getTitle();
            const newBody = formatedBodyForRequest();
            await ProductFamiliesService.addProductFamily(newBody);

            let style = InfoModalStyle.SUCCESS;
            let onClose = back;

            setHasChange(false);
            setInfoModal({
                title,
                message: 'Família de produto salva com sucesso!',
                style,
                show: true,
                onClose,
            });
        } catch (e) {
            backendConnectionError(
                'Fail to create/edit product family',
                e,
                null,
                title,
                ValidationErrorProductFamilyText
            );
        } finally {
            setSaveLoading(false);
        }
    }

    async function editiFAmily() {
        const newBody = formatedBodyForRequest();
        const title = getTitle();

        async function proced() {
            try {
                const params = { uuid: uuid, formData: newBody };
                await ProductFamiliesService.editProductFamily({ ...params });

                let style = InfoModalStyle.SUCCESS;
                let onClose = () => navigate(0);

                setHasChange(false);
                setInfoModal({
                    title,
                    message: 'Família de produto editada com sucesso!',
                    style,
                    show: true,
                    onClose,
                });
            } catch (e) {
                backendConnectionError(
                    'Fail to create/edit product family',
                    e,
                    null,
                    title,
                    ValidationErrorProductFamilyText
                );
            } finally {
                setSaveLoading(false);
            }
        }

        if (newBody.updatedProducts) {
            setConfirmModal({
                title: 'Edição',
                message: 'Deseja atualizar essa família?',
                onConfirm: proced,
                show: true,
            });
        } else {
            proced();
        }
    }

    async function save() {
        if (saveLoading) return;
        if (hasValidationError()) return;

        setSaveLoading(true);

        if (!uuid) {
            await createFamily();
        } else {
            await editiFAmily();
        }
    }



    const formatedBodyForRequest = () => {
        const body = {
            ...formData,
            name: formData.name.trim(),
        };

        const changes = {};

        for (const key in body) {
            if (formDataClone[key] != body[key]) {
                changes[key] = body[key];
            }
        }

        if (Object.values(changes).length === 0) {
            return body;
        }

        const hasNewPriceChanged = Object.prototype.hasOwnProperty.call(
            changes,
            'newFamilyPrice'
        );

        const obj = {
            ...body,
            ...changes,
        };

        if (uuid) {
            obj.updatedProducts = hasNewPriceChanged;
        }

        return obj;
    };

    async function printProductFamily() {
        try {
            setLoading(true);
            await ProductFamiliesService.printProductFamily(formData.uuid);

        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }

    const fetchProducts = useCallback(async () => {
        try {
            const response = await ProductFamiliesService.getProductsByFamily({
                uuid: uuid,
                params: {
                    page: 0,
                    ...filter,
                },
            });
            setProducts(response);
        } catch (e) {
            console.error('Erro ao trazer os produtos da família', e);
            if (axios.isCancel(e)) {
                console.debug('Request cancelled.', e);
            }
        }
    }, [uuid, filter]);

    useEffect(() => {
        if (uuid) {
            fetchProducts();
        }
    }, [fetchProducts]);

    async function deleteProductFamily() {
        let title = 'Excluir família de produto';
        async function proceed() {
            try {
                setDeleteLoading(true);
                await ProductFamiliesService.deleteProductFamily(uuid);
                const message = 'Família de produto excluída com sucesso!';
                const style = InfoModalStyle.SUCCESS;
                const onClose = back;
                setHasChange(false);
                setInfoModal({
                    title,
                    message,
                    style,
                    show: true,
                    onClose,
                });
            } catch (e) {
                backendConnectionError(
                    'Fail to delete product family',
                    e,
                    null,
                    title,
                    ValidationErrorProductFamilyText
                );
            } finally {
                setDeleteLoading(false);
            }
        }

        setConfirmModal({
            title,
            message:
                'Você tem certeza que deseja excluir a família de produto?',
            onConfirm: proceed,
            show: true,
        });
    }

    useEffect(() => {
        setLoading(saveLoading, true);
    }, [saveLoading]);

    function getTitle(windowTitle = false) {
        if (uuid) {
            let title = 'Editar família de produto';
            if (windowTitle && productFamilyName)
                title += ` - ${productFamilyName}`;
            return title;
        } else {
            return 'Nova família de produto';
        }
    }

    const handleProductTagsOptions = () => {
        const tagOptions = [];
        for (const tag in ProductTagTypeEnum) {
            if (tag !== ProductTagTypeEnum.PROMOTION_TAG) {
                tagOptions.push({
                    id: tag,
                    value: ProductTagTypeText(tag),
                });
            }
        }

        return tagOptions;
    };

    const renderForm = () => {
        if (loadingProductFamily) {
            return <Loading size={LoadingSize.LARGE} />;
        }

        return (
            <>
                <div>
                    <fieldset>
                        <legend>{'Dados'}</legend>
                        <div className={'gd field-group'}>
                            <div className={'gd-col-3'}>
                                <FieldText
                                    label={'Nome'}
                                    onChange={({ target }) =>
                                        updateFormData({ name: target.value })
                                    }
                                    value={formData?.name}
                                    validationError={formError?.name}
                                    maxLength={120}
                                    readOnly={!canSave}
                                />
                            </div>
                            <div className={'gd-col-3'}>
                                <FieldSelect
                                    label={'Etiqueta'}
                                    options={handleProductTagsOptions()}
                                    onChange={({ target }) =>
                                        updateFormData({ tag: target.value })
                                    }
                                    value={formData?.tag}
                                />
                            </div>
                            <div className={'gd-col-3'}>
                                <FieldNumber
                                    label={'Qtd. etiquetas'}
                                    minValue={1}
                                    thousandsSeparator={false}
                                    onChange={({ target }) =>
                                        updateFormData({
                                            tagQuantity: parseInt(target.value)
                                        })
                                    }
                                    value={formData?.tagQuantity}
                                    validationError={formError?.tagQuantity}
                                />
                            </div>
                            {!uuid && (
                                <div className={'gd-col-3'}>
                                    <FieldCurrency
                                        label={'Novo preço'}
                                        thousandsSeparator={false}
                                        readOnly={true}
                                        value={
                                            (
                                                formData?.newFamilyPrice ?? 0
                                            ).toLocaleString('pt-BR', {
                                                style: 'currency',
                                                currency: 'BRL',
                                            }) || 'R$ 0,00'
                                        }
                                    />
                                </div>
                            )}
                            {uuid && (
                                <div className={'gd-col-3'}>
                                    <FieldCurrency
                                        label={'Novo preço'}
                                        thousandsSeparator={false}
                                        readOnly={!uuid}
                                        value={
                                            (
                                                formData?.newFamilyPrice ?? 0
                                            ).toLocaleString('pt-BR', {
                                                style: 'currency',
                                                currency: 'BRL',
                                            }) || 'R$ 0,00'
                                        }
                                        onChange={(e) =>
                                            updateFormData({
                                                newFamilyPrice:
                                                    extractCurrencyNumber(
                                                        formatCurrency(
                                                            e.target.value
                                                        )
                                                    ),
                                            })
                                        }
                                    />
                                </div>
                            )}
                        </div>
                        <div className={'gd field-group'}>

                        </div>
                    </fieldset>
                </div>

                <div className={'controls mb-42'}>
                    <div className={'gd'}>
                        <div
                            className={`gd-col ${canSave ? 'gd-col-8' : 'gd-col-10'
                            }`}
                        />
                        <div className={'gd-col gd-col-2'}>
                            {uuid && canRemove && (
                                <Button
                                    ref={buttonDeleteRef}
                                    color={ButtonColor.BUTTON_COLOR_RED}
                                    loading={deleteLoading}
                                    onClick={deleteProductFamily}
                                >
                                    {`Excluir [${ENUM_SHORTCUT.SHORTCUT_DELETE}]`}
                                </Button>
                            )}
                        </div>
                        {canSave && (
                            <div className={'gd-col gd-col-2'}>
                                <Button
                                    ref={buttonSaveRef}
                                    color={ButtonColor.BUTTON_COLOR_GREEN}
                                    fontColor={
                                        ButtonFontColor.BUTTON_FONT_COLOR_LIGHT
                                    }
                                    loading={saveLoading}
                                    onClick={save}
                                >
                                    {`Salvar [${ENUM_SHORTCUT.SHORTCUT_CONFIRM}]`}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>

                <div>
                    <fieldset>
                        <legend>{'Produtos'}</legend>

                        <div className={'gd grid-productsfamily'}>
                            <div className={'gd-col-2'}>
                                <FieldText
                                    label={'Média dos custos da família'}
                                    readOnly={true}
                                    value={
                                        (products?.avgFamily ?? 0).toLocaleString('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                        }) || 'R$ 0,00'
                                    }
                                />
                            </div>
                            <div className={'gd-col-2'}>
                                {uuid && canRemove && (
                                    <div className={'tooltip-container'}>
                                        <Button
                                            disabled={isEdited}
                                            ref={buttonPrintRef}
                                            buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                            color={ButtonColor.BUTTON_COLOR_ORANGE}
                                            onClick={() => printProductFamily()}
                                            title={''}
                                        >
                                            {`Imprimir etiqueta  [${ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY}]`}
                                        </Button>

                                        {isEdited  && <span className={'tooltip-text'}>
                                            {'Você não pode imprimir no momento. Salve as alterações antes de imprimir a etiqueta.'}
                                        </span>}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className={'table-scroll'}>
                            <Table
                                currentSort={filter.order}
                                columns={[
                                    {
                                        name: 'Código barras',
                                        align: 'center',
                                        sortAsc:
                                            OrderByProductsEnum.BARCODE_ASC,
                                        sortDesc:
                                            OrderByProductsEnum.BARCODE_DESC,
                                        onSortChange: (order) =>
                                            setFilter({ ...filter, order }),
                                    },
                                    {
                                        name: 'Nome do produto',
                                        align: 'center',
                                        sortAsc: OrderByProductsEnum.NAME_ASC,
                                        sortDesc: OrderByProductsEnum.NAME_DESC,
                                        onSortChange: (order) =>
                                            setFilter({ ...filter, order }),
                                    },
                                    {
                                        name: 'Saldo CD',
                                        align: 'center',
                                    },
                                    {
                                        name: 'Mínimo CD',
                                        align: 'center',
                                    },
                                    {
                                        name: 'Custo',
                                        align: 'center',
                                        sortAsc:
                                            OrderByProductsEnum.PROFIT_MARGIN_ASC,
                                        sortDesc:
                                            OrderByProductsEnum.PROFIT_MARGIN_DESC,
                                        onSortChange: (order) =>
                                            setFilter({ ...filter, order }),
                                    },
                                    {
                                        name: 'Preço/Margem',
                                        align: 'center',
                                        sortAsc: OrderByProductsEnum.PRICE_ASC,
                                        sortDesc:
                                            OrderByProductsEnum.PRICE_DESC,
                                        onSortChange: (order) =>
                                            setFilter({ ...filter, order }),
                                    },
                                    {
                                        name: 'Novo preço/Nova Margem',
                                        align: 'center',
                                        sortAsc: OrderByProductsEnum.PRICE_ASC,
                                        sortDesc:
                                            OrderByProductsEnum.PRICE_DESC,
                                        onSortChange: (order) =>
                                            setFilter({ ...filter, order }),
                                    },
                                ]}
                            >
                                {products?.records ? (
                                    products?.records.map((product, index) => {
                                        return (
                                            <tr
                                                key={index}
                                                onClick={() => {
                                                    handleRowClickNavigate({
                                                        uuid: product?.uuid,
                                                    });
                                                }}
                                            >
                                                <td className={'center'}>
                                                    {product?.barCodes?.find(
                                                        (x) => x.main
                                                    )?.barCode || '-'}
                                                </td>
                                                <td className={'center'}>
                                                    {product.name1}
                                                </td>
                                                <td className={'center'}>
                                                    {product.stock}
                                                </td>
                                                <td className={'center'}>
                                                    {product.minValue1}
                                                </td>
                                                <td className={'center'}>
                                                    {formatValue(product.avgCost)}</td>
                                                <td className={'center'}>
                                                    {`${formatValue(product.price)} / ${calculateMarginPercentage(product.price, product.avgCost)}`}
                                                </td>
                                                <td className={'center'}>
                                                    {`${formatValue(product.newPrice)} / ${calculateMarginPercentage(product.newPrice, product.avgCost)}`}
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <></>
                                )}
                            </Table>
                            <Pagination
                                page={products?.page}
                                pageSize={products?.pageSize}
                                count={products?.count}
                                recordCount={products?.records?.length || 0}
                                onPageChange={(page) =>
                                    setFilter({ ...filter, page })
                                }
                            />
                        </div>
                    </fieldset>
                </div>
            </>
        );
    };

    const title = getTitle();
    return (
        <>
            <RouteChangePrompt enabled={hasChange} />
            <div className={'crud-form'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Produtos', route: getDashboardRoute() },
                        {
                            name: 'Famílias de produtos',
                            route: getProductFamiliesRoute(),
                        },
                        {
                            name: uuid
                                ? loadingProductFamily
                                    ? '...'
                                    : productFamilyName
                                : title,
                        },
                    ]}
                    backRoute={getProductFamiliesRoute()}
                    hideStore
                ></ScreenHeader>

                {renderForm()}
            </div>
        </>
    );
}

export function getProductFamilyFormRoute(uuid) {
    if (uuid) {
        return `/produtos/familias-de-produtos/${uuid}`;
    } else {
        return '/produtos/familias-de-produtos/novo';
    }
}
