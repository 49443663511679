import { useNavigate } from 'react-router';
import Button, { ButtonColor, ButtonStyle } from '../../components/Button';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import { getSelectSupplierRepresentativeRoute } from './SelectSupplierRepresentative';
import { useContext, useEffect, useState } from 'react';
import * as OrderReceptionService from '../../services/OrderReceptionService';
import { OrderBillingTypeEnum, OrderBillingTypeText } from 'erva-doce-common';
import { formatCurrency } from '../../components/FieldCurrency';
import { getOrderReceiveRoute } from '.';
import { getHandleScanProductsRoute } from './HandleScanProducts';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { formatValue } from '../../util/formatValue';

export default function SelectOrderDocument({ formData, updateFormData }) {
    const title = 'Listagem de Notas Fiscais';
    const navigate = useNavigate();
    const { backendConnectionError, setConfirmModal } = useContext(EnvironmentContext);

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    if (!formData.supplierRepresentative) {
        return navigate(getSelectSupplierRepresentativeRoute());
    }

    const nextPage = async () => {
        const proceed = async () => {
            try {
                const body = {
                    supplierRepresentative: formData.supplierRepresentative,
                    billings: formData.billings,
                };

                const data = await OrderReceptionService.create(body);

                navigate(getHandleScanProductsRoute(data.uuid));
            } catch(err) {
                console.log(err);
                backendConnectionError('Fail to fetch cancelReason', err, () => navigate(getSelectSupplierRepresentativeRoute()), title);
            }
        };

        await proceed();
    };

    const getInfo = async () => {
        setIsLoading(true);
        try {
            const res = await OrderReceptionService.searchBillings({ supplierRepresentative: formData.supplierRepresentative });
            if (res) {
                setData(res);
            }
        } catch(err) {
            console.log(err);
            navigate(getSelectSupplierRepresentativeRoute());
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getInfo();
    }, []);

    return (
        <>
            <div className={'stock crud-list'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Compras', route: getDashboardRoute() },
                        { name: 'Pedidos' },
                        { name: 'Recebimento de mercadorias', route: getOrderReceiveRoute() },
                        { name: 'Novo' },
                        { name: title, route: getSelectOrderDocumentRoute() },
                    ]}
                    backRoute={getSelectSupplierRepresentativeRoute()}
                    hideStore
                />
                <div className={'mb-20'}>
                    {
                        !isLoading ?
                            data?.length ? data?.map(billing => (
                                <div key={billing.id} className={'supplier-representative'}>
                                    <div>
                                        <input
                                            name={'supplierRepresentative'}
                                            id={billing.id}
                                            type={'checkbox'}
                                            value={billing.id}
                                            checked={formData?.billings?.find(x => x.id === billing.id) || false}
                                            onChange={(e) => {
                                                let newBillings = [...(formData.billings || [])];

                                                if (e.target.checked) {
                                                    newBillings.push(billing);
                                                } else {
                                                    newBillings = newBillings.filter(x => x.id !== billing.id);
                                                }

                                                updateFormData({ ...formData, billings: newBillings });
                                            }}
                                        />
                                    </div>
                                    <label htmlFor={billing.id} className={'supplier-representative--info'}>
                                        <div>
                                            <p className={'supplier-representative--socialReason'}><strong>{OrderBillingTypeText(billing.type)}</strong> {billing.type === OrderBillingTypeEnum.NO_DOCUMENT ? `Faturamento N. ${`${billing.id}`.padStart(7, '0')}` : `N.${billing.documentNumber}`}</p>
                                            <p className={'supplier-representative--cnpj'}>{formatValue(`${billing.total}`)}</p>
                                        </div>
                                    </label>
                                </div>
                            ))
                                : <p className={'text-center'}>{'Nenhuma nota encontrada'}</p>
                            : <p className={'text-center'}>{'Carregando...'}</p>
                    }
                </div>
                <div className={'col-12 col-md-3 mb-10'}>
                    <Button
                        className={'w-100'}
                        buttonStyle={ButtonStyle.BUTTON_NORMAL}
                        color={ButtonColor.BUTTON_COLOR_GREEN}
                        onClick={nextPage}
                        disabled={!formData?.billings?.length}
                    >
                        {'Receber produtos'}
                    </Button>
                </div>
            </div>
        </>
    );
}

export function getSelectOrderDocumentRoute() {
    return '/compras/recebimento-de-mercadorias/novo/notas-fiscais';
}
