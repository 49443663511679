import * as Sentry from '@sentry/react';
import axios from 'axios';
import {
    ENUM_SHORTCUT,
    OrderByVouchersEnum,
    VouchersStatusEnum,
} from 'erva-doce-common';
import ButtonGoto from '../../components/ButtonGoto';
import { useContext, useEffect, useRef, useState } from 'react';
import FieldTextSearch from '../../components/FieldTextSearch';
import LoadError from '../../components/LoadError';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import { IconFilter, IconFilter2x } from '../../components/images';
import Button, { ButtonColor, ButtonStyle } from '../../components/Button';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import ButtonBadge from '../../components/ButtonBadge';
import { formatValue } from '../../util/utilCurrency';
import * as VoucherService from '../../services/VoucherService';
import VoucherFilterModal from './VoucherFilterModal';
import { getCashClosingPage } from '../sales/CashClosing';
import { parse, format, isValid } from 'date-fns';
import { MdDocumentScanner } from 'react-icons/md';
import { useNavigate } from 'react-router';
import './Voucher.scss';

export default function Voucher() {
    const [voucher, setVoucher] = useState(null);
    const [voucherLoading, setVoucherLoading] = useState(null);
    const { addHotkey, removeHotkey, user, setConfirmModal } = useContext(EnvironmentContext);
    const [filter, setFilter] = useState({
        order: OrderByVouchersEnum.NAME_ASC,
        search: null,
        collaborator: [],
        cashier: [],
        date: null,
        page: 0,
        role: user.profile,
        status: null,
    });
    const [showFilter, setShowFilter] = useState(false);
    useEffect(() => {
        fetchVouchers();
    }, [filter]);
    const inputSearchRef = useRef();
    const filterBtnRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        const shortcutSearchSecondary = addHotkey(
            ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY,
            () => {
                inputSearchRef.current?.focus();
            }
        );

        const shortcutFilter = addHotkey(ENUM_SHORTCUT.SHORTCUT_FILTER, () => {
            if (filterBtnRef.current) {
                filterBtnRef.current.click();
            }
        });

        return () => {
            removeHotkey(shortcutSearchSecondary);
            removeHotkey(shortcutFilter);
        };
    }, []);

    async function RedeemVoucher(uuid) {
        async function proceed() {
            try {
                await VoucherService.RedeemVoucher(uuid);
                navigate(0);
            } catch (e) {
                console.error(e);
                Sentry.captureException(e);
            } finally {

            }

        }

        setConfirmModal({
            title: 'Atenção!',
            message: 'Tem certeza de que deseja confirmar a baixa deste vale? <br/> <b>Essa ação não pode ser desfeita.</b>',
            show: true,
            cancelText: 'Não',
            confirmText: 'Sim',
            onConfirm: proceed,
        });

    }

    async function fetchVouchers() {
        if (voucherLoading?.cancel) {
            voucherLoading.cancel();
        }
        try {
            const response = await VoucherService.getVouchers(
                filter.page,
                filter.search,
                filter.order,
                filter.date,
                filter.cashier,
                filter.collaborator,
                filter.role,
                filter.status
            );

            setVoucher(response);
            setVoucherLoading(null);
        } catch (e) {
            if (axios.isCancel(e)) {
                console.debug('Request cancelled.', e);
            } else {
                console.error(e);
                Sentry.captureException(e);
                setVoucherLoading(e);
                setVoucher(null);
            }
        }
    }

    function renderTable() {
        if (voucherLoading && voucherLoading instanceof Error) {
            return (
                <LoadError
                    // I18N
                    message={'Não foi possível carregar os Vales.'}
                    onTryAgain={fetchVouchers}
                />
            );
        }

        let rows = [];
        // noinspection JSUnresolvedReference
        const records = voucher?.records ?? [];
        for (const voucher of records) {
            const dateString = voucher.date.split(', ')[0];
            const parsedDate = parse(dateString, 'dd/MM/yyyy', new Date());

            if (!isValid(parsedDate)) {
                console.warn('Invalid date format!');
                continue;
            }

            const formattedDate = format(parsedDate, 'yyyy-MM-dd');

            const statusMap = {
                [VouchersStatusEnum.OPEN]: 'Aberto',
                [VouchersStatusEnum.WRITTEN_OFF]: 'Baixado',
                [VouchersStatusEnum.CANCELLED]: 'Cancelado',
            };

            rows.push(
                <tr key={voucher.uuid}>
                    <td className={'center'}>{voucher.cashier.name ?? '-'}</td>
                    <td className={'center'}>{voucher.collaborator ?? '-'}</td>
                    {
                        user?.profile === 'ADMIN' ? (
                            <td className={'center'}>{voucher.updatedBy ?? '-'}</td>
                        ) : null
                    }
                    <td className={'center'}>{voucher.date ?? '-'}</td>
                    <td className={'center'}>
                        {' '}
                        {statusMap[voucher.status] ?? '-'}
                    </td>
                    <td className={'center'}>
                        {' '}
                        {formatValue({ value: voucher.value }) ?? '-'}
                    </td>

                    <td className={'center actions-column'} colSpan={2}>
                        <div className={'actions-container'}>
                            {

                                user?.profile === 'ADMIN' ? (
                                    <Button
                                        disabled={voucher.status === VouchersStatusEnum.WRITTEN_OFF || voucher.status === VouchersStatusEnum.CANCELLED}
                                        title={'Dar Baixa'}
                                        onClick={() =>
                                            RedeemVoucher(voucher.uuid)
                                        }
                                        className={'button transparent'}
                                    >
                                        <MdDocumentScanner className={'button-icon'} />
                                    </Button>

                                ) : null
                            }

                            <ButtonGoto
                                title={'Consultar Fechamento'}
                                onClick={() => {
                                    window.open(
                                        getCashClosingPage(
                                            voucher.cashier.uuid,
                                            formattedDate,
                                        ),
                                        '',
                                        ''
                                    );
                                }}
                            />
                        </div>
                    </td>
                </tr>
            );
        }

        return (
            <>
                <Table
                    loading={voucherLoading}
                    currentSort={filter.order}
                    showSeparatorRow={true}
                    columns={[
                        {
                            name: 'Caixa',
                            sortAsc: OrderByVouchersEnum.Cashier_ASC,
                            sortDesc: OrderByVouchersEnum.Cashier_DESC,
                            onSortChange: (order) =>
                                setFilter({ ...filter, order }),
                            align: 'center',
                        },
                        {
                            // I18N
                            name: 'Colaborador',
                            sortAsc: OrderByVouchersEnum.COLLABORATOR_ASC,
                            sortDesc: OrderByVouchersEnum.COLLABORATOR_DESC,
                            onSortChange: (order) =>
                                setFilter({ ...filter, order }),
                            align: 'center',
                        },

                        ...(user?.profile === 'ADMIN'
                            ? [
                                {
                                    name: 'Atualizado Por',
                                    sortAsc: OrderByVouchersEnum.UPDATED_BY_ASC,
                                    sortDesc: OrderByVouchersEnum.UPDATED_BY_DESC,
                                    onSortChange: (order) =>
                                        setFilter({ ...filter, order }),
                                    align: 'center',
                                },
                            ]
                            : []),

                        {
                            name: 'Data',
                            sortAsc: OrderByVouchersEnum.DATE_ASC,
                            sortDesc: OrderByVouchersEnum.DATE_DESC,
                            onSortChange: (order) =>
                                setFilter({ ...filter, order }),
                            align: 'center',
                        },
                        {
                            name: 'Status',
                            sortAsc: OrderByVouchersEnum.STATUS_ASC,
                            sortDesc: OrderByVouchersEnum.STATUS_DESC,
                            onSortChange: (order) =>
                                setFilter({ ...filter, order }),
                            align: 'center',
                        },
                        {
                            name: 'Valor',
                            sortAsc: OrderByVouchersEnum.VALUE_ASC,
                            sortDesc: OrderByVouchersEnum.VALUE_DESC,
                            onSortChange: (order) =>
                                setFilter({ ...filter, order }),
                            align: 'center',
                        },
                        {
                            name: 'Ações',
                            align: 'center',
                        },
                    ]}
                >
                    {rows}
                </Table>
                <Pagination
                    page={voucher?.page}
                    pageSize={voucher?.pageSize}
                    count={voucher?.count}
                    recordCount={voucher?.records?.length}
                    onPageChange={(page) => setFilter({ ...filter, page })}
                />
            </>
        );
    }
    const filterBadgeCount =
        (filter?.collaborator?.length ?? 0) +
        (filter?.cashier?.length ?? 0) +
        (filter?.date ? 1 : 0);

    return (
        <>
            <div className={'crud-list '}>
                <ScreenHeader
                    title={'Vales'}
                    breadcrumbs={[
                        { name: 'Administrativo', route: getVoucherRoute() },
                        { name: 'Vales', route: getVoucherRoute() },
                    ]}
                />
                <div className={'controls'}>
                    <div className={'gd'}>
                        <div className={'gd-col  gd-col-9'}>
                            <FieldTextSearch
                                ref={inputSearchRef}
                                // I18N
                                label={`<em>Buscar por <strong>Caixa</strong> ou <strong> Colaborador</strong> [${ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY}]</em>`}
                                onChange={({ target }) =>
                                    setFilter({
                                        ...filter,
                                        search: target.value,
                                        page: 0,
                                    })
                                }
                            />
                        </div>
                        <div className={'gd-col gd-col-3'}>
                            <Button
                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                color={ButtonColor.BUTTON_COLOR_ORANGE}
                                icon={IconFilter}
                                icon2x={IconFilter2x}
                                onClick={() => setShowFilter(true)}
                                badgeChildren={
                                    !!filterBadgeCount && (
                                        <ButtonBadge count={filterBadgeCount} />
                                    )
                                }
                                ref={filterBtnRef}
                            >
                                {`Filtrar [${ENUM_SHORTCUT.SHORTCUT_FILTER}]`}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={'table-scroll'}>{renderTable()}</div>
            </div>
            <VoucherFilterModal
                show={showFilter}
                onCancel={() => setShowFilter(false)}
                filter={filter}
                onConfirm={(complementaryFilter) => {
                    setShowFilter(false);
                    // noinspection JSCheckFunctionSignatures
                    setFilter({
                        ...filter,
                        ...complementaryFilter,
                    });
                }}
            />
        </>
    );
}

export function getVoucherRoute() {
    //I18N
    return '/administrativo/vales';
}
