import * as Sentry from '@sentry/react';
import axios from 'axios';
import {
    OrderByProductsFamiliesEnum,
    ProductTagTypeText,
    PermissionsEnum,
    ENUM_SHORTCUT
} from 'erva-doce-common';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import Button, { ButtonStyle } from '../../components/Button';
import FieldTextSearch from '../../components/FieldTextSearch';
import LoadError from '../../components/LoadError';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import { IconAdd, IconAdd2x } from '../../components/images';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as ProductFamiliesService from '../../services/ProductsFamiliesService';
import { getProductFamilyFormRoute } from './ProductFamilyForm';

export default function ProductsFamilies() {
    const [productFamilies, setProductFamilies] = useState(null);
    const [productFamiliesLoading, setProductFamiliesLoading] = useState(null);
    const [productFamiliesSearch, setProductFamiliesSearch] = useState('');
    const [filter, setFilter] = useState({
        order: OrderByProductsFamiliesEnum.NAME_ASC,
        search: null,
        page: 0
    });
    const inputSearchRef = useRef();
    const {
        addHotkey,
        removeHotkey,
        user,
    } = useContext(EnvironmentContext);

    const canAdd = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_PRODUCTS);
    const navigate = useNavigate();
    const handleRowClick = (route) => navigate(route);

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        fetchProductFamilies();
    }, [filter]);

    useEffect(() => {
        const shortcutSearchSecondary = addHotkey(ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY, () => {
            inputSearchRef.current?.focus();
        });

        const shortcutCreatePrimary = addHotkey(ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY, () => {
            navigate(getProductFamilyFormRoute());
        });

        return () => {
            removeHotkey(shortcutSearchSecondary);
            removeHotkey(shortcutCreatePrimary);
        };
    }, []);

    async function fetchProductFamilies() {
        if (productFamiliesLoading?.cancel) {
            productFamiliesLoading.cancel();
        }
        try {
            const cancelToken = axios.CancelToken.source().token;
            setProductFamiliesLoading(cancelToken);
            const response = await ProductFamiliesService.getProductsFamilies(filter.page, filter.search, filter.order,
                cancelToken);
            setProductFamilies(response);
            setProductFamiliesLoading(null);
        } catch (e) {
            if (axios.isCancel(e)) {
                console.debug('Request cancelled.', e);
            } else {
                console.error(e);
                Sentry.captureException(e);
                setProductFamiliesLoading(e);
                setProductFamilies(null);
            }
        }
    }

    function renderTable() {
        if (productFamiliesLoading && productFamiliesLoading instanceof Error) {
            return (
                <LoadError
                    message={'Não foi possível carregar as famílias de produtos'}
                    onTryAgain={fetchProductFamilies}
                />
            );
        }

        let rows = [];
        // noinspection JSUnresolvedReference
        const records = productFamilies?.records ?? [];
        for (const s of records) {
            rows.push(
                <tr key={s.uuid} onClick={() => handleRowClick(getProductFamilyFormRoute(s.uuid))}>
                    <td>{s.name}</td>
                    <td>{s.tag ? ProductTagTypeText(s.tag) : ''}</td>
                    <td className={'center'}>{s.tagQuantity}</td>
                </tr>
            );
        }

        return (
            <>
                <Table
                    loading={productFamiliesLoading}
                    currentSort={filter.order}
                    columns={[
                        {
                            name: 'Nome da família de produto',
                            sortAsc: OrderByProductsFamiliesEnum.NAME_ASC,
                            sortDesc: OrderByProductsFamiliesEnum.NAME_DESC,
                            onSortChange: (order) => setFilter({ ...filter, order }),
                        },
                        {
                            name: 'Etiqueta',
                        },
                        {
                            name: 'Qtd. etiquetas',
                            align: 'center',
                        },
                    ]}>
                    {rows}
                </Table>
                <Pagination
                    page={productFamilies?.page}
                    pageSize={productFamilies?.pageSize}
                    count={productFamilies?.count}
                    recordCount={productFamilies?.records?.length}
                    onPageChange={page => setFilter({ ...filter, page })}
                />
            </>
        );
    }

    return (
        <div className={'crud-list'}>
            <ScreenHeader
                title={'Famílias de produtos'}
                breadcrumbs={[
                    { name: 'Produtos', route: getDashboardRoute() },
                    { name: 'Famílias de produtos', route: getProductFamiliesRoute() },
                ]}
                hideStore
            />
            <div className={'controls'}>
                <div className={'gd'}>
                    <div className={`gd-col ${canAdd ? 'gd-col-9' : 'gd-col-12'}`}>
                        <FieldTextSearch
                            ref={inputSearchRef}
                            label={`<em>Buscar por <strong>famílias de produtos</strong> [${ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY}]</em>`}
                            onChange={({ target }) =>
                                setProductFamiliesSearch(target.value)
                            }
                            value={productFamiliesSearch}
                            onChangeDebounce={(value) => {
                                setFilter({ ...filter, search: value });
                            }}
                            debounceTime={400}
                        />
                    </div>
                    {canAdd && (
                        <div className={'gd-col gd-col-3'}>
                            <Button
                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                icon={IconAdd}
                                icon2x={IconAdd2x}
                                route={getProductFamilyFormRoute()}
                            >
                                {`Nova família de produto [${ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY}]`}
                            </Button>
                        </div>
                    )}
                </div>
            </div>

            <div className={'table-scroll'}>
                {renderTable()}
            </div>
        </div>
    );
}

export function getProductFamiliesRoute() {
    //I18N
    return '/produtos/familias-de-produtos';
}
