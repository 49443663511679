import { useContext, useEffect, useRef, useState } from 'react';
import SimpleConfirmModal from '../../../components/modal/SimpleConfirmModal';
import FieldText from '../../../components/FieldText';
import FieldSelect from '../../../components/FieldSelect';
import { BannerLocationTypeEnum, BannerLocationTypeText, BannerTypeEnum, BannerTypeText, EcommercePageListEnum, EcommercePageListText, ValidationErrorBannerText } from 'erva-doce-common';
import FieldDate from '../../../components/FieldDate';
import FieldProductBrand from '../../../components/FieldProductBrand';
import FieldProductCategory from '../../../components/FieldProductCategory';
import FieldLiveSearchSelectionTable from '../../../components/FieldLiveSearchSelectionTable';
import { DateTime } from 'luxon';
import { EnvironmentContext } from '../../../contexts/EnviromentContext';
import * as BannersService from '../../../services/BannersService';
import Section from '../../../components/Section';
import Button from '../../../components/Button';
import SwitchToggle from '../../../components/SwichToggle';
import emptyField from '../../../util/validateEmptyTextField';

const bannerTypeDimensions = {
    [BannerTypeEnum.CARD]: {
        desktop: {
            width: 220,
            height: 420,
        },
        mobile: {
            width: 160,
            height: 310,
        },
    },
    [BannerTypeEnum.SECTION]: {
        desktop: {
            width: 1440,
            height: 350,
        },
        mobile: {
            width: 375,
            height: 400,
        },
    },
    [BannerTypeEnum.SLIDER]: {
        desktop: {
            width: 1440,
            height: 576,
        },
        mobile: {
            width: 375,
            height: 400,
        },
    },
};

export default function BannerFormModal({
    show,
    onCancel,
    onConfirm,
    initialData,
}) {
    const inputImageMobileRef = useRef();
    const inputImageDesktopRef = useRef();
    const { backendConnectionError, setInfoModal } = useContext(EnvironmentContext);

    const [formData, setFormData] = useState(initialData);
    const [formError, setFormError] = useState({});
    const title = (formData?.id ? 'Editar' : 'Criar') + ' Banner';
    const dimensions = bannerTypeDimensions?.[formData?.type];

    const confirm = async () => {
        if (hasValidationError()) return;

        try {
            const data = {
                active: formData.active,
                type: formData.type,
                locationType: formData.locationType,
                title: formData.title,
                description: formData.description,
                link: formData.link,
                isExternalLink: formData.isExternalLink,
                startAt: formData.startAt,
                endAt: formData.endAt,
                locations: formData.locations,
                desktopBanner: formData.desktopBanner,
                mobileBanner: formData.mobileBanner,
            };

            if (initialData.id) {
                await BannersService.update(initialData.uuid, data);
            } else {
                await BannersService.create(data);
            }

            onConfirm(formData);
        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to fetch destroy banner order', err, null, title, ValidationErrorBannerText);
        }
    };

    const updateFormData = (value) => {
        setFormData(state => ({
            ...state,
            ...value,
        }));
    };

    const hasValidationError = () => {
        let hasError = false;
        setFormError({});

        if (!formData?.type) {
            hasError = true;
            setFormError(state => ({
                ...state,
                type: 'Preencha corretamente',
            }));
        }

        if (!formData?.title || emptyField(formData.title)) {
            hasError = true;
            setFormError(state => ({
                ...state,
                title: 'Preencha corretamente',
            }));
        }

        if (!formData?.description || emptyField(formData.description)) {
            hasError = true;
            setFormError(state => ({
                ...state,
                description: 'Preencha corretamente',
            }));
        }

        if (formData?.startAt || formData?.endAt) {
            const startAt = DateTime.fromFormat(formData.startAt, 'yyyy-MM-dd');
            const endAt = DateTime.fromFormat(formData.endAt, 'yyyy-MM-dd');

            if (startAt.startOf('day') > endAt.startOf('day')) {
                hasError = true;
                setFormError(state => ({
                    ...state,
                    startAt: 'Preencha corretamente',
                    endAt: 'Preencha corretamente',
                }));
            }
        }

        if (!formData?.locationType) {
            hasError = true;
            setFormError(state => ({
                ...state,
                locationType: 'Preencha corretamente',
            }));
        } else {
            if (formData.locationType !== BannerLocationTypeEnum.ALL && !formData?.locations?.length) {
                hasError = true;
                setFormError(state => ({
                    ...state,
                    locationType: 'Selecione pelo menos uma opção',
                }));
            }
        }

        if (!formData?.mobileBanner) {
            hasError = true;
            setFormError(state => ({
                ...state,
                mobileBanner: 'Adicione a versão de Dispositivos Móveis corretamente antes de prosseguir',
            }));
        }

        if (!formData?.desktopBanner) {
            hasError = true;
            setFormError(state => ({
                ...state,
                desktopBanner: 'Adicione a versão de Desktop corretamente antes de prosseguir',
            }));
        }

        return hasError;
    };

    const handleTypeOptions = () => {
        const options = [];
        for (const option in BannerTypeEnum) {
            options.push({
                id: option,
                value: BannerTypeText(option),
            });
        }

        return options;
    };

    const handleLocationTypeOptions = () => {
        const options = [];
        for (const option in BannerLocationTypeEnum) {
            options.push({
                id: option,
                value: BannerLocationTypeText(option),
            });
        }

        return options;
    };

    const handlePagesOptions = () => {
        const options = [];
        for (const option in EcommercePageListEnum) {
            if (!formData?.locations?.find(x => x.value === option)) {
                options.push({
                    id: option,
                    value: EcommercePageListText(option),
                });
            }
        }

        return options;
    };

    async function readImageAsBase64(file, dimension) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = (e) => {
                const base64String = reader.result;
                const img = new Image();

                img.src = base64String;

                img.onload = function () {
                    const height = this.height;
                    const width = this.width;

                    if (width < dimension.width || height < dimension.height) {
                        reject(e);
                    }

                    resolve(base64String);
                };
            };
            reader.onerror = (e) => {
                reject(e);
            };
            reader.readAsDataURL(file);
        });
    }

    async function getImageBase64(event, key) {
        const file = event.target.files[0];
        const dimension = key === 'desktopBanner' ? dimensions.desktop : dimensions.mobile;

        if (file) {
            try {
                const imgBase64 = await readImageAsBase64(file, dimension);
                updateFormData({
                    [key]: {
                        imageURL: `data:image;base64${imgBase64}`,
                        name: file.name,
                        mimetype: file.type,
                    },
                });
            } catch (err) {
                setInfoModal({
                    title,
                    message: `A imagem para o tipo ${BannerTypeText(formData.type)} deve ter dimensões de no mínimo ${dimension.width}px por ${dimension.height}px`,
                    show: true,
                });
            }
        }
    }

    const onRemoveImage = (key) => {
        updateFormData({ [key]: null });
    };

    useEffect(() => {
        setFormError({});
        setFormData(initialData);
    }, [initialData]);

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={onCancel}
            onConfirm={confirm}
            confirmText={'Salvar'}
            title={title}
            className={'modal-banner'}
        >
            <>
                <div className={'row'}>
                    <div className={'col-6'}>
                        <Section title={`Imagem para Desktop${formData?.type && dimensions ? ` (${dimensions.desktop.width}x${dimensions.desktop.height})` : ''}`}>
                            {
                                formData?.desktopBanner ? (
                                    <div className={'image-content'}>
                                        <img src={formData.desktopBanner.imageURL} className={'image'} width={dimensions.desktop.width} />
                                        <Button
                                            className={'transparent mt-8'}
                                            onClick={() => onRemoveImage('desktopBanner')}
                                            style={{ textDecoration: 'underline' }}
                                        >
                                            {'Excluir'}
                                        </Button>
                                    </div>
                                ) : (
                                    <Button
                                        className={'image-button transparent'}
                                        onClick={() => inputImageDesktopRef.current?.click()}
                                        style={{ textDecoration: 'underline', textAlign: 'right' }}
                                        disabled={!formData?.type}
                                    >
                                        <input
                                            ref={inputImageDesktopRef}
                                            type={'file'}
                                            accept={'image/jpeg, image/png'}
                                            onChange={(e) => getImageBase64(e, 'desktopBanner')}
                                            style={{ display: 'none' }}
                                        />
                                        {'+ Adicionar imagem'}
                                    </Button>
                                )
                            }
                        </Section>
                        <Section title={`Imagem para Dispositivos Móveis${formData?.type && dimensions ? ` (${dimensions.mobile.width}x${dimensions.mobile.height})` : ''}`}>
                            {
                                formData?.mobileBanner ? (
                                    <div className={'image-content'}>
                                        <img src={formData.mobileBanner.imageURL} className={'image'} width={dimensions.mobile.width} />
                                        <Button
                                            className={'transparent mt-8'}
                                            onClick={() => onRemoveImage('mobileBanner')}
                                            style={{ textDecoration: 'underline' }}
                                        >
                                            {'Excluir'}
                                        </Button>
                                    </div>
                                ) : (
                                    <Button
                                        className={'image-button transparent'}
                                        onClick={() => inputImageMobileRef.current?.click()}
                                        style={{ textDecoration: 'underline', textAlign: 'right' }}
                                        disabled={!formData?.type}
                                    >
                                        <input
                                            ref={inputImageMobileRef}
                                            type={'file'}
                                            accept={'image/jpeg, image/png'}
                                            onChange={(e) => getImageBase64(e, 'mobileBanner')}
                                            style={{ display: 'none' }}
                                        />
                                        {'+ Adicionar imagem'}
                                    </Button>
                                )
                            }
                        </Section>
                        {
                            formError?.mobileBanner && (
                                <div className={'alert alert-error mb-8'}>
                                    {formError?.mobileBanner}
                                </div>
                            )
                        }
                        {
                            formError?.desktopBanner && (
                                <div className={'alert alert-error'}>
                                    {formError?.desktopBanner}
                                </div>
                            )
                        }
                    </div>
                    <div className={'col-6'}>
                        <div className={'row'}>
                            <div className={'col-12 d-flex align-items-center mb-16'}>
                                <SwitchToggle
                                    defaultChecked={!!formData?.active}
                                    label={'Banner Ativo'}
                                    onChange={() => {
                                        updateFormData({ active: !formData?.active });
                                    }}
                                />
                            </div>
                            <div className={'col-6'}>
                                <FieldSelect
                                    label={'Formato do banner'}
                                    options={handleTypeOptions()}
                                    onChange={({ target }) => {
                                        let type = target.value;

                                        if (target === '-1' || target.value === '-1') {
                                            type = null;
                                        }

                                        updateFormData({ type, desktopBanner: null, mobileBanner: null });
                                    }}
                                    value={formData?.type || null}
                                    validationError={formError?.type}
                                    placeholder={'Selecione'}
                                    disableDefaultOption={false}
                                />
                            </div>
                            <div className={'col-12'}>
                                <FieldText
                                    label={'Título'}
                                    onChange={({ target }) => updateFormData({ title: target.value })}
                                    value={formData?.title || ''}
                                    validationError={formError?.title}
                                    maxLength={255}
                                />
                            </div>
                            <div className={'col-12'}>
                                <FieldText
                                    label={'Descrição'}
                                    onChange={({ target }) => updateFormData({ description: target.value })}
                                    value={formData?.description || ''}
                                    validationError={formError?.description}
                                    maxLength={255}
                                />
                            </div>
                            <div className={'col-8'}>
                                <FieldText
                                    label={'Link'}
                                    onChange={({ target }) => updateFormData({ link: target.value })}
                                    value={formData?.link || ''}
                                    validationError={formError?.link}
                                />
                            </div>
                            <div className={'col-4 d-flex align-items-center'}>
                                <SwitchToggle
                                    defaultChecked={!!formData?.isExternalLink}
                                    label={'Link externo'}
                                    onChange={() => {
                                        updateFormData({ isExternalLink: !formData?.isExternalLink });
                                    }}
                                />
                            </div>
                            <div className={'col-6'}>
                                <FieldDate
                                    label={'Data de início'}
                                    value={formData?.startAt || ''}
                                    onChange={({ target }) => updateFormData({ startAt: target.value })}
                                    validationError={formError?.startAt}
                                />
                            </div>
                            <div className={'col-6'}>
                                <FieldDate
                                    label={'Data de fim'}
                                    value={formData?.endAt || ''}
                                    onChange={({ target }) => updateFormData({ endAt: target.value })}
                                    validationError={formError?.endAt}
                                />
                            </div>
                            <div className={'col-6'}>
                                <FieldSelect
                                    label={'Localização do banner'}
                                    options={handleLocationTypeOptions()}
                                    onChange={({ target }) => {
                                        let locationType = target.value;

                                        if (target === '-1') {
                                            locationType = null;
                                        }

                                        updateFormData({ locationType, locations: [] });
                                    }}
                                    value={formData?.locationType || '-1'}
                                    validationError={formError?.locationType}
                                    placeholder={'Selecione'}
                                    disableDefaultOption={false}
                                />
                            </div>
                            <div className={'col-6'}>
                                {
                                    formData?.locationType === BannerLocationTypeEnum.PAGE && (
                                        <FieldSelect
                                            label={'Páginas'}
                                            options={handlePagesOptions()}
                                            onChange={({ target }) => {
                                                let type = target.value;

                                                if (target === '-1') {
                                                    return;
                                                }

                                                updateFormData({
                                                    locations: [
                                                        ...formData.locations,
                                                        {
                                                            value: type,
                                                            label: EcommercePageListText(type),
                                                        },
                                                    ],
                                                });
                                            }}
                                            value={'-1'}
                                            validationError={formError?.locations}
                                            placeholder={'Selecione'}
                                            disableDefaultOption={false}
                                        />
                                    )
                                }
                                {
                                    formData?.locationType === BannerLocationTypeEnum.CATEGORY && (
                                        <FieldProductCategory
                                            label={'Categorias'}
                                            multipleSelection={true}
                                            onAddItem={(category) => {
                                                updateFormData({
                                                    locations: [
                                                        ...formData.locations,
                                                        {
                                                            value: category.id,
                                                            label: category.value,
                                                        },
                                                    ],
                                                });
                                            }}
                                            ignoreItems={formData?.locations?.map(
                                                (store) => store.value
                                            )}
                                            validationError={formError?.locations}
                                        >
                                        </FieldProductCategory>
                                    )
                                }
                                {
                                    formData?.locationType === BannerLocationTypeEnum.BRAND && (
                                        <FieldProductBrand
                                            label={'Marcas'}
                                            multipleSelection={true}
                                            onAddItem={(brand) => {
                                                updateFormData({
                                                    locations: [
                                                        ...formData.locations,
                                                        {
                                                            value: brand.id,
                                                            label: brand.value,
                                                        },
                                                    ],
                                                });
                                            }}
                                            ignoreItems={formData?.locations.map(
                                                (store) => store.value
                                            )}
                                            validationError={formError?.locations}
                                        >
                                        </FieldProductBrand>
                                    )
                                }
                            </div>
                            {
                                formData?.locationType !== BannerLocationTypeEnum.ALL && (
                                    <div className={'col-12'}>
                                        <FieldLiveSearchSelectionTable
                                            descriptionField={'label'}
                                            value={formData?.locations || []}
                                            onRemove={(record) => {
                                                updateFormData({
                                                    locations: formData?.locations.filter(
                                                        (item) =>
                                                            item.value !== record.value
                                                    ),
                                                });
                                            }}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </>
        </SimpleConfirmModal>
    );
}
