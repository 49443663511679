import { useEffect, useState } from 'react';
import { OrderByVirtualOrdersEnum, PaymentsTypeText, SaleChannelEnum, SaleChannelText, VirtualOrderStatus } from 'erva-doce-common';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import * as CustomerService from '../../services/CustomerService';
import LoadError from '../../components/LoadError';
import { getVirtualOrderDetailRoute } from '../loja-virtual/virtual-order-detail/VirtualOrderDetail';
import { useNavigate } from 'react-router';
import { getSalesFormRoute } from '../sales/SalesForm';
import CustomerOrderFilterModal from './CustomerOrderFilterModal';

const filtersOrder = {
    order: OrderByVirtualOrdersEnum.CREATED_DATE_DESC,
    page: 0,
    initialDate: null,
    finalDate: null,
    channel: null
};

export default function CustomerTableVirtualOrder({ 
    customerUuid, 
    showFilter,
    setShowFilter
}) {
    const [orders, setOrders] = useState();
    const [haveFetchOrderError, setHaveFetchOrderError] = useState(false);
    const [virtualOrderLoading, setVirtulOrderLoading] = useState(false);
    const [filters, setFilters] = useState(filtersOrder);
    const navigate = useNavigate();
    const handleRowClick = (route) => navigate(route);

    const fetchOrder = async () => {
        try {
            setVirtulOrderLoading(true);
            const results = await CustomerService.getVirtualOrders(customerUuid, filters);

            setOrders(results);
        } catch (error) {
            setHaveFetchOrderError(true);
        } finally {
            setVirtulOrderLoading(false);
            setHaveFetchOrderError(false);
        }
    };

    const handleOrderDetail = (order) => {
        switch (order?.channel) {
        case SaleChannelEnum.ECOMMERCE:
            return handleRowClick(
                getVirtualOrderDetailRoute(order.virtualOrderId)
            );
        case SaleChannelEnum.FISICA:
            return handleRowClick(
                getSalesFormRoute(order.saleUuid)
            );
        }
    };

    useEffect(() => {
        fetchOrder();
    }, [filters]);

    return haveFetchOrderError ? (
        <LoadError
            // I18N
            message={'Não foi possível carregar as vendas'}
            onTryAgain={() => fetchOrder()}
        />
    ) : (
        <>
            <div className={'table-scroll'}>
                <Table
                    loading={virtualOrderLoading}
                    currentSort={filters.order}
                    columns={[
                        {
                            name: 'N° Venda',
                            sortAsc: OrderByVirtualOrdersEnum.ID_ASC,
                            sortDesc: OrderByVirtualOrdersEnum.ID_DESC,
                            onSortChange: (order) => setFilters({ ...filters, order })
                        },
                        {
                            name: 'Data/Hora',
                            sortAsc: OrderByVirtualOrdersEnum.CREATED_DATE_ASC,
                            sortDesc: OrderByVirtualOrdersEnum.CREATED_DATE_DESC,
                            onSortChange: (order) => setFilters({ ...filters, order })
                        },
                        {
                            name: 'Valor',
                            sortAsc: OrderByVirtualOrdersEnum.TOTAL_ASC,
                            sortDesc: OrderByVirtualOrdersEnum.TOTAL_DESC,
                            onSortChange: (order) => setFilters({ ...filters, order })
                        },
                        { name: 'NF' },
                        { name: 'Pagamento' },
                        { name: 'Status' },
                        { name: 'Canal de vendas', align: 'center' }
                    ]}>
                    {orders?.records && orders?.records.map(order => {
                        return (
                            <tr key={order.saleId} onClick={() => handleOrderDetail(order)}>
                                <td>{`${order.saleId}`.padStart(7, '0')}</td>
                                <td>{new Date(order.createdAt).toLocaleString('pt-br')} </td>
                                <td>{order.total?.toLocaleString(undefined, { style: 'currency', currency: 'BRL', })}</td>
                                <td>{order.nfce ?? '-'}</td>
                                <td>
                                    {PaymentsTypeText(order.paymentMethod)}
                                </td>
                                <td>
                                    {order.status ? VirtualOrderStatus(order.status) : '-'}
                                </td>
                                <td className={'text-center'}>
                                    {order?.channel && SaleChannelText(order.channel)}
                                </td>
                            </tr>
                        );
                    })}
                </Table>
                <Pagination
                    page={orders?.page}
                    pageSize={orders?.pageSize}
                    count={orders?.count}
                    recordCount={orders?.records.length}
                    onPageChange={page => setFilters({ ...filters, page: page })}
                />

                <CustomerOrderFilterModal 
                    show={showFilter}
                    filter={filtersOrder}
                    updateFilters={setFilters}
                    onCancel={() => setShowFilter(false)}
                    onConfirm={() => setShowFilter(false)}
                />
            </div>
        </>
    );
}