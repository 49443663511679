import api from '../util/api';

export async function getSales({ page, search, order, sellers, cashiers, initialDate, finalDate, cancelToken }) {
    return (await api.get('/sales', {
        params: {
            page,
            search,
            order,
            sellers,
            cashiers,
            initialDate,
            finalDate,
        },
        cancelToken: cancelToken
    })).data;
}

export async function getSale(uuid) {
    return (await api.get(`/sales/${uuid}`)).data;
}

export async function addSale(formData) {
    return (await api.put('/sales', formData)).data;
}

export async function registerTefTransaction() {
    return (await api.post('/sales/register-tef-transaction')).data;
}

export async function editSale(uuid, formData) {
    return await api.put(`/sales/${uuid}`, formData);
}

export async function addCustomerToSale(formData) {
    return await api.post('/sales/addCustomerToSale', formData);
}

export async function deleteSale(uuid) {
    return await api.delete(`/sales/${uuid}`);
}
