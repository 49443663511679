import { useEffect, useRef, useState } from 'react';
import './NotificationCenter.scss';
import { MdNotifications, MdNotificationsNone } from 'react-icons/md';
import classNames from 'classnames';
import useOutsideClick from '../../hooks/useOutsideClick';
import useWindowResize from '../../hooks/useWindowResize';
import Button, { ButtonColor, ButtonStyle } from '../Button';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import * as NotificationService from '../../services/NotificationService';

export default function NotificationCenter() {
    const wrapperRef = useRef();
    const optionsRef = useRef();
    const width = useWindowResize();

    const [isOpen, setIsOpen] = useState(false);
    const [sideToOpen, setSideToOpen] = useState('center');
    const [notifications, setNotifications] = useState([]);
    const maxNotificationsLength = 99;
    const notificationsCount = notifications.length > maxNotificationsLength ? `${maxNotificationsLength}+` : notifications.length;

    useOutsideClick(wrapperRef, () => setIsOpen(false));

    const getDateDiff = (date) => {
        const currentDate = DateTime.now();
        const oldDate = DateTime.fromISO(date);
        const diff = Math.round(currentDate.diff(oldDate, 'minutes').minutes);

        if (diff < 60) {
            if (diff === 0) {
                return 'agora';
            }
            return `${diff}min atrás`;
        } else if (diff < 1440) {
            return `${Math.ceil(diff / 60)}h atrás`;
        } else {
            return oldDate.toFormat('dd/MM/yyyy HH:mm');
        }
    };

    const getNotifications = async () => {
        try {
            const res = await NotificationService.getNotifications();

            setNotifications(res || []);
        } catch(err) {
            console.log(err);
            setNotifications([]);
        }
    };

    const viewNotification = async (id) => {
        try {
            await NotificationService.viewNotification(id);

            setNotifications(state => state.filter(x => x.id !== id));
        } catch(err) {
            console.log(err);
        }
    };

    const viewAllNotifications = async () => {
        try {
            await NotificationService.viewAllNotifications();

            setNotifications([]);
        } catch(err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getNotifications();

        const timeout = setInterval(() => {
            if (isOpen) return;

            getNotifications();
        }, 30000);

        return () => clearInterval(timeout);
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            if (
                optionsRef.current.getBoundingClientRect().left +
            optionsRef.current.scrollWidth >
            window.innerWidth
            ) {
                setSideToOpen('left');
            }
        }
    }, [isOpen]);

    useEffect(() => {
        setSideToOpen('center');
    }, [width]);

    return (
        <div ref={wrapperRef} className={classNames('notification-wrapper', { show: isOpen })}>
            <button
                title={'Notificações'}
                aria-label={'Notificações'}
                onClick={() => setIsOpen(state => !state)}
                className={'notification-btn'}
                data-count={notificationsCount}
            >
                {
                    notifications.length ? <MdNotifications size={32} /> : <MdNotificationsNone size={32} />
                }
            </button>
            <div ref={optionsRef} className={classNames('notification-list', `direction-${sideToOpen}`)}>
                <div className={'notification-controls'}>
                    <button onClick={() => setIsOpen(false)} className={'btn-close'}>{'X'}</button>
                    <Button
                        onClick={viewAllNotifications}
                        className={'btn-remove-all'}
                        disabled={!notifications.length}
                        color={ButtonColor.BUTTON_COLOR_ORANGE}
                        buttonStyle={ButtonStyle.BUTTON_TINY}
                    >
                        {'Remover todas'}
                    </Button>
                    <hr className={'mt-16'} />
                </div>
                <ul className={'list'}>
                    {
                        notifications.length ? notifications.map(notification => (
                            <li key={notification.id}>
                                <Link to={notification.redirectURL} onClick={() => viewNotification(notification.id)}>
                                    <p className={'title'}>{notification.title}</p>
                                    <p 
                                        className={'description text-ellipsis-truncate-lines'}
                                        title={notification.description}
                                    >
                                        {notification.description}
                                    </p>
                                    <p className={'createdAt'}>{getDateDiff(notification.createdAt)}</p>
                                </Link>
                                <button onClick={() => viewNotification(notification.id)} className={'btn-close'}>{'X'}</button>
                            </li>
                        )) : (
                            <p>{'Nenhuma notificação encontrada'}</p>
                        )
                    }
                </ul>
            </div>
        </div>
    );
}
