import { useContext, useEffect, useState } from 'react';
import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import './TransferModal.scss';
import FieldSelect from '../../components/FieldSelect';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import FieldNumber from '../../components/FieldNumber';

export default function TransferModal({
    show,
    onCancel,
    onConfirm,
    product,
}) {
    if (!product || product.length === 0) return null;
    
    const { selectedStore } = useContext(EnvironmentContext);
    
    const transferOptions = [
        {
            id: 'CD',
            value: 'CD',
        },
        {
            id: `${selectedStore?.id}`,
            value: `Loja (${selectedStore?.fantasyName})`,
        },
    ];
    
    const [formError, setFormError] = useState({});
    const [formData, setFormData] = useState({
        id: product?.id,
        to: `${selectedStore?.id}`,
        from: 'CD',
        count: 0,
    });
    const [maxValue, setMaxValue] = useState(0);

    const updateFormData = (newData) => {
        setFormData(state => ({
            ...state,
            ...newData,
        }));
    };

    const handleChangeOption = ({ key, compareTo, value }) => {
        if (formData[compareTo] === value[key]) {
            setFormData({
                ...formData,
                [compareTo]: transferOptions.find(x => x.id !== value[key]).id,
            });
        }

        updateFormData(value);
    };

    const confirm = () => {
        if(hasValidationErrors()) return;
        onConfirm(formData);
    };

    function hasValidationErrors() {
        let hasError = false;
        if(formData.count === 0){
            hasError = true;
            setFormError({ errorCount: 'O valor mínimo para transferência é 1 unidade.' });
        }

        return hasError;
    }

    useEffect(() => {
        updateFormData({ count: 0 });

        if(formData?.from === 'CD') {
            setMaxValue(product?.warehouse?.stock || 0);
            return;
        } 

        setMaxValue(product?.productStore?.stock || 0);

    }, [product, formData?.from]);

    return (
        <SimpleConfirmModal
            className={'transfer-modal'}
            show={show}
            onCancel={onCancel}
            onConfirm={confirm}
            confirmText={'Concluir transferência'}
            cancelText={'Cancelar trasnferência'}
            title={'Transferir itens entre estoques'}
            footerColumn
        >
            <div className={'product-info'}>
                <table>
                    <thead>
                        <tr>
                            <th>{'Produto'}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td 
                                title={product?.name || ''}
                                className={'text-ellipsis-truncate-lines'}
                            >
                                {product?.name || '-'}
                            </td>
                        </tr>
                        <br />
                        <tr>
                            <td 
                                title={product?.barCode || '-'}
                                className={'text-ellipsis-truncate-lines'}
                            >
                                {product?.barCode || '-'}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className={'form mt-24'}>
                <FieldSelect
                    label={'Transferir <strong>de:</strong>'}
                    required={true}
                    options={transferOptions}
                    value={formData?.from}
                    disableDefaultOption={true}
                    onChange={({ target }) => {
                        handleChangeOption({ key: 'from', compareTo: 'to', value: { from: target.value } });
                    }}
                />
                <FieldSelect
                    label={'Transferir <strong>para:</strong>'}
                    required={true}
                    options={transferOptions}
                    value={formData?.to}
                    disableDefaultOption={true}
                    onChange={({ target }) => {
                        handleChangeOption({ key: 'to', compareTo: 'from', value: { to: target.value } });
                    }}
                />
                <div className={'information-label'}>
                    <div className={'row'}>
                        <div className={'col-6'}>
                            <div>
                                <FieldNumber
                                    label={'Qtd que deve ser transferida'}
                                    maxValue={formData?.from === 'CD' ? product?.warehouse?.stock : product?.productStore?.stock}
                                    fieldGroup={false}
                                    required={true}
                                    thousandsSeparator={false}
                                    decimalLimit={0}
                                    value={!formData?.count ? 0 : formData?.count}
                                    onChange={({ target }) => {
                                        updateFormData({ count: Number(target.value) });
                                    }}
                                    validationError={formError?.errorCount}
                                />                   
                            </div>
                        </div>
                        <div className={'col-6'}>
                            <div>
                                <FieldNumber
                                    label={'Qtd máxima a ser transferida'}
                                    required={false}
                                    readOnly
                                    value={maxValue || 0}
                                />                   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SimpleConfirmModal>
    );
}
