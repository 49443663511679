import api from '../util/api';
import focusApi from '../util/focusApi';

export async function getCfeBody(saleUuid) {
    return (await api.get(`cfe/${saleUuid}`)).data;
}

export async function createCfe(cfeBody, ref) {

    const queryParams = {
        ref: ref,
    };
    return (await focusApi.post('', cfeBody, {
        params: queryParams,
        headers: {
            'Content-Type': 'application/json', 
        },
    }));
}

export async function getCfePdf(chaveCfe) {
    return (await focusApi.get(`/cfe/${chaveCfe}.pdf`, {
        responseType: 'blob',
    }));
}

export async function saveResponseApi(cfeInfos) {
    return await api.post('/cfe', cfeInfos);
}

export async function createNFCe(saleUuid) {
    const queryParams = {
        sale: saleUuid
    };

    return (await api.post('/nfce', {
        params: queryParams
    }));
}

export async function getNFCePdf(chaveNfe) {
    return (await api.get(`/nfce/pdf/${chaveNfe}`, {
        responseType: 'blob',
    }));

}