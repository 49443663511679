export enum VirtualPaymentMethodEnum {
  CREDIT_CARD = "CREDIT_CARD",
  BANK_SLIP = "BANK_SLIP",
  PIX = "PIX",
}

export function VirtualPaymentMethod(
  method: VirtualPaymentMethodEnum | string
) {
  switch (method) {
    case VirtualPaymentMethodEnum.CREDIT_CARD:
      // I18N
      return "Cartão de Crédito";
    case VirtualPaymentMethodEnum.BANK_SLIP:
      // I18N
      return "Boleto";
    case VirtualPaymentMethodEnum.PIX:
      // I18N
      return "PIX";
    default:
      throw new Error(`Not implemented for ${method}`);
  }
}
