export enum OrderByVouchersEnum {
    Cashier_ASC = "Cashier_ASC",
    Cashier_DESC = "Cashier_DESC",
    DATE_ASC = "DATE_ASC",
    DATE_DESC = "DATE_DESC",
    VALUE_ASC = "VALUE_ASC",
    VALUE_DESC = "VALUE_DESC",
    COLLABORATOR_DESC = "COLLABORATOR_DESC",
    COLLABORATOR_ASC = "COLLABORATOR_ASC",
    STATUS_ASC = "STATUS_ASC",
    STATUS_DESC = "STATUS_DESC",
    UPDATED_BY_ASC = "UPDATED_BY_ASC",
    UPDATED_BY_DESC = "UPDATED_BY_DESC",
}