import classNames from 'classnames';

export default function ToolTip({ left, title, description, className, titleClassName }) {
    return (
        <div className={classNames('tooltip-container', { left: !!left }, className)}>
            <span className={classNames('tooltip', titleClassName)}>
                {title && title}
                <span className={'tooltip-text'}>
                    {description && description}
                </span>
            </span>
        </div>
    );
}
