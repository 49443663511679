import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import ProductDevolutionList from '../../components/ProductDevolutionList';

function DevolutionSaleModal({
    show,
    onCancel,
    onConfirm,
    cancelEsc,
    productsExchange,
}){
    return (
        <div className={'sales-payment-modal'}>
            <SimpleConfirmModal
                // I18N
                cancelEsc={cancelEsc}
                show={show}
                onCancel={() => onCancel()}
                onConfirm={() => onConfirm()}
                confirmText={'Concluir'}
                disabled={!productsExchange?.some((product) => product.amount > 0)}
            >
                <div className={'gd'}>
                    <div className={'gd-col-12  title'}>
                        {'Finalizar Devolução'}
                    </div>
                </div>
                <div className={'gd'}>
                    <div className={'gd-col-12'}>
                        <ProductDevolutionList
                            productsExchange={productsExchange}
                        />
                    </div>
                </div>
            </SimpleConfirmModal>
        </div>
    );
}

export default DevolutionSaleModal;