import { useContext, useEffect, useRef, useState } from 'react';
import ScreenHeader from '../../components/logged/ScreenHeader';
import Table from '../../components/Table';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import Button, { ButtonColor, ButtonStyle } from '../../components/Button';
import { IconAdd, IconAdd2x, IconEdit, IconEdit2x } from '../../components/images';
import { getCampaignFormRoute } from './CampaignForm';
import Pagination from '../../components/Pagination';
import { useNavigate } from 'react-router';
import { DateTime } from 'luxon';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import * as CampaignService from '../../services/CampaignService';
import FieldTextSearch from '../../components/FieldTextSearch';
import { ENUM_SHORTCUT } from 'erva-doce-common';

const INITIAL_FILTER = {
    page: 0,
    search: '',
};

export default function Campaign() {
    const title = 'Campanhas';
    const navigate = useNavigate();
    const inputSearchRef = useRef();
    const { backendConnectionError, addHotkey,
        removeHotkey, } = useContext(EnvironmentContext);

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({});
    const [campaignSearch, setCampaignSearch] = useState('');
    const [filter, setFilter] = useState(INITIAL_FILTER);

    const fetchData = async () => {
        try {
            setIsLoading(true);

            const res = await CampaignService.get(filter);

            setData(res);
        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to fetch campaings', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const shortcutSearchSecondary = addHotkey(ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY, () => {
            inputSearchRef.current?.focus();
        });

        const shortcutCreatePrimary = addHotkey(ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY, () => {
            navigate(getCampaignFormRoute());
        });

        return () => {
            removeHotkey(shortcutSearchSecondary);
            removeHotkey(shortcutCreatePrimary);
        };
    }, []);

    useEffect(() => {
        fetchData();
    }, [filter]);
    return (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Comercial', route: getDashboardRoute() },
                        { name: title, route: getCampaignRoute() },
                    ]}
                    hideStore
                />
                <div className={'row justify-content-end mb-32'}>
                    <div className={'col-6 col-md-4'}>
                        <FieldTextSearch
                            ref={inputSearchRef}
                            label={
                                `<em>Buscar campanha por <strong>Nome [${ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY}]</strong></em>`
                            }
                            onChange={({ target }) =>{
                                setCampaignSearch(target.value);
                            }}
                            value={campaignSearch}
                            onChangeDebounce={(value) => {
                                setFilter({ ...filter, search: value });
                            }}
                            debounceTime={400}
                        />
                    </div>
                    <div className={'hide-mobile col-md-6'}></div>
                    <div className={'col-6 col-md-2'}>
                        <Button
                            buttonStyle={ButtonStyle.BUTTON_SHADOW}
                            color={ButtonColor.BUTTON_COLOR_GREEN}
                            icon={IconAdd}
                            icon2x={IconAdd2x}
                            onClick={() => navigate(getCampaignFormRoute())}
                            className={'w-100'}
                        >
                            {`Nova campanha [${ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY}]`}
                        </Button>
                    </div>
                </div>
                <div className={'mt-24'}>
                    <Table
                        loading={isLoading}
                        columns={[
                            {
                                name: 'Nome',
                                align: 'center',
                                width: '20%',
                            },
                            {
                                name: 'Descrição',
                                align: 'center',
                            },
                            {
                                name: 'Valor',
                                align: 'center',
                            },
                            {
                                name: 'Situação',
                                align: 'center',
                            },
                            {
                                name: 'Data de início',
                                align: 'center',
                            },
                            {
                                name: 'Data de fim',
                                align: 'center',
                            },
                            {
                                name: 'Ações',
                                align: 'center',
                            },
                        ]}
                    >
                        {data?.records?.map((campaign) => (
                            <tr key={campaign.id}>
                                <td
                                    className={'text-center'}
                                >
                                    {campaign.name}
                                </td>
                                <td
                                    className={'text-center'}
                                >
                                    {campaign.description || '-'}
                                </td>
                                <td className={'text-center'}>
                                    {campaign.value}{'%'}
                                </td>
                                <td className={'text-center'}>
                                    {campaign.isActive ? 'Ativa' : 'Inativa'}
                                </td>
                                <td className={'text-center'}>
                                    {DateTime.fromFormat(campaign.startAt, 'yyyy-MM-dd').toFormat('dd/MM/yyyy')}
                                </td>
                                <td className={'text-center'}>
                                    {DateTime.fromFormat(campaign.endAt, 'yyyy-MM-dd').toFormat('dd/MM/yyyy')}
                                </td>
                                <td className={'text-center'}>
                                    <Button
                                        className={'transparent mt-8'}
                                        onClick={() => navigate(getCampaignFormRoute(campaign.uuid))}
                                    >
                                        <img
                                            src={IconEdit}
                                            srcSet={`${IconEdit} 1x, ${IconEdit2x} 2x`}
                                            alt={'Editar campanha'}
                                            title={'Editar campanha'}
                                            style={{
                                                width: '16px',
                                            }}
                                        />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </Table>
                    <div className={'w-100 d-flex justify-content-center'}>
                        <Pagination
                            page={data?.page}
                            pageSize={data?.pageSize}
                            count={data?.count}
                            recordCount={data?.records?.length}
                            onPageChange={page => setFilter({ ...filter, page })}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export function getCampaignRoute() {
    return '/comercial/campanhas';
}
