export enum VouchersStatusEnum {
    OPEN = "OPEN",
    WRITTEN_OFF = "WRITTEN_OFF",
    CANCELLED = "CANCELLED",
}

export function VoucherStatusText(voucherStatus: VouchersStatusEnum | string) {
    switch (voucherStatus) {
    case VouchersStatusEnum.OPEN:
        // I18N
        return "Aberto";
    case VouchersStatusEnum.WRITTEN_OFF:
        // I18N
        return "Baixado";
    case VouchersStatusEnum.CANCELLED:
        // I18N
        return "Cancelado";

    default:
        throw new Error(`Not implemented for ${voucherStatus}`);
    }
}
