import { OrderProductsStatusEnum, OrderProductsStatusText, PermissionsEnum } from 'erva-doce-common';
import { getProductFormRoute } from '../../products/ProductsForm';
import FieldNumber from '../../../components/FieldNumber';
import Table from '../../../components/Table';
import { formatValue } from '../../../util/formatValue';
import ToolTip from '../../../components/ToolTip';
import PermissionedLink from '../../../components/PermissionedLink';

export default function OrderBillingTable({
    data,
    filter,
    isLoading,
    updateItem,
    canEdit,
    totals,
}) {

    const COL_SPAN_TABLE = 18;

    return (
        <>
            <Table
                loading={isLoading}
                columns={[
                    {
                        name: 'EAN',
                        align: 'center',
                    },
                    {
                        name: 'Código do Fornecedor',
                        align: 'center',
                    },
                    {
                        name: 'Produtos',
                        width: '20%',
                    },
                    {
                        name: 'Pedido QTD',
                        align: 'center',
                    },
                    {
                        name: 'Saldo cancelado',
                        align: 'center',
                    },
                    {
                        name: 'Saldo faturado',
                        align: 'center',
                    },
                    {
                        name: 'Não faturado',
                        align: 'center',
                    },
                    {
                        name: 'XML',
                        align: 'center',
                    },
                    {
                        name: 'Custo unitário',
                        align: 'center',
                    },
                    {
                        name: 'Total produtos',
                        align: 'center',
                    },
                    {
                        name: 'ICMS',
                        align: 'center',
                    },
                    {
                        name: 'IPI',
                        align: 'center',
                    },
                    {
                        name: 'Frete',
                        align: 'center',
                    },
                    {
                        name: 'Custo final',
                        align: 'center',
                    },
                    {
                        name: 'Total final',
                        align: 'center',
                    },
                    {
                        name: 'Diferença Qtd',
                        align: 'center',
                    },
                    {
                        name: 'Provador previsto',
                        align: 'center',
                    },
                    {
                        name: 'Status',
                        align: 'center',
                        width: '10%',
                    },
                ]}
            >
                {data.length ? data?.map((product) => (
                    <tr key={product.id}>
                        <td className={'text-center'}>{product?.barCodes?.find(x => x.main)?.barCode || product?.barCodes[0].barCode || '-'}</td>
                        <td className={'text-center'}>{product.supplierCode || '-'}</td>
                        <td>
                            {
                                product.infoAdd ? (
                                    <ToolTip
                                        title={
                                            <PermissionedLink
                                                permission={PermissionsEnum.HANDLE_PRODUCTS}
                                                to={getProductFormRoute(product.productUuid)}
                                            >
                                                {product.name}
                                            </PermissionedLink>
                                        }
                                        description={product.infoAdd}
                                    />
                                ) : (
                                    <PermissionedLink
                                        permission={PermissionsEnum.HANDLE_PRODUCTS}
                                        to={getProductFormRoute(product.productUuid)}
                                    >
                                        {product.name}
                                    </PermissionedLink>
                                )
                            }
                        </td>
                        <td className={'text-center'}>{product.count}</td>
                        <td className={'text-center'}>{product.countCanceled}</td>
                        <td className={'text-center'}>{product.countBilled}</td>
                        <td className={'count-selected text-center'}>
                            {canEdit.noDocument && product.status !== OrderProductsStatusEnum.CANCELADO ? (
                                <FieldNumber
                                    fieldGroup={false}
                                    required={true}
                                    thousandsSeparator={false}
                                    readOnly={product.status === OrderProductsStatusEnum.CANCELADO}
                                    value={product.countNoDocument}
                                    onBlur={({ target }) =>
                                        updateItem(product.id, {
                                            countNoDocument: Number(target.value),
                                        })
                                    }
                                />
                            ) : (
                                product.countNoDocument
                            )}
                        </td>
                        <td className={'text-center'}>{product.countXML}</td>
                        <td className={'text-center'}>{formatValue(product.cost)}</td>
                        <td className={'text-center'}>{formatValue(product.total)}</td>
                        <td className={'count-selected text-center'}>
                            {canEdit.icms && product.status !== OrderProductsStatusEnum.CANCELADO ? (
                                <FieldNumber
                                    fieldGroup={false}
                                    required={true}
                                    thousandsSeparator={true}
                                    integerLimit={3}
                                    decimalLimit={2}
                                    readOnly={product.status === OrderProductsStatusEnum.CANCELADO}
                                    value={product.icms}
                                    onBlur={({ target }) =>
                                        updateItem(product.id, {
                                            icms: Number(target.value),
                                        })
                                    }
                                />
                            ) : (
                                formatValue(product.icms)
                            )}
                        </td>
                        <td className={'count-selected text-center'}>
                            {canEdit.ipi && product.status !== OrderProductsStatusEnum.CANCELADO ? (
                                <FieldNumber
                                    fieldGroup={false}
                                    required={true}
                                    thousandsSeparator={true}
                                    integerLimit={3}
                                    decimalLimit={2}
                                    readOnly={product.status === OrderProductsStatusEnum.CANCELADO}
                                    value={product.ipi}
                                    onBlur={({ target }) =>
                                        updateItem(product.id, {
                                            ipi: Number(target.value),
                                        })
                                    }
                                />
                            ) : (
                                formatValue(product.ipi)
                            )}
                        </td>
                        <td className={'count-selected text-center'}>
                            {canEdit.frete && product.status !== OrderProductsStatusEnum.CANCELADO ? (
                                <FieldNumber
                                    fieldGroup={false}
                                    required={true}
                                    thousandsSeparator={true}
                                    integerLimit={3}
                                    decimalLimit={2}
                                    readOnly={product.status === OrderProductsStatusEnum.CANCELADO}
                                    value={product.frete}
                                    onBlur={({ target }) =>
                                        updateItem(product.id, {
                                            frete: Number(target.value),
                                        })
                                    }
                                />
                            ) : (
                                formatValue(product.frete)
                            )}
                        </td>
                        <td className={'text-center'}>{formatValue(product.costFinal || 0)}</td>
                        <td className={'text-center'}>{formatValue(product.totalFinal || 0)}</td>
                        <td className={'text-center'}>{(Number(product.countBilled) + Number(product.countXML) + Number(product.countNoDocument) + Number(product.countCanceled)) - Number(product.count)}</td>
                        <td className={'text-center'}>{'0'}</td>
                        <td className={'text-center'}>{OrderProductsStatusText(product.status)}</td>
                    </tr>
                )) : <tr><td colSpan={COL_SPAN_TABLE} className={'text-center'}>{'Nenhum item encontrado'}</td></tr>}
                <tr className={'total-row'}>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className={'text-center'}>{formatValue(totals['total'])}</td>
                    <td className={'text-center'}>{formatValue(totals['icms'])}</td>
                    <td className={'text-center'}>{formatValue(totals['ipi'])}</td>
                    <td className={'text-center'}>{formatValue(totals['frete'])}</td>
                    <td className={'text-center'}>{formatValue(totals['costFinal'])}</td>
                    <td className={'text-center'}>{formatValue(totals['totalFinal'])}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </Table>
        </>
    );
}
