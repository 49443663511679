import api from '../util/api';

export async function getExchanges(page, search, order, cancelToken) {
    return (await api.get('/exchanges', {
        params: {
            page,
            search,
            order
        },
        cancelToken: cancelToken
    })).data;
}

export async function getExchange(uuid) {
    return (await api.get(`/exchanges/${uuid}`)).data;
}

export async function getExchangesByCustomerCpf(cpf) {
    return (await api.get(`/exchanges/cpf/${cpf}`)).data;
}

export async function getExchangeTotal(uuid) {
    return (await api.get(`/exchanges/total/${uuid}`)).data;
}

export async function addExchange(formData) {
    return (await api.put('/exchanges', formData)).data;
}

export async function editExchange(uuid, formData) {
    return await api.put(`/exchanges/${uuid}`, formData);
}

export async function deleteExchange(uuid) {
    return await api.delete(`/exchanges/${uuid}`);
}

