import './MovementsForm.scss';
import * as ProductMovementsService from '../../../services/ProductMovementsService';
import Table from '../../../components/Table';
import { ProductMovementsEnum, ProductMovementsFilterEnum , ProductMovementsText } from 'erva-doce-common';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import Pagination from '../../../components/Pagination';

const FILTER = {
    individualSale: ProductMovementsFilterEnum.INDIVIDUAL_SALE,
    dailySale: '',
    weeklySale: '',
    monthlySale: '',
    entries: '',
    page: 0, 
};

const BTN_FILTERS = [
    { key: 'individualSale', label: 'Venda individual', value: ProductMovementsFilterEnum.INDIVIDUAL_SALE },
    { key: 'dailySale', label: 'Venda diária', value: ProductMovementsFilterEnum.DAILY_SALE },
    { key: 'weeklySale', label: 'Venda semanal', value: ProductMovementsFilterEnum.WEEKLY_SALE },
    { key: 'monthlySale', label: 'Venda mensal', value: ProductMovementsFilterEnum.MONTHLY_SALE },
    { key: 'entries', label: 'Entradas', value: ProductMovementsFilterEnum.ENTRIES },
];

export default function MovementsForm({ uuid }) {

    const [movements, setMovement] = useState(null);
    const [filter, setFilter] = useState(FILTER);
    const [isLoading, setIsLoading] = useState(false);

    const data = useCallback(async () => {
        try {
            setIsLoading(true);
            const movements = await ProductMovementsService.getProduct({ uuid, filter });

            setMovement(movements);
        } catch (error) {
            console.error('erro', error);            
        } finally {
            setIsLoading(false);
        }
    }, [uuid, filter]);

    useEffect(() => {
        data();
    }, [uuid, filter]);

    return (
        <>
            <div className={'product-form-container'}>
                <div>                
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <div className={'row justify-content-end mb-20'}>
                                {BTN_FILTERS.map(({ key, label, value }, index) => (
                                    <div
                                        key={key}
                                        className={classNames(
                                            'col-1 pill-suggestion-movement mr-4',
                                            { 'active': filter?.[key] === value },
                                            { 'ml-4': index !== 0 },
                                            { 'mr-14': index == BTN_FILTERS.length - 1 }
                                        )}
                                        onClick={() => setFilter({ [key]: value, page: 0 })}
                                    >
                                        {label}
                                    </div>
                                ))}
                            </div>    
                        </div>
                        <div className={'col-12'}>
                            <Table
                                loading={isLoading}
                                columns={[
                                    {
                                        name: 'Periodo',
                                        align: 'center',
                                    },
                                    {
                                        name: 'Ação',
                                        align: 'center',
                                    },
                                    {
                                        name: 'Quantidade',
                                        align: 'center',
                                    }
                                ]}
                            >
                                {
                                    movements?.records ? (
                                        movements?.records.map((movement, index) => {
                                            return (
                                                <tr key={index}
                                                    className={classNames( 
                                                        movement.action === ProductMovementsEnum.ENTRY ? 'line-green' : 'line-red'
                                                    )}
                                                >
                                                    <td className={'center'}>
                                                        {movement.period}
                                                    </td>
                                                    <td className={'center'}>
                                                        {`${ProductMovementsText(movement.movement)}`}
                                                    </td>
                                                    <td className={'center'}>
                                                        {movement.total}
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <></>
                                    )
                                }
                            </Table>
                            <div className={'d-flex justify-content-center'}>
                                <Pagination
                                    page={movements?.page}
                                    pageSize={movements?.pageSize}
                                    count={movements?.count}
                                    recordCount={movements?.records?.length || 0}
                                    onPageChange={(page) => setFilter({ ...filter, page })}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}