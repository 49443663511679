import { Link } from 'react-router-dom';
import ButtonRemove from '../../components/ButtonRemove';
import { formatValue } from '../../util/formatValue';
import PasswordModal from './PasswordModal';
import { ValidationErrorUserText } from 'erva-doce-common';
import { useContext, useState } from 'react';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import * as CollaboratorsService from '../../services/CollaboratorsService';


function SalesProductTable({
    productsSelected,
    onRemoveProduct,
    exchangeData,
    updateFormData,
    setExchangeData,
    selectedProdutcsToExchange,
    isSaleEditable=false,
    getExchangesFormRoute,
}) {
    const { backendConnectionError } = useContext(EnvironmentContext);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [productToRemove, setProductToRemove] = useState(null);
    const [removeExchange, setRemoveExchange] = useState(false);
    const [, setPasswordForm] = useState({});

    async function CheckCollaboratorPassword(password) {
        setPasswordForm({});
        if (password) {
            try {
                await CollaboratorsService.checkCollaboratorPassword(password);
                onRemoveProduct(productToRemove);

                if (removeExchange) {
                    setExchangeData({});
                    updateFormData({ exchange: null });
                }
            } catch (error) {
                const { errors } = error.response.data;
                backendConnectionError(
                    'Fail to fetch password',
                    error,
                    null,
                    ValidationErrorUserText(errors[0])
                );
            }
        }
        setShowPasswordModal(false);
    }

    function amountToExchange(prod) {
        if (!selectedProdutcsToExchange) return 0;

        const productSelected = selectedProdutcsToExchange.find(
            (p) => p.uuid === prod.uuid
        );
        const totalAmountValue = productSelected?.amount > 0
            ? productSelected.amount : 0;
        const value = Math.min(totalAmountValue, prod.amountAvailableForReturn);

        return value === 0 ? '-' : value;
    }

    return (
        <>
            <div>
                <div className={'table-container'}>
                    <table className={'products_header table'}>
                        <thead>
                            <tr>
                                {isSaleEditable && <th style={{ width: '10%' }}>{'Seleção'}</th>}
                                <th style={{ width: '5%' }}>{'Linha'}</th>
                                <th style={{ width: '40%' }} className={'text-start'}>
                                    {'Descrição do produto'}
                                </th>
                                <th>{'Quantidade'}</th>
                                {isSaleEditable && <th>{'Quantidade devolvida'}</th>}
                                <th>{'Preço unitário'}</th>
                                <th>{'Total'}</th>
                                {isSaleEditable
                                    ? (<th>{'Devolução'}</th>)
                                    : (<th style={{ width: '5%' }} />)
                                }
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>

            <div className={'middle-container'}>
                <table className={'products_body'}>
                    <tbody>
                        {productsSelected.length ? (
                            productsSelected.map((product, index) => (
                                <tr
                                    key={index}
                                    className={`${amountToExchange(product) > 0 && 'products-exchange'}`}
                                >
                                    {isSaleEditable &&
                                        <td style={{ width: '10%' }}>
                                            {product.amountAvailableForReturn > 0 && (
                                                <input
                                                    type={'text'}
                                                    className={'text-center'}
                                                    value={amountToExchange(product)}
                                                    disabled
                                                />
                                            )}
                                        </td>
                                    }
                                    <td style={{ width: '5%' }}>{index + 1}</td>
                                    <td style={{ width: '40%' }} className={'text-start'}>
                                        {product.name1}
                                    </td>
                                    <td className={'text-center'}>{product.amount}</td>
                                    {isSaleEditable && <td>{product.returnedAmount}</td>}
                                    <td >{formatValue(product.price)}</td>
                                    <td>{formatValue(product.total)}</td>
                                    {(!isSaleEditable && onRemoveProduct)
                                        ? (
                                            <td style={{ width: '5%' }}>
                                                <ButtonRemove onClick={() => {
                                                    setProductToRemove(product.uuid);
                                                    setShowPasswordModal(true);
                                                }} />
                                            </td>
                                        )
                                        : (<td>{product.devolutions?.length
                                            ? product.devolutions?.map((devolution) => (
                                                <Link
                                                    key={devolution.id}
                                                    target={'_blank'}
                                                    to={getExchangesFormRoute(devolution.uuid)}
                                                >
                                                    {devolution.id}
                                                </Link>
                                            ))
                                            : '-'}
                                        </td>)
                                    }
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={6}>
                                    {'Nenhum produto adicionado'}
                                </td>
                            </tr>
                        )}
                        {exchangeData?.products?.map((product) => (
                            <tr key={product.id} className={'products-exchange'}>
                                {isSaleEditable && (
                                    <td style={{ width: '10%' }}></td>
                                )}
                                <td style={{ width: '5%' }}>{'Devolução: '}</td>
                                <td style={{ width: '40%' }} className={'text-start'}>
                                    {product.name1}
                                </td>
                                <td>{product.amount}</td>
                                {isSaleEditable && <td></td>}
                                <td>{formatValue(product.price)}</td>
                                <td>{formatValue(product.total)}</td>
                                <td style={{ width: '5%' }}>
                                    {!isSaleEditable && (
                                        <ButtonRemove
                                            onClick={() => {
                                                setProductToRemove(product.uuid);
                                                setShowPasswordModal(true);
                                                setRemoveExchange(true);
                                            }}
                                        />
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <PasswordModal
                show={showPasswordModal}
                cancelEsc={true}
                onChangeForm={setPasswordForm}
                onCancel={() => {
                    setPasswordForm({ password: '' });
                    setShowPasswordModal(false);
                }}
                onConfirm={(target) => {
                    if (target.formData.password) {
                        CheckCollaboratorPassword(target.formData.password);
                    }
                }}
            />
        </>
    );
}

export default SalesProductTable;
