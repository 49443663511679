import api from '../util/api';

export async function getDocuments(filter) {
    return (
        await api.get('/order-billing-documents', {
            params: filter,
        })
    ).data;
}

export async function uploadDocuments(data, callback) {
    return (
        await api.post('/order-billing-documents', data, {
            headers: {
                'content-type': 'multipart/form-data',
            },
            onUploadProgress: callback,
        })
    );
}


export async function fetchDanfePdf({ id }) {
    try {
        
        const data = await api.get(`/order-billing-documents/dunfe/${id}`, {
            responseType: 'blob',
        });

        const contentType = data.headers['content-type'];
        const blob = new Blob([data.data], { type: contentType });
        const url = window.URL.createObjectURL(blob);

        window.open(url, '_blank');
    } catch (error) {
        console.error('Error when opening pdf', error);
    }
}