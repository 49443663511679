import * as Sentry from '@sentry/react';
import axios from 'axios';
import {
    ENUM_SHORTCUT,
    OrderBySuppliersEnum,
    PermissionsEnum,
} from 'erva-doce-common';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import Button, { ButtonColor, ButtonStyle } from '../../components/Button';
import ButtonBadge from '../../components/ButtonBadge';
import { maskCnpjString } from '../../components/FieldCnpj';
import { maskPhoneString } from '../../components/FieldPhone';
import FieldTextSearch from '../../components/FieldTextSearch';
import LoadError from '../../components/LoadError';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import { IconAdd, IconAdd2x, IconFilter, IconFilter2x } from '../../components/images';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as SuppliersService from '../../services/SuppliersService';
import { getSupplierFormRoute } from './SupplierForm';
import SuppliersFilterModal from './SuppliersFilterModal';

export default function Suppliers() {
    const [suppliers, setSuppliers] = useState(null);
    const [suppliersSearch, setSuppliersSearch] = useState('');
    const [suppliersLoading, setSuppliersLoading] = useState(null);
    const [filter, setFilter] = useState({
        order: OrderBySuppliersEnum.FANTASY_NAME_ASC,
        search: null,
        segmentations: [],
        page: 0
    });
    const inputSearchRef = useRef();
    const {
        addHotkey,
        removeHotkey,
        user,
    } = useContext(EnvironmentContext);
    const [showFilter, setShowFilter] = useState(false);

    const canAdd = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_SUPPLIERS);
    const navigate = useNavigate();
    const handleRowClick = (route) => navigate(route);
    const filterBtnRef = useRef();


    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        fetchSuppliers();
    }, [filter]);

    useEffect(() => {
        const shortcutSearchPrimary = addHotkey(ENUM_SHORTCUT.SHORTCUT_SEARCH_PRIMARY, () => {
            inputSearchRef.current?.focus();
        });

        const shortcutCreatePrimary = addHotkey(ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY, () => {
            navigate(getSupplierFormRoute());
        });

        const shortcutFilter = addHotkey(ENUM_SHORTCUT.SHORTCUT_FILTER, () => {
            if(filterBtnRef.current) {
                filterBtnRef.current.click();
            }
        });

        return () => {
            removeHotkey(shortcutSearchPrimary);
            removeHotkey(shortcutCreatePrimary);
            removeHotkey(shortcutFilter);
        };
    }, []);

    async function fetchSuppliers() {
        if (suppliersLoading?.cancel) {
            suppliersLoading.cancel();
        }
        try {
            const cancelToken = axios.CancelToken.source().token;
            setSuppliersLoading(cancelToken);
            const response = await SuppliersService.getSuppliers(
                filter.page,
                filter.search,
                filter.segmentations?.map(segmentation => segmentation.id),
                filter.order,
                cancelToken);
            setSuppliers(response);
            setSuppliersLoading(null);
        } catch (e) {
            if (axios.isCancel(e)) {
                console.debug('Request canceled.', e);
            } else {
                console.error(e);
                Sentry.captureException(e);
                setSuppliersLoading(e);
                setSuppliers(null);
            }
        }
    }

    function renderTable() {
        if (suppliersLoading && suppliersLoading instanceof Error) {
            return (
                <LoadError
                    // I18N
                    message={'Não foi possível carregar os fornecedores'}
                    onTryAgain={fetchSuppliers}
                />
            );
        }

        let rows = [];
        // noinspection JSUnresolvedReference
        const records = suppliers?.records ?? [];
        for (const supplier of records) {
            rows.push(
                <tr
                    key={supplier.uuid}
                    onClick={() =>
                        handleRowClick(getSupplierFormRoute(supplier.uuid))
                    }
                >
                    <td>{supplier.fantasyName ?? '-'}</td>
                    <td>{supplier.socialReason}</td>
                    <td>{supplier.segmentation ?? '-'}</td>
                    <td>{maskCnpjString(supplier.cnpj)}</td>
                    <td>{maskPhoneString(supplier.phone)}</td>
                </tr>
            );
        }

        return (
            <>
                <Table
                    loading={suppliersLoading}
                    currentSort={filter.order}
                    columns={[
                        {
                            // I18N
                            name: 'Nome fantasia',
                            sortAsc: OrderBySuppliersEnum.FANTASY_NAME_ASC,
                            sortDesc: OrderBySuppliersEnum.FANTASY_NAME_DESC,
                            onSortChange: (order) => setFilter({ ...filter, order })
                        }, {
                            // I18N
                            name: 'Razão social',
                            sortAsc: OrderBySuppliersEnum.SOCIAL_REASON_ASC,
                            sortDesc: OrderBySuppliersEnum.SOCIAL_REASON_DESC,
                            onSortChange: (order) => setFilter({ ...filter, order })
                        }, {
                            // I18N
                            name: 'Segmentação',
                            sortAsc: OrderBySuppliersEnum.SEGMENTATION_ASC,
                            sortDesc: OrderBySuppliersEnum.SEGMENTATION_DESC,
                            onSortChange: (order) => setFilter({ ...filter, order })
                        }, {
                            // I18N
                            name: 'CNPJ',
                            sortAsc: OrderBySuppliersEnum.CNPJ_ASC,
                            sortDesc: OrderBySuppliersEnum.CNPJ_DESC,
                            onSortChange: (order) => setFilter({ ...filter, order })
                        }, {
                            // I18N
                            name: 'Telefone',
                            sortAsc: OrderBySuppliersEnum.PHONE_ASC,
                            sortDesc: OrderBySuppliersEnum.PHONE_DESC,
                            onSortChange: (order) => setFilter({ ...filter, order })
                        },
                    ]}>
                    {rows}
                </Table>
                <Pagination
                    page={suppliers?.page}
                    pageSize={suppliers?.pageSize}
                    count={suppliers?.count}
                    recordCount={suppliers?.records?.length}
                    onPageChange={page => setFilter({ ...filter, page })}
                />
            </>
        );
    }

    const filterBadgeCount = (filter?.segmentations?.length ?? 0);

    return (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    title={'Fornecedores'}
                    breadcrumbs={[
                        { name: 'Compras', route: getDashboardRoute() },
                        { name: 'Fornecedores', route: getSuppliersRoute() },
                    ]}
                    hideStore
                />
                <div className={'controls'}>
                    <div className={'gd'}>
                        <div className={`gd-col ${canAdd ? 'gd-col-6' : 'gd-col-9'}`}>
                            <FieldTextSearch
                                ref={inputSearchRef}
                                label={`<em>Buscar por <strong>fornecedor</strong> ou <strong>CNPJ</strong> [${ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY}]</em>`}
                                onChange={({ target }) =>{
                                    setSuppliersSearch(target.value);
                                }}
                                value={suppliersSearch}
                                onChangeDebounce={(value) => {
                                    setFilter({ ...filter, search: value });
                                }}
                                debounceTime={400}
                            />
                        </div>
                        {canAdd && (
                            <div className={'gd-col gd-col-3'}>
                                <Button
                                    buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                    icon={IconAdd}
                                    icon2x={IconAdd2x}
                                    route={getSupplierFormRoute()}
                                >
                                    {`Novo fornecedor [${ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY}]`}
                                </Button>
                            </div>
                        )}
                        <div className={'gd-col gd-col-3'}>
                            <Button
                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                color={ButtonColor.BUTTON_COLOR_ORANGE}
                                icon={IconFilter}
                                icon2x={IconFilter2x}
                                badgeChildren={!!filterBadgeCount && <ButtonBadge count={filterBadgeCount} />}
                                onClick={() => setShowFilter(true)}
                                ref={filterBtnRef}
                            >
                                {`Filtrar [${ENUM_SHORTCUT.SHORTCUT_FILTER}]`}
                            </Button>
                        </div>
                    </div>
                </div>

                <div className={'table-scroll'}>
                    {renderTable()}
                </div>
            </div>
            <SuppliersFilterModal
                show={showFilter}
                onCancel={() => setShowFilter(false)}
                filter={filter}
                onConfirm={(complementaryFilter) => {
                    setShowFilter(false);
                    setFilter({
                        ...filter,
                        ...complementaryFilter,
                    });
                }}
            />
        </>

    );
}

export function getSuppliersRoute() {
    //I18N
    return '/compras/fornecedores';
}