import { useRef } from 'react';
import * as MenuService from '../../../../services/MenuService';
import { createCancelTokenSource } from '../../../../util/api';
import FieldLiveSearch from '../../../../components/FieldLiveSearch';

function FieldSubCategory({
    onSelected,
    select,
    filter,
    ...props
}) {
    const cancelTokenSourceRef = useRef(null);
    const liveSearchRef = useRef(null);

    async function fetchValue() {
        const result = await MenuService.getSubCategory();

        return result.length > 0 ? result[0].name : '';
    }

    async function fetch() {
        cancelTokenSourceRef.current?.cancel();
        cancelTokenSourceRef.current = await createCancelTokenSource();
        const result = await MenuService.getSubCategory(filter);

        const subCategories = [];
        for (const subCategory of result) {
            subCategories.push({
                id: subCategory.uuid,
                value: subCategory.name,
                category: subCategory.uuid
            });
        }

        return subCategories;
    }

    return (
        <>
            <FieldLiveSearch
                label={'SubCategorias de produtos'}
                fetchFn={fetch}
                fetchValueFn={fetchValue}
                onSelected={onSelected}
                select={select}
                hasPagination={true}
                ref={liveSearchRef}
                token={true}
                {...props}
            />
        </>
    );
}

export default FieldSubCategory;