import { useState, useEffect } from 'react';
import Table from '../../components/Table';
import FieldNumber from '../../components/FieldNumber';
import { formatValue } from '../../util/utilCurrency';
import Section from '../../components/Section';
import './CalculateCash.scss';

const TOTALS_TABLE = [
    { coin: 'fiveCents', total: 0, pct: 20, value: 0.05, package: '', loose: '' },
    { coin: 'tenCents', total: 0, pct: 10, value: 0.1, package: '', loose: '' },
    { coin: 'twentyFiveCents', total: 0, pct: 5, value: 0.25, package: '', loose: '' },
    { coin: 'fiftyCents', total: 0, pct: 2, value: 0.5, package: '', loose: '' },
    { coin: 'oneReal', total: 0, pct: 10, value: 1, package: '', loose: '' },
    { coin: 'twoReal', total: 0, pct: 50, value: 2, package: '', loose: '' },
    { coin: 'fiveReal', total: 0, pct: 20, value: 5, package: '', loose: '' },
    { coin: 'tenReal', total: 0, pct: 10, value: 10, package: '', loose: '' },
    { coin: 'twentyReal', total: 0, pct: 5, value: 20, package: '', loose: '' },
    { coin: 'fiftyReal', total: 0, pct: 2, value: 50, package: '', loose: '' },
    { coin: 'hundredReal', total: 0, pct: 1, value: 100, package: '', loose: '' },
];

export default function CalculateCash({
    onTotalChange,
    setInfoCalculateCash,
    valueDate,
    infoCalculateCash,
    disabled,
    onKeyDown,
    className,
}) {
    const [totalTable, setTotalTable] = useState(TOTALS_TABLE);

    useEffect(() => {
        if (!infoCalculateCash || infoCalculateCash.length === 0) {
            setInfoCalculateCash(TOTALS_TABLE);
            setTotalTable(
                TOTALS_TABLE.map((item) => ({
                    ...item,
                    package: 0,
                    loose: 0,
                    total: 0,
                }))
            );

            onTotalChange(0);

        } else {
            const updatedTable = infoCalculateCash.map((item) => ({
                ...item,
                package: Number(item.package) || 0,
                loose: Number(item.loose) || 0,
                total: Number(item.total) || 0,
            }));

            setTotalTable(updatedTable);

            const newTotal = updatedTable.reduce((acc, item) => acc + item.total, 0);
            
            onTotalChange(newTotal);
        }
    }, [infoCalculateCash, valueDate]);


    const returnTotalTable = (table = totalTable) =>
        formatValue({
            value: table.reduce((acc, item) => acc + item.total, 0),
        });
    const calculateTotalPackages = (table = totalTable) =>
        formatValue({
            value: table.reduce((acc, item) => acc +    (item.package || 0) * (item.pct || 0) * (item.value || 0) , 0),
        });
          
    const calculateTotalLoose = (table = totalTable) =>
        formatValue({
            value: table.reduce((acc, item) => acc + (item.loose || 0) * (item.value || 0), 0),
        });

    const handleInputChange = ({ index, field, value }) => {
        const updatedTotalTable = [...totalTable];
        const item = updatedTotalTable[index];

        const newValue = parseFloat(value.replace(',', '.')) || 0;
        item[field] = newValue;
        item.total =
            (item.package || 0) * (item.pct || 0) * (item.value || 0) +
            (item.loose || 0) * (item.value || 0);

        setTotalTable(updatedTotalTable);
        const newTotal = updatedTotalTable.reduce((acc, item) => acc + item.total, 0);
        
        onTotalChange(newTotal);

        setInfoCalculateCash(updatedTotalTable);
    };

    return (
        <Section title={'Dinheiro'}>
            <div className={`money-container  ${className}`}>

                <div className={'money-container-body ' }>
                    <Table
                        columns={[
                            { name: 'Valores', align: 'center' },
                            { name: 'Qtd. pacote', align: 'center' },
                            { name: 'Qtd. solta', align: 'center' },
                            { name: 'Total', align: 'center' },
                        ]}
                    >
                        {totalTable?.map((row, index) => (
                            <tr key={index}  onKeyDown={onKeyDown} >
                                <td className={'center'}>
                                    <div>
                                        <p>
                                            {formatValue({
                                                value: row.value,
                                            })}
                                        </p>
                                    </div>
                                </td>
                                <td className={'input-style'}>
                                    <FieldNumber
                                        minValue={0}
                                        thousandsSeparator={false}
                                        onChange={({ target }) =>
                                            handleInputChange({
                                                field: 'package',
                                                index: index,
                                                value: target.value,
                                            })
                                        }
                                        value={row?.package || ''}
                                        disabled={disabled}
                                    />
                                </td>
                                <td >
                                    <div className={'input-style'}>
                                        <FieldNumber
                                            minValue={0}
                                            thousandsSeparator={false}
                                            onChange={({ target }) =>
                                                handleInputChange({
                                                    field: 'loose',
                                                    index: index,
                                                    value: target.value,
                                                })
                                            }
                                            value={row?.loose || ''}
                                            disabled={disabled}
                                        />

                                    </div>

                                </td>
                                <td className={'center'}>

                                    <p>
                                        {formatValue({
                                            value: row.total,
                                        })}
                                    </p>

                                </td>
                            </tr>
                        ))}
                        
                        <tr>

                            <td></td>
                            <td className={'center total'} >{`Total Pacotes:  ${calculateTotalPackages(totalTable)}`}</td>
                            <td className={'center total'}  >{`Total Soltas: ${calculateTotalLoose(totalTable)}`}</td>
                            <td></td>

                        </tr>

                        <tr>
                            <td colSpan={5} className={'total-cash'}><strong>{`Total em dinheiro: ${returnTotalTable(totalTable)}`}</strong></td>
                        </tr>

                    </Table>
                </div>
            </div>
        </Section>
    );
}
