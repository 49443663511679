export enum BillToPayFixedTypeEnum {
  DAILY = "DAILY",
  WEAKLY = "WEAKLY",
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
}

export function BillToPayFixedTypeText(type: BillToPayFixedTypeEnum | string) {
  switch (type) {
    case BillToPayFixedTypeEnum.DAILY:
      return "Diária";
    case BillToPayFixedTypeEnum.WEAKLY:
      return "Semanal";
    case BillToPayFixedTypeEnum.MONTHLY:
      return "Mensal";
    case BillToPayFixedTypeEnum.YEARLY:
      return "Anual";
    default:
      throw new Error(`Not implemented for ${type}`);
  }
}
