import { useContext, useEffect, useRef, useState } from 'react';
import ScreenHeader from '../../components/logged/ScreenHeader';
import * as BillsService from '../../services/BillsService';
import Pagination from '../../components/Pagination';
import { formatValue } from '../../util/formatValue';
import ButtonGoto from '../../components/ButtonGoto';
import Table from '../../components/Table';
import { formatCurrency } from '../../components/FieldCurrency';
import { DateTime } from 'luxon';
import { AccountBankText, ENUM_SHORTCUT } from 'erva-doce-common';
import { getBillsToPayFormRoute } from '../bills-to-pay/BillsToPayForm';
import { getBillsToReceiveFormRoute } from '../bills-to-receive/BilsToReceiveForm';
import FieldSelect from '../../components/FieldSelect';
import { capitalize } from 'lodash';
import Button, { ButtonColor, ButtonStyle } from '../../components/Button';
import PermissionedLink from '../../components/PermissionedLink';
import { IconFilter, IconFilter2x } from '../../components/images';
import { getStoresFormRoute } from '../stores/StoreForm';
import { PermissionsEnum } from 'erva-doce-common';
import BillsFilterModal from './BillsFilterModal';
import './Bills.scss';
import Picture, { PictureSize, PictureStyle } from '../../components/Picture';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import ToolTip from '../../components/ToolTip';
import FieldDate from '../../components/FieldDate';

const INITIAL_FILTER = {
    page: 0,
    initDate: DateTime.now().startOf('month'),
    endDate: DateTime.now().endOf('month'),
    month: DateTime.now().month,
    startAt: DateTime.now().toLocal().toFormat('yyyy-MM-dd'),
    finishAt: DateTime.now().toLocal().toFormat('yyyy-MM-dd'),
};

export default function Bills() {
    const title = 'Conta corrente';
    const { selectedStore, addHotkey, removeHotkey } = useContext(EnvironmentContext);

    const [filter, setFilter] = useState(INITIAL_FILTER);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({});
    const [info, setInfo] = useState({});
    const [startAt, setStartAt] = useState(filter.startAt ?? '');
    const [finishAt, setFinishAt] = useState(filter.finishAt ?? '');
    const filterBtnRef = useRef();

    const fetchItems = async () => {
        try {
            setIsLoading(true);

            const res = await BillsService.get({
                ...filter,
            });

            setData(res);
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchAllInfo = async () => {
        try {
            setIsLoading(true);

            const res = await BillsService.getInfo({
                ...filter,
            });

            setInfo(res);
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchItems();
    }, [filter]);

    useEffect(() => {
        fetchAllInfo();
    }, [filter, selectedStore]);

    useEffect(() => {
        const shortcutFilter = addHotkey(ENUM_SHORTCUT.SHORTCUT_FILTER, () => {
            if (filterBtnRef.current) {
                filterBtnRef.current.click();
            }
        });

        return () => {
            removeHotkey(shortcutFilter);
        };
    }, []);

    return (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Financeiro', route: '/' },
                        { name: title, route: getBillsRoute() },
                    ]}
                />
            </div>
            <div className={'row'}>
                <div className={'col-12'}>
                    <div className={'purchase-table'}>
                        <table className={'table'}>
                            <thead>
                                <tr>
                                    <th>{}</th>
                                    <th>{}</th>
                                    <th>{}</th>
                                    <th>{}</th>
                                    <th>{'Saldo anterior'}</th>
                                    <th>{'Saldo atual'}</th>
                                    <th>{'Movimentos do dia'}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className={'d-flex align-items-center'}>
                                            <Picture
                                                picture={
                                                    selectedStore?.picture
                                                }
                                                name={selectedStore?.fantasyName}
                                                size={PictureSize.MAIN_MENU}
                                                style={PictureStyle.USER_SQUARE}
                                                editPicture={false}
                                            />
                                            <p className={'ml-8'}>
                                                <PermissionedLink
                                                    permission={
                                                        PermissionsEnum.HANDLE_PRODUCTS
                                                    }
                                                    to={getStoresFormRoute(selectedStore.uuid)}
                                                >
                                                    {selectedStore.fantasyName}
                                                </PermissionedLink>
                                            </p>
                                        </div>
                                    </td>
                                    <td>
                                        {selectedStore?.fantasyName}
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>{formatValue(info?.lastTotal)}</td>
                                    <td>{formatValue(info?.total)}</td>
                                    <td>{formatValue(info?.salesToday)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className={'row mb-32'}>
                <div className={'col-6'}>

                </div>
                <div className={'col-6'}>
                    <div className={'row'}>

                        <div className={'col-12 align-right mt-4'}>
                            <label className={'color-black mb-20'}>
                                {'Filtrar por período'}
                            </label>
                        </div>
                        <div className={'col-6'}>
                            <FieldDate
                                label={'Início'}
                                onChange={({ target }) => {
                                    setStartAt(target.value);
                                    setFilter((data) => ({
                                        ...data,
                                        startAt: target.value,
                                    }));
                                }}
                                value={startAt}
                            >
                            </FieldDate>
                        </div>

                        <div className={'col-6'}>
                            <FieldDate
                                label={'Término'}
                                onChange={({ target }) => {
                                    setFinishAt(target.value);
                                    setFilter((data) => ({
                                        ...data,
                                        finishAt: target.value,
                                    }));
                                }}
                                value={finishAt}
                                minDate={startAt}
                            >
                            </FieldDate>
                        </div>
                    </div>
                </div>
            </div>
            <div id={'bills-table'}>
                <Table
                    loading={isLoading}
                    currentSort={filter.order}
                    columns={[
                        {
                            name: 'Nome',
                        },
                        {
                            name: 'Emissão',
                            align: 'center',
                        },
                        {
                            name: 'N° Título',
                            align: 'center',
                        },
                        {
                            name: 'Parcela',
                            align: 'center',
                        },
                        {
                            name: 'Débito/Crédito',
                            align: 'center',
                        },
                        {
                            name: 'Vencimento',
                            align: 'center',
                        },
                        {
                            name: 'Pagamento',
                            align: 'center',
                        },
                        {
                            name: 'Conta',
                            align: 'center',
                        },
                        {
                            name: 'Ações',
                            align: 'end',
                        },
                    ]}
                >
                    {
                        data?.records?.length ? data?.records?.map((item, index) => (
                            <tr
                                className={'table-row'}
                                key={index}
                            >
                                <td className={'supplier'}>
                                    <div>
                                        {
                                            item.description ? (
                                                <ToolTip title={item.name} description={item.description} />
                                            ) : <p>{item.name}</p>
                                        }
                                        <p>
                                            {item.creditor?.fantasyName || item.supplier?.fantasyName || '-'}
                                        </p>
                                    </div>
                                </td>
                                <td className={'text-center'}>{DateTime.fromISO(item.issueDate).toFormat('dd/MM/yyyy')}</td>
                                <td className={'text-center'}>{item.numberDocument || '-'}</td>
                                <td className={'text-center'}>{`${item.currentInstallment}/${item.totalInstallments}`}</td>
                                <td className={`text-center ${item.type}`}>{item.type === 'billToPay' ? '-' : '+'}{formatCurrency(item.value.toFixed(2))}</td>
                                <td className={'text-center'}>{DateTime.fromISO(item.dueDate).toFormat('dd/MM/yyyy')}</td>
                                <td className={'text-center'}>{item.finishedAt ? DateTime.fromISO(item.finishedAt).toFormat('dd/MM/yyyy HH:mm') : '-'}</td>
                                <td className={'text-center'}>{item?.account ? `${AccountBankText(item?.account?.bank)} - ${item?.account?.accountNumber}` : '-'}</td>
                                <td className={'text-center'}>
                                    <div className={'d-flex justify-content-end'}>
                                        <ButtonGoto route={item.type === 'billToPay' ? getBillsToPayFormRoute(item.uuid) : getBillsToReceiveFormRoute(item.uuid)} />
                                    </div>
                                </td>
                            </tr>
                        )) : <tr><td colSpan={10} className={'text-center'}>{'Nenhum item encontrado'}</td></tr>
                    }
                    {
                        !!data?.records?.length && (
                            <tr className={'total-row'}>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className={'text-center'}>{formatValue(data.total)}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )
                    }
                </Table>
                <Pagination
                    page={data?.page}
                    pageSize={data?.pageSize}
                    count={data?.count}
                    recordCount={data?.records?.length || 0}
                    onPageChange={(page) => setFilter({ ...filter, page })}
                />
            </div>
        </>
    );
}

export function getBillsRoute() {
    return '/financeiro/conta-corrente';
}
