import { forwardRef } from 'react';
import FieldText from './FieldText';
import { ENUM_SHORTCUT } from 'erva-doce-common';

function FieldInteger({
    onConfirm,
    hasError,
    ...props
}, ref) {
    const handleKeyDown = (e) => {
        const modKey = e.shiftKey || e.ctrlKey || e.altKey || e.metaKey;
        const key = e.key;
        if (!modKey && key.length === 1 && (key < '0' || key > '9')) {
            e.preventDefault();
        } else if (key === ENUM_SHORTCUT.SHORTCUT_ENTER) {
            if (!hasError) {
                onConfirm();
            }
        }
    };

    return (
        <FieldText
            ref={ref}
            onKeyDown={(e) => handleKeyDown(e)}
            {...props}
        />
    );
}

export default forwardRef(FieldInteger);