import Table from '../../components/Table';
import { IconButtonGoto, IconButtonGoto2x } from '../../components/images';
import { formatValue } from '../../util/formatValue';
import { maskCnpjString } from '../../components/FieldCnpj';
import { OrderByOrderDocumentsEnum } from 'erva-doce-common';
import { fetchDanfePdf } from '../../services/DocumentsServices';
import PermissionedLink from '../../components/PermissionedLink';
import { PermissionsEnum } from 'erva-doce-common';
import { getSupplierFormRoute } from '../suppliers/SupplierForm';

export default function OrderDocumentsTable({
    data,
    isLoading,
    filter,
    onSortChange,
}) {

    async function fetchToPdf({ id }) {
        await fetchDanfePdf({ id });
    }

    return (
        <Table
            loading={isLoading}
            currentSort={filter.order}
            columns={[
                {
                    name: 'Emitente',
                    sortAsc: OrderByOrderDocumentsEnum.EMITENTE_ASC,
                    sortDesc: OrderByOrderDocumentsEnum.EMITENTE_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
                {
                    name: 'CNPJ',
                    align: 'center',
                    sortAsc: OrderByOrderDocumentsEnum.CNPJ_EMITENTE_ASC,
                    sortDesc: OrderByOrderDocumentsEnum.CNPJ_EMITENTE_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
                {
                    name: 'N° Nota',
                    align: 'center',
                    sortAsc: OrderByOrderDocumentsEnum.NUMBER_ASC,
                    sortDesc: OrderByOrderDocumentsEnum.NUMBER_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
                {
                    name: 'Dt emissão',
                    align: 'center',
                    sortAsc: OrderByOrderDocumentsEnum.EMITED_AT_ASC,
                    sortDesc: OrderByOrderDocumentsEnum.EMITED_AT_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
                {
                    name: 'Vencimento',
                    align: 'center',
                    sortAsc: OrderByOrderDocumentsEnum.DUE_AT_ASC,
                    sortDesc: OrderByOrderDocumentsEnum.DUE_AT_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
                {
                    name: 'Natureza',
                    align: 'center',
                },
                {
                    name: 'Total',
                    align: 'center',
                    sortAsc: OrderByOrderDocumentsEnum.TOTAL_ASC,
                    sortDesc: OrderByOrderDocumentsEnum.TOTAL_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
                {
                    name: 'Status',
                    align: 'center',
                },
                {
                    name: 'Ação',
                    align: 'center',
                },
                {
                    name: 'Visualizar PDF',
                    align: 'center',
                },
            ]}
        >
            {data?.map((document, index) => (
                <tr key={index}>
                    <td>
                        <PermissionedLink
                            permission={PermissionsEnum.HANDLE_SUPPLIERS}
                            to={getSupplierFormRoute(document.emitenteUuid)}
                        >
                            {document.emitente}
                        </PermissionedLink>
                    </td>
                    <td className={'text-center'}>
                        {maskCnpjString(document.cnpjEmitente)}
                    </td>
                    <td className={'text-center'}>{document.numberDocument}</td>
                    <td className={'text-center'}>{document.emitedAt}</td>
                    <td className={'text-center'}>{document.dueAt}</td>
                    <td className={'text-center'}>{document.natureza}</td>
                    <td className={'text-center'}>
                        {formatValue(document.total)}
                    </td>
                    <td className={'text-center'}>
                        {document.isLinked ? 'Vinculado' : 'Desvinculado'}
                    </td>
                    <td className={'text-center'}>
                        <div className={'d-flex justify-content-center'}>
                            <a
                                className={'transparent'}
                                href={document.fileUrl}
                                target={'_blank'}
                                rel={'noreferrer'}
                            >
                                <img
                                    src={IconButtonGoto}
                                    srcSet={`${IconButtonGoto} 1x, ${IconButtonGoto2x} 2x`}
                                    alt={'Acessar XML'}
                                    title={'Acessar XML'}
                                    style={{
                                        width: '24px',
                                    }}
                                />
                            </a>
                        </div>
                    </td>
                    <td className={'text-center'}>
                        <a onClick={() => fetchToPdf({ id: document.id })}>
                            <img
                                src={IconButtonGoto}
                                srcSet={`${IconButtonGoto} 1x, ${IconButtonGoto2x} 2x`}
                                alt={'Visualizar PDF'}
                                title={'Visualizar PDF'}
                                style={{
                                    width: '24px',
                                }}
                            />
                        </a>
                    </td>
                </tr>
            ))}
        </Table>
    );
}
