import nextId from 'react-id-generator';
import Field from './Field';
import { forwardRef, useRef } from 'react';
import { IconSelectArrow, IconSelectArrow2x } from './images';

function FieldSelect({
    options,
    validationError,
    required,
    placeholder,
    children,
    disableDefaultOption = true,
    disabled = false,
    className = '',
    ...props
}, ref) {
    const id = props.id ?? nextId('field-select');
    const idError = `${id}-error`;
    const fieldRef = useRef();

    function createPlaceholderOption() {
        if (placeholder) {
            return (
                <option key={'-1'} value={'-1'} disabled={disableDefaultOption}>
                    {placeholder}
                </option>
            );
        } else {
            return (<></>);
        }
    }

    function createOptions() {
        let array = [];
        for (const option of options) {
            array.push(
                <option key={option.id} value={option.id}>
                    {option.value}
                </option>
            );
        }
        return array;
    }

    let selectStyle = {};
    if (!disabled) {
        selectStyle.backgroundImage = `image-set(url(${IconSelectArrow}) 1x, url(${IconSelectArrow2x}) 2x)`;
    }

    if (validationError) {
        selectStyle.border = '1px solid #B92020';
    }
    
    return (
        <Field
            ref={fieldRef}
            {...props}
            idInput={id}
            idError={idError}
            bottomChildren={children}
            hasIcon={true}
            className={'has-icon small-icon '}
            validationError={validationError}
        >
            <select
                id={id}
                onChange={props?.onChange}
                value={props?.value}
                required={Boolean(required)}
                aria-required={Boolean(required)}
                aria-invalid={Boolean(validationError)}
                aria-errormessage={idError}
                style={selectStyle}
                disabled={disabled}
                className={className}
            >
                {createPlaceholderOption()}
                {createOptions()}
            </select>
        </Field>
    );
}

export default forwardRef(FieldSelect);
