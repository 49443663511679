import { useContext, useRef, useState } from 'react';
import { IconPaginationNext, IconPaginationNext2x } from './images';
import './ProfileDropDown.scss';
import classNames from 'classnames';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import useOutsideClick from '../hooks/useOutsideClick';
import Picture, { PictureSize } from './Picture';

export default function ProfileDropDown({ className }) {
    const containerRef = useRef(null);
    const { user, selectStore, selectedStore } = useContext(EnvironmentContext);

    const [active, setActive] = useState(false);

    const enableMultiSelection = user.isAdmin && !!user?.stores?.length;

    useOutsideClick(containerRef, () => setActive(false));

    return (
        <div className={classNames('wrapper', className)} ref={containerRef}>
            <div className={'profile-container'} onClick={() => setActive(!active)}>
                <div className={'profile-picture'}>
                    <Picture
                        picture={selectedStore?.picture}
                        name={selectedStore?.fantasyName}
                        size={PictureSize.MAIN_MENU}
                        editPicture={false}
                    />
                </div>
                <p>{selectedStore?.fantasyName}</p>
                {
                    enableMultiSelection ? (
                        <div className={classNames('profile-arrow', { active })}>
                            <img
                                src={IconPaginationNext}
                                srcSet={`${IconPaginationNext} 1x, ${IconPaginationNext2x} 2x`}
                                alt={'Abrir lojas'}
                                title={'Abrir lojas'}
                            />
                        </div>
                    ) : (<div></div>)
                }
            </div>
            {
                enableMultiSelection && active && (
                    <div className={'profile-dropdown'}>
                        <ul>
                            {
                                user?.stores?.map(store => (
                                    <li key={store.id}  title={'Selecionar loja'} onClick={() => {
                                        selectStore(store);
                                        setActive(false);
                                    }}>
                                        {store?.fantasyName}
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                )
            }
        </div>
    );
}
