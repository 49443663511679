import FieldLiveSearch from './FieldLiveSearch';
import { useContext, useEffect, useRef, useState } from 'react';
import { createCancelTokenSource } from '../util/api';
import * as MachinesService from '../services/MachinesService';
import { OrderByMachinesEnum } from 'erva-doce-common';
import { IconArrowDropdown } from './images';
import { EnvironmentContext } from '../contexts/EnviromentContext';

function FieldMachine({
    label,
    onSelected,
    select,
    ...props
}) {
    const cancelTokenSourceRef = useRef(null);
    const liveSearchRef = useRef(null);
    const [data, setData] = useState([]);
    const [value, setValue] = useState('');
    const [hasFocus, setHasFocus] = useState(false);
    const [isOptionsReady, setIsOptionsReady] = useState(false);
    const { addHotkey, removeHotkey } = useContext(EnvironmentContext);

    const activeElement = document.activeElement;
    const focusableElements = Array.from(
        document.querySelectorAll('input, [tabindex]:not([tabindex=\'-1\'])')
    );
    const indexElement = focusableElements.indexOf(activeElement);
    const validKeyRange = Array.from({ length: data.length }).map((_, index) => (index + 1).toString());

    const getAllOptions = async () => {
        cancelTokenSourceRef.current?.cancel();
        cancelTokenSourceRef.current = await createCancelTokenSource();
        const result = await MachinesService.getMachines(0, '',
            OrderByMachinesEnum.NAME_ASC, cancelTokenSourceRef.current.token);

        const mappedOptions = result.records.map((record, index) => ({
            id: record['uuid'],
            value: record['name'],
            type: record['type'],
            code: ++index,
        }));

        setData(mappedOptions);
        setIsOptionsReady(true);
    };

    async function fetchValue(uuid) {
        const result = await MachinesService.getMachines(0, uuid, OrderByMachinesEnum.NAME_ASC);
        return result['records']?.length ? result['records'][0].name : '';
    }

    async function fetch(searchStr, page) {
        cancelTokenSourceRef.current?.cancel();
        cancelTokenSourceRef.current = await createCancelTokenSource();
        const result = await MachinesService.getMachines(page, searchStr,
            OrderByMachinesEnum.NAME_ASC, cancelTokenSourceRef.current.token);

        const mappedOptions = result['records'].map((record, index) => ({
            id: record['uuid'],
            value: record['name'],
            type: record['type'],
            code: data.findIndex(x => x.id === record['uuid']) +1,
        }));

        return mappedOptions;
    }

    const handleKeyPress = (event) => {
        const { key } = event;
        if (indexElement === -1) return;

        if (validKeyRange.includes(key)) {
            const index = parseInt(key, 10) - 1;

            if (index < data.length && data[index]) {
                event.preventDefault();
                const selectedOption = data[index];
                onSelected(selectedOption);
                setValue(selectedOption.value);
            }
        }

        if (indexElement < focusableElements.length - 1) {
            focusableElements[indexElement + 1].focus();
        }
    };

    useEffect(() => {
        const shortcuts = [];
        if (hasFocus && activeElement.name !== 'payment-total' && activeElement.name !== 'payment-parcel') {
            validKeyRange.map((index) => {
                const shortcut = addHotkey(index.toString(), (event) => {
                    if (!isOptionsReady) return;
                    if (!hasFocus) return;
                    handleKeyPress(event);
                });
                shortcuts.push(shortcut);
            });
            return () => {
                shortcuts.map((shortcut) => removeHotkey(shortcut));
            };
        }
    }, [isOptionsReady, hasFocus]);

    useEffect(() => {
        getAllOptions();
    }, []);

    useEffect(() => {
        if (select?.value) {
            setValue(select.value);
        }
    }, [select]);

    return (
        <FieldLiveSearch
            ref={liveSearchRef}
            className={'field-machine'}
            label={label}
            fetchFn={fetch}
            fetchValueFn={fetchValue}
            onSelected={(e) => { onSelected(e); setValue(e?.value || ''); }}
            select={select}
            hasPagination={true}
            icon={IconArrowDropdown}
            icon2x={IconArrowDropdown}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            existingCode
            onFocus={() => setHasFocus(true)}
            onBlur={() => setHasFocus(false)}
            {...props}
        />
    );
}

export default FieldMachine;
