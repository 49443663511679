export enum VirtualOrderStatusEnum {
    RECEIVED = 'RECEIVED',
    PAYMENT_APPROVED = 'PAYMENT_APPROVED',
    SEPARATING = 'SEPARATING',
    NOTE_ISSUED = 'NOTE_ISSUED',
    SUBMITTED = 'SUBMITTED',
    DELIVERED = 'DELIVERED',
    CANCELLED = 'CANCELLED',
    COMPLETED = 'COMPLETED',
}

export function VirtualOrderStatus(status: VirtualOrderStatusEnum | string) {
    switch (status) {
        case VirtualOrderStatusEnum.RECEIVED:
            // I18N
            return "Recebido";
        case VirtualOrderStatusEnum.PAYMENT_APPROVED:
           // I18N
           return "Pagamento aprovado";
        case VirtualOrderStatusEnum.SEPARATING:
            // I18N
            return "Em separação";
        case VirtualOrderStatusEnum.NOTE_ISSUED:
            // I18N
            return "Nota emitida";
        case VirtualOrderStatusEnum.SUBMITTED:
            // I18N
            return "Enviado";
        case VirtualOrderStatusEnum.DELIVERED:
            // I18N
            return "Entregue";
        case VirtualOrderStatusEnum.CANCELLED:
            // I18N
            return "Cancelado";
        case VirtualOrderStatusEnum.COMPLETED:
            // I18N
            return "Pedido Concluido";
        default:
            throw new Error(`Not implemented for ${status}`)
    }
}

export const VirtualOrderStatusNextStatus = {
  [VirtualOrderStatusEnum.PAYMENT_APPROVED]: [
    VirtualOrderStatusEnum.RECEIVED,
    VirtualOrderStatusEnum.SEPARATING
  ],
  [VirtualOrderStatusEnum.SEPARATING]: [
    VirtualOrderStatusEnum.RECEIVED,
    VirtualOrderStatusEnum.PAYMENT_APPROVED,
    VirtualOrderStatusEnum.NOTE_ISSUED
  ],
  [VirtualOrderStatusEnum.NOTE_ISSUED]: [
    VirtualOrderStatusEnum.RECEIVED,
    VirtualOrderStatusEnum.PAYMENT_APPROVED,
    VirtualOrderStatusEnum.SEPARATING,
    VirtualOrderStatusEnum.SUBMITTED,
  ],
  [VirtualOrderStatusEnum.SUBMITTED]: [
    VirtualOrderStatusEnum.RECEIVED,
    VirtualOrderStatusEnum.PAYMENT_APPROVED,
    VirtualOrderStatusEnum.SEPARATING,
    VirtualOrderStatusEnum.NOTE_ISSUED,
    VirtualOrderStatusEnum.DELIVERED,
  ],
  [VirtualOrderStatusEnum.DELIVERED]: [
    VirtualOrderStatusEnum.RECEIVED,
    VirtualOrderStatusEnum.PAYMENT_APPROVED,
    VirtualOrderStatusEnum.SEPARATING,
    VirtualOrderStatusEnum.NOTE_ISSUED,
  ],
  [VirtualOrderStatusEnum.COMPLETED]: [
    VirtualOrderStatusEnum.RECEIVED,
    VirtualOrderStatusEnum.PAYMENT_APPROVED,
    VirtualOrderStatusEnum.SEPARATING,
    VirtualOrderStatusEnum.NOTE_ISSUED,
    VirtualOrderStatusEnum.DELIVERED
  ],
};
