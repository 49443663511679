import { useContext, useEffect, useRef, useState } from 'react';
import ScreenHeader from '../../../components/logged/ScreenHeader';
import { getDashboardRoute } from '../../../dashboard/Dashboard';
import { getStockCheckRoute } from './StockCheck';
import { useNavigate, useParams } from 'react-router';
import { getStockCheckDetailRoute } from './StockCheckDetail';
import { Loading } from '../../../components/Loading';
import * as StockCheckService from '../../../services/StockCheckService';
import { ProductStockCheckFilterTabEnum } from 'erva-doce-common';
import { EnvironmentContext } from '../../../contexts/EnviromentContext';
import './StockCheckForm.scss';
import FieldTextSearch from '../../../components/FieldTextSearch';
import FieldNumber from '../../../components/FieldNumber';
import Button, { ButtonColor, ButtonStyle } from '../../../components/Button';
import FieldDivergenceReason from '../../../components/FieldDivergenceReason';
import ErrorMessage from '../../../components/ErrorMessage';

function NewForm({ stockCheckId, title }) {
    const navigate = useNavigate();
    const inputSearchRef = useRef();
    const countRef = useRef();
    const { backendConnectionError } = useContext(EnvironmentContext);

    const [filter, setFilter] = useState({ search: '' });
    const [product, setProduct] = useState(null);
    const [formError, setFormError] = useState({});
    const [formData, setFormData] = useState({});

    const updateFormData = (value) => {
        setFormData(state => ({
            ...state,
            ...value,
        }));
    };

    const resetForm = () => {
        setFormData({});
        setProduct(null);
        inputSearchRef?.current?.focus();
    };

    const fetchProductsByBarCode = async () => {
        const notFound = () => {
            resetForm();
            setFormError({
                search: '* Produto ou ean não encontrado!',
            });
        };

        try {
            if (filter.search) {
                let prod = null;

                if (isNaN(filter.search)) {
                    prod = await StockCheckService.getProductsByName(stockCheckId, filter.search);
                } else {
                    prod = await StockCheckService.getProductByBarCode(stockCheckId, filter.search);
                }

                if (prod?.uuid) {
                    setProduct(prod);
                    setFormError({});
                } else {
                    notFound();
                }
            }
        } catch (err) {
            console.log(err);
            notFound();
        } finally {
            setFilter({
                ...filter,
                search: '',
            });
        }
    };

    const save = async (newItem) => {
        try {
            const response = await StockCheckService.createItem(stockCheckId, {
                product: product.uuid,
                ...formData,
            });

            if (response.status === ProductStockCheckFilterTabEnum.DIVERGENCE) {
                navigate(getStockCheckFormRoute(stockCheckId, response.uuid));
            } else {
                if (newItem) {
                    resetForm();
                } else {
                    navigate(getStockCheckDetailRoute(stockCheckId));
                }
            }
        } catch(err) {
            console.log(err);
            backendConnectionError('Fail to fetch info', err, null, title);
        }
    };

    useEffect(() => {
        inputSearchRef?.current?.focus();
    }, [inputSearchRef]);

    useEffect(() => {
        if (product && countRef?.current?.inputElement) {
            countRef?.current?.inputElement?.focus();
        }
    }, [product, countRef]);

    return (
        <>
            <div className={'col-12 mb-14'}>
                <label>
                    {'Produto'}
                </label>
                <FieldTextSearch
                    fieldGroup={true}
                    ref={inputSearchRef}
                    label={
                        '<em>Busque um <strong>produto</strong> ou <strong>ean</strong></em> [F1]'
                    }
                    value={filter.search}
                    onChangeDebounce={fetchProductsByBarCode}
                    onChange={({ target }) => {
                        setFilter({
                            ...filter,
                            search: target.value,
                        });
                    }}
                    className={'text_filter'}
                />
                {
                    formError?.search && (
                        <ErrorMessage 
                            errorMessage={formError?.search}
                            className={'mt-4 mb-4'}
                        />
                    )
                }
                
            </div>
            {
                product && (
                    <>
                        <div className={'product-info mb-14'}>
                            <table>
                                <thead className={'mb-24'}>
                                    <tr>
                                        <th>{'Código barras'}</th>
                                        <th>{'Nome do produto'}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{product?.barCodes?.find(x => x.main)?.barCode || '-'}</td>
                                        <td>{[product.name1,product.name2,product.name3].join(' ')}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={'col-8 col-md-4 mb-14'}>
                            <FieldNumber
                                ref={countRef}
                                label={'Quantidade de itens conferidos:'}
                                fieldGroup={true}
                                thousandsSeparator={false}
                                decimalLimit={0}
                                value={formData?.count}
                                onChange={({ target }) => {
                                    updateFormData({ count: Number(target.value) });
                                }}
                                validationError={formError?.count}
                            />
                        </div>
                    </>
                )
            }
            <div className={'col-12 col-md-3 mb-10'}>
                <Button
                    className={'w-100'}
                    buttonStyle={ButtonStyle.BUTTON_NORMAL}
                    color={ButtonColor.BUTTON_COLOR_ORANGE}
                    onClick={() => save(true)}
                    disabled={!product || !formData.count}
                >
                    {'Conferir novo item'}
                </Button>
            </div>
            <div className={'col-12 col-md-3 mb-10'}>
                <Button
                    className={'w-100'}
                    buttonStyle={ButtonStyle.BUTTON_NORMAL}
                    color={ButtonColor.BUTTON_COLOR_GREEN}
                    onClick={() => save(false)}
                    disabled={!product || !formData.count}
                >
                    {'Salvar e sair'}
                </Button>
            </div>
            <div className={'col-12 col-md-3 mb-10'}>
                <Button
                    className={'w-100'}
                    buttonStyle={ButtonStyle.BUTTON_NORMAL}
                    color={ButtonColor.BUTTON_COLOR_GRAY}
                    onClick={() => navigate(getStockCheckDetailRoute(stockCheckId))}
                >
                    {'Cancelar conferência'}
                </Button>
            </div>
        </>
    );
}

function DivergenceForm({ itemUUID, stockCheckId, title }) {
    const navigate = useNavigate();
    const { backendConnectionError } = useContext(EnvironmentContext);

    const [isLoading, setIsLoading] = useState(false);
    const [product, setProduct] = useState({});
    const [formData, setFormData] = useState({});
    const [formError, setFormError] = useState({});

    const updateFormData = (value) => {
        setFormData(state => ({
            ...state,
            ...value,
        }));
    };

    const validateForm = () => {
        let isValid = true;

        setFormError({});

        if (!formData.divergenceReason) {
            setFormError(state => ({ ...state, divergenceReason: 'Selecione um Motivo de divergência' }));
            isValid = false;
        }

        return isValid;
    };

    const save = async (newItem) => {
        if (!validateForm()) return;

        try {
            await StockCheckService.updateItem(stockCheckId, itemUUID, formData);

            if (newItem) {
                navigate(getStockCheckFormRoute(stockCheckId));
            } else {
                navigate(getStockCheckDetailRoute(stockCheckId));
            }
        } catch(err) {
            console.log(err);
            backendConnectionError('Fail to fetch info', err, null, title);
        }
    };

    const getItem = async () => {
        try {
            setIsLoading(true);

            const item = await StockCheckService.getItemByUUID(stockCheckId, itemUUID);

            setProduct(item);
        } catch(err) {
            console.log(err);
            backendConnectionError('Fail to fetch info', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getItem();
    }, [itemUUID]);

    return (
        <>
            <h1 className={'head'}>{'Divergência encontrada!'}</h1>
            <h2 className={'subhead mb-20'}>{'A quantidade inserida é diferente da quantidade anotada prevista em estoque'}</h2>
            {
                product && (
                    <>
                        <div className={'product-info mb-20'}>
                            <table>
                                <thead className={'mb-24'}>
                                    <tr>
                                        <th>{'Nome do produto'}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{product.productName}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={'product-divergence-info mb-30'}>
                            <div className={'product-divergence-info-item'}>
                                <p>{'Quantidade inserida'}</p>
                                <p>{product.count}</p>
                            </div>
                            <div className={'product-divergence-info-item'}>
                                <p>{'Quantidade prevista'}</p>
                                <p>{product.stock}</p>
                            </div>
                            <div className={'product-divergence-info-item footer'}>
                                <p>{'Divergência de quantidade'}</p>
                                <p className={'divergence-count'}>{product.count - Math.abs(product.stock) > 0 && '+'}{product.count - Math.abs(product.stock)}</p>
                            </div>
                        </div>
                        <div className={'col-8 col-md-4 mb-14'}>
                            <FieldDivergenceReason
                                placeholder={'Selecione um motivo'}
                                onSelected={(divergenceReason) => updateFormData({ divergenceReason })}
                                select={formData.divergenceReason}
                                validationError={formError.divergenceReason}
                            />
                        </div>
                    </>
                )
            }
            <div className={'col-12 col-md-3 mb-10'}>
                <Button
                    className={'w-100'}
                    buttonStyle={ButtonStyle.BUTTON_NORMAL}
                    color={ButtonColor.BUTTON_COLOR_ORANGE}
                    onClick={() => save(true)}
                    disabled={!product || !formData.divergenceReason}
                >
                    {'Conferir novo item'}
                </Button>
            </div>
            <div className={'col-12 col-md-3 mb-10'}>
                <Button
                    className={'w-100'}
                    buttonStyle={ButtonStyle.BUTTON_NORMAL}
                    color={ButtonColor.BUTTON_COLOR_GREEN}
                    onClick={() => save(false)}
                    disabled={!product || !formData.divergenceReason}
                >
                    {'Salvar e sair'}
                </Button>
            </div>
        </>
    );
}

export default function StockCheckForm() {
    const title = 'Conferência de produtos';
    const navigate = useNavigate();
    const { id, itemUUID } = useParams();
    const { backendConnectionError } = useContext(EnvironmentContext);

    const [hasDivergence, setHasDivergence] = useState(null);

    const verifyCheckState = async () => {
        try {
            if (itemUUID !== 'novo') {
                const item = await StockCheckService.getItemByUUID(id, itemUUID);

                if (item.status === ProductStockCheckFilterTabEnum.DIVERGENCE) {
                    setHasDivergence(true);
                } else {
                    navigate(getStockCheckFormRoute(id));
                }
            } else {
                const check = await StockCheckService.getById(id);

                if (check.hasDivergence) {
                    navigate(getStockCheckFormRoute(id, check.divergenceItemUUID));
                } else {
                    setHasDivergence(false);
                }
            }
        } catch(err) {
            console.log(err);

            if (err?.response?.status === 404) {
                navigate(getStockCheckDetailRoute(id));
            } else {
                backendConnectionError('Fail to fetch info', err, null, title);
            }
        }
    };

    useEffect(() => {
        verifyCheckState();
    }, [id, itemUUID]);

    return (
        <>
            <div className={'stock crud-list'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Produtos', route: getDashboardRoute() },
                        { name: 'Estoque' },
                        { name: 'Minhas conferências', route: getStockCheckRoute() },
                        { name: title, route: getStockCheckDetailRoute(id) },
                    ]}
                    backRoute={!hasDivergence && getStockCheckDetailRoute(id)}
                />
            </div>
            <div className={'stock-check-form'}>
                {
                    hasDivergence === null && <Loading />
                }
                {
                    hasDivergence && itemUUID !== 'novo' ? <DivergenceForm stockCheckId={id} itemUUID={itemUUID} title={title} /> : <NewForm stockCheckId={id} title={title} />
                }
            </div>
        </>
    );
}

export function getStockCheckFormRoute(id, itemUUID) {
    if (!id) return getStockCheckRoute();

    if (itemUUID) {
        return `/produtos/estoque/conferencias/${id}/${itemUUID}`;
    } else {
        return `/produtos/estoque/conferencias/${id}/novo`;
    }
}
