import api from '../util/api';

export async function getCashiers(
    page,
    search,
    order,
    cancelToken
) {
    return (await api.get('/cashier', {
        params: {
            page,
            search,
            order,
        },
        cancelToken: cancelToken
    })).data;
}

export async  function getByUuidCollaborator(uuid) {
    return (await api.get(`/cashier/collaborator/${uuid}`))?.data;
}

export async function  openCashier(uuid) {
    return (await api.put(`/cashier/${uuid}/open`))?.data?.response;
}

export async function getCashierByUuid (uuid) {
    return (await api.get(`/cashier/${uuid}`))?.data;
}

export async function removeCashier(uuid) {
    return (await api.delete(`/cashier/${uuid}`))?.data?.response;
}


export async function createCashier(formData) {
    return await api.post('/cashier', formData);
}

export async function editCashier(uuid, formData) {
    return await api.put(`/cashier/${uuid}`, formData);
}

