import { useEffect, useState } from 'react';
import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import { SaleChannelEnum, SaleChannelText } from 'erva-doce-common';
import FieldSelect from '../../components/FieldSelect';

export default function CustomerOrderFilterModal({
    show,
    onCancel,
    onConfirm,
    filter,
    updateFilters,
}) {
    const [formData, setFormData] = useState(filter);

    const confirm = () => {
        updateFilters((prev) => {
            return { ...prev, channel: formData.channel };
        });
        onConfirm();
    };

    const handleChannelOptions = () => {
        const options = [];
        for (const option in SaleChannelEnum) {
            options.push({
                id: option,
                value: SaleChannelText(option),
            });
        }

        return options;
    };

    useEffect(() => {
        setFormData(filter);
    }, [filter]);

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={onCancel}
            onConfirm={confirm}
            title={'Filtrar'}
        >
            <div className={'suggestion_modal'}>
                <FieldSelect
                    label={'Canal de vendas'}
                    options={handleChannelOptions()}
                    onChange={({ target }) => {
                        let channel = target.value;

                        if (target === '-1') {
                            channel = null;
                        }

                        setFormData({ channel: channel });
                    }}
                    value={formData?.channel || '-1'}
                    placeholder={'Selecione'}
                    disableDefaultOption={false}
                />
            </div>
        </SimpleConfirmModal>
    );
}
