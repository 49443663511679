import FieldLiveSearch from './FieldLiveSearch';
import { useRef, useState } from 'react';
import { createCancelTokenSource } from '../util/api';
import * as CollaboratorsService from '../services/CollaboratorsService';
import Link from './Link';
import { OrderByUsersEnum } from 'erva-doce-common';

function FieldCollaboratorSeller({
    label,
    modal,
    onSelected,
    select,
    ...props
}) {
    const cancelTokenSourceRef = useRef(null);
    const liveSearchRef = useRef(null);
    const [, setShowModal] = useState(false);

    async function fetchValue(uuid) {
        const result = await CollaboratorsService.getUserByCodeSeller(0, uuid, OrderByUsersEnum.NAME_ASC);
        return result['records']?.length ? result['records'][0].name : '';
    }

    async function fetch(code, page) {
        cancelTokenSourceRef.current?.cancel();
        cancelTokenSourceRef.current = await createCancelTokenSource();

        const result = await CollaboratorsService.getUserByCodeSeller(
            page, code, 
            OrderByUsersEnum.NAME_ASC, 
            cancelTokenSourceRef.current.token
        );

        return result['records'].map((record) => ({
            id: record['uuid'],
            value: record['name'],
            code: record['id'],
        }));
    }

    function showClick() {
        if (modal) {
            return (
                <Link
                    modal={modal}
                    onClick={()=>setShowModal(true)}
                />
            );
        } else {
            return (<></>);
        }
    }

    return (
        <>
            <FieldLiveSearch
                // I18N
                label={label}
                fetchFn={fetch}
                fetchValueFn={fetchValue}
                onSelected={onSelected}
                select={select}
                hasPagination={true}
                ref={liveSearchRef}
                modal={showClick}
                existingCode
                {...props}
            />
        </>
    );
}

export default FieldCollaboratorSeller;
