import { useNavigate, useParams } from 'react-router';
import Button, { ButtonColor, ButtonFontColor, ButtonStyle } from '../../components/Button';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as OrderReceptionService from '../../services/OrderReceptionService';
import { useContext, useEffect, useRef, useState } from 'react';
import Pagination from '../../components/Pagination';
import { getOrderReceiveRoute } from '.';
import OrderReceivesDetailTable from './OrderReceivesDetailTable';
import FieldTextSearch from '../../components/FieldTextSearch';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getHandleScanProductsRoute } from './HandleScanProducts';
import FieldDate from '../../components/FieldDate';
import FieldText from '../../components/FieldText';
import { BillingTypeText, OrderBillingTypeText } from 'erva-doce-common';
import FieldCurrency, { formatCurrency } from '../../components/FieldCurrency';
import Table from '../../components/Table';
import { InfoModalStyle } from '../../components/modal/InfoModal';

const INITIAL_FILTER = {
    page: 0,
    product: '',
};

export default function OrderReceiveDetail() {
    const title = 'Recebimento de mercadoria';

    const inputSearchRef = useRef();
    const { uuid } = useParams();
    const navigate = useNavigate();
    const { backendConnectionError, setConfirmModal, setInfoModal } = useContext(EnvironmentContext);

    const [filter, setFilter] = useState(INITIAL_FILTER);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({});
    const [search, setSearch] = useState('');
    const [orderReceive, setOrderReceive] = useState({});

    const updateFilter = (name, value) => {
        setFilter({
            ...filter,
            [name]: value,
        });
    };

    const fetchReceives = async () => {
        try {
            setIsLoading(true);
            const data = await OrderReceptionService.getItems(uuid, filter);

            setData(data);
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    const finish = async () => {
        const proceed = async () => {
            try {
                setIsLoading(true);
                await OrderReceptionService.finish(uuid);

                setInfoModal({
                    title,
                    message: 'Recebimento encerrado com sucesso!',
                    style: InfoModalStyle.SUCCESS,
                    show: true,
                    onClose: () => navigate(getOrderReceiveRoute()),
                });
            } catch (err) {
                console.log(err);
                backendConnectionError('Fail to finish orderReceive', err, null, title);
            } finally {
                setIsLoading(false);
            }
        };

        setConfirmModal({
            title,
            message: 'Você tem certeza que deseja finalizar o recebimento?',
            onConfirm: proceed,
            show: true,
        });
    };

    const fetchOrderReceive = async () => {
        try {
            setIsLoading(true);
            const data = await OrderReceptionService.getByUUID(uuid);

            setOrderReceive(data);
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchReceives();
    }, [filter]);

    useEffect(() => {
        fetchOrderReceive();
    }, []);

    return (
        <>
            <div className={'stock crud-list'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Compras', route: getDashboardRoute() },
                        { name: 'Pedidos' },
                        { name: title, route: getOrderReceiveRoute() },
                        { name: 'Detalhes', route: getOrderReceiveDetailRoute(uuid) },
                    ]}
                    hideStore
                />
                <div className={'row'}>
                    <div className={'purchase-detail-table'}>
                        <table className={'table'}>
                            <thead>
                                <tr>
                                    <th className={'text-start'}>{'Fornecedor'}</th>
                                    <th className={'text-center'}>{'Responsável'}</th>
                                    <th className={'text-center'}>{'Data'}</th>
                                    <th className={'text-center'}>{'Finalizado em'}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className={'supplier'}>
                                        <div>
                                            <p>{orderReceive.supplierName}</p>
                                            <p>{orderReceive.representativeName}</p>
                                        </div>
                                    </td>
                                    <td className={'text-center'}>{orderReceive.responsibleName}</td>
                                    <td className={'text-center'}>{orderReceive.createdAt}</td>
                                    <td className={'text-center'}>{orderReceive.finishedAt || '-'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={'row mb-32'}>
                    <div className={'col-6 col-md-4'}>
                        <FieldTextSearch
                            ref={inputSearchRef}
                            label={
                                '<em>Buscar por <strong>EAN</strong> ou <strong>nome</strong> do produto</em>'
                            }
                            onChangeDebounce={() =>
                                updateFilter('product', search)
                            }
                            onChange={(e) =>
                                setSearch(e.target.value)
                            }
                            value={search}
                            className={'text_filter'}
                        />
                    </div>
                    <div className={'col-0 d-none d-md-block col-md-6'}></div>
                    <div className={'col-6 col-md-2'}>
                        {
                            !isLoading && !orderReceive.finishedAt && (
                                <Button
                                    buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                    color={ButtonColor.BUTTON_COLOR_ORANGE}
                                    onClick={() => navigate(getHandleScanProductsRoute(uuid))}
                                    className={'w-100'}
                                >
                                    {'Retomar recebimento'}
                                </Button>
                            )
                        }
                    </div>
                </div>
                <div className={'Order_suggestion_list'}>
                    <OrderReceivesDetailTable
                        data={data?.records}
                        isLoading={isLoading}
                        filter={filter}
                    />
                    <Pagination
                        page={data?.page}
                        pageSize={data?.pageSize}
                        count={data?.count}
                        recordCount={data?.records?.length || 0}
                        onPageChange={(page) => setFilter({ ...filter, page })}
                    />
                </div>
            </div>
            <div className={'row mt-32 mb-32'}>
                <div className={'col-12'}>
                    <Table
                        loading={isLoading}
                        columns={[
                            {
                                name: 'Vencimento',
                                width: '10%',
                            },
                            {
                                name: 'Tipo do boleto',
                                width: '20%',
                            },
                            {
                                name: 'Valor',
                                align: 'center',
                                width: '10%',
                            },

                            {
                                name: 'Numeração',
                                align: 'center',
                                width: '10%',
                            },
                        ]}
                    >
                        {orderReceive?.billings?.length ? orderReceive?.billings?.map((billing, index) => (
                            <tr key={index}>
                                <td className={'count-selected text-start'}>
                                    <FieldDate
                                        fieldGroup={false}
                                        readOnly={true}
                                        value={billing.dueDate || ''}
                                    />
                                </td>
                                <td className={'count-selected text-center'}>
                                    <FieldText
                                        readOnly
                                        textAlign={'left'}
                                        fieldGroup={false}
                                        required={false}
                                        value={billing.type ? OrderBillingTypeText(billing.type) : '-'}
                                    />
                                </td>
                                <td className={'count-selected text-center'}>
                                    <FieldCurrency
                                        type={'text'}
                                        value={formatCurrency(`${billing?.total || ''}`, true)}
                                        disabled={true}
                                        fieldGroup={false}
                                        required
                                    />
                                </td>
                                <td className={'count-selected text-center'}>
                                    <FieldText
                                        fieldGroup={false}
                                        required={true}
                                        value={billing.documentNumber}
                                        readOnly={true}
                                    />
                                </td>
                            </tr>
                        )) : <tr><td colSpan={17} className={'text-center'}>{'Nenhum item encontrado'}</td></tr>}
                    </Table>
                </div>
            </div>
            <div className={'row d-flex justify-content-end'}>
                {
                    !orderReceive.finishedAt && (
                        <div className={'col-6 align-right update-purchase'}>
                            <Button
                                buttonStyle={ButtonStyle.BUTTON_NORMAL}
                                className={'ml-10'}
                                color={ButtonColor.BUTTON_COLOR_GREEN}
                                fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                                onClick={finish}
                            >
                                {'Encerrar recebimento'}
                            </Button>
                        </div>
                    )
                }
            </div>
        </>
    );
}

export function getOrderReceiveDetailRoute(uuid) {
    if (uuid) {
        return `/compras/recebimento-de-mercadorias/${uuid}`;
    } else {
        return '/compras/recebimento-de-mercadorias';
    }
}
