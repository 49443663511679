import FieldText, { FieldTextLabelStyle } from './FieldText';
import { IconSearch, IconSearch2x } from './images';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

function FieldTextSearch(
    {
        icon,
        icon2x,
        onChange,
        enableDebounce = true,
        onChangeDebounce,
        debounceTime = 700,
        value,
        ...props
    },
    ref
) {
    const [localSearch, setLocalSearch] = useState(value || '');
    const firstUpdate = useRef(true);

    const executeOnChangeDebounce = useCallback(() => {
        if (enableDebounce && !!onChangeDebounce) {
            onChangeDebounce(localSearch);
        }
    }, [localSearch, enableDebounce, onChangeDebounce]);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        
        const timeout = setTimeout(
            () => executeOnChangeDebounce(),
            debounceTime
        );
        return () => clearTimeout(timeout);
    },  [localSearch, debounceTime]);

    const handleChange = (e) => {
        const value = e.target.value;
        setLocalSearch(value);
        if (onChange) onChange(e);
    };

    return (
        <FieldText
            ref={ref}
            fieldGroup={false}
            labelStyle={FieldTextLabelStyle.LABEL_INLINE}
            icon={icon ?? IconSearch}
            icon2x={icon2x ?? IconSearch2x}
            onChange={handleChange}
            value={value}
            className={'has-icon'}
            {...props}
        />
    );
}

export default forwardRef(FieldTextSearch);
