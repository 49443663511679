import React, { useContext, useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import ScreenHeader from '../../../components/logged/ScreenHeader';
import { getDashboardRoute } from '../../../dashboard/Dashboard';
import FieldTextSearch from '../../../components/FieldTextSearch';
import { ENUM_SHORTCUT, OrderByVirtualOrdersEnum, VirtualOrderStatus } from 'erva-doce-common';
import axios from 'axios';
import * as VirtualOrderService from '../../../services/VirtualOrderService';
import LoadError from '../../../components/LoadError';
import Table from '../../../components/Table';
import Pagination from '../../../components/Pagination';
import { useNavigate } from 'react-router';
import { formatValue } from '../../../util/formatValue';
import { DateTime } from 'luxon';
import { EnvironmentContext } from '../../../contexts/EnviromentContext';
import Button, { ButtonColor, ButtonStyle } from '../../../components/Button';
import { IconFilter, IconFilter2x } from '../../../components/images';
import FilterModal from './FilterModal';
import { getVirtualOrderDetailRoute } from '../virtual-order-detail/VirtualOrderDetail';

const FILTERS = {
    order: OrderByVirtualOrdersEnum.ID_ASC,
    search: null,
    page: 0,
    status: null,
    customer: '',
    customerCpf: ''
};

export default function VirtualOrderList() {
    const inputSearchCustomerRef = useRef();
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [showFilterModal, setShowFilterModal] = useState(null);
    const [filter, setFilter] = useState(FILTERS);
    const [search, setSearch] = useState({ customer: '' });
    const { addHotkey, removeHotkey } = useContext(EnvironmentContext);

    const navigate = useNavigate();
    const handleRowClick = (route) => navigate(route);
    const filterBtnRef = useRef(); 

    useEffect(() => {
        fetchData();
    }, [filter]);

    const updateFilter = (name, value) => {
        setFilter({
            ...filter,
            [name]: value,
        });
    };

    useEffect(() => {
        const shortcutFilter = addHotkey(ENUM_SHORTCUT.SHORTCUT_FILTER, () => {
            if(filterBtnRef.current) {
                filterBtnRef.current.click();
            }
        });

        const shortCutSearchPrimary = addHotkey(ENUM_SHORTCUT.SHORTCUT_SEARCH_PRIMARY, () => {
            inputSearchCustomerRef.current?.focus();
        });

        return () => {
            removeHotkey(shortCutSearchPrimary);
            removeHotkey(shortcutFilter);
        };
    }, []);

    async function fetchData() {
        if (isLoading?.cancel) {
            isLoading.cancel();
        }
        try {
            const cancelToken = axios.CancelToken.source().token;
            setIsLoading(cancelToken);
            const response = await VirtualOrderService.getVirtualOrders(filter);
            setData(response);
            setIsLoading(null);
        } catch (e) {
            if (axios.isCancel(e)) {
                console.debug('Request cancelled.', e);
            } else {
                console.error(e);
                Sentry.captureException(e);
                setIsLoading(e);
                setData(null);
            }
        }
    }

    function renderTable() {
        if (isLoading && isLoading instanceof Error) {
            return (
                <LoadError
                    // I18N
                    message={'Não foi possível carregar os Motivos de cancelamento'}
                    onTryAgain={fetchData}
                />
            );
        }
    
        let rows = [];
        const records = data?.records ?? [];
        for (const s of records) {
            rows.push(
                <tr
                    key={s.id}
                    onClick={() => handleRowClick(getVirtualOrderDetailRoute(s.id))}
                >
                    <td>{`${s.id}`.padStart(7, '0')}</td>
                    <td>{`${s.customerName} - ${s.customerCpf}`}</td>
                    <td className={'text-center'}>{VirtualOrderStatus(s.status)}</td>
                    <td className={'text-center'}>{formatValue(s.total)}</td>
                    <td className={'text-center'}>
                        {DateTime.fromISO(s.createdAt).toFormat('dd/MM/yyyy')}
                    </td>
                    <td className={'text-center'}>
                        {DateTime.fromISO(s.updatedAt).toFormat('dd/MM/yyyy')}
                    </td>
                </tr>
            );
        }
    
        return (
            <>
                <Table
                    loading={isLoading}
                    currentSort={filter.order}
                    columns={[
                        {
                            name: 'N° Pedido',
                            sortAsc: OrderByVirtualOrdersEnum.ID_ASC,
                            sortDesc: OrderByVirtualOrdersEnum.ID_DESC,
                            onSortChange: (order) => updateFilter('order', order),
                        },
                        {
                            name: 'Cliente',
                            sortAsc: OrderByVirtualOrdersEnum.CUSTOMER_ASC,
                            sortDesc: OrderByVirtualOrdersEnum.CUSTOMER_DESC,
                            onSortChange: (order) => updateFilter('order', order),
                        },
                        {
                            name: 'Status',
                            align: 'center',
                            sortAsc: OrderByVirtualOrdersEnum.STATUS_ASC,
                            sortDesc: OrderByVirtualOrdersEnum.STATUS_DESC,
                            onSortChange: (order) => updateFilter('order', order),
                        },
                        {
                            name: 'Valor',
                            align: 'center',
                            sortAsc: OrderByVirtualOrdersEnum.TOTAL_ASC,
                            sortDesc: OrderByVirtualOrdersEnum.TOTAL_DESC,
                            onSortChange: (order) => updateFilter('order', order),
                        },
                        {
                            name: 'Data de criação',
                            align: 'center',
                            sortAsc: OrderByVirtualOrdersEnum.CREATED_DATE_ASC,
                            sortDesc: OrderByVirtualOrdersEnum.CREATED_DATE_DESC,
                            onSortChange: (order) => updateFilter('order', order),
                        },
                        {
                            name: 'Última atualização',
                            align: 'center',
                            sortAsc: OrderByVirtualOrdersEnum.LAST_UPDATE_ASC,
                            sortDesc: OrderByVirtualOrdersEnum.LAST_UPDATE_DESC,
                            onSortChange: (order) => updateFilter('order', order),
                        },
                    ]}
                >
                    {rows}
                </Table>
                <Pagination
                    page={data?.page}
                    pageSize={data?.pageSize}
                    count={data?.count}
                    recordCount={data?.records?.length}
                    onPageChange={(page) => setFilter({ ...filter, page })}
                />
            </>
        );
    }
    
    return (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    title={'Listagem de pedidos'}
                    breadcrumbs={[
                        { name: 'Loja virtual', route: getDashboardRoute() },
                        {
                            name: 'Pedidos',
                        },
                    ]}
                    hideStore
                />
                <div className={'row justify-content-end mb-32'}>
                    <div className={'col-3'}>
                        <FieldTextSearch
                            ref={inputSearchCustomerRef}
                            label={
                                `<em>Buscar por <strong>clientes [${ENUM_SHORTCUT.SHORTCUT_SEARCH_PRIMARY}]</strong></em>`
                            }
                            onChangeDebounce={() =>
                                updateFilter('customer', search.customer)
                            }
                            onChange={(e) =>
                                setSearch({
                                    ...search,
                                    customer: e.target.value,
                                })
                            }
                            value={search.customer}
                            className={'text_filter'}
                        />
                    </div>
                    <div className={'col-3'} />
                    <div className={'col-4'} />
                    <div className={'col-2'}>
                        <Button
                            buttonStyle={ButtonStyle.BUTTON_SHADOW}
                            color={ButtonColor.BUTTON_COLOR_ORANGE}
                            icon={IconFilter}
                            icon2x={IconFilter2x}
                            onClick={() => {
                                setShowFilterModal(true);
                            }}
                            ref={filterBtnRef}
                            className={'w-100'}
                        >
                            {`Filtrar [${ENUM_SHORTCUT.SHORTCUT_FILTER}]`}
                        </Button>
                    </div>
                </div>
                <div className={'table-scroll'}>{renderTable()}</div>
            </div>
            <FilterModal
                show={showFilterModal}
                onCancel={() => setShowFilterModal(false)}
                onConfirm={(filter) => {
                    setShowFilterModal(false);
                    setFilter(filter);
                }}
                filter={filter}
            />
        </>
    );
}

export function getVirtualOrderListRoute() {
    return '/loja-virtual/pedidos/listagem';
}
