import { DateTime } from 'luxon';
import { formatValue } from '../../../util/formatValue';
import { CreditCardFlagEnum, VirtualOrderStatusEnum, VirtualPaymentMethod, VirtualPaymentMethodEnum } from 'erva-doce-common';

export default function PaymentTab({ data }) {
    const payment = data?.payment;
    const paymentMethod = data?.paymentMethod 
        && VirtualPaymentMethod(data?.paymentMethod);
    const paymentApproved = data.statusHistory.find(s => s.status === VirtualOrderStatusEnum.PAYMENT_APPROVED);

    const parcel = data?.paymentMethod === VirtualPaymentMethodEnum.CREDIT_CARD 
        ?  (data?.parcel) : '-';
    const couponCode = data?.coupon?.code;

    const [month, year] = payment.validity ? payment.validity.split('-') : [null, null];
    const formattedData = (month && year) && DateTime.fromObject({
        month: parseInt(month),
        year: parseInt(year),
    }).toFormat('M/yy');

    const couponValue = () => {
        const coupon = data?.coupon;
        if (data.total >= coupon?.minValue) {
            if (coupon.type === 'fixed') {
                return coupon.value;
            } else {
                return (data.total * (coupon.value / 100));
            }
        }
    };

    const paymentText = () => {
        if (
            data.paymentMethod === VirtualPaymentMethodEnum.PIX ||
            data.paymentMethod === VirtualPaymentMethodEnum.BANK_SLIP
        ) {
            return `${formatValue(data.total)}`;
        }
        return `${formatValue(data.total)} ${parcel > 1 ? `em ${parcel}x de ${formatValue(parcel)}` : 'à vista'}`;
    };

    const getFlagCard = () => {
        if (data.paymentMethod === VirtualPaymentMethodEnum.CREDIT_CARD) {
            switch (payment?.flag) {
            case CreditCardFlagEnum.AMEX:
                return '/assets/icons/cartao-amex.svg';
            case CreditCardFlagEnum.DINERS:
                return '/assets/icons/cartao-diners-club.svg';
            case CreditCardFlagEnum.ELO:
                return '/assets/icons/cartao-elo.svg';
            case CreditCardFlagEnum.MAESTRO:
                return '/assets/icons/cartao-maestro.svg';
            case CreditCardFlagEnum.MASTERCARD:
                return '/assets/icons/cartao-mastercard.svg';
            case CreditCardFlagEnum.PAYPAL:
                return '/assets/icons/cartao-pay-pal.svg';
            case CreditCardFlagEnum.VISA:
                return '/assets/icons/cartao-visa.svg';
            }
        } else if (data.paymentMethod === VirtualPaymentMethodEnum.PIX) {
            return '/assets/icons/pix.svg';
        } else {
            return '/assets/icons/boleto.svg';
        }
    };
    const srcImg = getFlagCard();

    return (
        <div className={'virtual-order-form-container'}>
            <div className={'row'}>
                <div className={'col-4'}>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <h2 className={'mb-10'}>{'Detalhes'}</h2>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <div>
                                {paymentApproved?.createdAt ? 
                                    (
                                        <p>
                                            {'Pagamento realizado em '}
                                            <strong>
                                                {
                                                    DateTime.fromISO(
                                                        paymentApproved?.createdAt
                                                        || data?.createdAt
                                                    ).toFormat('dd/MM/yyyy')
                                                }
                                            </strong>{', '}
                                            {'pedido pago no '} <strong>{paymentMethod}</strong> {' '}
                                            {'no valor de '} <strong>{paymentText()}</strong>
                                        </p>
                                    ) : (<p>{'Pagamento realizado no'} <strong>{paymentMethod}</strong> {' - Aguardando aprovação'}</p>)
                                }
                                
                            </div>
                        </div>
                    </div>
                    <div className={'row mt-42'}>
                        <div className={'col-12'}>
                            <h2 className={'mb-10'}>{'Descontos'}</h2>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <div className={'mr-20'}>
                                <p><strong>{'Cupom'}</strong></p>
                                <p>{couponCode ? `${couponCode} - ${formatValue(couponValue() || 0)}` : formatValue(couponValue() || 0)}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'col-8'}>
                    {data?.payment ? 
                        (
                            <>
                                <div className={'row'}>
                                    <div className={'col-12'}>
                                        <h2 className={'mb-10'}>{'Método'}</h2>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col-12'}>
                                        <div className={'mr-20'}>
                                            <img 
                                                src={srcImg}
                                                width={60}
                                                height={60}
                                                alt={'bandeira'} 
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>   
                        ) : 
                        (<p>{'Não há dados para exibir'}</p>)
                    }
                    {data?.paymentMethod === VirtualPaymentMethodEnum.CREDIT_CARD && (
                        <div className={'row mt-42'}>
                            <div className={'col-12'}>
                                <div className={'payment-card'}>
                                    <p className={'payment-flag'}>{payment?.flag}</p>
                                    <p className={'payment-number'}>
                                        <span className={'asterisks'}>{'**** **** ****'}</span>
                                        <span className={'number'}>{payment?.number}</span>
                                    </p>
                                    <p className={'validity'}>
                                        {'Validade'}
                                        <br />
                                        {formattedData || '-'}
                                    </p>
                                    <p>{data.customerName.toUpperCase()}</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}