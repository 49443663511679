export enum EcommerceBagStatusEnum {
  ATIVO = "ATIVO",
  FINALIZADO = "FINALIZADO"
}

export function EcommerceBagStatusText(ecommerceBagStatus: EcommerceBagStatusEnum | string) {
    switch (ecommerceBagStatus) {
    case EcommerceBagStatusEnum.ATIVO:
        return "Ativo";
    case EcommerceBagStatusEnum.FINALIZADO:
        return "Finalizado";
    default:
        throw new Error(`Not implemented for ${ecommerceBagStatus}`);
    }
}