import './ImportFileModal.scss';
import SimpleConfirmModal from '../components/modal/SimpleConfirmModal';
import { useContext, useEffect, useRef, useState } from 'react';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import { FileDrop } from 'react-file-drop';
import { IconRoundedCheckGreen } from './images';
import ToolTip from './ToolTip';

function ImportFileModal({
    show,
    onCancel,
    onConfirm,
    onRequestUpload,
    title: _title,
    allowMultipleFile = false,
    accept = [],
    validationMessages,
}) {
    const title = _title || 'Enviar arquivo';
    const inputRef = useRef(null);
    const { backendConnectionError } = useContext(EnvironmentContext);

    const [files, setFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState([]);
    const [errors, setErrors] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [errorsFile, setErrorsFile] = useState(false);
    function cancel() {
        resetFiles();
        onCancel();
    }

    function confirm() {
        resetFiles();
        onConfirm();
    }

    async function handleUpload() {
        try {
            setIsUploading(true);
            setErrors([]);

            let index = -1;

            for (const file of files) {
                index++;

                try {
                    const data = new FormData();
                    data.append('file', file, file.name);

                    await onRequestUpload(data, (progressEvent) => calcPercent(index, progressEvent));
                } catch (err) {
                    handleErrorMessages(err, index);
                }
            }
        } catch (err) {
            backendConnectionError('Fail to import XML file', err, null, title, validationMessages);
        } finally {
            setIsUploading(false);
        }
    }

    const calcPercent = (index, progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

        setUploadProgress((state) => {
            const newUploadProgress = [...state];
            newUploadProgress[index] = percentCompleted;

            return newUploadProgress;
        });
    };

    const handleErrorMessages = (err, index) => {
        const { response } = err;
        let messages = '';
        if (response?.status === 422) {

            if (validationMessages) {
                messages = response.data.errors.map((error, index) => {
                    const rawMessage = validationMessages(error);

                    const complement = response?.data?.complement?.[index];

                    if (complement) {
                        const vars = rawMessage.match(/{{([^}}]+)\}}/g);
                        let message = rawMessage;

                        if (vars?.length) {
                            vars.forEach((item) => {
                                const key = item.replace(/{{|}}/g, '');

                                if (complement[key]) {
                                    message = message.replace(item, complement[key]);
                                }
                            });
                        }

                        return message;
                    }

                    return rawMessage;
                });
            } else {
                messages = response.data.errors.map((error) => {
                    return error.message;
                });
            }
        } else if (response?.status === 500) {
            messages = ['Ocorreu um erro na leitura do arquivo'];
        }

        setErrors((state) => {
            const newUploadProgress = [...state];
            newUploadProgress[index] = messages;

            return newUploadProgress;
        });
    };

    const handleSetFiles = (e) => {
        const filesList = [...e.target.files];
        const [type] = accept;
    
        const allowedTypes = type === 'text/xml' 
            ? ['text/xml'] 
            : ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    
        const allowedExtensions = accept.map(ext => ext.toLowerCase());
    
        const validFiles = [];
        const invalidFiles = [];
    
        filesList.forEach((file) => {
            const isValidType = allowedTypes.includes(file.type);
            const isValidExtension = allowedExtensions.some((ext) => 
                file.name.toLowerCase().endsWith(ext)
            );
    
            if (isValidType || isValidExtension) {
                validFiles.push(file);
            } else {
                invalidFiles.push(file.name);
            }
        });
    
        if (invalidFiles.length) {
            setErrorsFile(`Os seguintes arquivos não são permitidos: ${invalidFiles.join(', ')}`);
            return;
        }
    
        setFiles(validFiles);
        setErrorsFile(null);
        inputRef.current.value = null;
    };
    
    const handleDrop = (files, e) => {
        e.preventDefault(); 
        const event = { target: { files } };
        handleSetFiles(event); 
    };   
    
    const resetFiles = () => {
        setFiles([]);
        setUploadProgress([]);
        setErrors([]);
        setErrorsFile(false);
        setIsUploading(false);
        inputRef.current.value = null;
    };

    useEffect(() => {
        handleUpload();
    }, [files, errorsFile]);

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={cancel}
            onConfirm={confirm}
            disabled={isUploading}
            title={title}
            className={'import-file-modal'}
        >
            <FileDrop
                className={'mb-20'}
                onTargetClick={() => inputRef?.current?.click()}
                onDrop={(files, e) => handleDrop(files, e)} 
            >
                {'Clique aqui ou arraste os arquivos'}
            </FileDrop>
            <input
                ref={inputRef}
                type={'file'}
                accept={accept.length ? accept.join(',') : '*'}
                multiple={allowMultipleFile}
                onChange={handleSetFiles}
                className={'d-none'}
            />
            {errorsFile && (
                <>
                    <p className={'text-red'}>{'Erro'}</p>
                    <p className={'text-red'}>{'Há arquivos não suportados para essa página'}</p>
                </>
            )}
            {
                !!files.length && (
                    <>
                        {'Arquivos selecionados'}
                        <ul className={'files-list'}>
                            {
                                files.map((file, index) => (
                                    <li key={index}>
                                        <div className={'d-flex w-50 align-items-center'}>
                                            <p className={'file-name'}>{file.name}</p>
                                        </div>
                                        {
                                            isUploading && (
                                                <progress value={uploadProgress[index]} max={100} />
                                            )
                                        }
                                        {
                                            !isUploading && (
                                                errors?.[index]?.length ? (
                                                    <ToolTip
                                                        title={'Falha na importação!'}
                                                        titleClassName={'text-red'}
                                                        description={
                                                            <div>
                                                                {
                                                                    errors[index].map((error, j) =>
                                                                        <p key={j}>{'- '}{error}{';'}</p>
                                                                    )
                                                                }
                                                            </div>
                                                        }
                                                    />
                                                ) : (
                                                    <div className={'d-flex align-items-center'}>
                                                        {'Importado com sucesso!'}
                                                        <img
                                                            src={IconRoundedCheckGreen}
                                                            alt={'Check'}
                                                        />
                                                    </div>
                                                )
                                            )
                                        }
                                    </li>
                                ))
                            }
                        </ul>
                    </>
                )
            }
        </SimpleConfirmModal>
    );
}

export default ImportFileModal;
