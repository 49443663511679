import api from '../util/api';

export async function getCollaborators(
    page,
    search,
    jobsTitles,
    stores,
    order,
    collaboratorsActive,
    collaboratorsInactive,
    cancelToken,
) {
    return (await api.get('/users', {
        params: {
            page,
            search,
            order,
            jobsTitles,
            stores,
            collaboratorsActive,
            collaboratorsInactive,
        },
        cancelToken: cancelToken
    })).data; 
}

export async function getUsersCollaborators(
    page,
    search,
    jobsTitles,
    stores,
    order,
    collaboratorsActive,
    collaboratorsInactive,
    cancelToken,
) {
    return (await api.get('/users/collaborators', {
        params: {
            page,
            search,
            order,
            jobsTitles,
            stores,
            collaboratorsActive,
            collaboratorsInactive,
        },
        cancelToken: cancelToken
    })).data; 
}

export async function inviteCollaborator(body) {
    return (await api.put('/users', body))?.data?.response;
}

export async function resendInvite(uuid) {
    return (await api.post('/users/resend-invite', {
        uuid
    }))?.data?.response;
}

export async function getCollaborator(uuid) {
    return (await api.get(`/users/${uuid}`)).data;
}

export async function editCollaborator(uuid, body) {
    return (await api.put(`/users/${uuid}`, body))?.data?.response;
}

export async function editMe(body) {
    return (await api.put('/users/me', body))?.data?.response;
}

export async function getMyProfile() {
    return (await api.get('/users/me')).data;
}

export async function deleteCollaborator(uuid) {
    return (await api.delete(`/users/${uuid}`))?.data?.response;
}

export async function checkCollaboratorPassword(password) {
    return await api.post('/users/check-collaborator-password', 
        { 
            password
        }
    );
}

export async function getUserByCodeSeller(
    page,
    code,
    order,
    cancelToken,
) {
    const options = {
        params: { page, code, order },
        cancelToken: cancelToken
    };

    return (await api.get('/users/code-seller', { ...options })).data;
}
