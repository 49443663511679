import { useNavigate, useParams } from 'react-router';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import { getCampaignRoute } from './Campaign';
import { useContext, useEffect, useRef, useState } from 'react';
import FieldText from '../../components/FieldText';
import FieldPercentage, { extractPercentageNumber } from '../../components/FieldPercentage';
import FieldDate from '../../components/FieldDate';
import Button, { ButtonColor, ButtonFontColor } from '../../components/Button';
import FieldStore from '../../components/FieldStore';
import FieldCurrency, { extractCurrencyNumber, formatCurrency } from '../../components/FieldCurrency';
import FieldLiveSearchSelectionTable from '../../components/FieldLiveSearchSelectionTable';
import Section from '../../components/Section';
import SearchProductModal from '../../components/SearchProductModal';
import Table from '../../components/Table';
import { IconButtonRemove, IconButtonRemove2x } from '../../components/images';
import PermissionedLink from '../../components/PermissionedLink';
import * as CampaignService from '../../services/CampaignService';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { DateTime } from 'luxon';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import { ENUM_SHORTCUT, ValidationErrorCampaignText, PermissionsEnum } from 'erva-doce-common';
import { getProductFormRoute } from '../products/ProductsForm';
import emptyField from '../../util/validateEmptyTextField';

const INITIAL_STATE = {
    value: 100,
    name: '',
    description: '',
    startAt: '',
    endAt: '',
    isActive: false,
    stores: [],
    products: [],
};

export default function CampaignForm() {
    const title = 'Campanhas';
    const { uuid } = useParams();
    const isNew = !uuid;
    const navigate = useNavigate();
    const {
        addHotkey,
        removeHotkey,
        setInfoModal,
        setConfirmModal,
        backendConnectionError,
    } = useContext(EnvironmentContext);

    const [formData, setFormData] = useState(INITIAL_STATE);
    const [formError, setFormError] = useState({});
    const [showProductModal, setShowProductModal] = useState(false);
    const addProductBtnRef = useRef();
    const confirmBtnRef = useRef();
    const cancelBtnRef = useRef();


    const updateData = (value) => {
        setFormData(state => ({
            ...state,
            ...value,
        }));
    };

    const updateItem = (id, value) => {
        const newProducts = formData.products.map(x => x.uuid === id ? { ...x, ...value } : x);
        updateData({ products: newProducts });
    };

    const hasValidationError = () => {
        let hasError = false;
        setFormError({});

        const startAt = DateTime.fromFormat(formData.startAt, 'yyyy-MM-dd');
        const endAt = DateTime.fromFormat(formData.endAt, 'yyyy-MM-dd');

        if (!startAt.isValid || !endAt.isValid || startAt.startOf('day') >= endAt.startOf('day')) {
            hasError = true;
            setFormError(state => ({
                ...state,
                startAt: 'Preencha corretamente',
                endAt: 'Preencha corretamente',
            }));
        }

        if (!formData.value || formData.value === '100%') {
            hasError = true;
            setFormError(state => ({ ...state, value: 'Deve ser diferente de 100%' }));
        }

        if (!formData.stores.length) {
            hasError = true;
            setFormError(state => ({ ...state, store: 'Selecione pelo menos uma loja' }));
        }

        if (!formData.products.length) {
            hasError = true;
            setInfoModal({
                title,
                style: InfoModalStyle.INFO,
                message: 'Selecione pelo menos um produto',
                show: true,
            });
        }

        if (!formData.name || emptyField(formData.name)) {
            hasError = true;
            setFormError(state => ({ ...state, name: 'Preencha corretamente' }));
        }

        return hasError;
    };

    const save = async () => {
        if (hasValidationError()) return;

        const data = {
            ...formData,
            value: extractPercentageNumber(`${formData.value}`),
            products: formData.products.map(x => ({ ...x, value: x.value ? extractCurrencyNumber(x.value) : null })),
        };

        try {
            let message = '';

            if (isNew) {
                await CampaignService.create(data);
                message = 'Campanha criada com sucesso!';
            } else {
                await CampaignService.update(uuid, data);
                message = 'Campanha atualizada com sucesso!';
            }

            setInfoModal({
                title,
                message,
                show: true,
                style: InfoModalStyle.SUCCESS,
                onClose: () => navigate(getCampaignRoute()),
            });
        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to save campaing', err, null, title, ValidationErrorCampaignText);
        }
    };

    const destroy = async () => {
        const proceed = async () => {
            try {
                await CampaignService.destory(uuid);

                setInfoModal({
                    title,
                    message: 'Campanha cancelada com sucesso!',
                    style: InfoModalStyle.SUCCESS,
                    show: true,
                    onClose: () => navigate(getCampaignRoute()),
                });
            } catch (err) {
                console.log(err);
                backendConnectionError('Fail to destroy campaing', err, null, title, ValidationErrorCampaignText);
            }
        };

        setConfirmModal({
            title,
            message: 'Tem certeza de que deseja cancelar a campanha?',
            show: true,
            onConfirm: proceed,
        });
    };

    const fetchInfo = async () => {
        try {
            const data = await CampaignService.getByUUID(uuid);

            setFormData({
                ...formData,
                ...data,
                products: data.products.map(x => ({ ...x, value: x.value ? formatCurrency(`${x.value}`, true) : '' })),
            });
        } catch (err) {
            if(err?.response?.status === 404) {
                navigate(getCampaignRoute());
                return;
            }
            backendConnectionError('Fail to get campaing', err, null, title);
        }
    };

    useEffect(() => {
        if (uuid) {
            fetchInfo();
        }
    }, [uuid]);

    useEffect(() => {
        const shortcutCreatePrimary = addHotkey(ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY, () => {
            if (!formData.isActive && addProductBtnRef.current) {
                addProductBtnRef.current.click();
            }
        });

        return () => {
            removeHotkey(shortcutCreatePrimary);
        };
    }, [formData]);

    useEffect(() => {
        const shortcutConfirm = addHotkey(ENUM_SHORTCUT.SHORTCUT_CONFIRM, () => {
            if (confirmBtnRef.current) {
                confirmBtnRef.current.click();
            }
        });

        const shortcutCancel = addHotkey(ENUM_SHORTCUT.SHORTCUT_CANCEL, () => {
            if (cancelBtnRef.current) {
                cancelBtnRef.current.click();
            }
        });

        return () => {
            removeHotkey(shortcutConfirm);
            removeHotkey(shortcutCancel);
        };
    }, []);

    return (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Comercial', route: getDashboardRoute() },
                        { name: title, route: getCampaignRoute() },
                        { name: isNew ? 'Novo' : 'Editar', route: getCampaignFormRoute(uuid) },
                    ]}
                    backRoute={getCampaignRoute()}
                    hideStore
                />
                {
                    uuid && formData.isActive !== null && (
                        <div className={'row mb-16'}>
                            <div className={'col-12'}>
                                <p><b>{'Status da campanha: '}</b>{formData.isActive ? 'Ativa' : 'Inativa'}</p>
                            </div>
                        </div>
                    )
                }
                <div className={'row'}>
                    <div className={'col-4'}>
                        <FieldText
                            label={'Nome'}
                            onChange={({ target }) =>
                                updateData({
                                    name: target.value,
                                })
                            }
                            placeholder={'Nome da campanha'}
                            value={formData?.name}
                            validationError={formError?.name}
                        />
                    </div>
                    <div className={'col-4'}>
                        <FieldText
                            label={'Descrição'}
                            onChange={({ target }) =>
                                updateData({
                                    description: target.value,
                                })
                            }
                            placeholder={'Descrição da campanha'}
                            value={formData?.description}
                            validationError={formError?.description}
                        />
                    </div>
                    <div className={'col-4'}>
                        <FieldPercentage
                            label={'Porcentagem aplicada'}
                            placeholder={'Valor de porcentagem'}
                            onChange={({ target }) =>
                                updateData({
                                    value:
                                        target.value,
                                })
                            }
                            disabled={formData.isActive}
                            value={formData?.value}
                            validationError={
                                formError?.value
                            }
                        />
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-4'}>
                        <FieldDate
                            label={'Data de início'}
                            type={'text'}
                            onChange={({ target }) => updateData({ startAt: target.value })}
                            value={formData?.startAt}
                            validationError={formError?.startAt}
                            disabled={formData.isActive}
                        />
                    </div>
                    <div className={'col-4'}>
                        <FieldDate
                            label={'Data de fim'}
                            type={'text'}
                            onChange={({ target }) => updateData({ endAt: target.value })}
                            value={formData?.endAt}
                            validationError={formError?.endAt}
                        />
                    </div>
                    <div className={'col-4'}>
                        <FieldStore
                            label={'Lojas contempladas'}
                            multipleSelection={true}
                            onAddItem={formData.isActive ? () => { } : (store) => {
                                updateData({
                                    stores: [
                                        ...(formData.stores || []),
                                        {
                                            uuid: store.id,
                                            fantasyName: store.value,
                                        },
                                    ],
                                });
                            }}
                            validationError={formError?.store}
                            ignoreItems={formData?.stores?.map(
                                (store) => store.uuid
                            )}
                        >
                            <FieldLiveSearchSelectionTable
                                descriptionField={'fantasyName'}
                                value={formData.stores || []}
                                onRemove={formData.isActive ? null : (record) => {
                                    updateData({
                                        stores: formData.stores.filter(
                                            (item) =>
                                                item.uuid !== record.uuid
                                        ),
                                    });
                                }}
                            />
                        </FieldStore>
                    </div>
                </div>
                <Section title={'Produtos'} className={'mt-24 mb-24'}>
                    <div className={'d-flex justify-content-end'}>
                        <Button

                            className={'mb-24'}
                            title={'Adicionar produto'}
                            color={ButtonColor.BUTTON_COLOR_GREEN}
                            disabled={formData.isActive}
                            onClick={() => {
                                setShowProductModal(true);
                            }}
                            ref={addProductBtnRef}
                        >
                            {`Adicionar novo produto [${ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY}]`}
                        </Button>
                    </div>
                    <Table
                        loading={false}
                        columns={[
                            {
                                name: 'Código',
                            },
                            {
                                name: 'Nome',
                            },
                            {
                                name: 'Novo preço customizado',
                            },
                            {},
                        ]}
                    >
                        {
                            formData?.products?.map(product => (
                                <tr key={product.uuid}>
                                    <td>{product.barCodes.find(x => x.main)?.barCode}</td>
                                    <td>
                                        <PermissionedLink
                                            permission={PermissionsEnum.HANDLE_PRODUCTS}
                                            to={getProductFormRoute(product.uuid)}
                                        >
                                            {product.name}
                                        </PermissionedLink>
                                    </td>
                                    <td className={'count-selected text-center'}>
                                        <FieldCurrency
                                            fieldGroup={false}
                                            required={true}
                                            disabled={formData.isActive}
                                            thousandsSeparator={false}
                                            value={product.value}
                                            onChange={({ target }) =>
                                                updateItem(product.uuid, {
                                                    value: formatCurrency(target.value),
                                                })
                                            }
                                        />
                                    </td>
                                    <td className={'text-center'}>
                                        {
                                            !formData.isActive && (
                                                <Button
                                                    className={'transparent mt-8'}
                                                    onClick={() => {
                                                        updateData({
                                                            products: formData.products.filter(
                                                                (item) =>
                                                                    item.uuid !== product.uuid
                                                            ),
                                                        });
                                                    }}
                                                >
                                                    <img
                                                        src={IconButtonRemove}
                                                        srcSet={`${IconButtonRemove} 1x, ${IconButtonRemove2x} 2x`}
                                                        alt={'Remover produto'}
                                                        title={'Remover produto'}
                                                        style={{
                                                            width: '16px',
                                                        }}
                                                    />
                                                </Button>
                                            )
                                        }
                                    </td>
                                </tr>
                            ))
                        }
                    </Table>
                </Section>
                <div className={'row d-flex justify-content-end'}>
                    <div className={'col-6 align-right update-purchase'}>
                        <div>
                            <Button
                                ref={cancelBtnRef}
                                color={ButtonColor.BUTTON_COLOR_RED}
                                fontColor={
                                    ButtonFontColor.BUTTON_FONT_COLOR_LIGHT
                                }
                                onClick={uuid ? destroy : () => navigate(getCampaignRoute())}
                            >
                                {`Cancelar [${ENUM_SHORTCUT.SHORTCUT_CANCEL}]`}
                            </Button>
                        </div>
                        <div className={'ml-10'}>
                            <Button
                                ref={confirmBtnRef}
                                color={ButtonColor.BUTTON_COLOR_GREEN}
                                fontColor={
                                    ButtonFontColor.BUTTON_FONT_COLOR_LIGHT
                                }
                                onClick={save}
                            >
                                {`Salvar [${ENUM_SHORTCUT.SHORTCUT_CONFIRM}]`}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <SearchProductModal
                show={showProductModal}
                onCancel={() => setShowProductModal(false)}
                onSelect={(prod) => {
                    if (prod) {
                        updateData({
                            products: [
                                ...(formData.products || []),
                                {
                                    uuid: prod.uuid,
                                    name: prod.name1,
                                    barCodes: prod.barCodes,
                                    value: '',
                                },
                            ],
                        });
                        setShowProductModal(false);
                    }
                }}
            />
        </>
    );
}

export function getCampaignFormRoute(uuid) {
    if (uuid) {
        return `/comercial/campanhas/${uuid}`;
    }
    return '/comercial/campanhas/novo';
}
