import { OrderBySuppliersEnum } from 'erva-doce-common';
import { useRef } from 'react';
import * as ContactsService from '../services/ContactsService';
import * as SuppliersService from '../services/SuppliersService';
import * as CustomerService from '../services/CustomerService';
import { createCancelTokenSource } from '../util/api';
import FieldLiveSearch from './FieldLiveSearch';

function FieldContact({ onSelected, select, hasCutomer = false, ...props }) {
    const cancelTokenSourceRef = useRef(null);
    const liveSearchRef = useRef(null);

    function getValue(record) {
        if (record.isSupplier) {
            return (
                record.fantasyName ||
                record.socialReason ||
                'Fornecedor sem nome'
            );
        } else if (record.isContact) {
            return (
                record.fantasyName || record.socialReason || 'Contato sem nome'
            );
        } else if (record.isCustomer) {
            return (
                `${record.name} ${record.lastName}`.trim() || 'Cliente sem nome'
            );
        }
        return 'Registro sem nome'; // Fallback para casos inesperados
    }

    async function fetchValue(uuid) {
        const result = await SuppliersService.getSuppliers(
            0,
            uuid,
            null,
            OrderBySuppliersEnum.FANTASY_NAME_ASC
        );
        return result['records']?.length ? result['records'][0].name : '';
    }

    async function fetch(searchStr, page) {
        cancelTokenSourceRef.current?.cancel();
        cancelTokenSourceRef.current = await createCancelTokenSource();
        const resultContacts = await ContactsService.getContacts(
            page,
            searchStr,
            null,
            OrderBySuppliersEnum.FANTASY_NAME_ASC,
            cancelTokenSourceRef.current.token
        );
        const resultSuppliers = await SuppliersService.getSuppliers(
            page,
            searchStr,
            null,
            OrderBySuppliersEnum.FANTASY_NAME_ASC,
            cancelTokenSourceRef.current.token
        );
        const resultCustomers = await CustomerService.getAll({
            page,
            searchStr,
        });

        const results = [
            ...resultContacts.records.map((x) => ({ ...x, isContact: true })),
            ...resultSuppliers.records.map((x) => ({ ...x, isSupplier: true })),
            ...resultCustomers.records.map((x) => ({ ...x, isCustomer: true })),
        ]
            .sort((a, b) => b.fantasyName - a.fantasyName)
            .filter((data) => {
                if(hasCutomer) {
                    return data;
                }

                return !data.isCustomer;
            });
        
        return results.map((record) => ({
            id: record['uuid'],
            value: getValue(record),
            phone: record['phone'],
            email: record['email'],
            isSupplier: record['isSupplier'],
            isContact: record['isContact'],
            isCustomer: record['isCustomer'],
        }));
    }

    return (
        <FieldLiveSearch
            ref={liveSearchRef}
            label={'Contato'}
            fetchFn={fetch}
            fetchValueFn={fetchValue}
            onSelected={onSelected}
            select={select}
            hasPagination={true}
            {...props}
        />
    );
}

export default FieldContact;
