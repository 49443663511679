import React, { useContext, useEffect, useState } from 'react';
import SimpleConfirmModal from '../../../components/modal/SimpleConfirmModal';
import FieldText from '../../../components/FieldText';
import SwitchToggle from '../../../components/SwichToggle';
import { EnvironmentContext } from '../../../contexts/EnviromentContext';
import * as FeedbackService from '../../../services/FeedbackService';
import FieldTextarea from '../../../components/FieldTextarea';

export default function FeedbackFormModal({
    show,
    onCancel,
    onConfirm,
    initialData,
}) {
    const { backendConnectionError, setConfirmModal } = useContext(EnvironmentContext);
    const [formData, setFormData] = useState(initialData);
    const [formError, setFormError] = useState({});
    const title = (formData?.uuid ? 'Editar' : 'Criar') + ' Feedback';
    const [_, setIsLoading] = useState(false);

    const updateFormData = (value) => {
        setFormData(state => ({
            ...state,
            ...value,
        }));
    };

    const save = async () => {
        if (hasValidationError()) return;

        const proceed = async () => {
            try {
                setIsLoading(true);

                const data = { ...formData };
    
                if (initialData.uuid) {
                    await FeedbackService.update(initialData.uuid, data);
                } else {
                    await FeedbackService.create(data);
                }
    
                onConfirm(formData);
            } catch (err) {
                console.log(err);
                backendConnectionError('Fail to fetch save feedback', err, null, title);
            }
        };

        setConfirmModal({
            title: 'Salvar Feedback',
            message: 'Deseja realmente salvar o feedback?',
            show: true,
            onConfirm: proceed,
        });
    };

    const hasValidationError = () => {
        let hasError = false;
        setFormError({});

        if (!formData?.name) {
            hasError = true;
            setFormError(state => ({
                ...state,
                name: 'Preencha corretamente',
            }));
        }

        if (!formData?.comment) {
            hasError = true;
            setFormError(state => ({
                ...state,
                comment: 'Preencha corretamente',
            }));
        }

        if (!formData?.occupation) {
            hasError = true;
            setFormError(state => ({
                ...state,
                occupation: 'Preencha corretamente',
            }));
        }

        return hasError;
    };

    useEffect(() => {
        setFormError({});
        setFormData(initialData);
    }, [initialData]);

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={onCancel}
            onConfirm={save}
            confirmText={'Salvar'}
            title={title}
            className={'modal-feedback'}
        >
            <div className={'row'}>
                <div className={'col-12 d-flex align-items-center mb-16'}>
                    <SwitchToggle
                        defaultChecked={!!formData?.active}
                        label={'Feedback Ativo'}
                        onChange={() => {
                            updateFormData({ active: !formData?.active });
                        }}
                    />
                </div>
                <div className={'col-12'}>
                    <FieldText
                        label={'Nome'}
                        onChange={({ target }) => updateFormData({ name: target.value })}
                        value={formData?.name || ''}
                        validationError={formError?.name}
                    />
                </div>
                <div className={'col-12'}>
                    <FieldText
                        label={'Profissão'}
                        onChange={({ target }) => updateFormData({ occupation: target.value })}
                        value={formData?.occupation || ''}
                        validationError={formError?.occupation}
                        maxLength={255}
                    />
                </div>
                <div className={'col-12'}>
                    <FieldTextarea
                        label={'Comentário'}
                        value={formData?.comment}
                        onChange={({ target }) => updateFormData({ comment: target.value })}
                        validationError={formError?.comment}
                    />
                </div>
            </div>
        </SimpleConfirmModal>
    );
}