import * as Sentry from '@sentry/react';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import './SalesForm.scss';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { CashStateEnum, ENUM_SHORTCUT, formatCpfMask, MachineTypeEnum, PaymentsEnum, PermissionsEnum, TypeNFSaleEnum, unmaskCpf, ValidationErrorUserText } from 'erva-doce-common';
import RouteChangePrompt from '../../components/RouteChangePrompt';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { getSalesRoute } from './Sales';
import FieldInteger from '../../components/FieldInteger';
import FieldTextSearch from '../../components/FieldTextSearch';
import { InfoModalStyle } from '../../components/modal/InfoModal';

import * as ProductsService from '../../services/ProductsService';
import * as SalesService from '../../services/SalesService';
import * as FocusApiService from '../../services/FocusApiService';
import * as TefApiService from '../../services/TefApiService';
import * as CashiersService from '../../services/CashiersService';
import * as CustomerService from '../../services/CustomerService';
import * as ExchangesService from '../../services/ExchangesService';
import * as CashStateService from '../../services/CashStateService';
import * as CollaboratorsService from '../../services/CollaboratorsService';

import Button, { ButtonFontColor, ButtonStyle } from '../../components/Button';
import { extractCurrencyNumber } from '../../components/FieldCurrency';
import currency from 'currency.js';
import { formatValue } from '../../util/formatValue';
import { formatReal } from '../../util/formatReal';
import Amount from '../../components/Amount';
import CashierOpeningModal from './CashierOpeningModal';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import CustomersFormModal from '../customers/CustomersFormModal';
import SalesModal from './SalesModal';
import SearchProductModal from '../../components/SearchProductModal';
import SalesPaymentModal from './SalesPaymentModal';
import SearchExchangeModal from '../../components/SearchExchangeModal';
import ConfirmPrintNF from '../../components/ConfirmPrintNF';
import SalesProductTable from './SalesProductTable';
import {
    isEmptyFormData,
    orderProducts,
    totalItensReduce,
    totalPayableReduce,
    totalSaleReduce
} from './_utils';
import PasswordModal from './PasswordModal';

const emptyFormData = {
    payments: [],
};

const emptyPayments = {
    quantityInstallments: 1,
    amountInstallments: 'R$ 0,00',
    total: 'R$ 0,00',
    paymentType: null,
    machine: null,
    newRow: true,
};

const emptyCashState = {
    collaborator: null,
    cashier: null,
    status: CashStateEnum.OPEN,
    data: {
        formData: {},
        products: [],
    },
};

export default function SalesForm() {
    const title = 'Nova venda';

    const navigate = useNavigate();
    const inputAmount = useRef();
    const inputSearchRef = useRef();
    const inputSellerRef = useRef();
    const currencyRefs = useRef([]);

    const [amount, setAmount] = useState(1);
    const {
        backendConnectionError,
        setInfoModal,
        user,
        setConfirmModal,
        setLoading,
        addHotkey,
        removeHotkey,
    } = useContext(EnvironmentContext);
    const [saveLoading, setSaveLoading] = useState(false);
    const [salesForm, setSalesForm] = useState();
    const [exchangeData, setExchangeData] = useState();
    const [couponData, setCouponData] = useState();
    const [couponTotal, setCouponTotal] = useState(0);
    const [hasChange, setHasChange] = useState(false);
    const [formData, setFormData] = useState(emptyFormData);
    const [filter, setFilter] = useState({ search: '' });
    const [productsSelected, setProductsSelected] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showSaleModal, setShowSaleModal] = useState(true);
    const [showExchangeModal, setShowExchangeModal] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [showCashierModal, setShowCashierModal] = useState(false);
    const [saleUuid, setSaleUuid] = useState();
    const [showCustomerFormModal, setShowCustomerFormModal] = useState(false);
    const [showConfirmPrintNF, setShowConfirmPrintNF] = useState(false);
    const [typeNF, setTypeNF] = useState();
    const [, setFormError] = useState(emptyFormData);
    const [, setValidateOnChange] = useState(false);
    const [showPrintinvoice, setShowPrintinvoice] = useState(false);
    const [clearSaleData, setClearSaleData] = useState(false);
    const [cashStateData, setCashStateData] = useState(emptyCashState);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [, setPasswordForm] = useState({});

    const canEditableCustomer = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_CUSTOMERS);
    const exchangeTotal = exchangeData?.total || 0;
    const totalSale = totalSaleReduce(productsSelected);
    const totalPayable = totalPayableReduce(productsSelected, exchangeTotal, couponTotal);
    const totalItens = totalItensReduce(productsSelected);

    const addProduct = useCallback((product) => {
        const productFound = productsSelected.find(
            (cartProduct) => cartProduct.uuid === product.uuid
        );

        if (Number(amount) <= 0) {
            setInfoModal({
                title: 'Alerta',
                message: 'Quantidade inválida!',
                style: InfoModalStyle.ERROR,
                show: true,
                onClose: () => {
                    setFilter({ ...filter, search: '' });
                },
            });
            return;
        }

        setProductsSelected((prevProducts) => {
            const updatedProducts = !productFound
                ? [
                    ...prevProducts,
                    {
                        ...product,
                        amount: Number(amount),
                        total: product.price * Number(amount),
                    },
                ]
                : prevProducts.map((product) => {
                    if (productFound.uuid === product.uuid) {
                        return {
                            ...product,
                            amount: Number(product.amount) + Number(amount),
                            total: product.price * (Number(product.amount) + Number(amount)),
                        };
                    }
                    return product;
                });

            setCashStateData((prevState) => {
                const updatedState = {
                    ...prevState,
                    data: {
                        ...prevState.data,
                        products: updatedProducts,
                    },
                };
                return updatedState;
            });
            setHasChange(true);

            return orderProducts(updatedProducts);
        });
    },[amount, productsSelected]);

    const fetchProductsByBarCode = async () => {
        try {
            if (filter.search) {
                const prod = await ProductsService.getProductByBarCode(filter.search);
                if (prod.uuid) {
                    addProduct(prod, true);
                    setAmount(1);
                    setFilter({ ...filter, search: '' });
                } else {
                    setInfoModal({
                        title: 'Alerta',
                        message: 'Produto não encontrado na loja!',
                        style: InfoModalStyle.ERROR,
                        show: true,
                        onClose: () => {},
                    });
                    setFilter({ ...filter, search: '' });
                }
            }
        } catch (e) {
            if (axios.isCancel(e)) {
                console.debug('Request cancelled.', e);
            } else {
                console.error(e);
                Sentry.captureException(e);
            }
        }
    };

    async function fetchExchange(uuid) {
        try {
            const exchange = await ExchangesService.getExchange(uuid);

            if (exchange) {
                setExchangeData(exchange);
            }
        } catch (error) {
            const { response } = error;
            if (response?.status === 404) {
                setInfoModal({
                    title,
                    message: 'Devolução não encontrada.',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: navigate(getSalesRoute()),
                });
            } else {
                backendConnectionError(
                    'Fail to fetch exchange',
                    error,
                    null,
                    title
                );
            }
        }
    }

    function updateFormData(data) {
        // noinspection JSCheckFunctionSignatures
        setFormData((formData) => ({ ...formData, ...data }));

        setCashStateData((prevState) => {
            const updatedState = {
                ...prevState,
                data: {
                    ...prevState.data,
                    formData: { ...prevState.data?.formData, ...data },
                },
            };
            return updatedState;
        });
        setHasChange(true);
    }

    function addRowPayment() {
        setFormData(state =>{
            const payments = [...state.payments];
            const totalBuy = productsSelected.reduce(
                (sum, product) => sum + Number(product.total),0)
                - exchangeTotal
                - couponTotal;
            const totalAlreadyIncluded = payments.reduce((acc, cur) => acc + extractCurrencyNumber(cur.total), 0);
            const total = currency(totalBuy - totalAlreadyIncluded, { precision: 2 });

            payments.push({
                ...emptyPayments,
                amountInstallments: formatReal(total),
                paymentType: undefined,
                machine: undefined,
                total: formatReal(total),
                quantityInstallments: 1,
            });

            return { ...state, payments };
        });
    }

    function checkPaymentIsDinnerOnly(payments) {
        const dinnerOnly = true;
        for (let p of payments) {
            if (p.paymentType?.type != PaymentsEnum.DINNER) {
                return false;
            }
        }
        return dinnerOnly;
    }

    async function createCfe(sale) {
        const cfeInfos = {
            bodyCfe: null,
            apiResponse: null,
            chaveNfe: null,
            sale: null,
            isSuccess: false,
        };

        try {
            setLoading({ show: true });
            const cfeData = await FocusApiService.getCfeBody(sale);
            cfeInfos.sale = sale;
            cfeInfos.bodyCfe = cfeData;

            const response = await FocusApiService.createCfe(cfeData, sale);
            cfeInfos.apiResponse = response.data;
            cfeInfos.chaveNfe = response.data.chave_nfe;
            cfeInfos.isSuccess = true;

            await printNF(response.data.chave_nfe);

            setHasChange(false);
            let message = 'Venda salva com sucesso!';
            let style = InfoModalStyle.SUCCESS;
            setInfoModal({
                title,
                message,
                style,
                show: true,
                onClose: () => navigate(0),
            });
        } catch (e) {
            cfeInfos.apiResponse =
                e.response?.data != null ? e.response?.data : e;
            cfeInfos.isSuccess = false;
            setHasChange(false);
            let message =
                'Venda salva com sucesso! Erro ao emitir CFE: ' +
                (e.response?.data?.mensagem != undefined
                    ? e.response?.data?.mensagem
                    : e.message);
            setInfoModal({
                title,
                message,
                style: InfoModalStyle.SUCCESS,
                show: true,
                onClose: () => navigate(0),
            });
        } finally {
            try {
                await FocusApiService.saveResponseApi(cfeInfos);
            } catch (e) {
                console.log('Erro ao salvar dados do cfe: ' + e);
            }
            setLoading(false);
        }
    }

    async function createNFCe(sale) {
        try {
            let message = null;
            setLoading({ show: true });
            const response = await FocusApiService.createNFCe(sale);
            if (response.data) {
                printNF(response.data);
                message = 'Venda salva com sucesso!';
            } else {
                message = 'Venda salva com sucesso! Erro ao emitir NFCe.';
            }

            let style = InfoModalStyle.SUCCESS;
            setInfoModal({
                title,
                message,
                style,
                show: true,
                onClose: () => navigate(0),
            });
        } catch (error) {
            console.log(error);
            let message = 'Venda salva com sucesso! Erro ao emitir NFCe:';
            backendConnectionError(
                message,
                error,
                () => {
                    let style = InfoModalStyle.SUCCESS;
                    setInfoModal({
                        title,
                        message: 'Venda salva com sucesso! Erro ao emitir NFCe.',
                        style,
                        show: true,
                        onClose: () => navigate(0),
                    });
                },
                'Emitir Nota Fiscal',
                (error) => error
            );
        }
    }

    async function createNFSale(sale) {
        if (typeNF == TypeNFSaleEnum.CFE) {
            await createCfe(sale);
            return;
        }
        await createNFCe(sale);
        return;
    }

    async function printNF(chaveNfe) {
        if (
            (formData.chaveNFe && formData.typeNF == TypeNFSaleEnum.CFE) ||
            typeNF == TypeNFSaleEnum.CFE
        ) {
            const responseCfePdf = await FocusApiService.getCfePdf(chaveNfe);
            const pdfBlob = new Blob([responseCfePdf.data], {
                type: 'application/pdf',
            });
            const pdfUrl = URL.createObjectURL(pdfBlob);
            window.open(pdfUrl);
            return;
        }
        const responseNfcePdf = await FocusApiService.getNFCePdf(chaveNfe);
        const pdfBlob = new Blob([responseNfcePdf.data], {
            type: 'application/pdf',
        });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl);
        return;
    }

    function removeRowPayment(index) {
        const newPayments = [...formData.payments];

        newPayments.splice(index, 1);
        updateFormData({ payments: newPayments });
    }

    function hasValidationError() {
        let hasError = false;
        setFormError(emptyFormData);
        setValidateOnChange(true);
        return hasError;
    }

    async function saveSale(returnRoute) {
        if (saveLoading) return;
        if (hasValidationError()) return;

        const tefRequests = [];
        const totalBuy = productsSelected.reduce(
            (sum, product) => sum + Number(product.total),0)
                - exchangeTotal
                - couponTotal;
        const totalAlreadyIncluded = formData.payments.reduce((acc, cur) => acc + extractCurrencyNumber(cur.total), 0);

        if (totalBuy - totalAlreadyIncluded !== 0) {
            setInfoModal({
                show: true,
                title: 'Métodos de pagamento inválidos',
                message: 'Verifique os valores dos pagamentos com o total da compra e tente novamente!',
            });
            return;
        }

        try {
            setSaveLoading(true);
            setLoading(true);

            let i = 0;
            for (const payment of formData.payments) {
                if (payment.paymentType.type !== PaymentsEnum.DINNER) {
                    if (payment.machine.type === MachineTypeEnum.TEF) {
                        if (!payment.nsu && !payment.tefConfirmed) {
                            try {
                                const id = await SalesService.registerTefTransaction();
                                const tefRequest = {
                                    id,
                                    quantityInstallments: payment.quantityInstallments,
                                    total: extractCurrencyNumber(payment.total),
                                    paymentType: payment.paymentType.type,
                                };
                                const res = await TefApiService.createTransaction(
                                    tefRequest
                                );

                                if (res?.status !== 'APROVADA') {
                                    throw new Error('Pagamento Inválido');
                                }

                                const newPayment = {
                                    ...payment,
                                    tefRes: res,
                                    nsu: res.nsu,
                                    tefId: id,
                                };

                                setFormData((state) => {
                                    const payments = [...state.payments];
                                    payments[i] = newPayment;

                                    return {
                                        ...state,
                                        payments,
                                    };
                                });

                                tefRequests.push(newPayment);
                            } catch (err) {
                                setConfirmModal({
                                    show: true,
                                    title: 'Pagamento TEF',
                                    message: 'O pagamento falhou. Tentar novamente?',
                                    onConfirm: saveSale,
                                    onCancel: () => setShowPaymentModal(true),
                                    confirmText: 'Tentar novamente',
                                });
                                setLoading(false);
                                return;
                            }
                        } else {
                            tefRequests.push(payment);
                        }
                    } else {
                        tefRequests.push(payment);
                    }
                } else {
                    tefRequests.push(payment);
                }
                i++;
            }

            for (const payment of tefRequests) {
                try {
                    if (!payment.tefId) continue;
                    await TefApiService.confirmTransaction(
                        payment.tefId,
                        payment
                    );

                    setFormData((state) => {
                        return {
                            ...state,
                            payments: [...state.payments].map((x) =>
                                x.tefId === payment.tefId ? { ...x, tefConfirmed: true } : x,
                            )
                        };
                    });
                } catch (err) {
                    setConfirmModal({
                        show: true,
                        title: 'Pagamento TEF',
                        message: 'A confirmação do pagamento falhou. Tentar novamente?',
                        onConfirm: saveSale,
                        onCancel: () => setShowPaymentModal(true),
                        confirmText: 'Tentar novamente',
                    });
                    setLoading(false);
                    return;
                }
            }

            setLoading(false);

            const payments = [];
            for (const p of tefRequests) {
                const { paymentType, machine, total, amountInstallments } = p;
                const payment = {
                    ...p,
                    paymentType: paymentType?.id ?? null,
                    tefId: p?.tefId ?? null,
                    nsu: p?.nsu ?? null,
                    machine: machine?.id ?? null,
                    total: extractCurrencyNumber(total ?? ''),
                    amountInstallments: extractCurrencyNumber(
                        amountInstallments ?? ''
                    ),
                };
                payments.push(payment);
            }

            const body = {
                ...formData,
                payments,
                customerCpf: unmaskCpf(formData.customerCpf),
                printedNote: formData.printedNote ?? true,
                lineQuantity: productsSelected.length,
                itemsQuantity: productsSelected.reduce(
                    (sum, pro) => sum + Number(pro.amount), 0
                ),
                total: productsSelected.reduce(
                    (sum, pro) => sum + Number(pro.total), 0
                ) - exchangeTotal - couponTotal,
                cpfNote: unmaskCpf(formData.cpfNote),
                seller: salesForm?.seller?.id ?? null,
                exchange: formData.exchange ?? null,
                coupon: couponData?.uuid ?? null,
                products: productsSelected,
                cashier: user.uuid,
            };

            // new sale
            let saleUUID = await SalesService.addSale(body);
            setHasChange(false);
            setSaleUuid(saleUUID);
            const onlyDinner = await checkPaymentIsDinnerOnly(
                formData.payments
            );
            if (onlyDinner) {
                setShowConfirmPrintNF(true);
            } else {
                createNFSale(saleUUID);
            }

            if (saleUUID) {
                const cashData = {
                    ...cashStateData,
                    status: CashStateEnum.FINISHED,
                };
                await editCashState(cashData);
            }

            setHasChange(false);

            if (returnRoute) {
                setTimeout(() => {
                    navigate(getSalesRoute());
                }, 200);
            }
        } catch (error) {
            backendConnectionError(
                'Fail to create/edit sale',
                error,
                null,
                title
            );
            for (const payment of tefRequests) {
                if (!payment.tefId) continue;
                await TefApiService.cancelTransaction(
                    payment.tefId,
                    payment
                );
            }
        } finally {
            setSaveLoading(false);
        }
    }

    async function editCashState(data) {
        const { uuid } = user;
        try {
            return await CashStateService.editCashState(uuid, data);
        } catch (error) {
            console.error('Failed to edit cash:', error);
        }
    }

    async function getCashState() {
        try {
            const cashState = await CashStateService.getCashState(user.uuid);
            const cashFormData = cashState.data?.formData;

            if (!cashFormData) return;

            if (Object.keys(cashFormData).length > 0) {
                const customerData = {
                    customerCpf: cashFormData.customerCpf,
                    cpfNote: cashFormData.customerCpf,
                    seller: cashFormData.seller,
                };
                updateFormData({
                    customerData,
                    ...cashFormData,
                    payments: cashFormData?.payments || [],
                });

                if (cashFormData.customerCpf) {
                    setShowSaleModal(false);
                }
            }

            if (cashState.data?.products.length > 0) {
                setHasChange(false);
                setProductsSelected(cashState.data?.products);
            }

            setHasChange(false);
            setCashStateData({ ...cashState });
        } catch (error) {
            console.error('Failed to fetch data:', error);
        } finally {
            setDataLoaded(true);
        }
    }

    async function fetchCollaboratorData() {
        try {
            const collaboratorData =
                await CashiersService.getByUuidCollaborator(user.uuid);
            if (Object.keys(collaboratorData).length === 0) {
                setInfoModal({
                    title: 'Caixa não encontrado',
                    message: 'É necessário ter um caixa para realizar vendas. ',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: () => {
                        navigate(getDashboardRoute());
                    },
                });
                return;
            }
            await getCashState();

            switch (collaboratorData?.status) {
            case 'CLOSED':
                setShowCashierModal(true);
                setShowSaleModal(false);
                break;
            case 'OPEN':
                setShowCashierModal(false);

                break;
            default:
                break;
            }
            setTypeNF(collaboratorData.typeNF);
        } catch (error) {
            console.error('Failed to fetch collaborator data:', error);
        }
    }

    const handleRedeemingRefunds = () => {
        if (formData.customerCpf) {
            setShowExchangeModal(true);
        } else {
            setInfoModal({
                title: 'Resgatar Devolução',
                message: 'Necessário vincular cliente para resgatar devolução',
                style: InfoModalStyle.ERROR,
                show: true,
            });
        }
    };

    const handleFinalizeSale = () => {
        if (productsSelected.length) {
            if (!formData.payments.length) {
                addRowPayment();
            }
            setShowPaymentModal(true);
        }
    };

    const removeCustomerSale = () => {
        setSalesForm((saleForm) => ({
            ...saleForm,
            cpfNote: null,
            customerCpf: null,
        }));

        setExchangeData({});
        updateFormData({
            customerCpf: null,
            customerName: null,
            cpfNote: null,
            exchange: null,
        });
        setClearSaleData(true);
    };

    const onRemoveProduct = (uuid) => {
        setProductsSelected((prevState) => {
            return prevState.filter(
                (selectedProduct) =>
                    selectedProduct.uuid !== uuid
            );
        });

        setCashStateData((prev) => {
            const updateState = {
                ...prev,
                data: {
                    formData: { ...prev.data?.formData },
                    products: prev.data?.products.filter(
                        (selectedProduct) => selectedProduct.uuid !== uuid),
                }
            };

            return updateState;
        });

    };

    async function CustomerByCpf(cpf) {
        if (cpf) {
            const customer = await CustomerService.getCustomerByCpf(cpf);
            if (customer) {
                setFormData({
                    ...formData,
                    customerCpf: customer.cpf,
                    customerName: customer.name,
                });
            } else {
                setFormData({
                    ...formData,
                    customerCpf: cpf,
                });
            }
            setShowCustomerFormModal(true);
        }
    }

    function hasValidData(data) {
        for (let key in data) {
            if (!isEmptyFormData(data[key])) {
                return true;
            }
        }
        return false;
    }

    async function CheckCollaboratorPassword(password) {
        setPasswordForm({});
        if (password) {
            try {
                await CollaboratorsService.checkCollaboratorPassword(password);
                removeCustomerSale();
            } catch (error) {
                const { errors } = error.response.data;
                backendConnectionError(
                    'Fail to fetch password',
                    error,
                    null,
                    ValidationErrorUserText(errors[0])
                );
            }
        }
        setShowPasswordModal(false);
    }

    useEffect(() => {
        if (formData.exchange) {
            fetchExchange(formData.exchange);
        }
    }, [formData.exchange]);

    useEffect(() => {
        fetchCollaboratorData();
    }, [user.uuid, showSaleModal, showCashierModal]);

    useEffect(() => {

        hasValidationError();

        const shortcutConfirm = addHotkey(
            ENUM_SHORTCUT.SHORTCUT_CONFIRM,
            async () => {
                if (productsSelected.length) {
                    if (!formData.payments.length) {
                        addRowPayment();
                    }
                    setShowPaymentModal(true);
                }

                if (showSaleModal && salesForm) {
                    inputSearchRef?.current?.focus();

                    if (salesForm.customerCpf) {
                        updateFormData({ customerCpf: salesForm.customerCpf });
                    }
                    if (salesForm.seller) {
                        updateFormData({ seller: salesForm.seller });
                    }
                    setShowSaleModal(false);
                }

                if (
                    salesForm &&
                    salesForm.customerCpf &&
                    !showCustomerFormModal &&
                    !formData.customerCpf
                ) {
                    await CustomerByCpf(salesForm.customerCpf);
                }

                if (showPaymentModal && !showPrintinvoice) {
                    setShowPaymentModal(false);
                    saveSale(false);
                }

                if (!showPaymentModal && showPrintinvoice) {
                    setShowPrintinvoice(true);
                }
            }
        );

        let shortcutSearchSecondary;
        if (!showCustomerFormModal && !showSaleModal) {
            shortcutSearchSecondary = addHotkey(ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY,
                () => {
                    setShowModal(true);
                }
            );
        }

        const shortcutReatrieve = addHotkey(ENUM_SHORTCUT.SHORTCUT_RETRIEVE,
            () => {
                if (!showCustomerFormModal && !showSaleModal)
                    setShowExchangeModal(true);
            }
        );

        return () => {
            removeHotkey(shortcutConfirm);
            if (shortcutSearchSecondary) {
                removeHotkey(shortcutSearchSecondary);
            }
            removeHotkey(shortcutReatrieve);
        };
    }, [formData, productsSelected, salesForm, showCustomerFormModal]);

    useEffect(() => {
        getCashState();
    }, []);

    useEffect(() => {
        if (dataLoaded && hasValidData(formData) || productsSelected.length > 0) {
            editCashState(cashStateData);
        }
    }, [productsSelected, formData, dataLoaded]);

    return (
        <>
            <RouteChangePrompt
                enabled={hasChange}
                message={'Realmente deseja sair?'}
            />
            <div
                className={'crud-form sales-form'}
                style={{ height: 'calc(100vh - 40px)' }}
            >
                <div
                    className={'header'}
                    style={{ display: 'flex', flexDirection: 'column' }}
                >
                    <ScreenHeader
                        title={title}
                        breadcrumbs={[
                            { name: 'Vendas', route: getSalesRoute() },
                            { name: title },
                        ]}
                        backRoute={getSalesRoute()}
                    />
                </div>

                <div className={'mb-28'}>
                    <div className={'row align-items-center'}>
                        <div className={'col-4 d-flex align-items-center'}>
                            <div className={'quantity-field mr-4'}>
                                <p> {'Quantidade:'} </p>
                                <FieldInteger
                                    ref={inputAmount}
                                    value={amount}
                                    onChange={({ target }) =>
                                        setAmount(target.value)
                                    }
                                    thousandsSeparator={false}
                                    maxLength={4}
                                    height={'54px'}
                                    fieldGroup={false}
                                    className={'amount-field'}
                                />
                            </div>
                            <FieldTextSearch
                                ref={inputSearchRef}
                                label={`<em>Buscar <strong>produto</strong></em> [${ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY}]`}
                                value={filter.search}
                                onChange={({ target }) => {
                                    const value = target.value.replace(/\D/g, '');
                                    setFilter({
                                        ...filter,
                                        search: value,
                                    });
                                }}
                                onKeyDown={(ev) => {
                                    if (ev.key === ENUM_SHORTCUT.SHORTCUT_ENTER || ev.code === ENUM_SHORTCUT.SHORTCUT_ENTER) {
                                        fetchProductsByBarCode();
                                    }
                                }}
                                className={'text_filter'}
                            />
                        </div>

                        <div className={'col-3 d-flex align-items-center'}>
                            <div className={'mr-24'}>
                                {formData.customerCpf ? (
                                    <div>
                                        <span>
                                            <b>{'Cliente: '}</b>
                                            {`${
                                                formData.customerName
                                                    ? `${formData.customerName} - `
                                                    : ''
                                            }${
                                                formatCpfMask(formData.customerCpf)
                                            }`}
                                        </span>
                                        {!formData.customerCpf ||
                                            <span
                                                className={'remove-customer clickable ml-8'}
                                                onClick={() => setShowPasswordModal(true)}
                                            >
                                                <b>{'X'}</b>
                                            </span>
                                        }
                                    </div>
                                ) : (
                                    <div>
                                        <span
                                            className={'clickable'}
                                            onClick={() => setShowSaleModal(true)}
                                        >
                                            <b>
                                                <u>{'Vincular cliente'}</u>
                                            </b>
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className={'col-5 align-right'}>
                            <div className={'row col-12 align-right'}>
                                <div className={'col-4'}>
                                    <Button
                                        className={'w-100'}
                                        buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                        fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                                        onClick={() => handleRedeemingRefunds()}
                                        disabled={exchangeData?.products?.length}
                                    >
                                        {`Resgatar devolução [${ENUM_SHORTCUT.SHORTCUT_RETRIEVE}]`}
                                    </Button>
                                </div>
                                <div className={'col-4'}>
                                    <Button
                                        className={'w-100'}
                                        buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                        fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                                        onClick={() => handleFinalizeSale()}
                                        disabled={!productsSelected.length || totalPayable <= 0}
                                    >
                                        {`Finalizar venda [${ENUM_SHORTCUT.SHORTCUT_CONFIRM}]`}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <SalesProductTable
                    onRemoveProduct={onRemoveProduct}
                    updateFormData={updateFormData}
                    productsSelected={productsSelected}
                    exchangeData={exchangeData}
                    setExchangeData={setExchangeData}
                />

                <div className={'mt-8 row'}>
                    <div className={'col-12 totals__container'}>
                        <div className={'totals__wrapper mr-6'}>
                            <div className={'d-flex'}>
                                <Amount
                                    title={'Qtd produtos'}
                                    amount={productsSelected.length}
                                    radius={'12px 0px 0px 12px'}
                                    className={'amount-sale mr-6'}
                                    inline
                                />
                                <Amount
                                    title={'Total a pagar'}
                                    amount={formatValue(totalSale)}
                                    radius={'0px 0px 0px 0px'}
                                    className={'amount-sale'}
                                />
                            </div>
                            <div className={'d-flex mt-6'}>
                                <Amount
                                    title={'Qtd itens'}
                                    amount={totalItens}
                                    radius={'12px 0px 0px 12px'}
                                    className={'amount-sale mr-6'}
                                    inline
                                />
                                <Amount
                                    title={'Devolução'}
                                    amount={formatValue(exchangeTotal)}
                                    radius={'0px 0px 0px 0px'}
                                    className={'amount-sale'}
                                />
                            </div>
                        </div>
                        <div className={'totals__sale'}>
                            <p>{'Total da compra'}</p>
                            <span>{formatValue(totalPayable)}</span>
                        </div>
                    </div>
                </div>
            </div>

            <CashierOpeningModal
                cancelEsc={true}
                show={showCashierModal}
                setShowSaleModal={setShowSaleModal}
                setShowCashierModal={setShowCashierModal}
                inputSellerRef={inputSellerRef}
                onCancel={() => {
                    setShowCashierModal(false);
                    navigate(getDashboardRoute());
                }}
            />

            <CustomersFormModal
                setData={setFormData}
                show={showCustomerFormModal && !showCashierModal}
                nameIsRequired={true}
                emailIsRequired={true}
                addressIsRequired={false}
                disabledField={!canEditableCustomer}
                value={formData.customerCpf || formData.cpfNote}
                onCancel={() => {
                    updateFormData({
                        customerCpf: null,
                        customerName: null,
                    });
                    setShowCustomerFormModal(false);
                    inputSearchRef?.current?.focus();
                }}
                disabledCPF={true}
                onConfirm={(customer) => {
                    setShowCustomerFormModal(false);
                    if (customer) {
                        const customerData = {
                            customerCpf: customer.cpf,
                            customerName: customer.name,
                            customerId: customer.id,
                        };

                        updateFormData(customerData);
                        inputSearchRef?.current?.focus();
                    }
                }}
                cancelEsc={true}
            />

            <SalesModal
                inputSellerRef={inputSellerRef}
                show={showSaleModal}
                cancelEsc={true}
                onCancel={() => {
                    setShowSaleModal(false);
                    inputSearchRef?.current?.focus();
                }}
                onChangeForm={setSalesForm}
                onConfirm={(target) => {
                    const customerData = {
                        customerCpf: target.formData?.customerCpf,
                        cpfNote: target.formData?.customerCpf,
                        seller: target.formData?.seller,
                    };
                    updateFormData(customerData);

                    if (target.formData.customerCpf) {
                        CustomerByCpf(unmaskCpf(target.formData.customerCpf)).then(() => {
                            setShowCustomerFormModal(true);
                        });
                    }
                    setShowSaleModal(false);
                    setClearSaleData(false);
                    inputSearchRef?.current?.focus();
                }}
                clearData={clearSaleData}
            />

            <SearchProductModal
                cancelEsc={true}
                show={showModal && !showCashierModal}
                onCancel={() => setShowModal(false)}
                onSelect={(prod) => {
                    if (prod) {
                        addProduct(prod);
                        setAmount(1);
                        setShowModal(false);
                    }
                }}
            />

            <SalesPaymentModal
                cancelEsc={true}
                setFormData={setFormData}
                show={showPaymentModal && !showCashierModal}
                currencyRefs={currencyRefs}
                formData={formData}
                salesForm={salesForm}
                setSalesForm={setSalesForm}
                amount={productsSelected.reduce(
                    (sum, product) => sum + Number(product.total),0)}
                couponTotal={couponTotal}
                setCouponTotal={setCouponTotal}
                couponData={couponData}
                setCouponData={setCouponData}
                exchangeTotal={exchangeTotal}
                updateFormData={updateFormData}
                addRowPayment={addRowPayment}
                removeRowPayment={removeRowPayment}
                onCancel={() => setShowPaymentModal(false)}
                onConfirm={() => {
                    setShowPaymentModal(false);
                    saveSale(false);
                }}
            />

            <SearchExchangeModal
                cancelEsc={true}
                show={showExchangeModal}
                onCancel={() => setShowExchangeModal(false)}
                searchValue={formData?.customerCpf}
                onSelect={(target) => {
                    if (target) {
                        updateFormData({ exchange: target.uuid });
                        setShowExchangeModal(false);
                    }
                }}
            />

            <ConfirmPrintNF
                cancelEsc={true}
                show={showConfirmPrintNF}
                onCancel={() => {
                    setShowConfirmPrintNF(false);
                    setHasChange(false);
                    let message = 'Venda salva com sucesso!';
                    let style = InfoModalStyle.SUCCESS;
                    setInfoModal({
                        title,
                        message,
                        style,
                        show: true,
                        onClose: () => navigate(0),
                    });
                }}
                onConfirm={() => {
                    createNFSale(saleUuid);
                    setShowConfirmPrintNF(false);
                }}
            />

            <PasswordModal
                show={showPasswordModal}
                cancelEsc={true}
                onChangeForm={setPasswordForm}
                onCancel={() => {
                    setPasswordForm({ password: '' });
                    setShowPasswordModal(false);
                }}
                onConfirm={(target) => {
                    if (target.formData.password) {
                        CheckCollaboratorPassword(target.formData.password);
                    }
                }}
            />
        </>
    );
}

export function getSalesFormRoute() {
    return '/vendas/novo';
}
