export enum OrderStatusEnum {
  A_CONFERIR = "A_CONFERIR",
  CONFERIDO = "CONFERIDO",
  ENVIADO = "ENVIADO",
  NEGOCIACAO = "NEGOCIACAO",
  CONFIRMADO = "CONFIRMADO",
  CANCELADO = "CANCELADO",
  FATURADO = "FATURADO",
  ENTREGUE = "ENTREGUE",
  FINALIZADO = "FINALIZADO",
}

export function OrderStatus(orderStatus: OrderStatusEnum | string) {
    switch (orderStatus) {
    case OrderStatusEnum.A_CONFERIR:
        // I18N
        return "A conferir";
    case OrderStatusEnum.CONFERIDO:
        // I18N
        return "Conferido";
    case OrderStatusEnum.ENVIADO:
        // I18N
        return "Enviado";
    case OrderStatusEnum.NEGOCIACAO:
        // I18N
        return "Negociação";
    case OrderStatusEnum.CONFIRMADO:
        // I18N
        return "Confirmado";
    case OrderStatusEnum.CANCELADO:
        // I18N
        return "Cancelado";
    case OrderStatusEnum.FATURADO:
        // I18N
        return "Faturado";
    case OrderStatusEnum.ENTREGUE:
        // I18N
        return "Entregue";
    case OrderStatusEnum.FINALIZADO:
        // I18N
        return "Finalizado";
    default:
        throw new Error(`Not implemented for ${orderStatus}`);
    }
}

export const OrderStatusNextStatus = {
    [OrderStatusEnum.A_CONFERIR]: [
        OrderStatusEnum.CONFERIDO,
        OrderStatusEnum.CANCELADO,
    ],
    [OrderStatusEnum.CONFERIDO]: [
        OrderStatusEnum.ENVIADO,
        OrderStatusEnum.CANCELADO,
    ],
    [OrderStatusEnum.ENVIADO]: [
        OrderStatusEnum.CONFIRMADO,
        OrderStatusEnum.NEGOCIACAO,
        OrderStatusEnum.CANCELADO,
    ],
    [OrderStatusEnum.NEGOCIACAO]: [
        OrderStatusEnum.CONFIRMADO,
        OrderStatusEnum.ENVIADO,
        OrderStatusEnum.CANCELADO,
    ],
    [OrderStatusEnum.CONFIRMADO]: [
        OrderStatusEnum.FATURADO,
        OrderStatusEnum.CANCELADO,
    ],
    [OrderStatusEnum.FATURADO]: [
        OrderStatusEnum.ENTREGUE,
        OrderStatusEnum.CANCELADO,
    ],
    [OrderStatusEnum.ENTREGUE]: [ OrderStatusEnum.FINALIZADO ],
};
