import './style.scss';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import FieldCheckbox from '../../components/FieldCheckbox';
import FieldCurrency, {
    extractCurrencyNumber,
    formatCurrency,
} from '../../components/FieldCurrency';
import FieldPercentage from '../../components/FieldPercentage';
import FieldText from '../../components/FieldText';
import Button, { ButtonColor, ButtonFontColor } from '../../components/Button';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { getCuponsRoute } from '.';
import {
    IconButtonRemove,
    IconButtonRemove2x,
    IconButtonRemoveSvg,
} from '../../components/images';
import {
    createANewCoupon,
    getCouponByUUID,
    updateCoupon,
    getCouponUsage,
} from '../../services/CouponService';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import UserCPFModal from './UserCPFModal';
import { CouponTypeEnum, ENUM_SHORTCUT, EnumCouponStatusUser, isUuid } from 'erva-doce-common';
import Table from '../../components/Table';
import { getSalesDetailFormRoute } from '../sales/SalesDetailForm';
import SwitchToggle from '../../components/SwichToggle';
import classNames from 'classnames';

const initialState = {
    id: '',
    code: '',
    dtCriação: '',
    description: '',
    value: 0,
    minValue: 0,
    isActive: true,
    freeShipping: false,
    typeFixed: false,
    typePercent: true,
    isOnlyEcommerce: false,
    availableInStore: false,
};

export default function CumpomForm() {
    const navigate = useNavigate();
    const { uuid } = useParams();
    const { 
        setInfoModal, 
        addHotkey, 
        removeHotkey,
    } = useContext(EnvironmentContext);
    const [data, setData] = useState(initialState);
    const [formError, setFormError] = useState({});
    const [userList, setUserList] = useState([]);
    const [userCPFModal, setUserCPFModal] = useState(false);
    const [usageCouponInfo, setUsageCouponInfo] = useState([]);
    const [disabledAvailableInStore, setDisabledAvailableInStore] = useState(false);
    const cancelBtnRef = useRef();
    const saveBtnRef = useRef();

    const cancel = () => {
        navigate('/comercial/cupons');
    };

    const addUserList = (user) => {
        const data = [];
        data.push(user);
        setUserList((prev) => [...prev, user]);
    };

    const confirm = async () => {
        if (hasValidationError()) return;

        try {
            const formData = createObjectFormData();

            if (uuid) {
                await updateCoupon({ formData, uuid });
                setInfoModal({
                    title: 'Editar Cupom',
                    message: 'Cupom editado com sucesso!',
                    style: InfoModalStyle.SUCCESS,
                    show: true,
                    onClose: () => closeModal(),
                });

                return;
            }

            if (!uuid) {
                await createANewCoupon({ formData });
                setInfoModal({
                    title: 'Criar Cupom',
                    message: 'Cupom criado com sucesso!',
                    style: InfoModalStyle.SUCCESS,
                    show: true,
                    onClose: () => closeModal(),
                });

                return;
            }
        } catch (error) {
            console.error('Erro ao fazer a requisição', error);
            if (uuid) {
                setInfoModal({
                    title: 'Editar Cupom',
                    message: 'Erro ao editar cupom!',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: () => { },
                });
                return;
            }
            setInfoModal({
                title: 'Criar Cupom',
                message: 'Erro ao criar cupom!',
                style: InfoModalStyle.ERROR,
                show: true,
                onClose: () => { },
            });
        }
    };

    const closeModal = () => {
        setData(initialState);
        navigate(getCuponsRoute());
    };

    const createObjectFormData = () => {
        const dataType = { type: '' };
        const dataValue = { value: 0 };
        const dataMinValue = { minValue: 0 };

        if (data.typeFixed) {
            dataType.type = CouponTypeEnum.FIXED;
            dataValue.value = extractCurrencyNumber(data.value);
        }

        if (data.typePercent) {
            dataType.type = CouponTypeEnum.PERCENT;
            dataValue.value = parsePorcentage(data.value);
        }

        if (data.freeShipping) {
            dataType.type = CouponTypeEnum.FREE_SHIPPING;
        }

        if (data.minValue) {
            dataMinValue.minValue = extractCurrencyNumber(data.minValue);
        }

        const formData = {
            code: data.code,
            type: dataType.type,
            minValue: dataMinValue.minValue,
            value: dataValue.value,
            isActive: data.isActive,
            freeShipping: data.freeShipping,
            description: data.description,
            isOnlyEcommerce: data.isOnlyEcommerce,
            availableInStore: data.availableInStore,
            users: uuid ? userList : userList.filter(uL => uL.status !== EnumCouponStatusUser.DELETED),
        };
        return formData;
    };

    const parsePorcentage = (value) => {
        const newValue = String(value);
        return parseFloat(newValue.replace('%', '').replace(',', '.'));
    };

    const updateData = (value) => {
        setData((state) => ({
            ...state,
            ...value,
        }));
    };

    const hasValidationError = () => {
        let hasError = false;
        const errorMessage = 'Preencha corretamente';
        const regex = /^[a-zA-Z0-9]+$/;
        setFormError({});

        if (!data.code) {
            hasError = true;
            setFormError((state) => ({
                ...state,
                code: errorMessage,
            }));
        } else if (!regex.test(data.code)) {
            hasError = true;
            setFormError((state) => ({
                ...state,
                code: 'O código do cupom só aceita letras e números',
            }));
        }

        if (!data.typeFixed && !data.typePercent && !data.freeShipping) {
            hasError = true;
            setFormError((state) => ({
                ...state,
                typeCoupon: 'Escolha ao menos uma das opções acima',
            }));
        }

        if (data.typeFixed && extractCurrencyNumber(data.value) <= 0) {
            const msg = 'O valor do desconto deve ser maior que 0';
            hasError = true;
            setFormError((state) => ({
                ...state,
                valueTypeFixed: msg,
            }));
        }

        if (data.typeFixed && extractCurrencyNumber(data.minValue) <= 0) {
            const msg = 'O valor mínimo deve ser maior que 0';
            hasError = true;
            setFormError((state) => ({
                ...state,
                minValueTypeFixed: msg,
            }));
        }

        if (data.typePercent && extractCurrencyNumber(data.value) <= 0) {
            const msg = 'O valor da porcentagem deve ser maior que 0';
            hasError = true;
            setFormError((state) => ({
                ...state,
                valueTypePercent: msg,
            }));
        }

        if (data.typePercent && extractCurrencyNumber(data.minValue) <= 0) {
            const msg = 'O valor mínimo deve ser maior que 0';
            hasError = true;
            setFormError((state) => ({
                ...state,
                minValueTypePercent: msg,
            }));
        }

        if (data.freeShipping && extractCurrencyNumber(data.minValue) <= 0) {
            const msg = 'O valor mínimo do frete deve ser maior que 0';
            hasError = true;
            setFormError((state) => ({
                ...state,
                freeShipping: msg,
            }));
        }

        if (data.description.length === 0) {
            hasError = true;
            setFormError((state) => ({
                ...state,
                description: 'Por favor, insira uma descrição para este cupom.',
            }));
        }

        const verifyIsOnlyEcommerce = data.isActive && !data.isOnlyEcommerce;
        const verifyAvailableInStore = data.isActive && !data.availableInStore;

        if (verifyIsOnlyEcommerce && verifyAvailableInStore) {
            hasError = true;
            setFormError((state) => ({
                ...state,
                chanelUseCoupon:
                    'Por favor, selecione onde deseja aplicar o cupom.',
            }));
        }

        return hasError;
    };

    useEffect(() => {
        const shortcutCancel = addHotkey(ENUM_SHORTCUT.SHORTCUT_CANCEL, () => {
            if (cancelBtnRef.current) {
                cancelBtnRef.current.click();
            }
        });

        const shortcutConfirm = addHotkey(
            ENUM_SHORTCUT.SHORTCUT_CONFIRM,
            () => {
                if (saveBtnRef.current) {
                    saveBtnRef.current.click();
                }
            }
        );

        return () => {
            removeHotkey(shortcutConfirm);
            removeHotkey(shortcutCancel);
        };
    }, []);

    const getCoupon = useCallback(async () => {
        try {
            if (!uuid) return;
            const coupon = await getCouponByUUID({ uuid });
            setData({
                ...coupon,
                minValue: formatCurrency(`${coupon?.minValue?.toFixed(2)}`),
                value: coupon?.typeFixed
                    ? formatCurrency(`${coupon?.value?.toFixed(2)}`)
                    : coupon?.value,
            });
            setUserList(coupon?.userList);
        } catch (error) {
            if(error?.response?.status === 404) {
                navigate(getCuponsRoute());
                return;
            }
        }
    }, [uuid]);

    const getCouponUsageApi = useCallback(async () => {
        if (!uuid) return;
        const result = await getCouponUsage({ couponUuid: uuid });
        setUsageCouponInfo(result);
    }, [uuid]);

    async function removeUserFromThisCoupon({ id }) {
        const updateStateOfUser = userList.map((user) => {
            if (user.id === id) {
                return { ...user, status: EnumCouponStatusUser.DELETED }; // Cria um novo objeto
            }
            return user;
        });
        setUserList(updateStateOfUser);
    }

    useEffect(() => {
        if(uuid) {
            getCoupon();
            getCouponUsageApi();
        }
    }, [uuid]);

    useEffect(() => {
        if (data.freeShipping && !disabledAvailableInStore) {
            setDisabledAvailableInStore(true);
            updateData({ availableInStore: false });
        }

        if (!data.freeShipping && disabledAvailableInStore) {
            setDisabledAvailableInStore(false);
        }
    }, [data.freeShipping, data.typeFixed, data.typePercent]);

    const title = uuid ? 'Editar Cupom' : 'Criar Cupom';
    return (
        <div>
            <ScreenHeader
                title={title}
                breadcrumbs={[
                    { name: 'Comercial', route: getCuponsRoute() },
                    { name: title, route: getCuponsRoute() },
                ]}
                hideStore
            />

            <fieldset>
                <legend>{'Informações do Cupom'}</legend>
            </fieldset>

            <div className={'crud-list-coupom-form '}>
                <div className={'row p-8'}>
                    <div className={'col-6'}>
                        <div className={'row'}>
                            <div className={'col-12'}>
                                <label className={'field-label'}>
                                    {'Status do cupom'}
                                </label>
                                <div className={'container-switch'}>
                                    <div className={'switches'}>
                                        <SwitchToggle
                                            defaultChecked={!!data?.isActive}
                                            label={'Cupom ativo'}
                                            onChange={(isActive) => {
                                                updateData({
                                                    isActive: isActive,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={'col-12 mt-20'}>
                                <label className={'field-label'}>
                                    {'Disponibilidade do cupom'}
                                </label>
                                <div className={'container-switch'}>
                                    <div className={'switches'}>
                                        <SwitchToggle
                                            defaultChecked={
                                                !!data?.isOnlyEcommerce
                                            }
                                            label={'Disponível no E-Commerce'}
                                            onChange={(isActive) => {
                                                updateData({
                                                    isOnlyEcommerce: isActive,
                                                });
                                            }}
                                        />
                                        <SwitchToggle
                                            defaultChecked={
                                                !!data?.availableInStore
                                            }
                                            label={'Disponível na loja física'}
                                            disabled={disabledAvailableInStore}
                                            onChange={(isActive) => {
                                                updateData({
                                                    availableInStore: isActive,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                                {formError.chanelUseCoupon && (
                                    <div
                                        className={classNames(
                                            'custom-validation-error mb-6'
                                        )}
                                    >
                                        <p>{formError.chanelUseCoupon}</p>
                                    </div>
                                )}
                            </div>
                            <div className={'col-12 mt-6'}>
                                <div>
                                    <FieldText
                                        label={'Insira o código do cupom'}
                                        onChange={({ target }) =>
                                            updateData({
                                                code: target.value.toUpperCase(),
                                            })
                                        }
                                        placeholder={'CUPOM20'}
                                        value={data?.code}
                                        validationError={formError?.code}
                                    />
                                </div>
                            </div>
                            <div className={'col-12'}>
                                <div>
                                    <FieldText
                                        label={
                                            'Adicione uma breve descrição do cupom'
                                        }
                                        onChange={({ target }) =>
                                            updateData({
                                                description: target.value,
                                            })
                                        }
                                        value={data?.description}
                                        validationError={formError?.description}
                                        maxLength={255}
                                        placeholder={'Ex: Cupom do natal'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'col-6'}>
                        <div className={'row'}>
                            <div className={'col-12'}>
                                <FieldCheckbox
                                    label={'Tipo de desconto'}
                                    inputs={[
                                        {
                                            type: 'radio',
                                            label: 'Percentual',
                                            value: data?.typePercent,
                                            checked: !!data?.typePercent,
                                            onChange: () => {
                                                updateData({
                                                    typePercent:
                                                        !data?.typePercent,
                                                    typeFixed: false,
                                                    freeShipping: false,
                                                    value: extractCurrencyNumber(
                                                        `${data.value || 0}`
                                                    ),
                                                });
                                            },
                                        },
                                        {
                                            type: 'radio',
                                            label: 'Fixo',
                                            value: data?.typeFixed,
                                            checked: !!data?.typeFixed,
                                            onChange: () => {
                                                const numValue = Number(
                                                    data.value
                                                );
                                                updateData({
                                                    typeFixed: !data?.typeFixed,
                                                    typePercent: false,
                                                    freeShipping: false,
                                                    value: formatCurrency(
                                                        !isNaN(numValue)
                                                            ? `${numValue.toFixed(
                                                                2
                                                            )}`
                                                            : '0.00'
                                                    ),
                                                });
                                            },
                                        },
                                        {
                                            type: 'radio',
                                            label: 'Frete Grátis',
                                            value: !!data?.freeShipping,
                                            checked: !!data?.freeShipping,
                                            onChange: () => {
                                                updateData({
                                                    typePercent: false,
                                                    typeFixed: false,
                                                    freeShipping:
                                                        !data?.freeShipping,
                                                });
                                            },
                                        },
                                    ]}
                                />

                                {!data?.typePercent && !data?.typeFixed && (
                                    <div className={'custom-validation-error'}>
                                        {formError.typeCoupon}
                                    </div>
                                )}
                            </div>
                            <div className={'col-12'}>
                                {data?.typePercent && (
                                    <div className={'row'}>
                                        <div className={'col-6'}>
                                            <FieldPercentage
                                                label={
                                                    'Porcentagem do desconto'
                                                }
                                                onChange={({ target }) =>
                                                    updateData({
                                                        value: target.value,
                                                    })
                                                }
                                                value={data?.value}
                                                validationError={
                                                    formError?.valueTypePercent
                                                }
                                            />
                                        </div>
                                        <div className={'col-6'}>
                                            <FieldCurrency
                                                label={
                                                    'Valor mínimo para o desconto'
                                                }
                                                value={data?.minValue || 'R$ 00,00'}
                                                onChange={(e) =>
                                                    updateData({
                                                        minValue:
                                                            formatCurrency(
                                                                e.target.value
                                                            ),
                                                    })
                                                }
                                                validationError={
                                                    formError?.minValueTypePercent
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                                {data?.typeFixed && (
                                    <div className={'row'}>
                                        <div className={'col-6'}>
                                            <FieldCurrency
                                                label={'Valor do desconto'}
                                                value={data?.value || ''}
                                                onChange={(e) =>
                                                    updateData({
                                                        value: formatCurrency(
                                                            e.target.value
                                                        ),
                                                    })
                                                }
                                                validationError={
                                                    formError?.valueTypeFixed
                                                }
                                            />
                                        </div>
                                        <div className={'col-6'}>
                                            <FieldCurrency
                                                label={
                                                    'Valor mínimo para o desconto'
                                                }
                                                value={data?.minValue || 'R$ 00,00'}
                                                onChange={(e) =>
                                                    updateData({
                                                        minValue:
                                                            formatCurrency(
                                                                e.target.value
                                                            ),
                                                    })
                                                }
                                                validationError={
                                                    formError?.minValueTypeFixed
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                                {data?.freeShipping && (
                                    <div className={'row'}>
                                        <div className={'col-6'}>
                                            <FieldCurrency
                                                label={
                                                    'Valor mínimo para o frete grátis'
                                                }
                                                value={data?.minValue || 'R$ 00,00'}
                                                onChange={(e) => {
                                                    updateData({
                                                        minValue:
                                                            formatCurrency(
                                                                e.target.value
                                                            ),
                                                    });
                                                }}
                                                validationError={
                                                    formError?.freeShipping
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={'col-12'}>
                        <div className={'col-12'}>
                            <div className={'user-list-coupon-container'}>
                                <div className={'user-list-coupon-header'}>
                                    <label>
                                        {'Adicione o cliente pelo CPF'}
                                    </label>
                                    <Button
                                        onClick={() => setUserCPFModal(true)}
                                        className={'transparent'}
                                        style={{
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        {'+ novo'}
                                    </Button>
                                </div>

                                {userList?.length ? (
                                    <div className={'user-list-coupon-content'}>
                                        {userList
                                            ?.filter(
                                                (user) =>
                                                    user.status ===
                                                    EnumCouponStatusUser.ACTIVED
                                            )
                                            .map((user, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={
                                                            'd-flex justify-content-between'
                                                        }
                                                    >
                                                        <p>
                                                            {user.name
                                                                ? `${user.name} `
                                                                : ''}
                                                            {'('}
                                                            {user.cpf}
                                                            {')'}
                                                        </p>
                                                        <div
                                                            className={
                                                                'd-flex align-items-start'
                                                            }
                                                        >
                                                            <Button
                                                                className={
                                                                    'transparent'
                                                                }
                                                                onClick={() => removeUserFromThisCoupon({ id: user.id })
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        IconButtonRemoveSvg
                                                                    }
                                                                    srcSet={`${IconButtonRemove} 1x, ${IconButtonRemove2x} 2x`}
                                                                    alt={
                                                                        'Excluir'
                                                                    }
                                                                    title={
                                                                        'Excluir'
                                                                    }
                                                                    style={{
                                                                        width: '11px',
                                                                    }}
                                                                />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                ) : (
                                    <></>
                                )}
                                <UserCPFModal
                                    showModal={userCPFModal}
                                    onCloseModal={setUserCPFModal}
                                    setUser={addUserList}
                                    userList={userList}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={'col-12 mt-42'}>
                        <div className={'button-container'}>
                            <div className={'ml-10'}>
                                <Button
                                    color={ButtonColor.BUTTON_COLOR_RED}
                                    fontColor={
                                        ButtonFontColor.BUTTON_FONT_COLOR_LIGHT
                                    }
                                    onClick={() => cancel()}
                                    ref={cancelBtnRef}
                                >
                                    {`Cancelar [${ENUM_SHORTCUT.SHORTCUT_CANCEL}]`}
                                </Button>
                            </div>
                            <div className={'ml-10'}>
                                <Button
                                    color={ButtonColor.BUTTON_COLOR_GREEN}
                                    fontColor={
                                        ButtonFontColor.BUTTON_FONT_COLOR_LIGHT
                                    }
                                    onClick={() => confirm()}
                                    ref={saveBtnRef}
                                >
                                    {`Salvar [${ENUM_SHORTCUT.SHORTCUT_CONFIRM}]`}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                {uuid && (
                    <div className={'mt-20'}>
                        <fieldset>
                            <legend>{'Clientes que usaram o cupom'}</legend>
                        </fieldset>
                        <Table
                            columns={[
                                { name: 'Id da venda', align: 'center' },
                                { name: 'Cliente', align: 'center' },
                                { name: 'Valor', align: 'center' },
                                { name: 'Comprado no dia', align: 'center' },
                                { name: 'Usado em', align: 'center' },
                            ]}
                        >
                            {usageCouponInfo?.map((coupon) => (
                                <tr key={coupon.saleUuid}>
                                    <td className={'text-center'}>
                                        {
                                            <Link
                                                onClick={(e) =>
                                                    e.stopPropagation()
                                                }
                                                target={'_blank'}
                                                to={getSalesDetailFormRoute(
                                                    coupon.saleUuid
                                                )}
                                            >
                                                {coupon.id}
                                            </Link>
                                        }
                                    </td>
                                    <td className={'text-center'}>
                                        {coupon.customer}
                                    </td>
                                    <td className={'text-center'}>
                                        {Number(coupon.total).toLocaleString(
                                            'pt-BR',
                                            {
                                                style: 'currency',
                                                currency: 'BRL',
                                            }
                                        )}
                                    </td>
                                    <td className={'text-center'}>
                                        {new Date(
                                            coupon.date
                                        ).toLocaleDateString('pt-BR')}
                                    </td>
                                    <td className={'text-center'}>
                                        {coupon.channelOfUse}
                                    </td>
                                </tr>
                            ))}
                        </Table>
                    </div>
                )}
            </div>
        </div>
    );
}

export function getCouponFormRoute(uuid) {
    if (uuid) {
        return `/comercial/cupons/${uuid}`;
    }
    return '/comercial/cupons/novo';
}
