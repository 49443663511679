import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { getVersion } from './version';

if (process.env.REACT_APP_SENTRY_DISABLED != 'true') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DNS,
        integrations: [new BrowserTracing()],
        release: getVersion(),
        environment: process.env.REACT_APP_SENTRY_ENV,
    });
}

const root = ReactDOM.createRoot(document.getElementById('root'));

const main = (
    //<React.StrictMode>
    //</React.StrictMode>
    <App />
);
if (process.env.REACT_APP_RECAPTCHA_KEY) {
    root.render(
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
            useRecaptchaNet
            scriptProps={{
                async: true,
                defer: true,
                appendTo: 'body'
            }}
        >
            {main}
        </GoogleReCaptchaProvider>
    );
} else {
    root.render(main);
}
