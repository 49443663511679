import './VirtualOrders.scss';
import React, { useContext, useEffect, useState } from 'react';
import ScreenHeader from '../../../components/logged/ScreenHeader';
import { DeliveryMethodTypeEnum, OrderByPurchaseSuggestionDetailedEnum, PermissionsEnum, ValidationErrorBannerText, VirtualOrderStatusEnum } from 'erva-doce-common';
import { EnvironmentContext } from '../../../contexts/EnviromentContext';
import { useParams } from 'react-router';
import * as VirtualOrdersService from '../../../services/VirtualOrderService';
import { getVirtualOrderRoute } from '../vitual-order/VirtualOrder';
import SummaryTab from './VirtualOrderSummaryTab';
import VirtualOrderStatusTimeline from './VirtualOrderStatusTimeline';
import Button, { ButtonColor, ButtonStyle } from '../../../components/Button';
import { IconFilter, IconFilter2x } from '../../../components/images';
import FieldTextSearch from '../../../components/FieldTextSearch';
import VirtualOrderProductsTable from './VirtualOrderProductsTable';
import * as VirtualOrderService from '../../../services/VirtualOrderService';
import VirtualOrderDetailFilterModal from './VirtualOrderDetailFilterModal';
import Pagination from '../../../components/Pagination';
import { InfoModalStyle } from '../../../components/modal/InfoModal';
import PaymentTab from './VirtualOrderPaymentTab';
import VirtualOrderDeliveryTab from './VirtualOrderDeliveryTab';
import * as Tabs from '@radix-ui/react-tabs';

const FILTERS = {
    order: OrderByPurchaseSuggestionDetailedEnum.PRODUCT_NAME_ASC,
    search: null,
    page: 0,
    product: '',
};

const TABS = {
    'SUMMARY': 'SUMMARY',
    'PAYMENT': 'PAYMENT',
    'DEVELIVED': 'DEVELIVED',
};

const TABS_TEXT = {
    'SUMMARY': 'Resumo',
    'PAYMENT': 'Pagamento',
    'DEVELIVED': 'Envio',
};

export default function VirtualOrderDetail() {
    const { id } = useParams();
    const { user } = useContext(EnvironmentContext);
    const [isLoading, setIsLoading] = useState(false);
    const [products, setProducts] = useState({});
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [order, setOrder] = useState({});
    const [search, setSearch] = useState({ product: '' });
    const [filter, setFilter] = useState(FILTERS);
    const [virtualTotal, setVirtualTotal] = useState();
    const { setInfoModal, setLoading, backendConnectionError } = useContext(EnvironmentContext);
    const [tab, setTab] = useState();

    const tabStatusOrder = () => {
        switch (order.status) {
        case VirtualOrderStatusEnum.RECEIVED:
        case VirtualOrderStatusEnum.COMPLETED:
            return setTab(TABS.SUMMARY);
        case VirtualOrderStatusEnum.PAYMENT_APPROVED:
            return setTab(TABS.PAYMENT);
        case VirtualOrderStatusEnum.NOTE_ISSUED:
        case VirtualOrderStatusEnum.SEPARATING:
        case VirtualOrderStatusEnum.SUBMITTED:
            return setTab(TABS.DEVELIVED);
        }
    };

    const canEditOrder = user.isAdmin || user.roles?.includes(PermissionsEnum.EDIT_ORDER);
    const canCheckOrder = user.isAdmin || user.roles?.includes(PermissionsEnum.CHECK_ORDER);

    const updateFilter = (name, value) => {
        setFilter({
            ...filter,
            [name]: value,
        });
    };

    const getProducts = async () => {
        try {
            setIsLoading(true);
            const data = await VirtualOrderService.getVirtualOrderProducts(id, filter);
            setProducts(data);
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchOrder = async () => {
        try {
            setIsLoading(true);
            const data = await VirtualOrdersService.getVirtualOrderById(id);

            setOrder(data);
            setVirtualTotal(data.total);
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    const btnActions = (title, callback = () => {}) => {
        return (
            <Button
                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                color={ButtonColor.BUTTON_COLOR_GREEN}
                onClick={() => callback()}
                className={'w-100'}
            >
                {title}
            </Button>
        ); 
    };

    const orderData = (order) => {
        const data = {
            customer: order.customer,
            cart: order.cart,
            seller: order.seller,
            deliveryMethod: order?.delivery.id,
            deliveryType: order?.delivery?.type,
            paymentMethod: order?.paymentMethod,
            virtualOrderPayment: order?.virtualOrderPayment,
            total: order.total,
        };

        return data;
    };

    const onSeparatingChange = async (showInfoMessage=true) => {
        try {
            setLoading(true);
            setIsLoading(true);

            const data = orderData(order);
            await VirtualOrderService.updateVirtualOrder(order.uuid, {
                ...data,
                status: VirtualOrderStatusEnum.SEPARATING
            });

            if (showInfoMessage) {
                setInfoModal({
                    title: 'Iniciar separação',
                    message: 'Pedido iniciado com sucesso!',
                    style: InfoModalStyle.SUCCESS,
                    show: true,
                });
            }
            await fetchOrder();
        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to save order', err, null, 'Separação do pedido');
        } finally {
            setIsLoading(false);
            setLoading(false);
        }
    };
    
    const onGenerateNFCeChange = async (showInfoMessage=true) => {
        try {
            setLoading(true);
            setIsLoading(true);
            
            const data = orderData(order);
            await VirtualOrderService.generateNFCe(order.id, {
                ...data,
                status: VirtualOrderStatusEnum.NOTE_ISSUED
            });

            if (showInfoMessage) {
                setInfoModal({
                    title: 'Emitir Nota Fiscal',
                    message: 'NFCe do pedido gerado com sucesso!',
                    style: InfoModalStyle.SUCCESS,
                    show: true,
                });
            }
            await fetchOrder();
        } catch (err) {
            console.log(err);
            const { data } = err.response;
            if (data?.errors?.length > 0) {
                backendConnectionError(
                    'Fail to save order', 
                    err, 
                    null, 
                    'Emitir Nota Fiscal', 
                    (error) => error
                );
            }
        } finally {
            setIsLoading(false);
            setLoading(false);
        }
    };
    
    const onSubmittedChange = async (showInfoMessage=true) => {
        try {
            setLoading(true);
            setIsLoading(true);
            
            const data = orderData(order);
            await VirtualOrderService.updateVirtualOrder(order.uuid, {
                ...data,
                status: VirtualOrderStatusEnum.SUBMITTED
            });

            if (showInfoMessage) {
                setInfoModal({
                    title: 'Pedido enviado',
                    message: 'Pedido enviado com sucesso!',
                    style: InfoModalStyle.SUCCESS,
                    show: true,
                });
            }
            await fetchOrder();
        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to save order', err, null, 'Pedido enviado');
        } finally {
            setIsLoading(false);
            setLoading(false);
        }
    };
    
    const onDeliveredChange = async (showInfoMessage=true) => {
        try {
            setLoading(true);
            setIsLoading(true);
            
            const data = orderData(order);
            await VirtualOrderService.updateVirtualOrder(order.uuid, {
                ...data,
                status: VirtualOrderStatusEnum.DELIVERED
            });

            if (showInfoMessage) {
                setInfoModal({
                    title: 'Pedido recebido',
                    message: 'Pedido recebido com sucesso!',
                    style: InfoModalStyle.SUCCESS,
                    show: true,
                });
            }
            await fetchOrder();
        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to save order', err, null, 'Pedido recebido');
        } finally {
            setIsLoading(false);
            setLoading(false);
        }
    };
    
    const handleActionsStatus = () => {
        switch (order.status) {
        case VirtualOrderStatusEnum.PAYMENT_APPROVED: 
            return btnActions('Iniciar separação', () => onSeparatingChange());
        case VirtualOrderStatusEnum.SEPARATING:
            return btnActions('Emitir Nota Fiscal', () => onGenerateNFCeChange());
        case VirtualOrderStatusEnum.NOTE_ISSUED:
            if (order?.delivery.type === DeliveryMethodTypeEnum.FIX_TAX) {
                return btnActions('Pedido enviado', () => onSubmittedChange());
            } else {
                return null;
            }
        case VirtualOrderStatusEnum.SUBMITTED:
            if (order?.delivery?.type === DeliveryMethodTypeEnum.FIX_TAX) {
                return btnActions('Pedido recebido', () => onDeliveredChange());
            } else {
                return null;
            }
        default:
            return null;
        }
    };
    
    useEffect(() => {
        fetchOrder();
        getProducts();
    }, [filter]);

    useEffect(() => {
        tabStatusOrder();
    }, [order]);

    return (
        <>
            <div className={'purchase-detail'}>
                <div className={'crud-list-order-detail'}>
                    <Tabs.Root
                        value={tab}
                        className={'tabs_container'}
                        onValueChange={(ev) => setTab(ev)}
                    >
                        <ScreenHeader
                            title={'Pedido detalhado'}
                            breadcrumbs={[
                                { name: 'Loja-virtual', route: '/' },
                                {
                                    name: 'Pedidos',
                                    route: getVirtualOrderRoute(),
                                },
                                {
                                    name: 'Pedido detalhado',
                                    route: getVirtualOrderDetailRoute(),
                                },
                            ]}
                            backRoute={getVirtualOrderRoute()}
                            hideStore
                        />

                        <VirtualOrderStatusTimeline data={order} />

                        <Tabs.List
                            className={'tabs_list mt-20'}
                            aria-label={'Manage your account'}
                        >
                            {Object.values(TABS).map((status, index) => {
                                return (
                                    <Tabs.Trigger
                                        key={index}
                                        className={'tabs_trigger'}
                                        value={status}
                                        disabled={!canCheckOrder || !canEditOrder}
                                    >
                                        {`${TABS_TEXT[status]}`}
                                    </Tabs.Trigger>
                                );
                            })}
                        </Tabs.List>

                        <Tabs.Content value={TABS.SUMMARY}>
                            <SummaryTab
                                data={order}
                                virtualTotal={virtualTotal}
                            />
                        </Tabs.Content>

                        <Tabs.Content  value={TABS.PAYMENT}>
                            <PaymentTab
                                data={order}
                            />
                        </Tabs.Content>

                        <Tabs.Content  value={TABS.DEVELIVED}>
                            <VirtualOrderDeliveryTab
                                data={order}
                            />
                        </Tabs.Content>
                            
                        <div className={'row justify-content-end mt-42'}>
                            <div className={'col-3'}>
                                <FieldTextSearch
                                    label={
                                        '<em>Buscar por <strong>produto</strong> [ F2 ]</em>'
                                    }
                                    onChangeDebounce={() =>
                                        setFilter({
                                            ...filter,
                                            product: search.product,
                                        })
                                    }
                                    onChange={(e) =>
                                        setSearch({
                                            ...search,
                                            product: e.target.value,
                                        })
                                    }
                                    value={search.product}
                                    className={'text_filter'}
                                />
                            </div>
                            <div className={'col-2'}>
                                <Button
                                    buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                    color={ButtonColor.BUTTON_COLOR_ORANGE}
                                    icon={IconFilter}
                                    icon2x={IconFilter2x}
                                    onClick={() => {
                                        setShowFilterModal(true);
                                    }}
                                    className={'w-100'}
                                >
                                    {'Filtrar'}
                                </Button>
                            </div>
                        </div>
                        <div className={'mt-20 mb-20'}>
                            <VirtualOrderProductsTable
                                data={products?.records}
                                filter={filter}
                                isLoading={isLoading}
                                onSortChange={updateFilter}
                                canEditCost={canEditOrder}
                                canEditCount={canCheckOrder || canEditOrder}
                            />
                            <div>
                                <Pagination
                                    page={products?.page}
                                    pageSize={products?.pageSize}
                                    count={products?.count}
                                    recordCount={products?.records?.length || 0}
                                    onPageChange={(page) =>
                                        setFilter({ ...filter, page })
                                    }
                                />
                            </div>
                        </div>       
                        <div className={'row justify-content-end'}>
                            <div className={'col-2'}>
                                {handleActionsStatus()}
                            </div>
                        </div>
                    </Tabs.Root>
                </div>

                <VirtualOrderDetailFilterModal
                    show={showFilterModal}
                    filter={filter}
                    onCancel={() => setShowFilterModal(false)}
                    onConfirm={(filterData) => {
                        setShowFilterModal(false);
                        setFilter(filterData);
                    }}
                />
            </div>
        </>
    );
}

export function getVirtualOrderDetailRoute(id) {
    if (id) {
        return `/loja-virtual/pedidos/acompanhamento-de-pedidos/${id}/detalhes`;
    }

    return '/loja-virtual/pedidos';
}
