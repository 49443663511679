import './SearchProductModal.scss';
import * as ProductsService from '../services/ProductsService';
import * as Sentry from '@sentry/react';
import SimpleConfirmModal from '../components/modal/SimpleConfirmModal';
import { useEffect, useRef, useState } from 'react';
import FieldTextSearch from './FieldTextSearch';
import Table from './Table';
import axios from 'axios';
import Button, { ButtonStyle } from './Button';

function SearchProductModal({
    show,
    onCancel,
    onSelect,
    globalSearch,
    onlyParent,
    onNewProduct,
    newProductText='Criar novo',
    cancelEsc=false
}) {
    const [formData, setFormData] = useState({ products: [] });

    const [selectedIdx, setSelectedIdx] = useState(-1);
    const inputRef = useRef();

    const [filter, setFilter] = useState({
        search: null,
    });

    function updateFormData(data) {
        setFormData((formData) => ({ ...formData, ...data }));
    }

    function cancel() {
        setFilter({ search: '' });
        setFormData({ products: [] });
        onCancel();
    }

    useEffect(() => {
        setTimeout(() => {
            inputRef.current?.focus();
        }, 200);
    }, [show]);


    useEffect(() => {
        fetchProductsByName();
    }, [filter]);

    async function fetchProductsByName() {
        try {
            if (filter.search) {
                const products = await ProductsService.getProductsByName(filter.search, { globalSearch, onlyParent });

                updateFormData([]);
                updateFormData({ products: products });
            }
        } catch (e) {
            if (axios.isCancel(e)) {
                console.debug('Request cancelled.', e);
            } else {
                console.error(e);
                Sentry.captureException(e);
            }
        }
    }

    function selectProduct(prod) {
        setFilter({ search: '' });
        onSelect && onSelect(prod);
    }

    useEffect(() => {
        if (!show) return;

        const checkKey = (event) => {
            if (event.key === 'ArrowUp') {
                setSelectedIdx((prevIdx) => Math.max(prevIdx - 1, 0));
            } else if (event.key === 'ArrowDown') {
                setSelectedIdx((prevIdx) => Math.min(prevIdx + 1, formData.products.length - 1));
            }
        };

        document.addEventListener('keydown', checkKey);

        return () => {
            document.removeEventListener('keydown', checkKey); // Remove o evento ao fechar a modal
        };
    }, [show, formData.products.length]);

    function renderTable() {
        let rows = [];
        // noinspection JSUnresolvedReference
        for (const [index, p] of formData.products.entries()) {
            const selectedRow = index === selectedIdx;
            rows.push(
                <tr key={p.uuid} onClick={() => selectProduct(p)} className={selectedRow ? 'selected' : null}>
                    <td>
                        {p?.barCodes?.find(x => x.main)?.barCode || ''}
                    </td>
                    <td>
                        {[p.name1, p.name2, p.name3].join(' ')}
                    </td>
                    <td>
                        {p.price?.toLocaleString(undefined, { style: 'currency', currency: 'BRL', })}
                    </td>
                </tr>
            );
        }

        return (
            <div>
                <Table
                    // loading={loading}
                    columns={[
                        {
                            // I18N
                            name: 'Cod. Barras'
                        },
                        {
                            // I18N
                            name: 'Produto'
                        },
                        {
                            // I18N
                            name: 'Valor'
                        },
                    ]}>
                    {rows}
                </Table>
            </div>
        );
    }

    return (

        <SimpleConfirmModal
            show={show}
            onCancel={cancel}
            onConfirm={() => selectProduct(formData.products[selectedIdx])}
            // I18N
            title={'Buscar Produto'}
            className={'search-product-modal'}
            confirmText={'Selecionar'}
            cancelEsc={cancelEsc}
        >
            <fieldset>
                {
                    !!onNewProduct && (
                        <div className={'row mb-16'}>
                            <div className={'col-12 d-flex justify-content-end'}>
                                <Button
                                    buttonStyle={ButtonStyle.BUTTON_TINY}
                                    onClick={onNewProduct}
                                    title={newProductText}
                                >
                                    {newProductText}
                                </Button>
                            </div>
                        </div>
                    )
                }
                <div className={'row'}>
                    <div className={'col-12'}>
                        <FieldTextSearch
                            ref={inputRef}
                            // I18N
                            value={filter.search}
                            label={'<em>Digite o produto desejado</em>'}
                            onChange={(e) => {                               
                                if(e.target.value) {                                    
                                    const search = e.target.value.replace(/^\/+|\/+$/g, '');
                                    setFilter({
                                        ...filter,
                                        search: search
                                    });
                                } else {
                                    setFilter({
                                        ...filter,
                                        search: ''
                                    });
                                    setFormData({ products: [] });
                                }
                            }}
                        />
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <div className={'gd'}>
                    <div className={'gd-col gd-col-12'}>
                        {renderTable()}
                    </div>
                </div>
            </fieldset>

            {selectedIdx !== -1 && formData.products[selectedIdx] && (
                <div className={'selected-product'}>
                    {formData.products[selectedIdx]?.name1 + ' ' + formData.products[selectedIdx]?.name2 + ' ' + formData.products[selectedIdx]?.name3}
                    <span>{formData.products[selectedIdx]?.price?.toLocaleString(undefined, { style: 'currency', currency: 'BRL', })}</span>
                </div>
            )}

        </SimpleConfirmModal>
    );
}

export default SearchProductModal;
