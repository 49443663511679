import { useRef, useState } from 'react';
import SubCategoryModal from '../admin/products-categories/SubCategoryModal';
import * as ProductsCategoriesService from '../services/ProductsCategoriesService';
import { createCancelTokenSource } from '../util/api';
import FieldLiveSearch from './FieldLiveSearch';
import Link from './Link';

function FieldProductSubCategory({
    validationCategory,
    addButton,
    onSelected,
    select,
    ...props
}) {
    const cancelTokenSourceRef = useRef(null);
    const liveSearchRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const uuid = (validationCategory && validationCategory.id) ? validationCategory?.id : (validationCategory && validationCategory.length > 0 ? validationCategory.map(c => c.id) : undefined);

    async function fetchValue() {
        const result = await ProductsCategoriesService.getSubCategory(select.id);
        return result ? result.name : '';
    }

    async function fetch() {
        cancelTokenSourceRef.current?.cancel();
        cancelTokenSourceRef.current = await createCancelTokenSource();
        const result = await ProductsCategoriesService.getCategories(uuid ? Array.isArray(uuid) ? uuid : [uuid] : []);
        const subCategories = [];
        for (const category of result) {
            for (const subCategory of category.subCategories) {
                subCategories.push({
                    id: subCategory.uuid,
                    value: subCategory.name,
                    category: subCategory.categoryUuid
                });
            }
        }

        return subCategories;
    }

    function addSubcategory() {
        if (addButton) {
            return (
                <Link
                    // I18N
                    description={'Cadastrar nova subcategoria'}
                    onClick={() => setShowModal(true)}
                />
            );
        } else {
            return null;
        }
    }
    return (
        <>
            <FieldLiveSearch
                // I18N
                label={'SubCategorias de produtos'}
                fetchFn={fetch}
                fetchValueFn={fetchValue}
                onSelected={onSelected}
                select={select}
                hasPagination={true}
                ref={liveSearchRef}
                addButton={addSubcategory()}
                token={true}
                {...props}
            />

            <SubCategoryModal
                show={showModal}
                onCancel={() => setShowModal(false)}
                category={validationCategory}
            />
        </>
    );
}

export default FieldProductSubCategory;
