import Section from '../../components/Section';
import './PaymentMethodTableScss.scss';
import { formatValue } from '../../util/utilCurrency';
import { PaymentsTypeText } from 'erva-doce-common';

export default function PaymentMethodTableComponent({ data }) {
    const renderPaymentMethods = (item) => {
        const paymentMethods = Object.entries(item).filter(
            ([key]) => key !== 'name' && key !== 'total'
        );
        return paymentMethods.map(([method, amount]) => (
            <div className={'sales-by-mode-table-content'} key={method}>
                <span>
                    {PaymentsTypeText(method)?.replace('Cartão de ', '')}
                </span>
                <span>{`${formatValue({ value: amount })}`}</span>
            </div>
        ));
    };

    return (
        <div className={'payment-method-table-container'}>
            <Section title={'Informações das vendas'}>
                <>
                    <div className={'gd box-container-movement-page'}>
                        {data?.map((item, index) => (
                            <div
                                className={
                                    'gd-col gd-col-3 sales-by-mode-table'
                                }
                                key={index}
                            >
                                <div className={'sales-by-mode-table-title'}>
                                    <span>{item.name}</span>
                                    <span>{`Total: ${formatValue({
                                        value: item.total,
                                    })}`}</span>
                                </div>
                                <div className={'sales-by-mode-table-body'}>
                                    <>{renderPaymentMethods(item)}</>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            </Section>
        </div>
    );
}
