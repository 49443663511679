export enum BillToPayTypeEnum {
  UNIQUE = "UNIQUE",
  INSTALLS = "INSTALLS",
  DAILY = "DAILY", 
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY", 
  YEARLY = "YEARLY"
}

export function BillToPayTypeText(type: BillToPayTypeEnum | string) {
    switch (type) {
    case BillToPayTypeEnum.UNIQUE:
        return "Conta única";
    case BillToPayTypeEnum.INSTALLS:
        return "Conta parcelada";
    case BillToPayTypeEnum.DAILY:
        return "Conta diária";
    case BillToPayTypeEnum.WEEKLY:
        return "Conta semanal";
    case BillToPayTypeEnum.MONTHLY:
        return "Conta mensal";
    case BillToPayTypeEnum.YEARLY:
        return "Conta anual";
    default:
        throw new Error(`Not implemented for ${type}`);
    }
}
