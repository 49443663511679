import { useEffect, useState } from 'react';
import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import FieldText from '../../components/FieldText';
import { ENUM_SHORTCUT } from 'erva-doce-common';

function PasswordModal({
    show,
    cancelEsc,
    onConfirm,
    onChangeForm,
    onCancel=false,
}) {
    const [formData, setFormData] = useState({ password: '' });
    const [formError, setFormError] = useState();
    const [, setValidateOnChange] = useState(false);

    function confirm() {
        let errors = hasValidationError();
        if(!errors) {
            onConfirm({ formData });
        }
    }

    function handleInputChange(data) {
        setFormData(data);
        onChangeForm({ ...data });
    }

    function hasValidationError() {
        let hasError = false;
        setFormError({});
        setValidateOnChange(true);

        if (formData.password === '') {
            hasError = true;
            setFormError({ password: 'Digite a senha.' });
        }

        return hasError;
    }

    function cancel() {
        setFormData({ password: '' });
        onChangeForm({ password: '' });
        onCancel();
    }

    function onKeyDownEvent(ev) {
        switch (ev.key || ev.code) {
        case ENUM_SHORTCUT.SHORTCUT_ENTER:
            return confirm();
        }
    }

    useEffect(() => {
        if (show) {
            setFormData({ password: '' });
        }
    }, [show]);

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={cancel}
            onConfirm={confirm}
            title={'Remoção produto'}
            cancelEsc={cancelEsc}
        >
            <div className={'gd'}>
                <div className={'gd-col-12'}>
                    <FieldText
                        label={'Senha'}
                        placeholder={'Informe a senha'}
                        value={formData.password}
                        onChange={({ target }) => {
                            handleInputChange({ password: target.value });
                        }}
                        validationError={formError?.password}
                        onKeyDown={(ev) => onKeyDownEvent(ev)}
                        type={'password'}
                    />
                </div>
            </div>
        </SimpleConfirmModal>
    );
}

export default PasswordModal;