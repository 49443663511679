import DOMPurify from 'dompurify';
import { useContext, useEffect, useRef } from 'react';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import SimpleConfirmModal from './SimpleConfirmModal';
import { ENUM_SHORTCUT } from 'erva-doce-common';

export default function ConfirmModal({
    show,
    zIndex,
    title,
    message,
    cancelText,
    confirmText,
    onCancel,
    onConfirm,
    danger = true,
}) {
    title = DOMPurify.sanitize(title);
    const { addHotkey, removeHotkey } = useContext(EnvironmentContext);
    const shortcutCancel = useRef();
    const shortcutConfirm = useRef();

    useEffect(() => {
        function removeHotkeys() {
            removeHotkey(shortcutCancel.current);
            removeHotkey(shortcutConfirm.current);
        }

        if (show) {
            shortcutCancel.current = addHotkey(ENUM_SHORTCUT.SHORTCUT_CANCEL, () => {
                if (onCancel) onCancel();
            });
            shortcutConfirm.current = addHotkey(ENUM_SHORTCUT.SHORTCUT_CONFIRM, () => {
                if (onConfirm) onConfirm();
            });
        } else {
            removeHotkeys();
        }
        return () => {
            removeHotkeys();
        };
    }, [show]);

    return (
        <SimpleConfirmModal
            show={show}
            zIndex={zIndex}
            cancelText={cancelText}
            confirmText={confirmText}
            onCancel={onCancel}
            onConfirm={onConfirm}
            danger={danger ?? true}

            title={
                <h2 dangerouslySetInnerHTML={{ __html: title }} title={title}/>
            }
        >
            <div className={'message'} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }}/>
        </SimpleConfirmModal>

    );
}