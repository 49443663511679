// noinspection JSCheckFunctionSignatures

import { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import * as AddressesListService from '../services/AddressesService';
import { parseNumberLocale } from '../util/numberParser';
import Button, { ButtonColor, ButtonFontColor } from './Button';
import FieldCity from './FieldCity';
import FieldNumber from './FieldNumber';
import FieldState from './FieldState';
import FieldText from './FieldText';
import FieldZipCode, { maskZipCodeString, unmaskZipCode } from './FieldZipCode';
import { ENUM_SHORTCUT } from 'erva-doce-common';

function FieldAddress({
    value,
    onChange,
    onClose,
    label,
    readOnly,
    ...props
}, ref) {
    const initialFormData = {
        zipCode: '',
        state: null,
        city: null,
        address: '',
        neighborhood: '',
        number: '',
        complement: '',
    };
    const [formData, setFormData] = useState(value ?? initialFormData);
    const [submittedData, setSubmittedData] = useState(value ?? initialFormData);
    const [showModal, setShowModal] = useState(false);
    const {
        addHotkey,
        removeHotkey,
        setLoading,
        backendConnectionError,
    } = useContext(EnvironmentContext);
    const cancelButtonRef = useRef();
    const confirmButtonRef = useRef();
    const shortcutCancel = useRef();
    const shortcutConfirm = useRef();
    const inputZipCodeRef = useRef();
    const lastZipCodeRef = useRef('');
    const lastShowRef = useRef(false);
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [formError, setFormError] = useState(initialFormData);

    useEffect(() => {
        const formData = value ?? emptyAddressData;
        setFormData(formData);
        setSubmittedData(formData);
    }, [value]);

    function updateFormData(data) {
        setFormData((formData) => {
            return {
                ...formData,
                ...data,
            };
        });
    }

    function cancel() {
        setShowModal(false);
    }

    function confirm() {
        let hasError = hasValidationError();
        if(!hasError) {
            setSubmittedData(formData);
            onChange(formData);
            setShowModal(false);
        }
    }

    function hasValidationError() {
        let hasError = false;
        setFormError(initialFormData);
        setValidateOnChange(true);
        if (!formData.zipCode) {
            hasError = true;
            // I18N
            // noinspection JSCheckFunctionSignatures
            setFormError((formError) => ({ ...formError, zipCode: 'Digite o CEP' }));
        }
        if (!formData.state) {
            hasError = true;
            // I18N
            // noinspection JSCheckFunctionSignatures
            setFormError((formError) => ({ ...formError, state: 'Escolha o Estado' }));
        }
        if (!formData.city) {
            hasError = true;
            // I18N
            // noinspection JSCheckFunctionSignatures
            setFormError((formError) => ({ ...formError, city: 'Escolha a cidade' }));
        }
        if (!formData.address) {
            hasError = true;
            // I18N
            // noinspection JSCheckFunctionSignatures
            setFormError((formError) => ({ ...formError, address: 'Digite o endereço' }));
        }
        if (!formData.neighborhood) {
            hasError = true;
            // I18N
            // noinspection JSCheckFunctionSignatures
            setFormError((formError) => ({ ...formError, neighborhood: 'Digite o bairro' }));
        }
        if (!formData.number) {
            hasError = true;
            // I18N
            // noinspection JSCheckFunctionSignatures
            setFormError((formError) => ({ ...formError, number: 'Digite o número' }));
        }

        return hasError;
    }

    async function fetchZipCode({ target }) {
        if (lastZipCodeRef.current === target.value) return;
        lastZipCodeRef.current = target.value;

        try {
            // I18N
            setLoading(true, false, 'Carregando endereço...');
            const address = target.value ? await AddressesListService.getAddress(unmaskZipCode(target.value)) : null;
            if (address) {
                updateFormData({
                    state: { id: address.state, value: address.state },
                    city: { id: address.city, value: address.city },
                    address: address.address,
                    neighborhood: address.neighborhood,
                });
            }
        } catch (e) {
            backendConnectionError('Fail to fetch address', e);
        } finally {
            setLoading(false);
        }

    }

    useEffect(() => {
        function removeHotkeys() {
            removeHotkey(shortcutCancel.current);
            removeHotkey(shortcutConfirm.current);
        }

        if (showModal === lastShowRef.current) return; // avoids unnecessary trigger
        lastShowRef.current = showModal;

        if (showModal) {
            shortcutCancel.current = addHotkey(ENUM_SHORTCUT.SHORTCUT_CANCEL, () => cancelButtonRef.current?.click());
            shortcutConfirm.current = addHotkey(ENUM_SHORTCUT.SHORTCUT_CONFIRM, () => confirmButtonRef.current?.click());
            setTimeout(() => {
                inputZipCodeRef.current?.inputElement?.focus();
                lastZipCodeRef.current = inputZipCodeRef.current?.inputElement?.value;
            }, 200);
        } else {
            removeHotkeys();
            //  restore form data only after modal is hidden
            setTimeout(() => {
                setFormData(submittedData);
                if (onClose) onClose();
            }, 200); // css transition time
        }

        return () => {
            removeHotkeys();
        };
    }, [showModal]);

    function inlineAddress(simplified = true) {
        let address = '';
        if (submittedData.address) {
            address += `${submittedData.address.trim()}, `;
        }
        if (submittedData.number) {
            address += `${submittedData.number.trim()}, `;
        }

        if (!simplified) {
            if (submittedData.neighborhood) {
                address += `${submittedData.neighborhood.trim()}, `;
            }
            if (submittedData.complement) {
                address += `${submittedData.complement.trim()}, `;
            }
        }

        if (submittedData.city) {
            address += `${submittedData.city.value.trim()}, `;
        }
        if (submittedData.state) {
            address += `${submittedData.state.value.trim()}, `;
        }
        if (submittedData.zipCode) {
            address += `${maskZipCodeString(submittedData.zipCode.trim())}, `;
        }
        if (address.length) {
            address = address.slice(0, -2); // remove last comma
        }

        return address;
    }

    return (
        <>
            <FieldText
                {...props}
                readOnly={true}
                label={label}
                value={inlineAddress()}
                onFocus={() => {
                    if (!readOnly) {
                        setShowModal(true);
                    }
                }}
                title={inlineAddress(false)}
            />
            <div className={`modal modal-address ${showModal ? 'show' : ''}`}>
                <div className={'backdrop'} />
                <div className={'container'}>
                    <div className={'body'}>
                        <div className={'title'}>
                            <h2>
                                {label}
                            </h2>
                        </div>
                        <div className={'form'}>
                            <div className={'gd'}>
                                <div className={'gd-col gd-col-4'}>
                                    <FieldZipCode
                                        ref={inputZipCodeRef}
                                        // I18N
                                        label={'CEP'}
                                        value={formData.zipCode}
                                        onChange={(e) => updateFormData({ zipCode: e.target.value })}
                                        onBlur={fetchZipCode}
                                        validationError={formError.zipCode}
                                    />
                                </div>
                                <div className={'gd-col gd-col-3'}>
                                    <FieldState
                                        options={[]}
                                        select={formData.state}
                                        onSelected={(data) => updateFormData(
                                            {
                                                state: data,
                                                city: null,
                                            }
                                        )}
                                        validationError={formError.state}
                                    />
                                </div>
                                <div className={'gd-col gd-col-5'}>
                                    <FieldCity
                                        state={formData.state}
                                        select={formData.city}
                                        onSelected={(data) => {
                                            updateFormData({ city: data });
                                        }}
                                        validationError={formError.city}
                                    />
                                </div>
                            </div>
                            <div className={'gd'}>
                                <div className={'gd-col gd-col-12'}>
                                    <FieldText
                                        // I18N
                                        label={'Endereço'}
                                        maxLength={120}
                                        value={formData.address}
                                        onChange={({ target }) => updateFormData({ address: target.value })}
                                        validationError={formError.address}
                                    />
                                </div>
                            </div>
                            <div className={'gd'}>
                                <div className={'gd-col gd-col-9'}>
                                    <FieldText
                                        // I18N
                                        label={'Bairro'}
                                        maxLength={120}
                                        value={formData.neighborhood}
                                        onChange={({ target }) => updateFormData({ neighborhood: target.value })}
                                        validationError={formError.neighborhood}
                                    />
                                </div>
                                <div className={'gd-col gd-col-3'}>
                                    <FieldNumber
                                        // I18N
                                        label={'Número'}
                                        value={formData.number}
                                        onChange={({ target }) => { updateFormData({ number: target.value }); }}
                                        validationError={formError.number}
                                    />
                                </div>
                            </div>
                            <div className={'gd'}>
                                <div className={'gd-col gd-col-12'}>
                                    <FieldText
                                        // I18N
                                        label={'Complemento'}
                                        maxLength={120}
                                        value={formData.complement}
                                        onChange={({ target }) => updateFormData({ complement: target.value })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={'controls'}>
                            <Button
                                ref={cancelButtonRef}
                                color={ButtonColor.BUTTON_COLOR_GRAY}
                                onClick={cancel}
                            >
                                {`Cancelar [${ENUM_SHORTCUT.SHORTCUT_CANCEL}]`}
                            </Button>
                            <Button
                                ref={confirmButtonRef}
                                fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                                onClick={confirm}
                            >
                                {`Salvar [${ENUM_SHORTCUT.SHORTCUT_CONFIRM}]`}
                            </Button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export const emptyAddressData = {
    zipCode: '',
    state: null,
    city: null,
    address: '',
    neighborhood: '',
    number: '',
    complement: '',
};

export function getAddressData(object) {
    return {
        zipCode: object.zipCode ?? '',
        state: !object.state ? null : { id: object.state, value: object.state },
        city: !object.city ? null : { id: object.city, value: object.city },
        address: object.address ?? '',
        neighborhood: object.neighborhood ?? '',
        number: object.number?.toLocaleString() ?? '',
        complement: object.complement ?? '',
    };
}

export function getAddressObject(addressData) {
    return {
        zipCode: unmaskZipCode(addressData.zipCode),
        state: addressData.state?.value ?? '',
        city: addressData.city?.value ?? '',
        address: addressData.address,
        neighborhood: addressData.neighborhood,
        number: addressData.number ? parseNumberLocale(addressData.number) : null,
        complement: addressData.complement,
    };
}


export default forwardRef(FieldAddress);