export const setInfoLocalStorage = ({ field, data }) => {
    localStorage.setItem(field, JSON.stringify(data));
};

export const getInfoLocalStorage = ({ field }) => {
    return JSON.parse(localStorage.getItem(field));
};

export const removeDataLocalStoage = ({ field }) => {
    localStorage.removeItem(field);
};