import api from '../util/api';

export async function getCashClosingByCashier(id) {
    return (await api.get(`/cash-closing/cashier/${id}`)).data;
}


export async function closingCashier(cashBreakdownData, receiptBreakdownData, total,uuidCashier, cardTotal) {
    const payload = {
        cashBreakdownData,
        receiptBreakdownData,
        total,
        uuidCashier,
        cardTotal

    };
    return (await api.put('/cash-closing', payload)).data;
}


export async function editClosingCashierJvvc(uuid, cashBreakdownData, receiptBreakdownData, total, cardTotal, day) {
    const payload = {
        cashBreakdownData,
        receiptBreakdownData,
        total,
        cardTotal,
        day
    };
    return (await api.put(`/cash-closing/${uuid}`, payload)).data;
}

export async function getDatelesscashclosing(uuid, day) {
    const params = {
        day
    };
    return (await api.get(`/cash-closing/${uuid}`, { params })).data;
}
