import { OrderBySuppliersEnum } from 'erva-doce-common';
import { useRef } from 'react';
import * as SuppliersService from '../services/SuppliersService';
import { createCancelTokenSource } from '../util/api';
import FieldLiveSearch from './FieldLiveSearch';

function FieldSupplier({
    onSelected,
    select,
    ...props
}) {
    const cancelTokenSourceRef = useRef(null);
    const liveSearchRef = useRef(null);

    async function fetchValue(uuid) {
        const result = await SuppliersService.getSuppliers(0, uuid, null,
            OrderBySuppliersEnum.FANTASY_NAME_ASC);
        return result['records']?.length ? result['records'][0].name : '';
    }

    async function fetch(searchStr, page) {
        cancelTokenSourceRef.current?.cancel();
        cancelTokenSourceRef.current = await createCancelTokenSource();
        const result = await SuppliersService.getSuppliers(page, searchStr, null,
            OrderBySuppliersEnum.FANTASY_NAME_ASC, cancelTokenSourceRef.current.token);
        return result['records'].map((record) => ({
            id: record['uuid'],
            value: record['fantasyName'],
            socialReason: record['socialReason'],
            picture: record['picture'],
            phone: record['phone'],
            email: record['email'],
        }));
    }

    return (
        <FieldLiveSearch
            ref={liveSearchRef}
            // I18N
            label={'Fornecedor'}
            fetchFn={fetch}
            fetchValueFn={fetchValue}
            onSelected={onSelected}
            select={select}
            hasPagination={true}
            {...props}
        />
    );
}

export default FieldSupplier;
