import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import FieldCurrency from '../../components/FieldCurrency';
import FieldText from '../../components/FieldText';
import './TransactionModal.scss';
import { useContext, useEffect, useState, useMemo } from 'react';
import { formatCurrency, extractCurrencyNumber } from '../../components/FieldCurrency';
import { addCashMovement } from '../../services/CashMovementsService';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { InfoModalStyle } from '../../components/modal/InfoModal';

export default function TransactionModal({
    show,
    onCancel,
    title,
    valueDate,
    cashClosinguuid,
    total,
    cashieruuid
}) {

    const [formData, setFormData] = useState({});
    const {
        setInfoModal,
    } = useContext(EnvironmentContext);
    const disabled = useMemo(() => {
        return !formData?.value || !formData?.description;
    });

    useEffect(() => {
        if (!show) {
            setFormData({});
        }
    }, [show]);
    async function confirm() {

        try {
            await addCashMovement({
                type: title,
                value: formData.value,
                reason: formData.description,
                day: valueDate,
                cashClosinguuid: cashClosinguuid,
                total: total, 
                cashieruuid: cashieruuid
            });
            setInfoModal({
                title: 'Movimentação de caixa registrada',
                message: 'A movimentação de caixa foi registrada com sucesso.',
                style: InfoModalStyle.SUCCESS,
                show: true,
                onClose: () => {
                    onCancel();
                    window.location.reload();
                },
            });
            setFormData({});
        } catch (error) {
            setInfoModal({
                title: 'Erro ao registrar movimentação de caixa',
                message: 'Não foi possível registrar a movimentação de caixa. Tente novamente.',
                style: InfoModalStyle.ERROR,
                show: true,
            });
        }
    }

    function cancel() {
        onCancel();
        setFormData({});
    }

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={cancel}
            onConfirm={confirm}
            disabled={disabled}
            title={title === 'SANGRIA' ? 'Sangria' : 'Reforço'}
        >
            <div className={'transaction-modal'}>
                <FieldText
                    // I18N
                    label={title === 'SANGRIA' ? 'Motivo da sangria' : 'Motivo do reforço'}
                    value={formData?.description || ''}
                    onChange={(e) => {
                        setFormData({
                            ...formData,
                            description: e.target.value
                        });
                    }}

                />
                <FieldCurrency
                    // I18N
                    label={'Valor'}
                    value={(formData?.value ?? 0).toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                    }) || 'R$ 0,00'}
                    onChange={(e) => {
                        setFormData({
                            ...formData,
                            value: extractCurrencyNumber(formatCurrency(e.target.value)),
                        });
                    }}
                />
            </div>
        </SimpleConfirmModal>
    );
}