export enum OrderByVirtualOrdersEnum {
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  TOTAL_ASC = "TOTAL_ASC",
  TOTAL_DESC = "TOTAL_DESC",
  CUSTOMER_ASC = "CUSTOMER_ASC",
  CUSTOMER_DESC = "CUSTOMER_DESC",
  STATUS_ASC = "STATUS_ASC",
  STATUS_DESC = "STATUS_DESC",
  CREATED_DATE_ASC = "CREATED_DATE_ASC",
  CREATED_DATE_DESC = "CREATED_DATE_DESC",
  LAST_UPDATE_ASC = "LAST_UPDATE_ASC",
  LAST_UPDATE_DESC = "LAST_UPDATE_DESC",
}