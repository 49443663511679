import { useContext, useEffect, useState } from 'react';
import { extractCurrencyNumber, formatCurrency } from '../../../components/FieldCurrency';
import FieldNumber from '../../../components/FieldNumber';
import FieldProductBrand from '../../../components/FieldProductBrand';
import FieldProductCategory from '../../../components/FieldProductCategory';
import FieldProductFamily from '../../../components/FieldProductFamily';
import FieldProductSubCategory from '../../../components/FieldProductSubCategory';
import FieldSegmentation from '../../../components/FieldSegmentation';
import FieldText from '../../../components/FieldText';
import FieldTextarea from '../../../components/FieldTextarea';
import MultipleImages from '../../../components/modal/MultipleImages';
import Button, { ButtonStyle } from '../../../components/Button';
import { IconButtonRemove, IconEditSvg, IconTagCrueltyFree, IconTagNatural, IconTagOrganico, IconTagVegano } from '../../../components/images';
import BarCodesModal from '../../../components/BarCodesModal';
import ConfirmDeleteBarCodesModal from '../../../components/ConfirmDeleteBarCodesModal';
import { handleValidateEAN } from '../../../util/validateEAN';
import Table from '../../../components/Table';
import { useNavigate } from 'react-router';
import SwitchToggle from '../../../components/SwichToggle';
import SearchProductModal from '../../../components/SearchProductModal';
import FieldInteger from '../../../components/FieldInteger';
import { getStoresFormRoute } from '../../stores/StoreForm';
import { getProductFormRoute } from '../ProductsForm';
import FieldStore from '../../../components/FieldStore';
import { formatValue } from '../../../util/formatValue';
import { PermissionsEnum, ProductTagTypeEnum, ProductTagTypeText, TagEnum, TagText } from 'erva-doce-common';
import { Link } from 'react-router-dom';
import FieldSelect from '../../../components/FieldSelect';
import { EnvironmentContext } from '../../../contexts/EnviromentContext';
import classNames from 'classnames';

const ICONS_TAG = {
    [TagEnum.CRUELTY_FREE]: IconTagCrueltyFree,
    [TagEnum.NATURAL]: IconTagNatural,
    [TagEnum.ORGANICO]: IconTagOrganico,
    [TagEnum.VEGANO]: IconTagVegano,
};

export default function MainForm({ data, formError, updateFormData, barCodes, setBarCodes, isNew }) {
    const { user } = useContext(EnvironmentContext);

    const [showImagesModal, setShowImagesModal] = useState(false);
    const [showProductVariationModal, setShowProductVariationModal] = useState(false);
    const [showProductSimilarModal, setShowProductSimilarModal] = useState(false);
    const [barCodesModal, setBarCodesModal] = useState(false);
    const [confirmDeleteBarCodesModal, setConfirmDeleteBarCodesModal] =
        useState(false);
    const [idBarCode, setIdBarCode] = useState();
    const [idBarCodeToDelete, setIdBarCodeToDelete] = useState();
    const [barCode, setBarCode] = useState();

    const disabled = !user.isAdmin && (!user.roles?.includes(PermissionsEnum.HANDLE_PRODUCTS) && !user.roles?.includes(PermissionsEnum.HANDLE_PRODUCT_MAIN));
    const mainVariationProduct = data?.variations?.find(x => x.uuid === data.parentProduct);
    const mainUCVProduct = data?.ucvs?.find(x => x.uuid === data.ucvParentProduct);

    const updateVariationItem = (uuid, value) => {
        const newVariations = [...data.variations].map((x) => {
            if (x.uuid === uuid) {
                return {
                    ...x,
                    ...value,
                };
            }

            return x;
        });

        updateFormData({ variations: newVariations });
    };

    const handleTags = (e) => {
        const value = e.target.value;
        const tags = [...data.tags].filter((x) => x !== value);
        const checked = e.target.checked;

        if (checked) {
            tags.push(value);
        }

        updateFormData({ tags });
    };

    const handleProductTagsOptions = () => {
        const tagOptions = [];
        for (const tag in ProductTagTypeEnum) {
            if (tag !== ProductTagTypeEnum.PROMOTION_TAG) {
                tagOptions.push({
                    id: tag,
                    value: ProductTagTypeText(tag),
                });
            }
        }

        return tagOptions;
    };

    useEffect(() => {
        if (idBarCodeToDelete) {
            setConfirmDeleteBarCodesModal(true);
        }
    }, [idBarCodeToDelete]);

    return (
        <>
            <div className={'product-form-container'}>
                <div className={'row'}>
                    <div className={'col-3'}>
                        <div className={'row'}>
                            <div className={'col-12'}>
                                <h2 className={'mb-10'}>{'Imagens'}</h2>
                            </div>
                            <div className={'col-12'}>
                                {data?.pictures?.length ? (
                                    <div className={'thumbnail-picture'}>
                                        <img src={data.pictures[0].imageURL} />
                                    </div>
                                ) :
                                    <div className={'thumbnail-content'}>
                                        <button title={'Editar imagens'} disabled={disabled} className={'edit-images'} onClick={() => setShowImagesModal(true)}>
                                            <p>{'+'}</p>
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col-12'}>
                                <div className={'d-flex'}>
                                    <div className={'image-content'}>
                                        {!!data?.pictures?.length && (
                                            <>
                                                {
                                                    data.pictures.slice(1).map((picture, index) => (
                                                        <img key={index} src={picture.imageURL} />
                                                    ))
                                                }
                                                <button title={'Editar imagens'} disabled={disabled} className={'edit-images'} onClick={() => setShowImagesModal(true)}>
                                                    <p>{'+'}</p>
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'row mt-42'}>
                            <div className={'col-6'}>
                                <FieldNumber
                                    label={'Peso'}
                                    suffix={'g'}
                                    onChange={({ target }) =>
                                        updateFormData({ weight: target.value })
                                    }
                                    value={data?.weight}
                                    validationError={formError?.weight}
                                    disabled={disabled}
                                    readOnly={disabled}
                                />
                            </div>
                            <div className={'col-6'}>
                                <FieldNumber
                                    label={'Peso Líquido (GS1)'}
                                    suffix={'g'}
                                    onChange={({ target }) =>
                                        updateFormData({ weight: target.value })
                                    }
                                    value={data?.weight}
                                    validationError={formError?.weight}
                                    disabled={disabled}
                                    readOnly={disabled}
                                />
                            </div>
                            <div className={'col-6'}>
                                <FieldSelect
                                    label={'Etiqueta'}
                                    options={handleProductTagsOptions()}
                                    onChange={({ target }) =>
                                        updateFormData({ tag: target.value })
                                    }
                                    value={
                                        data?.tag ||
                                        ProductTagTypeEnum.DEFAULT_TAG
                                    }
                                    disabled={disabled}
                                    readOnly={disabled}
                                />
                            </div>
                            <div className={'col-6'}>
                                <FieldNumber
                                    label={'Qtd. etiquetas'}
                                    minValue={1}
                                    maxValue={20}
                                    thousandsSeparator={false}
                                    onChange={({ target }) =>
                                        updateFormData({
                                            tagQuantity: target.value,
                                        })
                                    }
                                    value={data?.tagQuantity || ''}
                                    validationError={formError?.tagQuantity}
                                    disabled={disabled}
                                    readOnly={disabled}
                                />
                            </div>
                        </div>
                        <div className={'row mt-12'}>
                            <div className={'col-12'}>
                                <h2 className={'mb-10'}>{'Tags'}</h2>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col-12 d-flex w-100 justify-content-between flex-wrap tag-wrapper'}>
                                {Object.values(TagEnum).map((tag) => (
                                    <div className={'tag-container'} key={tag}>
                                        <label htmlFor={tag} className={'tag-label-container'}>
                                            <div className={'tag-input-container'}>
                                                <input
                                                    id={tag}
                                                    name={'tags'}
                                                    type={'checkbox'}
                                                    value={tag || ''}
                                                    onChange={(e) => handleTags(e)}
                                                    checked={data?.tags?.includes(tag)}
                                                    disabled={disabled}
                                                />
                                            </div>
                                            <div
                                                className={classNames('tag-icon-container')}
                                            >
                                                <img
                                                    src={ICONS_TAG[tag]}
                                                    width={46}
                                                    height={46}
                                                    alt={TagText(tag)}

                                                />
                                                <p>{TagText(tag)}</p>
                                            </div>
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={'row mt-42'}>
                            <div className={'col-12 switch-wrapper'}>
                                <label>{'Listar em Queiridinhos da Edos?'}</label>
                                <SwitchToggle
                                    defaultChecked={data.isBestOfEdos}
                                    disabled={disabled}
                                    onChange={() => {
                                        updateFormData({
                                            isBestOfEdos: !data.isBestOfEdos,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className={'row mt-42'}>
                            <div className={'col-12 switch-wrapper'}>
                                <label>{'Não incluir na lista de compras'}</label>
                                <SwitchToggle
                                    defaultChecked={data.notPurchaseSuggestion}
                                    disabled={disabled}
                                    onChange={() => {
                                        updateFormData({
                                            notPurchaseSuggestion: !data.notPurchaseSuggestion,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={'col-9'}>
                        <div className={'row'}>
                            <div className={'col-12'}>
                                <h2 className={'mb-10'}>{'Características'}</h2>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col-4'}>
                                <FieldText
                                    label={'Nome do Produto'}
                                    onChange={({ target }) =>
                                        updateFormData({ name1: target.value })
                                    }
                                    value={data?.name1 || ''}
                                    validationError={formError?.name1}
                                    maxLength={120}
                                    disabled={disabled}
                                    readOnly={disabled}
                                />
                            </div>
                            <div className={'col-4'}>
                                <FieldProductFamily
                                    label={'Família'}
                                    addButton
                                    onSelected={(family) => updateFormData({ family })}
                                    select={data.family}
                                    validationError={formError.family}
                                    disabled={disabled}
                                    readOnly={disabled}
                                />
                            </div>
                            <div className={'col-4 d-flex align-items-center'}>
                                <p>{'Preço do produto na Família: '}{data.family ? formatCurrency('10') : 'Produto sem família'}</p>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col-4'}>
                                <FieldText
                                    label={'Nome Web'}
                                    onChange={({ target }) =>
                                        updateFormData({ shortName1: target.value })
                                    }
                                    value={data?.shortName1 || ''}
                                    validationError={formError?.shortName1}
                                    maxLength={120}
                                    disabled={disabled}
                                    readOnly={disabled}
                                />
                            </div>
                            <div className={'col-2'}>
                                <FieldProductBrand
                                    label={'Marca'}
                                    addButton
                                    onSelected={(brand) =>
                                        updateFormData({ brand })
                                    }
                                    select={data.brand}
                                    validationError={formError.brand}
                                    disabled={disabled}
                                    readOnly={disabled}
                                />
                            </div>
                            <div className={'col-2'}>
                                <FieldSegmentation
                                    label={'Segmentação'}
                                    addButton
                                    onSelected={(segmentation) =>
                                        updateFormData({ segmentation })
                                    }
                                    select={data.segmentation}
                                    validationError={formError.segmentation}
                                    disabled={disabled}
                                    readOnly={disabled}
                                />
                            </div>
                            <div className={'col-2'}>
                                <FieldProductCategory
                                    label={'Categoria'}
                                    addButton
                                    onSelected={(category) => {
                                        updateFormData({ category });
                                    }}
                                    select={data.category}
                                    validationError={formError.category}
                                    disabled={disabled}
                                    readOnly={disabled}
                                />
                            </div>
                            <div className={'col-2'}>
                                <FieldProductSubCategory
                                    label={'Subcategoria'}
                                    addButton
                                    validationCategory={data.category}
                                    onSelected={(subCategory) =>
                                        updateFormData({ subCategory })
                                    }
                                    select={data.subCategory}
                                    validationError={formError.subCategory}
                                    form
                                    disabled={disabled}
                                    readOnly={disabled}
                                />
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col-12'}>
                                <FieldTextarea
                                    label={'Descrição'}
                                    onChange={({ target }) => updateFormData({ description: target.value })}
                                    value={data?.description || ''}
                                    validationError={formError.description}
                                    rows={8}
                                    disabled={disabled}
                                    readOnly={disabled}
                                />
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col-12'}>
                                <FieldTextarea
                                    label={'Especificações Técnicas'}
                                    onChange={({ target }) => updateFormData({ shortDescription: target.value })}
                                    value={data?.shortDescription || ''}
                                    validationError={formError.shortDescription}
                                    rows={8}
                                    disabled={disabled}
                                    readOnly={disabled}
                                />
                            </div>
                        </div>
                        <div className={'row mt-42'}>
                            <div className={'col-3'}>
                                <h2 className={'mb-10'}>{'Códigos EAN'}</h2>
                            </div>
                            <div className={'col-9 d-flex justify-content-end'}>
                                <Button
                                    buttonStyle={ButtonStyle.BUTTON_TINY}
                                    onClick={() => setBarCodesModal(true)}
                                    disabled={disabled}
                                >
                                    {'Adicionar Código'}
                                </Button>
                            </div>
                        </div>
                        <div className={'row mt-20'}>
                            <div className={'col-12'}>
                                <Table
                                    columns={[
                                        {
                                            name: 'EAN',
                                        },
                                        {
                                            name: 'Principal',
                                        },
                                        {
                                            width: '20%',
                                        },
                                    ]}
                                >
                                    {barCodes?.map((p) => {
                                        return (
                                            <tr key={p.id}>
                                                <td>
                                                    {p.barCode}
                                                </td>
                                                <td>
                                                    {p.main ? 'Sim' : 'Não'}
                                                </td>
                                                <td>
                                                    <div className={'d-flex justify-content-end'}>
                                                        <Button
                                                            className={
                                                                'transparent mr-16'
                                                            }
                                                            onClick={() => {
                                                                setIdBarCode(p.id);
                                                                setBarCode(p.barCode);
                                                                setBarCodesModal(true);
                                                            }}
                                                            disabled={disabled}
                                                        >
                                                            <img
                                                                src={IconEditSvg}
                                                                alt={'Editar'}
                                                                title={'Editar'}
                                                                style={{
                                                                    width: '16px',
                                                                }}
                                                            />
                                                        </Button>
                                                        <Button
                                                            className={'transparent'}
                                                            onClick={() =>
                                                                setIdBarCodeToDelete(p.id)
                                                            }
                                                            disabled={disabled}
                                                        >
                                                            <img
                                                                src={
                                                                    IconButtonRemove
                                                                }
                                                                alt={'Excluir'}
                                                                title={'Excluir'}
                                                                style={{
                                                                    width: '11px',
                                                                }}
                                                            />
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </Table>
                                {formError.barCodes && (
                                    <p className={'barcodes-msg-error'}>
                                        {formError.barCodes}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className={'row mt-42'}>
                            <div className={'col-3'}>
                                <h2 className={'mb-10'}>{'Lojas'}</h2>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col-2'}>
                                <FieldStore
                                    label={'Adicionar loja'}
                                    onSelected={(store) => {
                                        if (!store) return;
                                        updateFormData({
                                            stores: [
                                                ...data.stores,
                                                {
                                                    uuid: store.id,
                                                    fantasyName: store.value,
                                                    balance: 0,
                                                    price: extractCurrencyNumber(data.price),
                                                },
                                            ],
                                        });
                                    }}
                                    ignoreItems={data.stores.map(
                                        (store) => store.uuid
                                    )}
                                    disabled={disabled}
                                    readOnly={disabled}
                                />
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col-12'}>
                                <Table
                                    columns={[
                                        {
                                            name: 'Loja',
                                            width: '20%',
                                        },
                                        {
                                            name: 'Tipo loja',
                                            align: 'center',
                                        },
                                        {
                                            align: 'center',
                                            name: 'Campanha ativa?',
                                        },
                                        {
                                            align: 'center',
                                            name: 'Preço de venda',
                                        },
                                        {
                                            align: 'center',
                                            name: 'Estoque',
                                        },
                                        {},
                                    ]}
                                >
                                    {data?.stores?.map((p) => {
                                        const campaign = data?.campaigns?.find(x => x.stores.find(y => y.uuid === p.uuid) && x.isActive);

                                        return (
                                            <tr key={p.uuid}>
                                                <td>
                                                    <Link
                                                        className={'product-name'}
                                                        onClick={(e) => e.stopPropagation()}
                                                        target={'_blank'}
                                                        to={getStoresFormRoute(p.uuid)}
                                                    >
                                                        {p.fantasyName}
                                                    </Link>
                                                </td>
                                                <td className={'text-center'}>
                                                    {p.isOnline ? 'Online' : 'Física'}
                                                </td>
                                                <td className={'text-center'}>
                                                    {campaign ? `Sim (${campaign.name} - ${campaign.value}%)` : 'Não'}
                                                </td>
                                                <td className={'text-center'}>
                                                    {formatValue(p.price)}
                                                </td>
                                                <td className={'text-center'}>
                                                    {`${p.balance}${p.isOnline ? ' (CD)' : ''}`}
                                                </td>
                                                <td>
                                                    <div className={'d-flex justify-content-end'}>
                                                        <Button
                                                            className={'transparent'}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                updateFormData({
                                                                    stores: data.stores.filter(
                                                                        (item) =>
                                                                            item.uuid !== p.uuid
                                                                    ),
                                                                });
                                                            }
                                                            }
                                                            disabled={disabled}
                                                        >
                                                            <img
                                                                src={
                                                                    IconButtonRemove
                                                                }
                                                                alt={'Excluir'}
                                                                title={'Excluir'}
                                                                style={{
                                                                    width: '11px',
                                                                }}
                                                            />
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </Table>
                            </div>
                        </div>
                        {
                            data.parentProduct ? <>
                                <div className={'row mt-42'}>
                                    <div className={'col-6'}>
                                        <h2 className={'mb-10'}>{'Variação'}</h2>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col-4'}>
                                        <FieldText
                                            label={'Variação do produto'}
                                            onChange={({ target }) =>
                                                updateFormData({
                                                    variation: target.value,
                                                })
                                            }
                                            value={data?.variation || ''}
                                            validationError={formError?.variation}
                                            disabled={disabled}
                                            readOnly={disabled}
                                        />
                                    </div>
                                    <div className={'col-8'}>
                                        <label >{'Variação principal: '}</label>
                                        <Link
                                            className={'product-name ml-16'}
                                            onClick={(e) => e.stopPropagation()}
                                            target={'_blank'}
                                            to={getProductFormRoute(mainVariationProduct?.uuid)}
                                        >
                                            {mainVariationProduct?.name1}{' - '}{mainVariationProduct?.variation}
                                        </Link>
                                    </div>
                                </div>
                            </>
                                : <>
                                    <div className={'row mt-42'}>
                                        <div className={'col-6'}>
                                            <h2 className={'mb-10'}>{'Variações'}</h2>
                                        </div>
                                        <div className={'col-6 d-flex justify-content-end'}>
                                            <Button
                                                buttonStyle={ButtonStyle.BUTTON_TINY}
                                                onClick={() => setShowProductVariationModal(true)}
                                                title={'Criar novo produto como variável'}
                                                disabled={disabled || isNew}
                                            >
                                                {'Nova variação'}
                                            </Button>
                                        </div>
                                    </div>
                                    <div className={'row'}>
                                        <div className={'col-2'}>
                                            <FieldText
                                                label={'Variação principal'}
                                                onChange={({ target }) =>
                                                    updateFormData({
                                                        variation: target.value,
                                                    })
                                                }
                                                value={data?.variation || ''}
                                                validationError={formError?.variation}
                                                disabled={disabled}
                                                readOnly={disabled}
                                            />
                                        </div>
                                    </div>
                                    <div className={'row'}>
                                        <div className={'col-12'}>
                                            <Table
                                                columns={[
                                                    {
                                                        name: 'EAN',
                                                        width: '20%',
                                                    },
                                                    {
                                                        name: 'Nome do produto',
                                                    },
                                                    {
                                                        name: 'Variação',
                                                        width: '20%',
                                                    },
                                                ]}
                                            >
                                                {data?.variations?.map((p) => {
                                                    return (
                                                        <tr key={p.uuid}>
                                                            <td>
                                                                {p.barCodes[0].barCode}
                                                            </td>
                                                            <td>
                                                                <Link
                                                                    className={'product-name'}
                                                                    onClick={(e) => e.stopPropagation()}
                                                                    target={'_blank'}
                                                                    to={getProductFormRoute(p.uuid)}
                                                                >
                                                                    {p.name1}
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <FieldText
                                                                    fieldGroup={false}
                                                                    required
                                                                    disabled={disabled || p.uuid === data.parentProduct}
                                                                    value={p.variation}
                                                                    validationError={
                                                                        formError?.variations
                                                                    }
                                                                    onChange={({
                                                                        target,
                                                                    }) =>
                                                                        updateVariationItem(
                                                                            p.uuid,
                                                                            {
                                                                                variation:
                                                                        target.value,
                                                                            }
                                                                        )}
                                                                    readOnly={disabled}
                                                                />
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </Table>
                                        </div>
                                    </div>
                                </>
                        }
                        <div className={'row mt-42'}>
                            <div className={'col-6'}>
                                <h2 className={'mb-10'}>{'Unidades de Compra e Venda (UCV)'}</h2>
                            </div>
                            {
                                !data.ucvParentProduct && (
                                    <div className={'col-6 d-flex justify-content-end'}>
                                        <Button
                                            buttonStyle={ButtonStyle.BUTTON_TINY}
                                            onClick={() => window.open(`/produtos/listagem/novo?uuid=${data.uuid}&ucvParentProduct=true`, '_blank')}
                                            title={'Criar nova Unidade de Compra e Venda'}
                                            disabled={disabled || isNew}
                                        >
                                            {'Nova Unidade de Compra e Venda'}
                                        </Button>
                                    </div>
                                )
                            }
                        </div>
                        {
                            !data.ucvParentProduct ? (
                                <>
                                    <div className={'row'}>
                                        <div className={'col-2'}>
                                            <FieldInteger
                                                label={'UCV principal'}
                                                onChange={({ target }) =>
                                                    updateFormData({
                                                        packageQuantity: target.value,
                                                    })
                                                }
                                                value={data?.packageQuantity || ''}
                                                thousandsSeparator={false}
                                                validationError={formError?.packageQuantity}
                                                maxLength={15}
                                                disabled={true}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className={'row'}>
                                        <div className={'col-12'}>
                                            <Table
                                                columns={[
                                                    {
                                                        name: 'EAN',
                                                        width: '20%',
                                                    },
                                                    {
                                                        name: 'Nome do produto',
                                                    },
                                                    {
                                                        name: 'Quantidade comercializada',
                                                        width: '30%',
                                                    },
                                                ]}
                                            >
                                                {data?.ucvs?.map((p) => {
                                                    return (
                                                        <tr key={p.uuid}>
                                                            <td>
                                                                {p.barCodes[0].barCode}
                                                            </td>
                                                            <td>
                                                                <Link
                                                                    className={'product-name'}
                                                                    onClick={(e) => e.stopPropagation()}
                                                                    target={'_blank'}
                                                                    to={getProductFormRoute(p.uuid)}
                                                                >
                                                                    {p.name1}
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <FieldText
                                                                    fieldGroup={false}
                                                                    value={p.packageQuantity}
                                                                    validationError={
                                                                        formError?.packageQuantity
                                                                    }
                                                                    onChange={({
                                                                        target,
                                                                    }) =>
                                                                        updateFormData({ packageQuantity: target.value })
                                                                    }
                                                                    disabled={disabled}
                                                                    readOnly={disabled}
                                                                />
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </Table>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className={'row'}>
                                        <div className={'col-2'}>
                                            <FieldInteger
                                                label={'UCV do produto'}
                                                onChange={({ target }) =>
                                                    updateFormData({
                                                        packageQuantity: target.value,
                                                    })
                                                }
                                                value={data?.packageQuantity || ''}
                                                thousandsSeparator={false}
                                                validationError={formError?.packageQuantity}
                                                maxLength={15}
                                                disabled={disabled}
                                                readOnly={disabled}
                                            />
                                        </div>
                                        <div className={'col-10'}>
                                            <label >{'UCV principal: '}</label>
                                            <Link
                                                className={'product-name ml-16'}
                                                onClick={(e) => e.stopPropagation()}
                                                target={'_blank'}
                                                to={getProductFormRoute(mainUCVProduct?.uuid)}
                                            >
                                                {mainUCVProduct?.name1}
                                            </Link>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        <div className={'row mt-42'}>
                            <div className={'col-6'}>
                                <h2 className={'mb-10'}>{'Semelhantes'}</h2>
                            </div>
                            <div className={'col-6 d-flex justify-content-end'}>
                                <Button
                                    buttonStyle={ButtonStyle.BUTTON_TINY}
                                    onClick={() => setShowProductSimilarModal(true)}
                                    title={'Criar novo produto semelhante'}
                                    disabled={disabled || isNew}
                                >
                                    {'Novo semelhante'}
                                </Button>
                            </div>
                        </div>
                        <div className={'row mt-16'}>
                            <div className={'col-12'}>
                                <Table
                                    columns={[
                                        {
                                            name: 'EAN',
                                            width: '20%',
                                        },
                                        {
                                            name: 'Nome do produto',
                                        },
                                        {},
                                    ]}
                                >
                                    {data?.similarProducts?.map((p) => {
                                        return (
                                            <tr key={p.uuid}>
                                                <td>
                                                    {p.barCodes?.find(x => x.main)?.barCode}
                                                </td>
                                                <td>
                                                    <Link
                                                        className={'product-name'}
                                                        onClick={(e) => e.stopPropagation()}
                                                        target={'_blank'}
                                                        to={getProductFormRoute(p.uuid)}
                                                    >
                                                        {p.name1}
                                                    </Link>
                                                </td>
                                                <td>
                                                    <div className={'d-flex justify-content-end'}>
                                                        <Button
                                                            className={'transparent'}
                                                            onClick={() => {
                                                                updateFormData({
                                                                    similarProducts: data.similarProducts.filter(
                                                                        (item) =>
                                                                            item !== p
                                                                    ),
                                                                });
                                                            }
                                                            }
                                                            disabled={disabled}
                                                        >
                                                            <img
                                                                src={
                                                                    IconButtonRemove
                                                                }
                                                                alt={'Excluir'}
                                                                title={'Excluir'}
                                                                style={{
                                                                    width: '11px',
                                                                }}
                                                            />
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <MultipleImages
                showModal={showImagesModal}
                onCloseModal={setShowImagesModal}
                pictures={data?.pictures || []}
                editPicture={true}
                productName={
                    <p>
                        {data.name1}
                    </p>
                }
                onChange={(image) => {
                    updateFormData({
                        pictures: [...data.pictures, image],
                    });
                }}
                onRemoveImage={(image) => {
                    const filteredImages = data.pictures.filter(
                        (img) =>
                            image?.uuid
                                ? img.uuid !== image.uuid
                                : img.id !== image.id
                    );
                    updateFormData({ pictures: filteredImages });
                }}
            />

            <BarCodesModal
                showModal={barCodesModal}
                onCloseModal={setBarCodesModal}
                barCodes={barCodes}
                onSetBarCodes={setBarCodes}
                idBarCode={idBarCode}
                setIdBarCode={setIdBarCode}
                barCode={barCode}
                setBarCode={setBarCode}
            />

            <ConfirmDeleteBarCodesModal
                showModal={confirmDeleteBarCodesModal}
                onConfirm={() => {
                    const newBarCodes = barCodes.filter(
                        (code) => code.id !== idBarCodeToDelete
                    );

                    if (newBarCodes?.length === 1) {
                        newBarCodes[0].main = true;
                    } else {
                        if (
                            newBarCodes[0]?.main &&
                            !handleValidateEAN(
                                newBarCodes[0]?.barCode
                            )
                        ) {
                            newBarCodes[0].main = false;
                        }
                    }

                    setBarCodes(newBarCodes);
                    setIdBarCodeToDelete(null);
                    setConfirmDeleteBarCodesModal(false);
                }}
                onCancel={() => {
                    setIdBarCodeToDelete(null);
                    setConfirmDeleteBarCodesModal(false);
                }}
            />

            <SearchProductModal
                globalSearch
                onlyParent
                onNewProduct={() => {
                    window.open(`/produtos/listagem/novo?uuid=${data.uuid}&parentProduct=true`, '_blank');
                    setShowProductVariationModal(false);
                }}
                newProductText={'Criar novo produto variável'}
                show={showProductVariationModal}
                onCancel={() => setShowProductVariationModal(false)}
                onSelect={(prod) => {
                    if (prod) {
                        const newVariations = [...data.variations];

                        newVariations.push(prod);

                        updateFormData({
                            variations: newVariations,
                        });

                        setShowProductVariationModal(false);
                    }
                }}
            />

            <SearchProductModal
                globalSearch
                onlyParent
                show={showProductSimilarModal}
                onCancel={() => setShowProductSimilarModal(false)}
                onSelect={(prod) => {
                    if (prod) {
                        const newSimilars = [...data.similarProducts];

                        newSimilars.push(prod);

                        updateFormData({
                            similarProducts: newSimilars,
                        });

                        setShowProductSimilarModal(false);
                    }
                }}
            />
        </>
    );
}
