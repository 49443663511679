import { useEffect, useState } from 'react';
import FieldDate from '../../components/FieldDate';
import FieldNumber from '../../components/FieldNumber';
import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import './SuggestionModal.scss';
import { DateTime } from 'luxon';
import ToolTip from '../../components/ToolTip';

export default function PurchaseSuggestionsFilterModal({
    show,
    onCancel,
    onConfirm,
    filter,
}) {

    const [ref1InitialDate, setRef1InitialDate] = useState(filter.ref1InitialDate ?? '');
    const [ref2InitialDate, setRef2InitialDate] = useState(filter.ref2InitialDate ?? '');
    const [ref1FinalDate, setRef1FinalDate] = useState(filter.ref1FinalDate ?? '');
    const [ref2FinalDate, setRef2FinalDate] = useState(filter.ref2FinalDate ?? '');
    const [dateRef1, setDateRef1] = useState(30);
    const [dateRef2, setDateRef2] = useState(30);

    const confirm = () => {
        onConfirm({
            ref1InitialDate,
            ref2InitialDate,
            ref1FinalDate,
            ref2FinalDate,
        });
    };

    useEffect(() => {
        setRef1InitialDate(DateTime.now().minus({ days: dateRef1 }).toFormat('yyyy-MM-dd'));
        setRef1FinalDate(DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd'));

        setRef2InitialDate(DateTime.now().minus({ days: dateRef1 + dateRef2 }).toFormat('yyyy-MM-dd'));
        setRef2FinalDate(DateTime.now().minus({ days: dateRef1 + 1 }).toFormat('yyyy-MM-dd'));
    }, [dateRef1, dateRef2]);

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={onCancel}
            onConfirm={confirm}
            // I18N
            title={'Sugestão do período'}
        >
            <div className={'suggestion_modal'}>
                <div className={'col-12 align-left'}>
                    <h3 className={'color-black mb-20'}>{'Referência 1'}</h3>
                </div>
                <div className={'col-12 d-flex mb-35'}>
                    {
                        [30, 60, 90].map((daysCount, index) => (
                            <div key={index} className={`pill-suggestion-day mr-20${dateRef1 === daysCount ? ' active' : ''}`} onClick={() => setDateRef1(daysCount)}>
                                {`${daysCount} dias`}
                            </div>
                        ))
                    }
                </div>
                <div className={'row'}>
                    <div className={'col-6'}>
                        <FieldDate
                            label={'Início'}
                            onChange={({ target }) => setRef1InitialDate(target.value)}
                            value={ref1InitialDate}
                        >

                        </FieldDate>
                    </div>

                    <div className={'col-6'}>
                        <FieldDate
                            label={'Término'}
                            onChange={({ target }) => setRef1FinalDate(target.value)}
                            value={ref1FinalDate}
                        >
                        </FieldDate>
                    </div>
                </div>

                <div className={'col-12 align-left mt-20 suggestion-tooltip'}>
                    <h3 className={' color-black mb-20'}>
                        {'Referência 2'}
                    </h3>
                    <ToolTip
                        description={'Datas da referência a partir da Referência 1'}
                    />
                </div>
                <div className={'col-12 d-flex mb-35'}>
                    {
                        [30, 60, 90].map((daysCount, index) => (
                            <div key={index} className={`pill-suggestion-day mr-20${dateRef2 === daysCount ? ' active' : ''}`} onClick={() => setDateRef2(daysCount)}>
                                {`${daysCount} dias`}
                            </div>
                        ))
                    }
                </div>
                <div className={'row'}>
                    <div className={'col-6'}>
                        <FieldDate
                            label={'Início'}
                            onChange={({ target }) => setRef2InitialDate(target.value)}
                            value={ref2InitialDate}
                        >
                        </FieldDate>
                    </div>

                    <div className={'col-6'}>
                        <FieldDate
                            label={'Término'}
                            onChange={({ target }) => setRef2FinalDate(target.value)}
                            value={ref2FinalDate}
                        >
                        </FieldDate>
                    </div>
                </div>
            </div>
        </SimpleConfirmModal>
    );
}
