import { VirtualOrderStatusNextStatus, VirtualOrderStatus, VirtualOrderStatusEnum } from 'erva-doce-common';
import {
    IconRoundedCheckGray,
    IconRoundedCheckGreen,
} from '../../../components/images';
import '../../order/order-detail/OrderStatusTimeline.scss';
import { DateTime } from 'luxon';
import React from 'react';

export default function VirtualOrderStatusTimeline({ data }) {
    if (data?.status === VirtualOrderStatusEnum.CANCELLED || data?.status === VirtualOrderStatusEnum.DELIVERED) {
        data.status = VirtualOrderStatusEnum.COMPLETED;
    }

    return (
        <div className={'purchase-status-timeline__container mb-42 mt-42'}>
            {Object.values(VirtualOrderStatusEnum).map((statusItem, index) => {
                if (statusItem === VirtualOrderStatusEnum.CANCELLED) return null;

                const isActive = data?.statusHistory 
                    && !!data.statusHistory.filter(x => x.status === statusItem)?.length 
                    || data?.status === VirtualOrderStatusEnum.COMPLETED;

                const activeItem = isActive && data.statusHistory.filter((x) => x.status === statusItem);
                const cancelledStatus =  data?.statusHistory?.find(
                    (x) => x.status === VirtualOrderStatusEnum.CANCELLED
                );
                
                const isNext = VirtualOrderStatusNextStatus?.[data.status]?.includes(statusItem);                
                const isLast = index === data?.statusHistory?.length - 1;

                return (
                    <div
                        key={index}
                        style={{ fontSize: 14 }}
                        className={'purchase-status-timeline__item'}
                    >
                        <div className={`circle${isActive ? ' active' : ''}`}>
                            <img
                                src={isActive
                                    ? IconRoundedCheckGreen
                                    : IconRoundedCheckGray
                                }
                                alt={'Check'}
                            />
                        </div>
                        <p>{VirtualOrderStatus(statusItem)}</p>
                        {isActive && (
                            <div className={'created-at'}>
                                {
                                    activeItem.length > 1 ? (
                                        <div className={'tooltip-container'}>
                                            <span className={'tooltip'}>
                                                {DateTime.fromISO(
                                                    activeItem.at(-1).createdAt
                                                ).toFormat('dd/MM/yyyy HH:mm')}{` (${activeItem.length})`}
                                                <span className={'tooltip-text'}>
                                                    <div>
                                                        <strong>{'Histórico:'}</strong>
                                                        {
                                                            activeItem.map((item, index) => (
                                                                <p key={index}>
                                                                    {DateTime.fromISO(item.createdAt).toFormat('dd/MM/yyyy HH:mm')}
                                                                </p>
                                                            ))
                                                        }
                                                    </div>
                                                </span>
                                            </span>
                                        </div>
                                    ) : (
                                        <p>
                                            {DateTime.fromISO(activeItem[0]?.createdAt || 
                                                data.statusHistory.find(s => s.status === VirtualOrderStatusEnum.PAYMENT_APPROVED)?.createdAt
                                            ).toFormat('dd/MM/yyyy HH:mm')}
                                        </p>
                                    )
                                }
                                {(data.urlNFCe && statusItem === VirtualOrderStatusEnum.NOTE_ISSUED) && (
                                    <a
                                        href={data.urlNFCe}
                                        target={'_blank'}
                                        rel={'noreferrer'}
                                        className={'d-block'}
                                    >
                                        {'Baixar Nota Fiscal'}
                                    </a>
                                )}
                            </div>
                        )}
                        {isNext && !isActive && (
                            <div className={'created-at'}>
                                {'Aguardando revisão'}
                            </div>
                        )}
                        {data.status === VirtualOrderStatusEnum.CANCELLED &&
                            isLast && (
                            <div className={'created-at'}>
                                {`(CANCELADO ${DateTime.fromISO(cancelledStatus.createdAt).toFormat('dd/MM/yyyy HH:mm')})`}
                            </div>
                        )}
                    </div>
                );})}
        </div>
    );
}
