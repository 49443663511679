import api from '../util/api';

export async function create(data) {
    return (
        await api.post('/bills-to-receive', data)
    ).data;
}

export async function update({ data }) {
    return (
        await api.put('/bills-to-receive/confirm-receivable', data)
    ).data;
}

export async function get(filters) {
    return (
        await api.get('/bills-to-receive', {
            params: filters,
        })
    ).data;
}

export async function getByUUID(uuid) {
    return (
        await api.get(`/bills-to-receive/${uuid}`)
    ).data;
}

export async function destroy(id) {
    return (
        await api.delete(`/bills-to-receive/${id}`)
    ).data;
}

