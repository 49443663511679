import './MovementScss.scss';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { OrderByNameCashierEnum } from 'erva-doce-common';
import FieldDate from '../../components/FieldDate';
import ScreenHeader from '../../components/logged/ScreenHeader';
import Section from '../../components/Section';
import Table from '../../components/Table';
import PaymentMethodTableComponent from './PaymentMethodTable';
import { formatValue } from '../../util/utilCurrency';
import Button, { ButtonColor, ButtonFontColor } from '../../components/Button';
import { getCashClosingPage } from './CashClosing';
import { getDateToDayForInputHtml } from '../../util/utilDate';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import CashFlowSummaryComponent from './CashFlowSummary';
import * as MovementService from '../../services/MovementService';
import * as CashierService from '../../services/CashiersService';
import * as CashClosingService from '../../services/CashClosingService';

export function getPanelPage() {
    return '/vendas/painel';
}

export default function PanelPage() {
    const title = 'Painel';
    const buttonSaveRef = useRef();
    const navigate = useNavigate();
    const { user, addHotkey, removeHotkey, setLoading } = useContext(EnvironmentContext);
    const [data, setDate] = useState(getDateToDayForInputHtml());
    const [caixa, setCaixa] = useState([]);
    const [shoppingInterval, setShoppingInterval] = useState([]);
    const [machineValues, setMachineValues] = useState([]);
    const [selectedCollaborator, setSelectedCollaborator] = useState({
        name: 'Nenhum caixa foi selecionado ainda',
        id: 0,
        value: '',
        cashClosingUuid: null,
    });

    const formatedDate = ({ type }) => {
        if (!data) return type;
        const [aaaa, mm, dd] = data.split('-');
        return `${dd}/${mm}/${aaaa} - ${type}`;
    };

    const fetchCaixas = async () => {
        setLoading(true);
        try {
            const result = await CashierService.getCashiers(
                0,
                null,
                OrderByNameCashierEnum.NAME_DESC
            );

            const cashierList = await Promise.all(
                result?.records?.map(async (record) => {
                    const cashClosing =
                        await CashClosingService.getCashClosingByCashier(record.id);
                    return {
                        id: record.id,
                        uuid: record.uuid,
                        value: record.name,
                        status: record.status,
                        collaborator: record.collaborator
                            ? {
                                name: record.collaborator.name,
                                id: record.collaborator.id,
                                uuid: record.collaborator.uuid,
                            }
                            : null,
                        cashClosingUuid: cashClosing?.uuid,
                        statusVouchers: cashClosing?.statusVouchers,
                    };
                })
            );
            setCaixa(cashierList);
        } catch (error) {
            console.error('Erro ao buscar caixas:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchCashMovements = async () => {
        try {
            const fetch = async ({ id }) => {
                const result = await MovementService.getCashMovements({
                    date: data,
                    userId: id,
                });
                setShoppingInterval(result);
            };

            if (selectedCollaborator?.id !== 0) {
                await fetch({ id: selectedCollaborator?.id });
                return;
            }

            if (user?.cashier?.collaborator && selectedCollaborator?.id !== 0) {
                await fetch({ id: user?.cashier?.collaborator });
                return;
            }

            setShoppingInterval([]);
        } catch (error) {
            console.error('Erro ao buscar movimentos de caixa:', error);
        }
    };

    const fetchMachineValues = async () => {
        try {
            const fetch = async ({ id }) => {
                const result = await MovementService.getMachineValues({
                    date: data,
                    userId: id,
                });
                setMachineValues(result);
            };

            if (selectedCollaborator?.id !== 0) {
                await fetch({ id: selectedCollaborator?.id });
                return;
            }

            if (user?.cashier?.collaborator && selectedCollaborator?.id !== 0) {
                await fetch({ id: user?.cashier?.collaborator });
                return;
            }

            setMachineValues([]);
        } catch (error) {
            console.error('Erro ao buscar valores da máquina:', error);
        }
    };

    const handleCollaboratorChange = ({ value }) => {
        const collaborator = caixa?.find((user) => user?.value === value);
        if (collaborator) {
            setSelectedCollaborator({
                ...collaborator?.collaborator,
                value: collaborator?.value,
                cashierId: collaborator?.id,
                uuid: collaborator?.uuid,
                status: collaborator?.status,
                cashClosingUuid: collaborator?.cashClosingUuid,
                statusVouchers: collaborator?.statusVouchers,
            });
        } else {
            setSelectedCollaborator({
                name: '',
                id: 0,
                value: '',
                uuid: null,
                status: null,
                cashClosingUuid: null,
            });
        }
    };

    useEffect(() => {
        fetchCaixas();
    }, []);

    useEffect(() => {
        const value = user?.cashier?.name;
        if (value) {
            handleCollaboratorChange({ value });
        }
    }, [caixa]);

    useEffect(() => {
        if (data || selectedCollaborator) {
            fetchMachineValues();
            fetchCashMovements();
        }
    }, [data, selectedCollaborator]);

    useEffect(() => {
        const f6Hotkey = addHotkey('F4', () => buttonSaveRef.current?.click());

        return () => {
            removeHotkey(f6Hotkey);
        };
    }, [addHotkey, removeHotkey]);

    return (
        <div className={'crud-list'}>
            <ScreenHeader
                title={title}
                breadcrumbs={[
                    { name: 'Caixa', route: '/' },
                    { name: title, route: getPanelPage() },
                ]}
            />

            <div className={'gd'}>
                <div className={'gd-col gd-col-6'} />
                <div className={'gd-col gd-col-2'}>
                    <FieldDate
                        onChange={({ target }) => {
                            setDate(target.value);
                        }}
                        value={data}
                        className={'select-cashier'}
                    />
                </div>

                <>
                    <div className={'gd-col gd-col-2 '}>
                        <div className={'user-collaborator-box'}>
                            <p>{selectedCollaborator.value}</p>
                        </div>
                    </div>
                    <div className={'gd-col gd-col-2 '}>
                        <div className={'user-collaborator-box'}>
                            <p>{selectedCollaborator.name}</p>
                        </div>
                    </div>
                </>
            </div>
            <Section
                title={formatedDate({ type: 'Reforço, Sangria e Trocas' })}
            >
                <CashFlowSummaryComponent
                    valueDate={data}
                    cashClosinguuid={selectedCollaborator.cashClosingUuid}
                    className={'mb-42 mt-10'}
                    cashierStatus={selectedCollaborator.status}
                    cashieruuid={selectedCollaborator.uuid}
                />


            </Section>

            <Section title={formatedDate({ type: 'Informações das vendas por hora' })}>
                <Table
                    columns={[
                        { name: 'Hora inicial', align: 'center' },
                        { name: 'Hora final', align: 'center' },
                        { name: 'Qtd. Vendas', align: 'center' },
                        { name: 'Total vendido', align: 'center' },
                    ]}
                >
                    {shoppingInterval.map((sale, index) => (
                        <tr key={index}>
                            <td className={'center'}>{sale.startTime}</td>
                            <td className={'center'}>{sale.endTime}</td>
                            <td className={'center'}>{sale.quantity}</td>
                            <td className={'center'}>
                                {formatValue({ value: sale.totalSales })}
                            </td>
                        </tr>
                    ))}
                </Table>
            </Section>

            <PaymentMethodTableComponent data={machineValues} />

            <div className={'controls row mt-42'}>
                <div className={'col-10'} />
                <div className={'col-2'}>
                    <Button
                        className={'w-100'}
                        ref={buttonSaveRef}
                        color={ButtonColor.BUTTON_COLOR_GREEN}
                        fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                        onClick={() =>
                            navigate(
                                getCashClosingPage(selectedCollaborator.uuid)
                            )
                        }
                    >
                        {'Fechar caixa [F4]'}
                    </Button>
                </div>
            </div>
        </div>
    );
}
