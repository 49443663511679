import { useNavigate } from 'react-router';
import Table from '../../components/Table';
import { formatValue } from '../../util/formatValue';
import { getOrderDetailRoute } from './order-detail/OrderDetail';
import { getRepresentativeFormRoute } from '../representatives/RepresentativeForm';
import { getSupplierFormRoute } from '../suppliers/SupplierForm';
import { DateTime } from 'luxon';
import { OrderByOrdersEnum, PermissionsEnum } from 'erva-doce-common';
import PermissionedLink from '../../components/PermissionedLink';

export default function OrdersTable({ data, filter, isLoading, onSortChange }) {
    const navigate = useNavigate();

    return (
        <Table
            loading={isLoading}
            currentSort={filter.order}
            columns={[
                {
                    name: 'N° Pedido',
                    sortAsc: OrderByOrdersEnum.ID_ASC,
                    sortDesc: OrderByOrdersEnum.ID_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
                {
                    name: 'Valor',
                    sortAsc: OrderByOrdersEnum.TOTAL_ASC,
                    sortDesc: OrderByOrdersEnum.TOTAL_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
                {
                    name: 'Listagem de fornecedores',
                    sortAsc: OrderByOrdersEnum.SUPPLIER_ASC,
                    sortDesc: OrderByOrdersEnum.SUPPLIER_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
                {
                    name: 'Em andamento',
                    align: 'center',
                },
                {
                    name: 'Valor pendência',
                    align: 'center',
                },
                {
                    name: 'Previsão Faturamento',
                    align: 'center',
                    sortAsc: OrderByOrdersEnum.BILLING_DATE_ASC,
                    sortDesc: OrderByOrdersEnum.BILLING_DATE_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
                {
                    name: 'Previsão Entrega',
                    align: 'center',
                    sortAsc: OrderByOrdersEnum.DELIVERY_DATE_ASC,
                    sortDesc: OrderByOrdersEnum.DELIVERY_DATE_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
                {
                    name: 'Última Atualização',
                    align: 'center',
                    sortAsc: OrderByOrdersEnum.LAST_UPDATE_ASC,
                    sortDesc: OrderByOrdersEnum.LAST_UPDATE_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
            ]}
        >
            {data?.map((item, index) => (
                <tr
                    className={'table-row'}
                    key={index}
                    onClick={() => navigate(getOrderDetailRoute(item.id))}
                >
                    <td className={'supplier'}>
                        <div>
                            <p>{`${item.id}`.padStart(7, '0')}</p>
                            <p>
                                {DateTime.fromISO(item.createdAt).toFormat(
                                    'dd/MM/yyyy'
                                )}
                            </p>
                        </div>
                    </td>
                    <td>{formatValue(item.total)}</td>
                    <td className={'supplier '}>
                        <div className={'d-flex flex-column'}>
                            <p> 
                                <PermissionedLink
                                    permission={PermissionsEnum.HANDLE_SUPPLIERS}
                                    to={getSupplierFormRoute(item?.supplierUuid)}
                                > 
                                    {item.supplierName} 
                            
                                </PermissionedLink>
                                <br />
                                <PermissionedLink
                                    permission={PermissionsEnum.HANDLE_SUPPLIERS}
                                    to={getRepresentativeFormRoute(item?.representativeUuid)}
                                > 
                                    {item.representativeName}
                                </PermissionedLink>
                            </p>
                        </div>
                    </td>
                    <td className={'supplier text-center'}>
                        {item.pendingOrdersValue ? (
                            <div className={'d-flex align-items-center'}>
                                <p>{formatValue(item.pendingOrdersValue)}</p>
                                <p>{`${`${item.pendingOrdersQty}`.padStart(
                                    2,
                                    '0'
                                )} andamento`}</p>
                            </div>
                        ) : (
                            <p>{'sem pedido'}</p>
                        )}
                    </td>
                    <td className={'supplier text-center'}>
                        {item.pendingValue ? (
                            <div className={'d-flex align-items-center'}>
                                <p>{formatValue(item.pendingValue)}</p>
                                <p>{item.pendingDate}</p>
                            </div>
                        ) : (
                            <p>{'sem pendência'}</p>
                        )}
                    </td>
                    <td className={'text-center'}>
                        {item.billingDate
                            ? DateTime.fromISO(item.billingDate).toFormat(
                                'dd/MM/yyyy'
                            )
                            : 'sem previsão'}
                    </td>
                    <td className={'text-center'}>
                        {item.deliveryDate
                            ? DateTime.fromISO(item.deliveryDate).toFormat(
                                'dd/MM/yyyy'
                            )
                            : 'sem previsão'}
                    </td>
                    <td className={'text-center'}>
                        {DateTime.fromISO(item.updatedAt).toFormat(
                            'dd/MM/yyyy'
                        )}
                    </td>
                </tr>
            ))}
        </Table>
    );
}
