import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import FieldCurrency, { extractCurrencyNumber, formatCurrency } from '../../components/FieldCurrency';
import FieldDate from '../../components/FieldDate';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import * as BillsToReceiveService from '../../services/BillsToReceiveService';
import { getBillsToReceiveRoute } from './BillsToReceive';
import { DateTime } from 'luxon';
import FieldNumber from '../../components/FieldNumber';
import Button, { ButtonColor, ButtonFontColor, ButtonStyle } from '../../components/Button';
import SelectAccountsModal from '../../components/SelectAccountsModal';
import FieldText from '../../components/FieldText';
import FieldContact from '../../components/FieldContact';
import { ENUM_SHORTCUT } from 'erva-doce-common';
import emptyField from '../../util/validateEmptyTextField';

const INITIAL_STATE = {
    name: '',
    description: '',
    value: '',
    issueDate: '',
    dueDate: '',
    totalInstallments: '',
    numberDocument: '',
};

export default function BillsToReceiveForm() {
    const title = 'Contas a receber';
    const { uuid } = useParams();
    const navigate = useNavigate();
    const isNew = !uuid;
    const {
        addHotkey,
        removeHotkey,
        setInfoModal,
        backendConnectionError,
    } = useContext(EnvironmentContext);

    const [, setIsLoading] = useState(false);
    const [formData, setFormData] = useState(INITIAL_STATE);
    const [formError, setFormError] = useState({});
    const [showSelectAccount, setShowSelectAccount] = useState(false);
    const createAccountReceivableBtnRef = useRef();
    const deleteReceiptBtnRef = useRef();
    const writeOffReceipt = useRef();

    const updateFormData = (value) => {
        setFormData(state => ({
            ...state,
            ...value,
        }));
    };

    const hasValidationErrors = () => {
        let hasError = false;
        setFormError({});

        if (!formData.name || emptyField(formData.name)) {
            hasError = true;
            setFormError(state => ({ ...state, name: 'Preencha corretamente o campo' }));
        }

        if (!formData.numberDocument || emptyField(formData.numberDocument)) {
            hasError = true;
            setFormError(state => ({ ...state, numberDocument: 'Preencha corretamente o campo' }));
        }
        if (!formData.payer?.id && !formData.supplier?.id && !formData.customer?.id) {
            hasError = true;
            setFormError(state => ({ ...state, payer: 'Preencha corretamente o campo' }));
        }

        if (!formData.value || extractCurrencyNumber(formData.value) === 0) {
            hasError = true;
            setFormError(state => ({ ...state, value: 'Preencha corretamente o campo' }));
        }

        if (!formData.issueDate) {
            hasError = true;
            setFormError(state => ({ ...state, issueDate: 'Preencha corretamente o campo' }));
        }

        if (!formData.dueDate) {
            hasError = true;
            setFormError(state => ({ ...state, dueDate: 'Preencha corretamente o campo' }));
        }

        return hasError;
    };

    const save = async () => {
        if (hasValidationErrors()) return;

        try {
            setIsLoading(true);
            await BillsToReceiveService.create({
                ...formData,
                value: extractCurrencyNumber(formData.value),
                payer: formData?.payer?.id,
                supplier: formData?.supplier?.id,
                customer: formData?.customer?.id,
            });

            setInfoModal({
                title,
                message: 'Novo recebimento criada com sucesso!',
                style: InfoModalStyle.SUCCESS,
                show: true,
                onClose: () => navigate(getBillsToReceiveRoute()),
            });

        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to save billToReceive', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    const update = async (accountId) => {
        try {
            setIsLoading(true);

            const data = [{
                uuid: uuid,       
                received: true,
                account: accountId,
            }];

            await BillsToReceiveService.update({ data });

            setInfoModal({
                title,
                message: 'Baixa em recebimento com sucesso',
                style: InfoModalStyle.SUCCESS,
                show: true,
                onClose: () => navigate(getBillsToReceiveRoute()),
            });

        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to save billToReceive', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    const destroy = async () => {
        try {
            setIsLoading(true);
            await BillsToReceiveService.destroy(uuid);

            setInfoModal({
                title,
                message: 'Recebimento excluído com sucesso!',
                style: InfoModalStyle.SUCCESS,
                show: true,
                onClose: () => navigate(getBillsToReceiveRoute()),
            });

        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to save billToReceive', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchInfo = async () => {
        try {
            const res = await BillsToReceiveService.getByUUID(uuid);
            setFormData({
                ...res,
                issueDate: DateTime.fromISO(res.issueDate).toFormat('yyyy-MM-dd'),
                dueDate: DateTime.fromISO(res.dueDate).toFormat('yyyy-MM-dd'),
                value: formatCurrency(res.value.toFixed(2)),
                payer: res.payer ? {
                    id: res.payer.uuid,
                    value: res.payer.fantasyName,
                } : null,
                supplier: res.supplier ? {
                    id: res.supplier.uuid,
                    value: res.supplier.fantasyName,
                } : null,
                customer: res.customer ? {
                    id: res.customer.uuid,
                    value: res.customer.name,
                } : null,
            });
        } catch (err) {
            console.log(err);
            if(err?.response?.status === 404) {
                navigate(getBillsToReceiveRoute());
            }
        }
    };

    useEffect(() => {
        if (uuid) {
            fetchInfo();
        }
    }, [uuid]);

    useEffect(() => {
        const shortcutCreatePrimary = addHotkey(ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY, () => {
            if (isNew && createAccountReceivableBtnRef.current) {
                createAccountReceivableBtnRef.current.click();
            }
        });

        return () => {
            removeHotkey(shortcutCreatePrimary);
        };
    }, []);

    useEffect(() => {
        const shortcutDelete = addHotkey(ENUM_SHORTCUT.SHORTCUT_DELETE, () => {
            if (!formData.orderBilling && deleteReceiptBtnRef.current) {
                deleteReceiptBtnRef.current.click();
            }
        });

        const shortcutConfirm = addHotkey(ENUM_SHORTCUT.SHORTCUT_CONFIRM, () => {
            if (!formData.payed && writeOffReceipt.current) {
                writeOffReceipt.current.click();
            }
        });

        return () => {
            removeHotkey(shortcutDelete);
            removeHotkey(shortcutConfirm);
        };

    }, [formData]);
    return (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Financeiro', route: '/' },
                        { name: title, route: getBillsToReceiveRoute() },
                        { name: uuid || 'Novo', route: getBillsToReceiveFormRoute(uuid) },
                    ]}
                    backRoute={getBillsToReceiveRoute()}
                    hideStore
                />
                <div className={'row'}>
                    <div className={'col-4'}>
                        <FieldText
                            label={'Nome'}
                            type={'text'}
                            onChange={({ target }) => updateFormData({ name: target.value })}
                            value={formData?.name}
                            validationError={formError?.name}
                            disabled={!isNew}
                        />
                    </div>
                    <div className={'col-4'}>
                        <FieldText
                            label={'Descrição'}
                            type={'text'}
                            onChange={({ target }) => updateFormData({ description: target.value })}
                            value={formData?.description}
                            validationError={formError?.description}
                            disabled={!isNew}
                        />
                    </div>
                    <div className={'col-4'}>
                        <FieldContact
                            label={'Pagador'}
                            type={'text'}
                            select={formData?.payer || formData?.supplier || formData?.customer}
                            onSelected={(e) => updateFormData({ 
                                payer: e?.isContact ? e : null, 
                                supplier: e?.isSupplier ? e : null,
                                customer: e?.isCustomer ? e : null 
                            })}
                            validationError={formError?.payer}
                            disabled={!isNew}
                            hasCutomer
                        />
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-2'}>
                        <FieldText
                            label={'Número do título'}
                            type={'text'}
                            onChange={({ target }) => updateFormData({ numberDocument: target.value })}
                            value={formData?.numberDocument}
                            validationError={formError?.numberDocument}
                            disabled={!isNew}
                        />
                    </div>
                    <div className={'col-2'}>
                        <FieldCurrency
                            label={'Valor da despesa'}
                            type={'text'}
                            onChange={({ target }) => updateFormData({ value: formatCurrency(target.value) })}
                            value={formData?.value}
                            validationError={formError?.value}
                            disabled={!isNew}
                        />
                    </div>
                    <div className={'col-2'}>
                        <FieldDate
                            label={'Data de emissão'}
                            type={'text'}
                            onChange={({ target }) => updateFormData({ issueDate: target.value })}
                            value={formData?.issueDate}
                            validationError={formError?.issueDate}
                            disabled={!isNew}
                        />
                    </div>
                    <div className={'col-2'}>
                        <FieldDate
                            label={'Data de validade'}
                            type={'text'}
                            onChange={({ target }) => updateFormData({ dueDate: target.value })}
                            value={formData?.dueDate}
                            validationError={formError?.dueDate}
                            disabled={!isNew}
                        />
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-2'}>
                        <FieldNumber
                            label={'Quantidade de parcelas'}
                            onChange={({ target }) => updateFormData({ totalInstallments: target.value })}
                            value={formData?.totalInstallments}
                            validationError={formError?.totalInstallments}
                            thousandsSeparator={false}
                            disabled={!isNew}
                            minValue={1}
                        />
                    </div>
                </div>
                <div className={'row d-flex justify-content-end'}>
                    <div className={'col-6 align-right update-purchase'}>
                        {
                            isNew ? (
                                <Button
                                    ref={createAccountReceivableBtnRef}
                                    buttonStyle={ButtonStyle.BUTTON_NORMAL}
                                    className={'ml-10 w-50'}
                                    color={ButtonColor.BUTTON_COLOR_GREEN}
                                    onClick={save}
                                >
                                    {`Criar conta [${ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY}]`}
                                </Button>
                            ) : (
                                <>
                                    {
                                        formData && !formData.sale && (
                                            <Button
                                                ref={deleteReceiptBtnRef}
                                                buttonStyle={ButtonStyle.BUTTON_NORMAL}
                                                className={'ml-10'}
                                                color={ButtonColor.BUTTON_COLOR_GRAY}
                                                onClick={destroy}
                                            >
                                                {`Excluir recebimento [${ENUM_SHORTCUT.SHORTCUT_DELETE}]`}
                                            </Button>
                                        )
                                    }
                                    {
                                        formData && !formData.received && (
                                            <Button
                                                ref={writeOffReceipt}
                                                buttonStyle={ButtonStyle.BUTTON_NORMAL}
                                                fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                                                className={'ml-10'}
                                                color={ButtonColor.BUTTON_COLOR_GREEN}
                                                onClick={() => setShowSelectAccount(true)}
                                            >
                                                {`Dar baixa em recebimento [${ENUM_SHORTCUT.SHORTCUT_CONFIRM}]`}
                                            </Button>
                                        )
                                    }
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
            <SelectAccountsModal
                show={showSelectAccount}
                onCancel={() => {
                    setShowSelectAccount(false);
                }}
                onConfirm={(e) => {
                    setShowSelectAccount(false);
                    update(e);
                }}
            />
        </>
    );
}

export function getBillsToReceiveFormRoute(uuid) {
    if (uuid) {
        return `/financeiro/contas-a-receber/${uuid}`;
    } else {
        return '/financeiro/contas-a-receber/novo';
    }
}
