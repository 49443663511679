import { extractCurrencyNumber } from '../../../components/FieldCurrency';

export const totalSaleReduce = (productsSelected) => {
    if (!productsSelected.length) return 0;

    return productsSelected.reduce(
        (sum, product) => sum + Number(product.total), 0
    ) ?? 0;
};

export const totalPayableReduce = (productsSelected, exchangeTotal, couponTotal) => {
    if (!productsSelected.length) return 0;

    return (
        productsSelected.reduce(
            (sum, product) => sum + Number(product.total), 0
        ) - exchangeTotal - couponTotal
    ) ?? 0;
};

export const totalItensReduce = (productsSelected) => {
    if (!productsSelected.length) return 0;

    return productsSelected.reduce(
        (sum, product) => sum + Number(product.amount), 0
    ) ?? 0;
};

export const orderProducts = (listProducts) => {
    if (!listProducts) return;
    
    return listProducts.sort((a, b) => a.amount - b.amount);
};

export const alreadyPayedTotal = (payments) => {
    return payments.filter((x) => x.tefId && x.tefConfirmed)
        .reduce((sum, payment) => sum + extractCurrencyNumber(payment.total), 0);
};

export const numberOfPayments = (payments, addRow = false) => {
    const filteredPayments = payments.filter((x) => !x.tefId && !x.tefConfirmed).length;
    return addRow ? filteredPayments + 1 : filteredPayments - 1;
};

export const isEmptyFormData = (value) => {
    if (value === null || value === undefined) return true;
    if (Array.isArray(value) && value.length === 0) return true;
    if (typeof value === 'object' && Object.keys(value).length === 0) return true;
    return false;
};