import api from '../util/api';

export async function getRepresentative(id) {
    return (await api.get(`/representatives/${id}`)).data;
}

export async function addRepresentative(data) {
    return await api.put('/representatives', data);
}

export async function getRepresentativesBySupplier(uuids) {
    return (await api.post('/representatives', uuids)).data;
}

export async function getRepresentatives(
    page,
    search,
    segmentations,
    order,
    cancelToken
) {
    return (await api.get('/representatives', {
        params: {
            page,
            search,
            segmentations,
            order
        },
        cancelToken: cancelToken
    })).data;
}

export async function editRepresentative(id, data) {
    return await api.put(`/representatives/${id}`, data);
}

export async function removeRepresentative(id) {
    return await api.delete(`/representatives/${id}`);
}

export async function checkRepSupplierRelation(id) {
    return (await api.get(`/representatives/checkRepSupplier/${id}`)).data;
}