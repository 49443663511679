import './PricingForm.scss';
import { useContext, useState } from 'react';
import FieldPercentage from '../../../components/FieldPercentage';
import FieldCurrency, { extractCurrencyNumber, formatCurrency } from '../../../components/FieldCurrency';
import { calculateMarginPercentage } from '../../../util/calculateMarginPercentage';
import { DateTime } from 'luxon';
import Table from '../../../components/Table';
import { Link } from 'react-router-dom';
import { getCampaignFormRoute } from '../../campaign/CampaignForm';
import { getStoresFormRoute } from '../../stores/StoreForm';
import { formatValue } from '../../../util/formatValue';
import { PermissionsEnum, ProductStatusEnum } from 'erva-doce-common';
import ToolTip from '../../../components/ToolTip';
import { EnvironmentContext } from '../../../contexts/EnviromentContext';
import PermissionedLink from '../../../components/PermissionedLink';
import { getSupplierFormRoute } from '../../suppliers/SupplierForm';
import { getRepresentativeFormRoute } from '../../representatives/RepresentativeForm';
import ErrorMessage from '../../../components/ErrorMessage';

function resumePricing(data) {
    const { selectedStore } = useContext(EnvironmentContext);

    const storePercentage = selectedStore.percentage || 100;
    const storePrice = formatValue(data.newPrice * (storePercentage / 100));

    const message = (
        <div>
            <p>{'O produto '}<strong>{data.name1}</strong>{' em '}<strong>{selectedStore.fantasyName}</strong>{' está sendo vendido por '}<strong>{formatCurrency(data?.priceStore)}</strong>{'.'}</p>
            <br />
            {
                (storePercentage !== 100 || data.promotionName) && (
                    <p>{'O preço de venda é composto por: '}</p>
                )
            }
            {
                storePercentage !== 100 && (
                    <>
                        <br />
                        <p>
                            {'- Porcentagem de '}<strong>{(storePercentage)}{'%'}</strong>{' da loja sobre o preço base do produto.'}
                            <br />
                            <code>{formatValue(data.newPrice)}{' * '}{storePercentage}{'% = '}{storePrice}</code>
                        </p>
                    </>
                )
            }
            {
                data.promotionName && (
                    <>
                        <br />
                        {
                            data.promotionValue ? (
                                <p>
                                    {'- Valor fixo de '}<strong>{data.promotionValue}</strong>{' da campanha '}<strong>{data.promotionName}</strong> {'ativa nessa loja.'}
                                    <br />
                                    <code>{formatValue(storePrice)}{' * '}{data.promotionPercentage}{'% = '}{storePrice}</code>
                                </p>

                            ) : (
                                <p>
                                    {'- Porcentagem de '}<strong>{data.promotionPercentage}{'%'}</strong>{' da campanha '}<strong>{data.promotionName}</strong>{' ativa nessa loja.'}
                                    <br />
                                    <code>{formatCurrency(storePrice)}{' * '}{data.promotionPercentage}{'% = '}{formatValue(extractCurrencyNumber(storePrice) * (data.promotionPercentage / 100))}</code>
                                </p>

                            )
                        }
                    </>
                )
            }
            {
                data.statusStore !== ProductStatusEnum.REGULAR && (
                    <>
                        <br />
                        <p>{'** A etiqueta ainda não foi impressa nessa loja para o preço de venda estar atualizado.'}</p>
                    </>
                )
            }
            {
                selectedStore.isOnline && (
                    <>
                        <br />
                        <p>{'** A loja é do tipo online, o preço de venda é atualizado logo após a alteração.'}</p>
                    </>
                )
            }
        </div>
    );

    return message;
}

export default function PricingForm({ data, updateFormData, formError }) {
    const { user } = useContext(EnvironmentContext);

    const [initialSuggestion, setInitialSuggestion] = useState('40,00');
    const [finalSuggestion, setFinalSuggestion] = useState('60,00');

    const disabled = !user.isAdmin && (!user.roles.includes(PermissionsEnum.HANDLE_PRODUCTS) && !user.roles?.includes(PermissionsEnum.HANDLE_PRODUCT_PRICING));

    const handleCalcSuggestedPrice = (profitMargin) => {
        if (!profitMargin || !data?.cost) return 'R$ 0,00';
        const cost = extractCurrencyNumber(data.cost);
        const formattedMargin = parseFloat(profitMargin);
        const suggestionPrice = cost / (1 - formattedMargin / 100);
        return suggestionPrice.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });
    };

    return (
        <>
            <div className={'product-form-container'}>
                <div className={'row'}>
                    <div className={'col-3'}>
                        <div className={'row'}>
                            <div className={'col-12'}>
                                <h2 className={'mb-10'}>{'Resumo'}</h2>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col-12'}>
                                <p>{resumePricing(data)}</p>
                            </div>
                        </div>
                        <div className={'row mt-42'}>
                            <div className={'col-12'}>
                                <h2 className={'mb-10'}>{'Histórico'}</h2>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col-12'}>
                                <div className={'mr-20'}>
                                    <p><strong>{'Última atualização de preço'}</strong></p>
                                    <p>{data.dateLastPriceUpdate ? DateTime.fromISO(data.dateLastPriceUpdate).toFormat('dd/MM/yyyy HH:mm') : '-'}</p>
                                </div>
                            </div>
                        </div>
                        <div className={'row mt-20'}>
                            <div className={'col-12'}>
                                <div>
                                    <p><strong>{'Última atualização de preço'}</strong></p>
                                    <p>{data.dateLastPriceUpdate ? DateTime.fromISO(data.dateLastPriceUpdate).toFormat('dd/MM/yyyy HH:mm') : '-'}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'col-9'}>
                        <div className={'row'}>
                            <div className={'col-4'}>
                                <div className={'row price-suggestion'}>
                                    <div className={'col-12'}>
                                        <h2 className={'mb-10'}>{'Apoio para cálculo'}</h2>
                                    </div>
                                    <div className={'col-12'}>
                                        <div className={'row'}>
                                            <div className={'col-12'}>
                                                <div className={'d-flex align-items-end'}>
                                                    <FieldCurrency
                                                        label={'Sugestão inicial'}
                                                        value={handleCalcSuggestedPrice(
                                                            initialSuggestion
                                                        )}
                                                        disabled
                                                        className={'mr-8'}
                                                    />
                                                    <FieldPercentage
                                                        label={''}
                                                        value={initialSuggestion || ''}
                                                        onChange={({ target }) =>
                                                            setInitialSuggestion(target.value)
                                                        }
                                                        disabled={disabled}
                                                        readOnly={disabled}
                                                    />
                                                </div>
                                            </div>
                                            <div className={'col-6'}>
                                                {formError?.newPrice && (
                                                    <ErrorMessage
                                                        errorMessage={formError?.newPrice}
                                                    />
                                                )}
                                            </div>
                                        </div>

                                    </div>
                                  
                                    <div className={'col-12'}>
                                        <div className={'row'}>
                                            <div className={'col-12'}>
                                                <div className={'d-flex align-items-end'}>
                                                    <FieldCurrency
                                                        label={'Sugestão final'}
                                                        value={handleCalcSuggestedPrice(
                                                            finalSuggestion
                                                        )}
                                                        disabled
                                                        className={'mr-8'}
                                                    />
                                                    <FieldPercentage
                                                        label={''}
                                                        value={finalSuggestion || ''}
                                                        onChange={({ target }) =>
                                                            setFinalSuggestion(target.value)
                                                        }
                                                        disabled={disabled}
                                                        readOnly={disabled}
                                                    />
                                                </div>
                                            </div>
                                            <div className={'col-6'}>
                                                {formError?.newPrice && (
                                                    <ErrorMessage
                                                        errorMessage={formError?.newPrice}
                                                    />
                                                )}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className={'col-1'}></div>
                            <div className={'col-4'}>
                                <div className={'row'}>
                                    <div className={'col-12'}>
                                        <h2 className={'mb-10'}>{'Preço Base'}</h2>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col-12'}>
                                        <div className={'row'}>
                                            <div className={'col-12'}>
                                                <div className={'d-flex align-items-end'}>
                                                    <FieldCurrency
                                                        label={'Preço atual'}
                                                        className={'mr-8'}
                                                        value={
                                                            (
                                                                data?.price ?? 0
                                                            ).toLocaleString('pt-BR', {
                                                                style: 'currency',
                                                                currency: 'BRL',
                                                            }) || 'R$ 0,00'
                                                        }
                                                        onChange={() => {}}
                                                        disabled
                                                    />
                                                    <FieldPercentage
                                                        label={'Margem atual'}
                                                        value={calculateMarginPercentage(data.price, data.avgCost)}
                                                        disabled
                                                        onChange={() => {}}
                                                    />
                                                </div>
                                            </div>
                                            <div className={'col-6'}>
                                                {formError?.price && (
                                                    <ErrorMessage
                                                        errorMessage={formError?.price}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col-12'}>
                                        <div className={'row'}>
                                            <div className={'col-12'}>
                                                <div className={'d-flex align-items-end'}>
                                                    <FieldCurrency
                                                        label={'Novo preço'}
                                                        className={'mr-8'}
                                                        value={
                                                            (
                                                                data?.newPrice ?? 0
                                                            ).toLocaleString('pt-BR', {
                                                                style: 'currency',
                                                                currency: 'BRL',
                                                            }) || 'R$ 0,00'
                                                        }
                                                        onChange={(e) =>
                                                            updateFormData({
                                                                newPrice: extractCurrencyNumber(
                                                                    formatCurrency(e.target.value)
                                                                ),
                                                            })
                                                        }
                                                        disabled={disabled}
                                                        readOnly={disabled}
                                                    />
                                                    <FieldPercentage
                                                        label={'Nova margem'}
                                                        value={calculateMarginPercentage(data.newPrice, data.avgCost)}
                                                        disabled
                                                        onChange={() => {}}
                                                    />
                                                </div>
                                            </div>
                                            <div className={'col-6'}>
                                                {formError?.newPrice && (                              
                                                    <ErrorMessage
                                                        errorMessage={formError?.newPrice}
                                                    />
                                                )}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'row mt-42'}>
                            <div className={'col-12'}>
                                <h2 className={'mb-10'}>{'Lojas'}</h2>
                            </div>
                        </div>
                        <div className={'row mt-20'}>
                            <div className={'col-12'}>
                                <Table
                                    columns={[
                                        {
                                            name: 'Loja',
                                            width: '20%',
                                        },
                                        {
                                            name: 'Tipo loja',
                                            align: 'center',
                                        },
                                        {
                                            align: 'center',
                                            name: 'Estoque',
                                        },
                                        {
                                            name: 'Porcentagem loja',
                                            align: 'center',
                                        },
                                        {
                                            align: 'center',
                                            name: 'Preço base loja',
                                        },
                                        {
                                            name: 'Campanha ativa?',
                                            align: 'center',
                                        },
                                        {
                                            name: 'Preço de venda atual',
                                            align: 'center',
                                        },
                                    ]}
                                >
                                    {data.stores.map((p) => {
                                        const campaign = data?.campaigns?.find(x => x.stores.find(y => y.uuid === p.uuid) && x.isActive);
                                        const percentage = p?.percentage ?? 0;

                                        return (
                                            <tr key={p.uuid}>
                                                <td>
                                                    <PermissionedLink
                                                        permission={PermissionsEnum.HANDLE_SUPPLIERS}
                                                        to={getStoresFormRoute(p.uuid)}
                                                    >
                                                        {p.fantasyName}
                                                    </PermissionedLink>
                                                </td>
                                                <td className={'text-center'}>
                                                    {p.isOnline ? 'Online' : 'Física'}
                                                </td>
                                                <td className={'text-center'}>
                                                    {`${p.balance}${p.isOnline ? ' (CD)' : ''}`}
                                                </td>
                                                <td className={'text-center'}>
                                                    {percentage + '%'}
                                                </td>
                                                <td className={'text-center'}>
                                                    {formatValue(data.newPrice * (percentage / 100))}
                                                </td>
                                                <td className={'text-center'}>
                                                    {campaign ? `Sim (${campaign.name} - ${campaign.value}%)` : 'Não'}
                                                </td>
                                                <td className={'text-center'}>
                                                    <strong className={'d-flex justify-content-center'}>
                                                        {
                                                            p.status !== ProductStatusEnum.REGULAR ? (
                                                                <ToolTip title={formatValue(p.price)} left description={`Aguardando impressão na loja para o novo preço ${formatValue(p.newPrice)} ser utilizado`} />
                                                            ) : formatValue(p.price)
                                                        }
                                                    </strong>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </Table>
                            </div>
                        </div>
                        <div className={'row mt-42'}>
                            <div className={'col-12'}>
                                <h2 className={'mb-10'}>{'Campanhas'}</h2>
                            </div>
                        </div>
                        <div className={'row mt-20'}>
                            <div className={'col-12'}>
                                <Table
                                    columns={[
                                        {
                                            name: 'Nome',
                                        },
                                        {
                                            name: 'Lojas',
                                        },
                                        {
                                            name: 'Campanha ativa?',
                                            align: 'center',
                                        },
                                        {
                                            name: 'Porcentagem',
                                            align: 'center',
                                        },
                                        {
                                            name: 'Período',
                                            align: 'center',
                                        },
                                    ]}
                                >
                                    {data?.campaigns?.map((p) => {
                                        return (
                                            <tr key={p.id}>
                                                <td>
                                                    <PermissionedLink
                                                        permission={PermissionsEnum.HANDLE_CAMPAIGNS}
                                                        to={getCampaignFormRoute(p.uuid)}
                                                    >
                                                        {p.name}
                                                    </PermissionedLink>
                                                </td>
                                                <td>
                                                    <PermissionedLink
                                                        permission={PermissionsEnum.HANDLE_SUPPLIERS}
                                                        to={getStoresFormRoute(p.stores?.map(x => x.uuid))}
                                                    >
                                                        {p.stores?.map(x => x.fantasyName).join(', ')}
                                                    </PermissionedLink>
                                                </td>
                                                <td className={'text-center'}>
                                                    {p.isActive ? 'Sim' : 'Não'}
                                                </td>
                                                <td className={'text-center'}>
                                                    {p.value + '%'}
                                                </td>
                                                <td className={'text-center'}>
                                                    {p.startAt + ' - ' + p.endAt}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </Table>
                            </div>
                        </div>
                        <div className={'row mt-42'}>
                            <div className={'col-12'}>
                                <h2 className={'mb-10'}>{'Custos'}</h2>
                            </div>
                            <div className={'col-12'}>
                                <p className={'mt-16'}><strong>{'Último custo: '}</strong>{formatCurrency(data?.cost)}</p>
                            </div>
                            <div className={'col-12'}>
                                <p className={'mt-16'}><strong>{'Custo médio semestral: '}</strong>{formatCurrency(data?.avgCost)}</p>
                            </div>
                        </div>
                        <div className={'row mt-20'}>
                            <div className={'col-12'}>
                                <Table
                                    columns={[
                                        {
                                            name: 'Fornecedor',
                                        },
                                        {
                                            name: 'Representante',
                                        },
                                        {
                                            name: 'Código de Produto',
                                            align: 'center',
                                        },
                                        {
                                            name: 'Valor de custo',
                                            align: 'center',
                                        },
                                        {
                                            name: 'Embalagem mínima',
                                            align: 'center',
                                        },
                                        {
                                            name: 'Observações',
                                            align: 'center',
                                        },
                                        {
                                            name: 'Responsável',
                                            align: 'center',
                                        },
                                    ]}
                                >
                                    {data?.representativeSuppliers?.map((p) => {
                                        return (
                                            <tr key={p.id}>
                                                <td>
                                                    <PermissionedLink
                                                        permission={PermissionsEnum.HANDLE_SUPPLIERS}
                                                        to={getSupplierFormRoute(p?.supplierUuid)}
                                                    >
                                                        {p.supplierName}
                                                    </PermissionedLink>
                                                </td>
                                                <td>
                                                    <PermissionedLink
                                                        permission={PermissionsEnum.HANDLE_SUPPLIERS}
                                                        to={getRepresentativeFormRoute(p?.representativeUuid)}
                                                    >
                                                        {p.representativeName}
                                                    </PermissionedLink>
                                                </td>
                                                <td className={'text-center'}>
                                                    {p.supplierCode || '-'}
                                                </td>
                                                <td className={'text-center'}>
                                                    {formatValue(p.cost)}
                                                </td>
                                                <td className={'text-center'}>
                                                    {p.minPackageQuantity}
                                                </td>
                                                <td className={'text-center'}>
                                                    {p.observations || '-'}
                                                </td>
                                                <td className={'text-center'}>
                                                    {p.responsibleName || '-'}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
