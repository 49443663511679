import Dashboard, { getDashboardRoute } from '../dashboard/Dashboard';
import MainScreen from '../components/logged/MainScreen';
import Collaborators, { getCollaboratorsRoute } from './collaborators/Collaborators';
import CollaboratorForm, { getCollaboratorFormRoute } from './collaborators/CollaboratorForm';
import Representatives, { getRepresentativesRoute } from './representatives/Representatives';
import RepresentativeForm, { getRepresentativeFormRoute } from './representatives/RepresentativeForm';
import Suppliers, { getSuppliersRoute } from './suppliers/Suppliers';
import SupplierForm, { getSupplierFormRoute } from './suppliers/SupplierForm';
import Stores, { getStoresRoute } from './stores/Stores';
import StoreForm, { getStoresFormRoute } from './stores/StoreForm';
import Segmentations, { getSegmentationsRoute } from './segmentations/Segmentations';
import SegmentationForm, { getSegmentationFormRoute } from './segmentations/SegmentationForm';
import React, { useContext } from 'react';
import RouteErrorPage from '../components/RouteErrorPage';
import { Navigate, useRouteError } from 'react-router-dom';
import JobsTitles, { getJobsTitlesRoute } from './jobs-titles/JobsTitles';
import JobTitleForm, { getJobTitleFormRoute } from './jobs-titles/JobTitleForm';
import ExpensesTypes, { getExpensesTypesRoute } from './expenses-types/ExpensesTypes';
import ExpenseTypeForm, { getExpenseTypeFormRoute } from './expenses-types/ExpenseTypeForm';
import QuotationsTypes, { getQuotationsTypesRoute } from './quotations-types/QuotationsTypes';
import QuotationTypeForm, { getQuotationTypeFormRoute } from './quotations-types/QuotationTypeForm';
import ProductsFamilies, { getProductFamiliesRoute } from './products-families/ProductsFamilies';
import ProductFamilyForm, { getProductFamilyFormRoute } from './products-families/ProductFamilyForm';
import TaxesSituations, { getTaxesSituationsRoute } from './taxes-situations/TaxesSituations';
import TaxSituationForm, { getTaxSituationFormRoute } from './taxes-situations/TaxSituationForm';
import TaxesClassifications, { getTaxesClassificationsRoute } from './taxes-classifications/TaxesClassifications';
import TaxClassificationForm, { getTaxClassificationFormRoute } from './taxes-classifications/TaxClassificationForm';
import ProductsCategories, { getProductsCategoriesRoute } from './products-categories/ProductsCategories';
import ProductCategoryForm, { getProductsCategoriesFormRoute } from './products-categories/ProductCategoryForm';
import ProductsBrands, { getProductsBrandsRoute } from './products-brands/ProductsBrands';
import ProductBrandForm, { getProductBrandFormRoute } from './products-brands/ProductBrandForm';
import Products, { getProductsRoute } from './products/Products';
import ProductForm, { getProductFormRoute } from './products/ProductsForm';
import Exchanges, { getExchangesRoute } from './exchanges/Exchanges';
import ExchangesForm, { getExchangesFormRoute } from './exchanges/ExchangesForm';
import Sales, { getSalesRoute } from './sales/Sales';
import SalesForm, { getSalesFormRoute } from './sales/SalesForm';
import BillsToTeceive, { getBillsToReceiveRoute } from './bills-to-receive/BillsToReceive';
import BillsToReceiveForm, { getBillsToReceiveFormRoute } from './bills-to-receive/BilsToReceiveForm';
import PaymentsTypes, { getPaymentsTypesRoute } from './payments-types/PaymentsTypes';
import PaymentTypeForm, { getPaymentTypeFormRoute } from './payments-types/PaymentTypeForm';
import MachineForm, { getMachineFormRoute } from './machines/MachineForm';
import Machines, { getMachinesRoute } from './machines/Machines';
import PurchaseSuggestion, { getPurchaseSuggestionRoute } from './purchase-suggestion/PurchaseSuggestion';
import ProductLogs, { getProductLogsRoute } from './products/ProductLogs';
import Tags, { getTagsRoute } from './tags/Tags';
import Orders, { getOrdersRoute } from './order/Orders';
import SuggestionDetail, { getSuggestionDetailRoute } from './purchase-suggestion/SuggestionDetail';
import OrderDetail, { getOrderDetailRoute } from './order/order-detail/OrderDetail';
import CancelReason, { getCancelReasonRoute } from './cancel-reason/CancelReason';
import CancelReasonForm, { getCancelReasonFormRoute } from './cancel-reason/CancelReasonForm';
import { PermissionsEnum } from 'erva-doce-common';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import OrderList, { getOrderListRoute } from './order-list/OrderList';
import OrderBilling, { getOrderBillingRoute } from './order/order-billing/OrderBilling';
import OrderDocuments, { getOrderDocumentsRoute } from './order-documents/OrderDocuments';
import Stock, { getStockRoute } from './stock/Stock';
import StockBulk, { getStockBulkRoute } from './stock/StockBulk';
import StockProductLog, { getStockProductLogRoute } from './stock/StockProductLog';
import StockCheck, { getStockCheckRoute } from './stock/stock-check/StockCheck';
import AdminStockCheck, { getAdminStockCheckRoute } from './stock/admin-stock-check/AdminStockCheck';
import StockCheckForm, { getStockCheckFormRoute } from './stock/stock-check/StockCheckForm';
import AdminStockCheckDetail, { getAdminStockCheckDetailRoute } from './stock/admin-stock-check/AdminStockCheckDetail';
import StockCheckDetail, { getStockCheckDetailRoute } from './stock/stock-check/StockCheckDetail';
import DivergenceReason, { getDivergenceReasonRoute } from './divergence-reason/DivergenceReason';
import DivergenceReasonForm, { getDivergenceReasonFormRoute } from './divergence-reason/DivergenceReasonForm';
import Customers, { getCustomersRoute } from './customers/Customers';
import DeliveryArea, { getDeliveryAreaRoute } from './loja-virtual/areas-de-entrega';
import DeliveryAreaForm, { getDeliveryAreaFormRoute } from './loja-virtual/areas-de-entrega/DeliveryAreaForm';
import Banners, { getBannersRoute } from './loja-virtual/banners';
import Parametros, { getParametrosRoute } from './loja-virtual/parametros';
import MenuPrincipal, { getMenuPrincipalRoute } from './loja-virtual/menu-principal';
import Rodape, { getRodapeRoute } from './loja-virtual/rodape';
import Cupons, { getCuponsRoute } from './cupons';
import CumpomForm, { getCouponFormRoute } from './cupons/CupomForm';
import Newsletter, { getNewsletterRoute } from './loja-virtual/newsletters';
import Cashiers, { getCashiersRoute } from './cashier/Cashiers';
import CashiersForm, { getCashiersFormRoute } from './cashier/CashiersForm';
import BillsToPay, { getBillsToPayRoute } from './bills-to-pay/BillsToPay';
import BillsToPayForm, { getBillsToPayFormRoute } from './bills-to-pay/BillsToPayForm';
import AccountsForm, { getAccountsFormRoute } from './accounts/AccountsForm';
import Accounts, { getAccountsRoute } from './accounts/Accounts';
import Contacts, { getContactsRoute } from './contacts/Contacts';
import ContactForm, { getContactFormRoute } from './contacts/ContactForm';
import Bills, { getBillsRoute } from './bills/Bills';
import OrderReceptionRouter, { getOrderReceptionRouterRoute } from './order-receive/OrderReceptionRouter';
import OrderReceive, { getOrderReceiveRoute } from './order-receive';
import OrderReceiveDetail, { getOrderReceiveDetailRoute } from './order-receive/OrderReceiveDetail';
import HandleScanProducts, { getHandleScanProductsRoute } from './order-receive/HandleScanProducts';
import OrderDetailReceive, { getOrderDetailReceiveRoute } from './order/order-receive/OrderDetailReceive';
import Campaign, { getCampaignRoute } from './campaign/Campaign';
import CampaignForm, { getCampaignFormRoute } from './campaign/CampaignForm';
import MovementPage, { getPanelPage } from './sales/Panel';
import CashClosingPage, { getCashClosingPage } from './sales/CashClosing';
import GeneralCashClosingPage, { getGeneralCashClosingPage } from './sales/GeneralCashClosing';
import SupplierRepresentativeProducts, { getSupplierRepresentativeProductsRoute } from './supplier-representative-products';
import SalesDetailForm, { getSalesDetailFormRoute } from './sales/SalesDetailForm';
import Feedbacks, { getFeedbacksRoute } from './loja-virtual/feedbacks';
import VirtualOrderList, { getVirtualOrderListRoute } from './loja-virtual/virtual-order-list/VirtualOrderList';
import VirtualOrder, { getVirtualOrderRoute } from './loja-virtual/vitual-order/VirtualOrder';
import VirtualOrderDetail, { getVirtualOrderDetailRoute } from './loja-virtual/virtual-order-detail/VirtualOrderDetail';
import CustomerForm, { getCustomerFormRoute } from './customers/CustomerForm';

import Voucher, { getVoucherRoute } from './voucher/Voucher';
function HandleError() {
    return <RouteErrorPage error={useRouteError()}/>;
}

function RoleGuard({ roles=[], children }) {
    const { user } = useContext(EnvironmentContext);
    const isAllowed = roles.some(x => user.roles?.includes(x)) || user.isAdmin;

    return isAllowed ? children : <Navigate to={getDashboardRoute()} />;
}

const adminRoutes = [
    {
        path: getDashboardRoute(),
        element: (
            <MainScreen>
                <Dashboard />
            </MainScreen>
        ),
        errorElement: <HandleError />,
    },
    {
        path: getCashiersRoute(),
        element: (
            <MainScreen title={'Caixas'}>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_USERS]}
                >
                    <Cashiers />
                </RoleGuard>
            </MainScreen>
        ),
        errorElement: <HandleError />,
    },
    {
        path: getCashiersFormRoute(),
        element: (
            <MainScreen title={'Criar Caixa'}>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_USERS]}
                >
                    <CashiersForm />
                </RoleGuard>
            </MainScreen>
        ),
        errorElement: <HandleError />,
    },
    {
        path: getCashiersFormRoute(':uuid'),
        element: (
            <MainScreen title={'Editar Caixa'}>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_USERS]}
                >
                    <CashiersForm />
                </RoleGuard>
            </MainScreen>
        ),
        errorElement: <HandleError />,
    },
    {
        path: getVoucherRoute(),
        element: (
            <MainScreen title={'Vales'}>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_USERS, PermissionsEnum.CREATE_SALES]}
                >
                    <Voucher />
                </RoleGuard>
            </MainScreen>
        ),
        errorElement: <HandleError />,
    },
    {
        path: getCollaboratorsRoute(),
        element: (
            <MainScreen title={'Colaboradores'}>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_USERS]}
                >
                    <Collaborators />
                </RoleGuard>
            </MainScreen>
        ),
        errorElement: <HandleError />,
    },
    {
        path: getCollaboratorFormRoute(),
        element: (
            <MainScreen title={'Novo Colaborador'}>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_USERS]}
                >
                    <CollaboratorForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getCollaboratorFormRoute(':uuid'),
        element: (
            <MainScreen title={'Editar Colaborador'}>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_USERS]}
                >
                    <CollaboratorForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getRepresentativesRoute(),
        element: (
            <MainScreen title={'Representantes'}>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_SUPPLIERS]}
                >
                    <Representatives />
                </RoleGuard>
            </MainScreen>
        ),
        errorElement: <HandleError />,
    },
    {
        path: getRepresentativeFormRoute(),
        element: (
            <MainScreen title={'Novo Representante'}>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_SUPPLIERS]}
                >
                    <RepresentativeForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getRepresentativeFormRoute(':uuid'),
        element: (
            <MainScreen title={'Editar Representante'}>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_SUPPLIERS]}
                >
                    <RepresentativeForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getSuppliersRoute(),
        element: (
            <MainScreen title={'Fornecedores'}>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_SUPPLIERS]}
                >
                    <Suppliers />
                </RoleGuard>
            </MainScreen>
        ),
        errorElement: <HandleError />,
    },
    {
        path: getSupplierFormRoute(),
        element: (
            <MainScreen title={'Novo Fornecedor'}>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_SUPPLIERS]}
                >
                    <SupplierForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getSupplierFormRoute(':uuid'),
        element: (
            <MainScreen title={'Editar Fornecedores'}>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_SUPPLIERS]}
                >
                    <SupplierForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getSupplierRepresentativeProductsRoute(':id'),
        element: (
            <MainScreen title={'Listagem de produtos'}>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_SUPPLIERS]}
                >
                    <SupplierRepresentativeProducts />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getContactsRoute(),
        element: (
            <MainScreen title={'Contatos'}>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_SUPPLIERS, PermissionsEnum.HANDLE_BILLS]}
                >
                    <Contacts />
                </RoleGuard>
            </MainScreen>
        ),
        errorElement: <HandleError />,
    },
    {
        path: getContactFormRoute(),
        element: (
            <MainScreen title={'Novo Contato'}>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_SUPPLIERS, PermissionsEnum.HANDLE_BILLS]}
                >
                    <ContactForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getContactFormRoute(':uuid'),
        element: (
            <MainScreen title={'Editar Contato'}>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_SUPPLIERS, PermissionsEnum.HANDLE_BILLS]}
                >
                    <ContactForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getStoresRoute(),
        element: (
            <MainScreen title={'Lojas'}>
                <RoleGuard>
                    <Stores />
                </RoleGuard>
            </MainScreen>
        ),
        errorElement: <HandleError />,
    },
    {
        path: getStoresFormRoute(),
        element: (
            <MainScreen title={'Nova Loja'}>
                <RoleGuard>
                    <StoreForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getStoresFormRoute(':uuid'),
        element: (
            <MainScreen title={'Editar Loja'}>
                <RoleGuard>
                    <StoreForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getSegmentationsRoute(),
        element: (
            <MainScreen title={'Segmentações'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <Segmentations />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getSegmentationFormRoute(),
        element: (
            <MainScreen title={'Nova Segmentação'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <SegmentationForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getSegmentationFormRoute(':uuid'),
        element: (
            <MainScreen title={'Editar Segmentação'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <SegmentationForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getJobsTitlesRoute(),
        element: (
            <MainScreen title={'Cargos'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_JOB_TITLES]}>
                    <JobsTitles />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getJobTitleFormRoute(),
        element: (
            <MainScreen title={'Novo Cargo'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_JOB_TITLES]}>
                    <JobTitleForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getJobTitleFormRoute(':uuid'),
        element: (
            <MainScreen title={'Editar Cargo'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_JOB_TITLES]}>
                    <JobTitleForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getBillsRoute(),
        element: (
            <MainScreen title={'Conta Corrente'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <Bills />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getExpensesTypesRoute(),
        element: (
            <MainScreen title={'Tipos de despesas'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <ExpensesTypes />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getExpenseTypeFormRoute(),
        element: (
            <MainScreen title={'Novo Tipo de despesa'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <ExpenseTypeForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getExpenseTypeFormRoute(':uuid'),
        element: (
            <MainScreen title={'Editar Tipo de despesa'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <ExpenseTypeForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getQuotationsTypesRoute(),
        element: (
            <MainScreen title={'Tipos de cotações'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <QuotationsTypes />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getQuotationTypeFormRoute(),
        element: (
            <MainScreen title={'Novo Tipo de cotação'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <QuotationTypeForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getQuotationTypeFormRoute(':uuid'),
        element: (
            <MainScreen title={'Editar Tipo de cotação'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <QuotationTypeForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getProductFamiliesRoute(),
        element: (
            <MainScreen title={'Famílias'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <ProductsFamilies />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getProductFamilyFormRoute(),
        element: (
            <MainScreen title={'Nova Família'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <ProductFamilyForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getProductFamilyFormRoute(':uuid'),
        element: (
            <MainScreen title={'Editar Família'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <ProductFamilyForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getTaxesSituationsRoute(),
        element: (
            <MainScreen title={'Situações tributárias'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_FISCAL]}>
                    <TaxesSituations />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getTaxSituationFormRoute(),
        element: (
            <MainScreen title={'Nova Situação tributária'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_FISCAL]}>
                    <TaxSituationForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getTaxSituationFormRoute(':uuid'),
        element: (
            <MainScreen title={'Editar Situação tributária'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_FISCAL]}>
                    <TaxSituationForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getTaxesClassificationsRoute(),
        element: (
            <MainScreen title={'Classificações fiscais'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_FISCAL]}>
                    <TaxesClassifications />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getTaxClassificationFormRoute(),
        element: (
            <MainScreen title={'Nova Classificação fiscal'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_FISCAL]}>
                    <TaxClassificationForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getTaxClassificationFormRoute(':uuid'),
        element: (
            <MainScreen title={'Editar Classificação fiscal'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_FISCAL]}>
                    <TaxClassificationForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getProductsCategoriesRoute(),
        element: (
            <MainScreen title={'Categorias'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <ProductsCategories />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getProductsCategoriesFormRoute(),
        element: (
            <MainScreen title={'Nova Categoria'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <ProductCategoryForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getProductsCategoriesFormRoute(':uuid'),
        element: (
            <MainScreen title={'Editar Categoria'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <ProductCategoryForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getProductsBrandsRoute(),
        element: (
            <MainScreen title={'Marcas'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <ProductsBrands />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getProductBrandFormRoute(),
        element: (
            <MainScreen title={'Nova Marca'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <ProductBrandForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getProductBrandFormRoute(':uuid'),
        element: (
            <MainScreen title={'Editar Marca'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <ProductBrandForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getProductsRoute(),
        element: (
            <MainScreen title={'Produtos'}>
                <RoleGuard roles={[
                    PermissionsEnum.HANDLE_PRODUCTS,
                    PermissionsEnum.HANDLE_PRODUCT_FISCAL,
                    PermissionsEnum.HANDLE_PRODUCT_MAIN,
                    PermissionsEnum.HANDLE_PRODUCT_PRICING,
                    PermissionsEnum.VIEW_PRODUCT_FISCAL,
                    PermissionsEnum.VIEW_PRODUCT_PRICING,
                    PermissionsEnum.VIEW_PRODUCT_MAIN,
                ]}>
                    <Products />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getProductFormRoute(),
        element: (
            <MainScreen title={'Novo Produto'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <ProductForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getProductFormRoute(':uuid'),
        element: (
            <MainScreen title={'Editar Produto'}>
                <RoleGuard roles={[
                    PermissionsEnum.HANDLE_PRODUCTS,
                    PermissionsEnum.HANDLE_PRODUCT_FISCAL,
                    PermissionsEnum.HANDLE_PRODUCT_MAIN,
                    PermissionsEnum.HANDLE_PRODUCT_PRICING,
                    PermissionsEnum.VIEW_PRODUCT_FISCAL,
                    PermissionsEnum.VIEW_PRODUCT_PRICING,
                    PermissionsEnum.VIEW_PRODUCT_MAIN
                ]}>
                    <ProductForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getExchangesRoute(),
        element: (
            <MainScreen title={'Devoluções'}>
                <RoleGuard roles={[PermissionsEnum.CREATE_EXCHANGES]}>
                    <Exchanges />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getExchangesFormRoute(),
        element: (
            <MainScreen title={'Nova Devolução'}>
                <RoleGuard roles={[PermissionsEnum.CREATE_EXCHANGES]}>
                    <ExchangesForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getExchangesFormRoute(':uuid'),
        element: (
            <MainScreen title={'Editar Devolução'}>
                <RoleGuard roles={[PermissionsEnum.CREATE_EXCHANGES]}>
                    <ExchangesForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getSalesRoute(),
        element: (
            <MainScreen title={'Vendas'}>
                <RoleGuard roles={[PermissionsEnum.CREATE_SALES]}>
                    <Sales />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getGeneralCashClosingPage(),
        element: (
            <MainScreen title={'Painel geral'}>
                <RoleGuard roles={[PermissionsEnum.CREATE_SALES]}>
                    <GeneralCashClosingPage />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getPanelPage(),
        element: (
            <MainScreen title={'Painel'}>
                <RoleGuard roles={[PermissionsEnum.CREATE_SALES]}>
                    <MovementPage />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getCashClosingPage(),
        element: (
            <MainScreen title={'Fechamento de Caixa'}>
                <RoleGuard roles={[PermissionsEnum.CREATE_SALES]}>
                    <CashClosingPage />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getCashClosingPage(':uuid'),
        element: (
            <MainScreen title={'Editar Fechamento de Caixa'}>
                <RoleGuard roles={[PermissionsEnum.CREATE_SALES]}>
                    <CashClosingPage />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getSalesFormRoute(),
        element: (
            <MainScreen title={'Caixa'}>
                <RoleGuard roles={[PermissionsEnum.CREATE_SALES]}>
                    <SalesForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getSalesFormRoute(),
        element: (
            <MainScreen title={'Caixa'}>
                <RoleGuard roles={[PermissionsEnum.CREATE_SALES]}>
                    <SalesForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getSalesDetailFormRoute(':uuid'),
        element: (
            <MainScreen title={'Visualizar Venda'}>
                <RoleGuard roles={[PermissionsEnum.CREATE_SALES]}>
                    <SalesDetailForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getBillsToReceiveRoute(),
        element: (
            <MainScreen title={'Contas a receber'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <BillsToTeceive />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getBillsToPayRoute(),
        element: (
            <MainScreen title={'Contas a pagar'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <BillsToPay />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getBillsToReceiveFormRoute(),
        element: (
            <MainScreen title={'Nova Conta a Receber'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <BillsToReceiveForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getBillsToReceiveFormRoute(':uuid'),
        element: (
            <MainScreen title={'Editar Conta a Receber'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <BillsToReceiveForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getBillsToPayFormRoute(),
        element: (
            <MainScreen title={'Nova Conta a Pagar'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <BillsToPayForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getBillsToPayFormRoute(':uuid'),
        element: (
            <MainScreen title={'Editar Conta a Pagar'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <BillsToPayForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getAccountsFormRoute(':uuid'),
        element: (
            <MainScreen title={'Editar Conta Bancária'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <AccountsForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getAccountsFormRoute(),
        element: (
            <MainScreen title={'Nova Conta Bancária'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <AccountsForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getAccountsRoute(),
        element: (
            <MainScreen title={'Contas Bancárias'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <Accounts />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getPaymentsTypesRoute(),
        element: (
            <MainScreen title={'Tipos de pagamento'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <PaymentsTypes />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getPaymentTypeFormRoute(),
        element: (
            <MainScreen title={'Novo Tipo de pagamento'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <PaymentTypeForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getPaymentTypeFormRoute(':uuid'),
        element: (
            <MainScreen title={'Editar Tipo de pagamento'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <PaymentTypeForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getMachinesRoute(),
        element: (
            <MainScreen title={'Máquinas de pagamento'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <Machines />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getMachineFormRoute(),
        element: (
            <MainScreen title={'Nova Máquina de pagamento'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <MachineForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getMachineFormRoute(':uuid'),
        element: (
            <MainScreen title={'Editar Máquina de pagamento'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <MachineForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getPurchaseSuggestionRoute(),
        element: (
            <MainScreen title={'Sugestão de Pedido'}>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_SUGGESTIONS]}
                >
                    <PurchaseSuggestion />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getSuggestionDetailRoute(':id'),
        element: (
            <MainScreen title={'Detalhes da Sugestão de Pedido'}>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_SUGGESTIONS]}
                >
                    <SuggestionDetail />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getProductLogsRoute(':uuid'),
        element: (
            <MainScreen title={'Logs de Produto'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <ProductLogs />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getOrdersRoute(),
        element: (
            <MainScreen title={'Acompanhamento de Pedidos'}>
                <RoleGuard roles={[
                    PermissionsEnum.CHECK_ORDER,
                    PermissionsEnum.EDIT_ORDER,
                    PermissionsEnum.CONFIRM_ORDER,
                    PermissionsEnum.BILLING_ORDER,
                    PermissionsEnum.HANDLE_RECEIVE_ORDER,
                ]}>
                    <Orders />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getOrderDetailRoute(':id'),
        element: (
            <MainScreen title={'Detalhes do Pedido'}>
                <RoleGuard roles={[
                    PermissionsEnum.CHECK_ORDER,
                    PermissionsEnum.EDIT_ORDER,
                    PermissionsEnum.CONFIRM_ORDER,
                    PermissionsEnum.BILLING_ORDER,
                    PermissionsEnum.HANDLE_RECEIVE_ORDER,
                ]}>
                    <OrderDetail />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getTagsRoute(),
        element: (
            <MainScreen title={'Impressão de Etiquetas'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <Tags />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getCancelReasonRoute(),
        element: (
            <MainScreen title={'Motivos de Cancelamento'}>
                <RoleGuard>
                    <CancelReason />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getCancelReasonFormRoute(),
        element: (
            <MainScreen title={'Novo Motivo de Cancelamento'}>
                <RoleGuard>
                    <CancelReasonForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getCancelReasonFormRoute(':uuid'),
        element: (
            <MainScreen title={'Editar Motivo de Cancelamento'}>
                <RoleGuard>
                    <CancelReasonForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getOrderListRoute(),
        element: (
            <MainScreen title={'Pedidos'}>
                <RoleGuard roles={[
                    PermissionsEnum.CHECK_ORDER,
                    PermissionsEnum.EDIT_ORDER,
                    PermissionsEnum.CONFIRM_ORDER,
                    PermissionsEnum.BILLING_ORDER,
                    PermissionsEnum.HANDLE_RECEIVE_ORDER,
                ]}>
                    <OrderList />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getOrderBillingRoute(),
        element: (
            <MainScreen title={'Novo Faturamento'}>
                <RoleGuard roles={[PermissionsEnum.BILLING_ORDER]}>
                    <OrderBilling />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getOrderBillingRoute(':orderId', ':billingId'),
        element: (
            <MainScreen title={'Editar Faturamento'}>
                <RoleGuard roles={[PermissionsEnum.BILLING_ORDER]}>
                    <OrderBilling />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getOrderDetailReceiveRoute(':orderId', ':receiveId'),
        element: (
            <MainScreen title={'Editar Recebimento'}>
                <RoleGuard roles={[PermissionsEnum.BILLING_ORDER]}>
                    <OrderDetailReceive />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getOrderDocumentsRoute(),
        element: (
            <MainScreen title={'Documentos'}>
                <RoleGuard roles={[PermissionsEnum.BILLING_ORDER]}>
                    <OrderDocuments />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getStockRoute(),
        element: (
            <MainScreen title={'Gerenciar Estoque'}>
                <RoleGuard>
                    <Stock />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getStockBulkRoute(),
        element: (
            <MainScreen title={'Gerenciar Estoque em Massa'}>
                <RoleGuard>
                    <StockBulk />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getStockProductLogRoute(':uuid'),
        element: (
            <MainScreen title={'Log do Estoque de Produto'}>
                <RoleGuard roles={[PermissionsEnum.CREATE_STOCK_CHECK, PermissionsEnum.VALIDATE_STOCK_CHECK]}>
                    <StockProductLog />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getStockCheckRoute(),
        element: (
            <MainScreen title={'Minhas Conferências'}>
                <RoleGuard roles={[PermissionsEnum.CREATE_STOCK_CHECK]}>
                    <StockCheck />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getAdminStockCheckRoute(),
        element: (
            <MainScreen title={'Gerenciar Conferências'}>
                <RoleGuard roles={[PermissionsEnum.VALIDATE_STOCK_CHECK]}>
                    <AdminStockCheck />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getStockCheckFormRoute(':id', ':itemUUID'),
        element: (
            <MainScreen title={'Conferir Produtos'}>
                <RoleGuard roles={[PermissionsEnum.CREATE_STOCK_CHECK]}>
                    <StockCheckForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getAdminStockCheckDetailRoute(':id'),
        element: (
            <MainScreen title={'Detalhes da Conferência'}>
                <RoleGuard roles={[PermissionsEnum.VALIDATE_STOCK_CHECK]}>
                    <AdminStockCheckDetail />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getStockCheckDetailRoute(':id'),
        element: (
            <MainScreen title={'Detalhes da Conferência'}>
                <RoleGuard roles={[PermissionsEnum.CREATE_STOCK_CHECK]}>
                    <StockCheckDetail />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getDivergenceReasonRoute(),
        element: (
            <MainScreen title={'Motivos de Divergência'}>
                <RoleGuard>
                    <DivergenceReason />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getDivergenceReasonFormRoute(),
        element: (
            <MainScreen title={'Novo Motivo de Divergência'}>
                <RoleGuard>
                    <DivergenceReasonForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getDivergenceReasonFormRoute(':uuid'),
        element: (
            <MainScreen title={'Editar Motivo de Divergência'}>
                <RoleGuard>
                    <DivergenceReasonForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getCustomersRoute(),
        element: (
            <MainScreen title={'Clientes'}>
                <RoleGuard roles={[
                    PermissionsEnum.HANDLE_CUSTOMERS,
                    PermissionsEnum.CREATE_SALES,
                    PermissionsEnum.CREATE_EXCHANGES,
                ]}>
                    <Customers />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getCustomerFormRoute(':uuid'),
        element: (
            <MainScreen title={'Editar Cliente'}>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_USERS]}
                >
                    <CustomerForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getDeliveryAreaRoute(),
        element: (
            <MainScreen title={'Áreas de Entrega'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_VIRTUAL_STORE]}>
                    <DeliveryArea />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getDeliveryAreaFormRoute(':uuid'),
        element: (
            <MainScreen title={'Editar Área de Entrega'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_VIRTUAL_STORE]}>
                    <DeliveryAreaForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getVirtualOrderRoute(),
        element: (
            <MainScreen title={'Acompanhamento de pedido'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_VIRTUAL_STORE]}>
                    <VirtualOrder />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getVirtualOrderListRoute(),
        element: (
            <MainScreen title={'Listagem de pedidos'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_VIRTUAL_STORE]}>
                    <VirtualOrderList />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getVirtualOrderDetailRoute(':id'),
        element: (
            <MainScreen title={'Detalhes do Pedido'}>
                <RoleGuard roles={[
                    PermissionsEnum.CHECK_ORDER,
                    PermissionsEnum.EDIT_ORDER,
                    PermissionsEnum.CONFIRM_ORDER,
                    PermissionsEnum.BILLING_ORDER,
                    PermissionsEnum.HANDLE_RECEIVE_ORDER,
                ]}>
                    <VirtualOrderDetail />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getBannersRoute(),
        element: (
            <MainScreen title={'Banners'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_VIRTUAL_STORE]}>
                    <Banners />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getNewsletterRoute(),
        element: (
            <MainScreen title={'Newsletters'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_VIRTUAL_STORE]}>
                    <Newsletter />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getFeedbacksRoute(),
        element: (
            <MainScreen title={'Feedbacks'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_VIRTUAL_STORE]}>
                    <Feedbacks />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getParametrosRoute(),
        element: (
            <MainScreen title={'Parâmetros'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_VIRTUAL_STORE]}>
                    <Parametros />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getMenuPrincipalRoute(),
        element: (
            <MainScreen title={'Menu Principal'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_VIRTUAL_STORE]}>
                    <MenuPrincipal />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getCuponsRoute(),
        element: (
            <MainScreen title={'Cupons'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_CAMPAIGNS]}>
                    <Cupons />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getCouponFormRoute(),
        element: (
            <MainScreen title={'Novo Cupom'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <CumpomForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getCouponFormRoute(':uuid'),
        element: (
            <MainScreen title={'Editar Cupom'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <CumpomForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getRodapeRoute(),
        element: (
            <MainScreen title={'Rodapé'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_VIRTUAL_STORE]}>
                    <Rodape />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getHandleScanProductsRoute(':uuid'),
        element: (
            <MainScreen title={'Receber Produtos'}>
                <RoleGuard roles={[PermissionsEnum.RECEIVE_ORDER]}>
                    <HandleScanProducts />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getOrderReceptionRouterRoute(),
        element: (
            <MainScreen title={'Novo Recebimento'}>
                <RoleGuard roles={[PermissionsEnum.RECEIVE_ORDER]}>
                    <OrderReceptionRouter />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getOrderReceiveRoute(),
        element: (
            <MainScreen title={'Recebimentos'}>
                <RoleGuard roles={[PermissionsEnum.RECEIVE_ORDER]}>
                    <OrderReceive />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getOrderReceiveDetailRoute(':uuid'),
        element: (
            <MainScreen title={'Detalhes do Recebimento'}>
                <RoleGuard roles={[PermissionsEnum.RECEIVE_ORDER]}>
                    <OrderReceiveDetail />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getCampaignRoute(),
        element: (
            <MainScreen title={'Campanhas'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_CAMPAIGNS]}>
                    <Campaign />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getCampaignFormRoute(':uuid'),
        element: (
            <MainScreen title={'Editar Campanha'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_CAMPAIGNS]}>
                    <CampaignForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getCampaignFormRoute(),
        element: (
            <MainScreen title={'Nova Campanha'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_CAMPAIGNS]}>
                    <CampaignForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
];

export default adminRoutes;
